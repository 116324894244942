import { CsvIcon, XlsIcon, ImgIcon } from "../../icons";
import style from "./style.module.scss";
import { ValidateFileSheet, ValidateFileImg } from "./validate";
import Alert from "app/uikit/alert";
import { MIME_FILES } from "app/emun";

export const getAccept = type => {
	switch (type) {
		case "sheet":
			return ".csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
		case "img":
			return "image/png, image/jpeg, image/jpg";
		case "imgs":
			return "image/png, image/jpeg, image/jpg, image/svg+xml";
		default:
			return null;
	}
};

export const getValidator = (type, size, accept, text) => {
	switch (type) {
		case "sheet":
			return ValidateFileSheet(size, accept, text);
		case "img":
			return ValidateFileImg(size, accept, text);
		case "imgs":
			return ValidateFileImg(size, MIME_FILES.imgs, 'png | jpeg | jpg | svg');
		default:
			return null;
	}
};

export const getTextFiles = type => {
	switch (type) {
		case "sheet":
			return ".csv .xls.";
		case "img":
			return ".png .jpeg .jpg.";
		case "imgs":
			return ".png .jpeg .jpg. .svg";
		default:
			return null;
	}
};

export const getFiles = (Files, removeAll) =>
	Files.map((file, i) => {
		const { name } = file;
		return (
			<span className={style.file} key={name}>
				<span className={`small-text ${style.file_text}`}>
					{name.endsWith(".csv") && <CsvIcon />}
					{(name.endsWith(".xls") || name.endsWith(".xlsx")) && <XlsIcon />}
					{(name.endsWith(".jpg") || name.endsWith(".jpeg") || name.endsWith(".svg")  || name.endsWith(".png")) && <ImgIcon />}
					<span className={style.file_name}>{name}</span>
				</span>
				<span className={`sub-text ${style.size}`}>{file.size / 1000} kb</span>
				<span className={style.close} onClick={removeAll} />
			</span>
		);
	});

export const getRejectionsFiles = FilesRejections =>
	FilesRejections.map(({ errors }, i) => (
		<div key={i}>
			{errors
				.filter(el => el.code.slice(0, 6) === "custom")
				.map(e => (
					<Alert key={e.code} type="error" text={e.message} />
				))}
		</div>
	));
