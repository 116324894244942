import React from "react";
import {Edit, Delete} from 'app/icons'

const menuTableFile = (id,onChange)=>{
    return [
        {
            key: 'edit',
            id: id,
            onChange: onChange,
            render: ()=>(<div className="with-icon"><Edit/> Edit</div>)
        },
        {
            key: 'delete',
            id: id,
            onChange: onChange,
            render: ()=>(<div className="with-icon highlighted"><Delete/> Delete</div>)
        }
    ]
};

export default menuTableFile;
