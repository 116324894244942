import React from "react";
import Button from "app/uikit/button/active-button";
import { SuccessUploadSheet } from "app/icons";

const SuccessModal = ({ onClose, 
	added_to_repository_count,
	added_to_repository_percent,
	not_updated_count,
	not_updated_percent,
	updated_in_repository_count,
	updated_in_repository_percent }) => {
	return (
		<div className="text-centered">
			<div className="verification-icon m-y--16">
				<SuccessUploadSheet />
			</div>
			<h2 className="title-subheading title-subheading_bold m-b--8">
				Client list information has been loaded successfully.
			</h2>
			<p className="small-text">Added to repository: <strong>{added_to_repository_count}</strong> ({added_to_repository_percent}%)</p>
			<p className="small-text">Updated in repository: <strong>{updated_in_repository_count}</strong> ({updated_in_repository_percent}%)</p>
			<p className="small-text">Not updated (duplicates): <strong>{not_updated_count}</strong> ({not_updated_percent}%)</p>
			<div className="modal-footer modal-footer_centered">
				<Button onClick={onClose} size="medium" colorVariant="gradient">
					Continue
				</Button>
			</div>
		</div>
	);
};

export default SuccessModal;