import React, { useEffect } from "react"; 
import { useFormik } from "formik";
import Modal from "app/components/modal";
import validationSchema from "./validationSchema";
import ButtonActive from "app/uikit/button/active-button";
import Input from "app/uikit/input";
import Button from "app/uikit/button/active-button";
import Error from "app/uikit/error";
import { handlerErrors } from "app/helper/handlerErrors";

const AddNewUser = ({ onClose, open, loading, data = {}, errors, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			name: data?.full_name,
			email: data?.email,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: onSubmit
	});

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	return (
		<div>
			<Modal title="Editing user" onClose={onClose} resetForm={formik.resetForm} open={open}>
				<form onSubmit={formik.handleSubmit} noValidate>
					<Input fullWidth name="name" label="Full name" form={formik} required />
					<Input fullWidth name="email" label="Email" type="email" form={formik} required />

					<Error error={errors} />

					<div className="modal-footer">
						<Button onClick={onClose} size="medium" colorVariant="ghost">
							Cancel
						</Button>
						<ButtonActive
							type="submit"
							loading={loading}
							size="medium"
							colorVariant="gradient"
						>
							Save changes
						</ButtonActive>
					</div>
				</form>
			</Modal>
		</div>
	);
};

export default AddNewUser;