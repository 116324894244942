import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";

const PaginationElem = props => {
	const { onChange = () => {}, pagination: { page, total, limit } = {} } = props;

	const [currentPage, setCurrentPage] = useState(1);

	const handleChange = (event, value) => {
		setCurrentPage(value);
		onChange({page:value});
	};

	const count = Math.ceil(total / limit);

	useEffect(() => {
		setCurrentPage(page);
	}, [page]);

	return <Pagination page={currentPage} count={count || 1} onChange={handleChange} />;
};

export default PaginationElem;