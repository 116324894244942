import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import localStore from "app/helper/local-store";
import PATHS from "PATHS";
import {
	Support,
	Logo,
	// Help,
	// DarkMode,
	SettingsIcon,
	People,
	Integrations,
	Creatives,
	Campaigns,
	Reports,
	// Teams,
	UsersIcon,
	CategoriesIcon,
	InfoDashboardIcon,
	// ContentGeneration,
	EmployeesIcon
} from "../../icons";
import { PERMISSIONS } from "app/emun/permissions";
import ModalSupport from "./components/spport-modal";
import config from "app/config";
import style from "./style.module.scss";

const VERSION = process.env.REACT_APP_VERSION;
const MENU_ITEMS = [
	{
		icon: <People />,
		caption: "Audiences",
		permission: PERMISSIONS.account_sheets,
		link: PATHS.cabinet.marketplace,
		names: [PATHS.cabinet.marketplace, PATHS.cabinet.audiences]
	},
	{
		icon: <Integrations />,
		caption: "Integrations",
		link: PATHS.cabinet.integrations,
		permission: PERMISSIONS.account_integrations
	},
	{
		icon: <Creatives />,
		caption: "Creatives",
		link: PATHS.cabinet.creatives,
		permission: PERMISSIONS.account_creatives
	},
	{
		icon: <Campaigns />,
		caption: "Campaigns",
		link: PATHS.cabinet.campaigns,
		permission: PERMISSIONS.account_campains
	},
	{
		icon: <Reports />,
		caption: "Reports",
		link: PATHS.cabinet.reports,
		permission: PERMISSIONS.account_reports
	},
	{
		icon: <UsersIcon />,
		caption: "Users",
		link: PATHS.adminPanel.users,
		permission: PERMISSIONS.admin_users
	},
	{
		icon: <EmployeesIcon />,
		caption: "Employees",
		link: PATHS.adminPanel.employees,
		permission: PERMISSIONS.admin_sheets
	},
	{
		icon: <SettingsIcon />,
		caption: "APP settings",
		link: PATHS.adminPanel.settings,
		permission: PERMISSIONS.admin_general_settings
	},
	{
		icon: <CategoriesIcon />,
		caption: "Audience Categories",
		link: PATHS.adminPanel.categoriesAudience,
		permission: PERMISSIONS.admin_general_settings
	},
	{
		icon: <InfoDashboardIcon />,
		caption: "Info dashboard",
		link: PATHS.adminPanel.infoDashboard,
		permission: PERMISSIONS.admin_statistic
	}
	// {
	// 	icon: <ContentGeneration />,
	// 	caption: "Content generation",
	// 	link: PATHS.cabinet.contentGenetarion,
	// },
	// {
	// 	icon: <Teams />,
	// 	caption: "Teams",
	// 	link: PATHS.cabinet.teams,
	// 	permission:
	// }
];

const BOTTOM_ITEMS = [
	{
		icon: <Support />,
		caption: "Support",
		action: "support",
		link: ""
	}
];

const Aside = () => {
	const {
		user: { permissions }
	} = useSelector(state => state.user);
	const token = localStore.getAccessToken();
	const [modalSupportOpen, setModalSupportOpen] = useState(false);

	const onOpenModalSupport = () => {
		setModalSupportOpen(true);
	};

	const onCloseModalSupport = () => {
		setModalSupportOpen(false);
	};

	const onCheckAction = action => {
		switch (action) {
			case "support":
				onOpenModalSupport();
				break;
			default:
				return;
		}
	};

	return (
		<>
			<aside className="page-aside">
				<div className="logo p-x--24 p-b--12 p-x--24">
					<Link className={style.logo_link} to={config.defaultPage.link}>
						<Logo/>
					</Link>
				</div>

				<ul className="page-aside-list p-y--24 m-b--auto">
					{MENU_ITEMS.filter(item => token && (permissions[item.permission] || item.permission === undefined)).map(item => (
						<li key={item.caption}>
							<NavLink to={item.link}
							isActive={(match, location) => {
								const matchedBy = Array.isArray(item.names) && item.names.some(el => location.pathname === el) || item?.names === location.pathname;
								if (!match && !matchedBy) return false;
								return true;
							}}
							className="page-aside-list__item relative with-icon">
								<>{item.icon}</>
								{item.caption}
							</NavLink>
						</li>
					))}
				</ul>
				<ul className="page-aside-list p-y--24 m-t--auto">
					{BOTTOM_ITEMS.map(item => (
						<li key={item.caption}>
							{(item?.action && (
								<button
									onClick={() => {
										onCheckAction(item.action);
									}}
									className="page-aside-list__item relative with-icon"
								>
									<>{item.icon}</>
									{item.caption}
								</button>
							)) || (
								<Link to="#" className="page-aside-list__item relative with-icon">
									<>{item.icon}</>
									{item.caption}
								</Link>
							)}
						</li>
					))}
				</ul>

				<span className="p-x--24">Release version: {VERSION}</span>
			</aside>
			<ModalSupport onClose={onCloseModalSupport} open={modalSupportOpen} />
		</>
	);
};

export default Aside;