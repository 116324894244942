import React from "react";
import { Edit, Delete } from "app/icons";
import { PauseIconCircle, PlayIconCircle, RestartIconSmall } from "app/icons";

const item = (id, onChange, key, icon) => ({
	key: key,
	id: id,
	onChange: onChange,
	render: () => (
		<div className="with-icon">
			{icon} {key}
		</div>
	)
});

const menuTableFile = (id, onChange, record) => {
	let arr = [
		{
			key: "Edit",
			id: id,
			onChange: onChange,
			render: () => (
				<div className="with-icon">
					<Edit /> Edit
				</div>
			)
		}
	];
	switch (record?.status?.id) {
		case 1:
		case 5:
			arr.push(item(id, onChange, "Pause", <PauseIconCircle color="#64738C" />));
			break;
		case 4:
		case 2:
			if(record?.is_can_start){
				arr.push(item(id, onChange, "Start", <PlayIconCircle color="#64738C" />));
			}
			break;
		case 3:
			if(record?.is_can_restart){
				arr.push(item(id, onChange, "Relaunch", <RestartIconSmall color="#64738C" />));
			}
			break;
		default:
			break;
	}
	arr.push({
		key: "delete",
		id: id,
		onChange: onChange,
		render: () => (
			<div className="with-icon highlighted">
				<Delete /> Delete
			</div>
		)
	});

	return arr;
};

export default menuTableFile;
