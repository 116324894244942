import React from "react";
import { Link } from "react-router-dom";
import Layout from "app/components/includs/layout";
import { ErrorServer } from "app/icons";
import PATHS from "PATHS";
import Button from "app/uikit/button/active-button";

const ErrorServerUnavailable = () => {
	return (
		<Layout>
			<div className="login-box text-centered">
				<div className="verification-icon m-b--24">
					<ErrorServer />
				</div>
				<h1 className="title-main m-b--16">Something went wrong</h1>
				<p className="m-b--24 main-text">Sorry, the server is temporarily unavailable</p>
				<div className="m-b--24">
					<Button size="large" colorVariant="gradient" fullWidth>Try again</Button>
				</div>
				<Link to={PATHS.auth.login} className="orange-link">Back to Login</Link>
			</div>
		</Layout>
	);
};

export default ErrorServerUnavailable;
