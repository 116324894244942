import React from "react";
import ChecboxBase from "./checkbox-base";
import ChecboxLabel from "./checkbox-label";

const CheckboxDefault = props => {
	const { label, name, form, formCount, handleChange, validateField, ...rest } = props;
	const handleChangeField = async e =>{
		const value = e.target.checked;
		if(handleChange){
			await handleChange(value,name,e)
		}else{
			await form.setFieldValue(name,value);
		}
		if(validateField){
			await form.validateField(name);
		}
		form.setStatus({
			...form.status,
			dirtyFields:{
				...form.status?.dirtyFields,
				[name]: true
			}
		})
	}
	let propsForm = {
		name: name,
		checked: !!form?.values[name],
		onChange: handleChangeField,
		error: form?.touched[name] && Boolean(form?.errors[name]),
		helperText: form?.touched[name] && form?.errors[name]
	};

	if (label && form) {
		return <ChecboxLabel name={name} label={label} {...propsForm} {...rest} />;
	} else if (form) {
		return <ChecboxBase {...propsForm} {...rest} />;
	} else if (label) {
		return <ChecboxLabel name={name} label={label} {...rest} />;
	} else {
		return <ChecboxBase name={name} {...rest} />;
	}
};

export default CheckboxDefault;
