import yup from "app/yup";
import directory from "app/directory/errors";
import { ErrorBudget } from "app/errors-shcema";
import moment from "moment";

const dir = directory.en;
const today = moment().format("YYYY-MM-DD");

const validationSchema = isDaily=>{
	let  obj ={
		budget: ErrorBudget,
		start_datetime: yup.date().min(today, "Start date shouldn’t be in the past.").required(dir.required),
	};
	if(!isDaily){
		obj.end_datetime = yup
			.date()
			.when(
				"start_datetime",
				(start_datetime, schema) =>
					start_datetime && schema.min(start_datetime, "End date shouldn't be earlier then Start date.")
			)
			.test("min-date-start", "End date shouldn’t be in the past.", value => !moment(today).isAfter(value))
			.required(dir.required)
	}
	return yup.object(obj);
}

export default validationSchema;