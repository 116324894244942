import React, { Suspense, useEffect } from "react";
import { ConnectedRouter } from "connected-react-router/immutable";
import { LastLocationProvider } from "react-router-last-location";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import history from "./history";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import NotFoundRouter from "./notFoundRouter";
import { PERMISSIONS } from "app/emun/permissions";
import PATHS from "PATHS";
import actionHandBook from "app/store/handBooks/actions";
import actionGeneralSettings from "app/store/appSettings/actions";

//auth
import Login from "app/screens/auth/login";
import Registration from "app/screens/auth/registration";
import EmailVerification from "app/screens/auth/emailVerification";
import NewPassword from "app/screens/auth/newPassword";
import ErrorSendPwEmail from "app/screens/auth/errorSendPwResetEmail";
import PasswordRecovery from "app/screens/auth/passwordRecovery";
import VerificationCode from "app/screens/auth/verificationCode";
import ErrorServerUnavailable from "app/screens/auth/errorServerUnavailable";

//account
import Profile from "app/screens/account/profile";

//cabinet
import People from "app/screens/cabinet/people";

import Integrations from "app/screens/cabinet/integrations";
import EditFacebookIntegrations from "app/screens/cabinet/integrations/integrations-page/edit-fb-integrations";

import ScreenCreatives from "../screens/cabinet/creatives";
import CreateCreative from "../screens/cabinet/creatives/create-creative";

import ScreenCampaigns from "../screens/cabinet/campaigns";
import CreateCampaign from "../screens/cabinet/campaigns/create";
import EditCampaign from "../screens/cabinet/campaigns/edit";

import Reports from "../screens/cabinet/reports";

//admin
import Employees from "app/screens/admin/employees";
import ScreenUsers from "../screens/admin/users";
import ProfileUser from "app/screens/admin/users/account-profile";
import CreativesUser from "app/screens/admin/users/account-creatives";
import AudiencesUser from "app/screens/admin/users/account-audiences";
import CampaingUser from "app/screens/admin/users/account-campaing";
import ReportsUser from "app/screens/admin/users/account-reports";
import IntegrationsUser from "app/screens/admin/users/account-integrations";
import ContentGeneration from "app/screens/admin/content-generaion";
import AppSettings from "app/screens/admin/settings";
import CategoriesAudience from "app/screens/admin/categories-audience";
import InfoDashboard from "app/screens/admin/info-dashboard";


import PageNotFound from "app/screens/404";
import {SERVER_NAME} from "app/emun"

import userflow from 'userflow.js'


const PageLoading = () => {
	return <div>loading</div>;
};

const LayoutRouting = () => {
	const {
		user: { permissions, email, name, id }
	} = useSelector(state => state.user);
	const dispatch = useDispatch();
	useEffect(() => {
		if (Object.keys(permissions).length && email) {
			dispatch(actionHandBook.getHandBooks());
			dispatch(actionGeneralSettings.getGeneralSettings());
		}
		if(email){
			try{
				if(process.env.REACT_APP_SERVER_NAME === SERVER_NAME.development){
					return;
				}
				if(process.env.REACT_APP_SERVER_NAME === SERVER_NAME.production){
					userflow.init('ct_qlh3ilmkmvg2jkabl7yczjsaoa');
				}
				if(process.env.REACT_APP_SERVER_NAME === SERVER_NAME.stage){
					userflow.init('ct_263nzkd5ufexfbrokf5lgn2gvm');
				}
				userflow.identify(id, {
					name: name,
					email: email,
					signed_up_at: new Date().toISOString()
				})
			}catch(e){
				console.error(e,'userflow');
			}
		}
	}, [permissions, dispatch, email]);

	return (
		<Suspense fallback={<PageLoading />}>
			<ConnectedRouter history={history}>
				<LastLocationProvider>
					<Switch>
						<PublicRoute exact path={PATHS.auth.login} Component={Login} />
						<PublicRoute exact path={PATHS.auth.registration} Component={Registration} />
						<PublicRoute exact path={PATHS.auth.emailVerification} Component={EmailVerification} />
						<PublicRoute exact path={PATHS.auth.passwordRecovery} Component={PasswordRecovery} />
						<PublicRoute exact path={PATHS.auth.verificationCode} Component={VerificationCode} />
						<PublicRoute exact path={PATHS.auth.newPassword} Component={NewPassword} />
						<PublicRoute exact path={PATHS.auth.errorSendPwEmail} Component={ErrorSendPwEmail} />
						<PublicRoute
							permissions={permissions}
							exact
							path={PATHS.auth.errorServerUnavailable}
							Component={ErrorServerUnavailable}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_integrations}
							path={PATHS.cabinet.integrations}
							Component={Integrations}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_sheets}
							path={PATHS.cabinet.audiences}
							Component={People}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_sheets}
							path={PATHS.cabinet.marketplace}
							Component={People}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_integrations}
							path={PATHS.cabinet.editFbIntegrations}
							Component={EditFacebookIntegrations}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_creatives}
							path={PATHS.cabinet.creatives}
							Component={ScreenCreatives}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_creatives}
							path={PATHS.cabinet.createCreative}
							Component={CreateCreative}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_creatives}
							path={PATHS.cabinet.creativeEdit}
							Component={CreateCreative}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_campains}
							path={PATHS.cabinet.campaigns}
							Component={ScreenCampaigns}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_campains}
							path={PATHS.cabinet.createCampaing}
							Component={CreateCampaign}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_campains}
							path={PATHS.cabinet.campaingEdit}
							Component={EditCampaign}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_campains}
							path={PATHS.cabinet.createCampaingSteps}
							Component={CreateCampaign}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.account_reports}
							path={PATHS.cabinet.reports}
							Component={Reports}
						/>
						<PrivateRoute exact path={PATHS.account.profile} Component={Profile} />
						{/* <PrivateRoute exact path={PATHS.account.subsciption} Component={PageInProggress} /> */}
						{/* <PrivateRoute exact path={PATHS.account.payments} Component={PageInProggress} /> */}
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.users}
							Component={ScreenUsers}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.userAccount}
							Component={ProfileUser}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.userCreative}
							Component={CreativesUser}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.userAudiences}
							Component={AudiencesUser}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.userCampaigns}
							Component={CampaingUser}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.userIntegrations}
							Component={IntegrationsUser}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_users}
							path={PATHS.adminPanel.userReport}
							Component={ReportsUser}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_sheets}
							path={PATHS.adminPanel.employees}
							Component={Employees}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_sheets}
							path={PATHS.adminPanel.settings}
							Component={AppSettings}
						/>
						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_contact_categories}
							path={PATHS.adminPanel.categoriesAudience}
							Component={CategoriesAudience}
						/>

						<PrivateRoute
							exact
							permissions={permissions}
							permission={PERMISSIONS.admin_statistic}
							path={PATHS.adminPanel.infoDashboard}
							Component={InfoDashboard}
						/>

						<Redirect from="/" exact to={PATHS.cabinet.integrations} />
						<PrivateRoute exact path={PATHS.cabinet.contentGenetarion} Component={ContentGeneration} />
						<Route exact path={PATHS.pageNotFound} component={PageNotFound} />;
						<NotFoundRouter />
					</Switch>
				</LastLocationProvider>
			</ConnectedRouter>
		</Suspense>
	);
};

export default LayoutRouting;
