import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Loader from "app/components/loader";
import UserLayout from "app/components/includs/user-layout";
import Input from "app/uikit/input";
import DropZone from "app/components/dropzone";
import Select from "app/uikit/select";
import Button from "app/uikit/button/active-button";
import CreativePost from "./creative-post";
import { toBase64, getBase64Content, urlfileToObject, getParamsImage } from "app/helper/helper.files";
import { handlerErrors } from "app/helper/handlerErrors";
import { PLATFORMS } from "app/emun/platforms";
import { MagicWand } from "app/icons";
import validationSchema, { checkWarnings } from "./validationSchema";
import Error from "app/uikit/error";
import GenerateContent from "./generate-content";
import actions from "app/store/creatives/actions";
import style from "./style.module.scss";
import PATHS from "PATHS";


const AddCreative = props => {
	const { createingCreative, createCreativeError, currentCreative, creatives } = useSelector(state => state.creatives);
	const { creative_template_types } = useSelector(state => state.handBooks);
	const [bannerImg, setBannerImg] = useState(null);
	const [initialImg, setInitialImg] = useState(null);
	const [loading, setLoading] = useState(false);
	const [warnings, setWarnings] = useState({});
	const [generateContentOpen, setGenerateContentOpen] = useState(false);
	const [loadingBanner, setLoadingBanner] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		match: { params }
	} = props;

	const isEdit = !!params.id;

	const currentItem = isEdit && creatives.items.find(el => el.id === +params.id);

	const callToActionOptions = useMemo(() => {
		const find = creative_template_types?.find(el => el?.integration_type?.system_name === PLATFORMS.facebook_ads);
		if (!find) return [];
		const findCallToAction = find?.fields?.find(el => el.system_name === "call_to_action");
		if (!findCallToAction) return [];
		return findCallToAction?.values?.map(el => ({ text: el.name, value: el.id }));
	}, [creative_template_types]);

	useEffect(() => {
		if ((!creatives.items.length || !currentItem) && params.id) {
			dispatch(actions.getCreative(params.id));
			setLoading(true);
		}
		return () => {
			dispatch(actions.resetcreateCreative());
		};
	}, [dispatch]);

	useEffect(() => {
		if (!isEdit) return;
		const url = currentItem?.fields?.banner?.url || currentCreative?.fields?.banner?.url;
		const name = currentItem?.fields?.banner?.name || currentCreative?.fields?.banner?.name;
		if (!url && !name) return;
		urlfileToObject(url, name).then(res => {
			if (res.type === "error") return;
			setInitialImg([res]);
			setLoading(false);
		});
	}, [currentItem?.fields?.banner, currentCreative?.fields?.banner]);

	const toCratives = () => {
		history.push(PATHS.cabinet.creatives);
	};
	const handleSubmit = async values => {
		let data = {
			creative_template_type_system_name: creative_template_types[0].system_name,
			fields: {
				...values,
				banner: {
					name: values?.banner[0]?.name,
					content: await getBase64Content(values?.banner[0]),
					mime: values?.banner[0]?.type
				}
			}
		};
		if (isEdit) {
			return dispatch(actions.editCreative(data, params.id, toCratives));
		}
		dispatch(actions.createCreative(data, toCratives));
	};

	const initialValues = {
		creative_name: (isEdit && (currentItem?.fields?.creative_name || currentCreative?.fields?.creative_name)) || "",
		description: (isEdit && (currentItem?.fields?.description || currentCreative?.fields?.description)) || "",
		banner: initialImg || [],
		headline: (isEdit && (currentItem?.fields?.headline || currentCreative?.fields?.headline)) || "",
		website_url: (isEdit && (currentItem?.fields?.website_url || currentCreative?.fields?.website_url)) || "",
		call_to_action:
			(isEdit && (currentItem?.fields?.call_to_action || currentCreative?.fields?.call_to_action)) || "LEARN_MORE"
	};

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	const checkWarningImg = async file => {
		const params = await getParamsImage(file);
		const arr = [];
		if (params.ratio > 1.91) {
			arr.push("Banner aspect ratio should be in range from 1.91:1 to 1:1.");
		}
		if (params.width < 600 || params.height < 600) {
			arr.push("Minimum Width and Height should be at least 600 pixels each.");
		}

		setWarnings(state => ({ ...state, banner: arr }));
	};

	useEffect(() => {
		if (formik.values.banner.length) {
			checkWarningImg(formik.values.banner[0]);
		} else {
			setWarnings(state => ({ ...state, banner: "" }));
		}
	}, [formik.values.banner]);

	const setBanner = async () => {
		if (!formik.values.banner.length) {
			setBannerImg(null);
		} else {
			setLoadingBanner(true);
			const img = await toBase64(formik.values.banner[0]);
			setBannerImg(img);
			setLoadingBanner(false);
		}
	};

	useEffect(() => {
		setBanner();
	}, [formik.values.banner]);

	useEffect(() => {
		checkWarnings(formik.values, setWarnings);
	}, [formik.values]);

	useEffect(() => {
		handlerErrors(createCreativeError, formik.setErrors);
	}, [createCreativeError, formik.setErrors]);

	const onOpenGenerateContent = () => {
		setGenerateContentOpen(true);
	};
	const onCloseGenerateContent = () => {
		setGenerateContentOpen(false);
	};

	const nameAction = callToActionOptions.find(el => el.value === formik.values.call_to_action)?.text;

	const onChooseContent = text =>{
		if(!text) return;
		formik.setFieldValue('description',text);
		onCloseGenerateContent();
	};
	
	return (
		<>
			<UserLayout headerBackLink={PATHS.cabinet.creatives} headerBackLinkTitle="Creatives">
				<Loader loading={loading} delay>
					<div className={style.template_creation_wrapper}>
						<div className={style.template_creation_col}>
							<div>
								<h2 className="title-h2">Facebook Ads</h2>
								<div className="subtitle-block m-b--16">
									<p className="main-text">Create a new outstanding Facebook creative.</p>
								</div>
								<form onSubmit={formik.handleSubmit}>
									<Input
										warning={warnings.creative_name}
										fullWidth
										name="creative_name"
										type="text"
										label="Creative name"
										form={formik}
										required
									/>
									<Input
										multiline
										rows={3}
										fullWidth
										name="description"
										label="Primary Text"
										labelContent={
											<button type="button" onClick={onOpenGenerateContent} className={style.btn_generate_content}>
												Generate content <MagicWand />
											</button>
										}
										form={formik}
										required
									/>
									<DropZone
										fullWidth
										warning={warnings.banner}
										type="img"
										name="banner"
										form={formik}
										size={20000000}
										label="Banner"
										initialValue={initialImg}
										labelClassName={style.template_dropzone_label}
										required
									/>
									<Input warning={warnings.headline} fullWidth name="headline" type="text" label="Headline" form={formik} />
									<Input fullWidth name="website_url" type="text" label="Website URL" required form={formik} />
									<Select
										fullWidth
										list={callToActionOptions}
										className="m-b--24"
										name="call_to_action"
										label="Call to action"
										form={formik}
										anchorOrigin={{ vertical: "top" }}
										MenuProps={{ transformOrigin: { vertical: "bottom", horizontal: "left" }, class: "MuiPopover-paper-top" }}
										required
									/>
									<Error error={createCreativeError} />
									<Button type="submit" loading={createingCreative} className="button button_gradient button_medium">
										Save creative
									</Button>
								</form>
							</div>
						</div>
						<CreativePost
							loadingImg={loadingBanner}
							className={style.template_creation_example}
							webSite={formik.values.website_url}
							banner={bannerImg}
							description={formik.values.description}
							headline={formik.values.headline}
							nameAction={nameAction}
						/>
					</div>
				</Loader>
			</UserLayout>
			<GenerateContent onChoose={onChooseContent} open={generateContentOpen} onClose={onCloseGenerateContent} />
		</>
	);
};
export default withRouter(AddCreative);