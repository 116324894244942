export default {
	GET_AUDIENCES_REQUEST: "GET_AUDIENCES_REQUEST",
	GET_AUDIENCES_SUCCESS: "GET_AUDIENCES_SUCCESS",
	GET_AUDIENCES_FAILURE: "GET_AUDIENCES_FAILURE",

	GET_MARKETPLACE_REQUEST: "GET_MARKETPLACE_REQUEST",
	GET_MARKETPLACE_SUCCESS: "GET_MARKETPLACE_SUCCESS",
	GET_MARKETPLACE_FAILURE: "GET_MARKETPLACE_FAILURE",

	FETCH_DELETE_AUDIENCES_REQUEST: "FETCH_DELETE_AUDIENCES_REQUEST",
	FETCH_DELETE_AUDIENCES_SUCCESS: "FETCH_DELETE_AUDIENCES_SUCCESS",
	FETCH_DELETE_AUDIENCES_FAILURE: "FETCH_DELETE_AUDIENCES_FAILURE",

	FETCH_COMPOSE_AUDIENCE_REQUEST: "FETCH_COMPOSE_AUDIENCE_REQUEST",
	FETCH_COMPOSE_AUDIENCE_SUCCESS: "FETCH_COMPOSE_AUDIENCE_SUCCESS",
	FETCH_COMPOSE_AUDIENCE_FAILURE: "FETCH_COMPOSE_AUDIENCE_FAILURE",

	// FETCH_EDIT_AUDIENCE_REQUEST: "FETCH_EDIT_AUDIENCE_REQUEST",
	// FETCH_EDIT_AUDIENCE_SUCCESS: "FETCH_EDIT_AUDIENCE_SUCCESS",
	// FETCH_EDIT_AUDIENCE_FAILURE: "FETCH_EDIT_AUDIENCE_FAILURE",

	FETCH_PAGINATION_AUDIENCES_REQUEST: "FETCH_PAGINATION_AUDIENCES_REQUEST",
	FETCH_PAGINATION_AUDIENCES_SUCCESS: "FETCH_PAGINATION_AUDIENCES_SUCCESS",

	FETCH_FILTER_AUDIENCES_REQUEST: "FETCH_FILTER_AUDIENCES_REQUEST",
	FETCH_FILTER_AUDIENCES_SUCCESS: "FETCH_FILTER_AUDIENCES_SUCCESS",

	FETCH_FILTER_AUDIENCE_REQUEST: "FETCH_FILTER_AUDIENCE_REQUEST",
	FETCH_FILTER_AUDIENCE_SUCCESS: "FETCH_FILTER_AUDIENCE_SUCCESS",

	FETCH_GET_TEMPLATE_AUDIENCES_REQUEST: "FETCH_GET_TEMPLATE_AUDIENCES_REQUEST",

	RESET_AUDIENCES_TABLE_PARAMS: "RESET_AUDIENCES_TABLE_PARAMS",
	RESET_CONTACT_MODAL: "RESET_CONTACT_MODAL"
};
