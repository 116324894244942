import React from "react";
import UserLayout from "../../../components/includs/user-layout";
import PageTitle from "../../../components/page-title";
import Filter from "./components/filter";
import Dashboard from "./components/dashboard";

const ScreenInfoDashBoard = () => {
	return (
		<UserLayout>
			<PageTitle
				title="Users registrations diagram"
				subtitle="Here you can see how many users registered during the selected time period."
			/>
			<Filter />
			<Dashboard />
		</UserLayout>
	);
};

export default ScreenInfoDashBoard;
