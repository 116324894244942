import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import TableBase from "app/uikit/table";
import Toolbar from "app/uikit/table/toolbar";
import Footer from "app/uikit/table/footer";
import ConfirmDelete from "../modal/confirm-modal.jsx";
import useColumnsMenu from "app/hooks/useColumnsMenu";
import useColumnsCheck from "app/hooks/useColumnsCheck";
import { getCheckedItem } from "app/helper/index";
import EmptySearch from "app/uikit/table/emptySearch";
import debounce from "lodash.debounce";

const Table = props => {
	const {
		onChangeDelete,
		onChangeUnselect,
		onChangeSearch,
		onChangeDownload,
		onRowClick,
		pagination,
		onChangePagination,
		deleteIcon,
		deleteText,
		deleteLoading,
		deleteNames,
		columns,
		titleDelete,
		textAgree,
		textClose,
		emptySearch,
		emptySearchComponent,
		emptySearchText,
		data,
		action,
		hasData,
		filter,
		onHasData,
		loading,
		toolbar,
		hideElements: { delete: hideDelete, unSelect: hideUnselect, search: hideSearch } = {},
		settingsData: {
			check: {
				showId: dataShowId,
				hide:hideCheck
			} = {},
			menu: { 
				component: menuComponent, 
				onChange: menuOnChange, 
				render: menuRender, 
				renderWithHandler: menuRenderWithHandler,
				hide: hideMenu
			} = {},
		} = {},
		...rest
	} = props;

	const [deleteModal, setDeleteModal] = useState(false);
	const [deteTitle, setDeleTitle] = useState("");
	const [currentSelected, setCurrentSelected] = useState("");
	
	const mappedData = useMemo(() => data && data.map(el => ({ ...el, key: el.id })), [data]);

	const dispatch = useDispatch();

	const mappedDeleteText = useMemo(() => {
		if(typeof deleteText !== "function" ) return deleteText;
		if (deleteText) {
			return deleteText(currentSelected);
		}
	}, [currentSelected, deleteText]);

	const changeDelete = id => {
		handleOpenDeleteModal();
		setCurrentSelected(id);
	};

	useEffect(() => {
		const arr = currentSelected.split(",");
		if (typeof titleDelete === "object") {
			if (arr.length <= 1) {
				setDeleTitle(titleDelete.one);
			} else {
				setDeleTitle(titleDelete.many);
			}
		} else {
			setDeleTitle(titleDelete);
		}
	}, [currentSelected, titleDelete]);

	const menuHandleChange = (id, key) => {
		switch (key) {
			case "delete":
				if (!id) return;
				changeDelete(id);
				break;
			default:
				return menuOnChange(id, key);
		}
	};

	const {
		columnsWithCheck,
		ctrl: { checked, setChecked }
	} = useColumnsCheck(columns, mappedData, dataShowId, hideCheck);

	const columnsWithMenu = useColumnsMenu(columnsWithCheck, data, menuHandleChange, menuComponent, menuRender, hideMenu, menuRenderWithHandler);

	useEffect(() => {
		setCurrentSelected(getCheckedItem(checked));
	}, [checked]);

	const handleCloseDeleteModal = () => {
		setDeleteModal(false);
	};

	const handleOpenDeleteModal = () => {
		setDeleteModal(true);
	};

	const onDeleteAgree = () => {
		if(onChangeDelete){
			return onChangeDelete(currentSelected, handleCloseDeleteModal);
		}
		if (action) {
			dispatch(action.deleteItems(currentSelected, handleCloseDeleteModal));
		}
	};

	const delayedSearch = useCallback(
		debounce(v => {
			if (onChangeSearch) {
				return onChangeSearch(v);
			}
			if (action) {
				dispatch(action.fetchFilter({ "filter[search]": v }));
			}	
		}, 500),
		[]
	);

	const changeSearch =  useCallback(value => {
		delayedSearch(value);
	},[delayedSearch]);

	const changeUnselect = useCallback(() => {
		setChecked("unselect");
	},[setChecked]);

	const hanldeChangeDelete = useCallback(() => {
		if(!currentSelected) return;
		changeDelete(currentSelected);
	},[currentSelected]);

	const onPaginationChange = useCallback(data => {
		if (onChangePagination) {
			return onChangePagination(data);
		}
		if (action) {
			dispatch(action.fetchPagination(data));
		}
	},[action,onChangePagination]);
	
	const stateHasData = useMemo(() => {
		let hasData = false;
		let emptySearch = false;

		if ((filter && filter["filter[search]"]) !== undefined || data.length) {
			hasData = true;
		}

		if ((filter && filter["filter[search]"]) !== undefined || data.length) {
			emptySearch = true;
		}

		return {
			hasData,
			emptySearch
		};
	}, [filter, data, loading]);

	useEffect(() => {
		if (onHasData) {
			onHasData(stateHasData.hasData);
		}
	}, [stateHasData.hasData]);

	const handleChangeDownload = useCallback(()=>{
		if(!currentSelected) return;
		onChangeDownload(currentSelected);
	},[currentSelected]);

	const handleRowClick = (record, event) => {
		const target = event.target;
		const elem = target.closest('[data-target-table]');
		const elemTwo = target.closest('.MuiPaper-root');
		if(elem || elemTwo) return;
		onRowClick(record);
	};

	const rowClick = (record) => ({
		onClick: handleRowClick.bind(null, record),
	});

	return (
		<>
			<TableBase
				toolbar={
					<Toolbar
						selected={checked}
						onChangeDelete={hideDelete ? null : hanldeChangeDelete}
						onChangeUnselect={hideUnselect ? null : changeUnselect}
						onChangeSearch={hideSearch ? null : changeSearch}
						onChangeDownload={onChangeDownload ? handleChangeDownload : null}
						searchValue={filter && filter["filter[search]"]}
						{...toolbar}
					/>
				}
				footer={<Footer pagination={pagination} paginationChange={onPaginationChange} />}
				columns={columnsWithMenu}
				data={mappedData}
				loading={loading}
				emptyText={stateHasData.emptySearch}
				emptySearchComponent={emptySearchComponent || <EmptySearch  text={emptySearchText} />}
				onRow={onRowClick && rowClick || null}
				{...rest}
			/>
			<ConfirmDelete
				icon={deleteIcon}
				title={deteTitle}
				text={mappedDeleteText}
				loading={deleteLoading}
				open={deleteModal}
				onClose={handleCloseDeleteModal}
				onAgree={onDeleteAgree}
				textAgree={textAgree}
				textClose={textClose}
			/>
		</>
	);
};

export default Table;
