import React from "react";
import style from "./style.module.scss";
import classNames from "classnames/bind";

const HeaderLight = props => {
	const cx = classNames.bind(style);
	return <main className={cx("main", props.className)}>{props.children}</main>;
};

export default HeaderLight;
