import React from "react";
import { useDispatch } from "react-redux";
import MatchColumnsAudiece from "app/components/steps-upload-audiece/match-columns";
import actionsUploadAuciences from "app/store/uploadAuciences/actions";
import actionPeople from "app/store/people/actions";

const validate = values => {
	let errors = {
		error: []
	};
	const ArrValues = Object.values(values);

	if(ArrValues.findIndex(elem=>elem === 12) === -1) {
		errors.error = [...errors.error, "Please select tags"]
	}
	if(ArrValues.findIndex(elem=>elem === 7 || elem === 8) === -1) {
		errors.error = [...errors.error, "Please select audience contacts: email or phone."]
	}
	errors = !errors.error.length ? null : errors;
	return errors;
};

const MatchColumns = ({ changeStep, currentStep, nextStep, data, ...rest }) => {
	const dispatch = useDispatch();

	const onNextStep = () => {
		changeStep(nextStep);
		dispatch(actionPeople.getAudiences());
	};

	const onBack = () => {
		changeStep(currentStep - 1);
	};

	const handleSubmit = data => {
		dispatch(actionsUploadAuciences.fetchCreateCategoryAudience(data, onNextStep));
	};

	return (
		<MatchColumnsAudiece
			data={data}
			onSubmit={handleSubmit}
			onBack={onBack}
			validate={validate}
			{...rest}
		/>
	);
};

export default MatchColumns;