import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "app/uikit/button/active-button";
import { useFormik } from "formik";
import ButtonActive from "app/uikit/button/active-button";
import MatchTable from "./match-grid";
import { handlerErrors } from "app/helper/handlerErrors";
import { getBase64Content } from "app/helper/helper.files";

import Alert from "app/uikit/alert";

const validate = values => {
	const errors = {};
	for (let mark in values) {
		if (values[mark] === 7 || values[mark] === 8) {
			return errors;
		}
	}
	errors.error = "Please select audience contacts: email or phone.";
	return errors;
};

const MatchColumns = ({
	onSubmit,
	onBack,
	stepValue,
	options: listOptions,
	validate: validateProps,
	data: {
		columns,
		unmatched_columns_count,
		canCreate,
		message,
		missed_rows_count,
		audienceTemporaryData,
		loading,
		errors
	}
}) => {
	const { audience_column_types } = useSelector(state => state.handBooks);
	const [errorsMatching, setErrorsMatching] = useState(false);
	const onChange = (value, form, e) => {
		const target = e.target;
		const name = target.name;
		const values = form.values;
		for (let mark in values) {
			if (name === mark) {
				continue;
			}
			if (values[mark] === value) {
				form.setFieldValue(mark, null, false);
			}
		}
		form.setFieldValue(name, value);
	};

	const optionsChoosed = useMemo(() => listOptions || audience_column_types, [listOptions, audience_column_types]);

	const options = useMemo(
		() => [...optionsChoosed?.map(el => ({ text: el.name, value: el.id })), { text: "Choose an option", value: "" }],
		[optionsChoosed]
	);

	const handleSubmit = async values => {
		const columns = Object.keys(values)
			.map(el => {
				const id = el.split("_");
				const indexValue = values[el];
				return {
					column_type_id: indexValue && +indexValue,
					column_index: +id[id.length - 1]
				};
			})
			.filter(el => el.column_type_id);

		const requestData = {
			columns,
			file: {
				name: audienceTemporaryData?.file?.name,
				content: await getBase64Content(audienceTemporaryData?.file),
				mime: audienceTemporaryData?.file?.type
			},
			name: audienceTemporaryData?.name
		};
		onSubmit(requestData);
	};

	const initialValues = useMemo(() => {
		const obj = {};
		columns?.forEach(el => {
			obj[`column_index_${[el.column_index]}`] = el.column_type_id;
		});
		return obj;
	}, [columns]);

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validate: validateProps || validate,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		formik.validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	const onMissedWarnings = () => {
		setTimeout(() => {
			setErrorsMatching(true);
		}, 0);
	};

	const warnings = useMemo(() => {
		let arr = [];
		if (unmatched_columns_count) {
			arr.push(
				<span>
					There {`${unmatched_columns_count > 1 ? "are" : "is"}`}{" "}
					<strong>
						{unmatched_columns_count} {`${unmatched_columns_count > 1 ? "columns" : "column"}`}{" "}
					</strong>{" "}
					unmatched.
				</span>
			);
		}
		if (missed_rows_count) {
			arr.push(
				<span>
					There {`${missed_rows_count > 1 ? "are" : "is"}`}{" "}
					<strong>
						{missed_rows_count} {`${missed_rows_count > 1 ? "accounts" : "account"}`}{" "}
					</strong>{" "}
					without email and phone number. {`${missed_rows_count > 1 ? "These accounts" : "This account"}`} will not be
					uploaded.
				</span>
			);
		}
		return (arr.length && arr) || null;
	}, [unmatched_columns_count, missed_rows_count]);

	const ButtonSubmit = useMemo(() => {
		if (warnings && !errorsMatching) {
			return (
				<Button type="button" disabled={!canCreate} onClick={onMissedWarnings} size="medium" colorVariant="ghost">
					Skip issues
				</Button>
			);
		}
		return (
			<ButtonActive
				type="submit"
				loading={loading}
				disabled={!!formik.errors.error || !canCreate}
				size="medium"
				colorVariant="gradient"
			>
				Create list
			</ButtonActive>
		);
	}, [canCreate, loading, formik.errors.error, errorsMatching, warnings]);

	const dataMapped = useMemo(
		() =>
			columns?.sort(el => {
				if (!el.column_type_id) {
					return -1;
				}
				return 0;
			}),
		[columns]
	);
	return (
		<div className="modal-step-three">
			<div className="upload-next">
				<div className="upload-label main-text_bold">
					{stepValue && <span>{stepValue}</span>} Match columns with data types
				</div>
				<p className={`small-text ${stepValue && "upload-label__caption"}`}>To upload personal data, match them.</p>
			</div>

			<form onSubmit={formik.handleSubmit} noValidate>
				<div className="match-table-wrapper">
					<MatchTable options={options} onChangeSelect={onChange} form={formik} data={dataMapped} />
				</div>

				<div className="modal-footer modal-footer_with-warning">
					{!errorsMatching && canCreate && <Alert type="warning" text={warnings} />}
					<Alert type="error" text={formik.errors.error || errors?.columns || errors?.file || (!canCreate && message)} />
					<p className="small-text m-b--24">
						To complete creating list you have to upload correct list of target audience or skip these issues.
					</p>

					<Button onClick={onBack} size="medium" colorVariant="ghost">
						Back
					</Button>
					{ButtonSubmit}
				</div>
			</form>
		</div>
	);
};

export default MatchColumns;
