import React from "react";
import style from "../../../style.module.scss";
import Steps from "./steps";

const NewCampaign = (props) => {

	return (
		<div className={style["campaign-content"]}>
			<Steps {...props} />
		</div>
	);
};

export default NewCampaign;