import React from "react";
import Button from "app/uikit/button/active-button";
import { SuccessUploadSheet } from "app/icons";

const SuccessModal = ({ onClose, countRow, rowPercent }) => {
	return (
		<div className="text-centered">
			<div className="verification-icon m-y--16">
				<SuccessUploadSheet />
			</div>
			<h2 className="title-subheading title-subheading_bold m-b--8">
				Client list information has been loaded successfully
			</h2>
			<p className="small-text">Contacts uploaded: <strong>{countRow}</strong> ({rowPercent}%)</p>
			<div className="modal-footer modal-footer_centered">
				<Button onClick={onClose} size="medium" colorVariant="gradient">
					Continue
				</Button>
			</div>
		</div>
	);
};

export default SuccessModal;