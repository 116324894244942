import React from "react";
import UserLayout from "../../../../components/includs/user-layout";
import PATHS from "../../../../../PATHS";
import PageTitle from "../../../../components/page-title";
import CreateCampaing from "./create-campaign";

const NewCampaign = () => {
	return (
		<UserLayout headerBackLink={PATHS.cabinet.campaigns} headerBackLinkTitle="Campaigns">
			<PageTitle title="New Campaign" />
			<CreateCampaing />
		</UserLayout>
	);
};

export default NewCampaign;
