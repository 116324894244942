import React, { useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";

import actions from "app/store/campaign/actions";
import PATHS from "PATHS";

import Stepreview from "../../../steps/step-review";
import ConfirmModal from "../../../modals/campaign-create";
import PauseModal from "../../../modals/pause-campaign";
import StartModal from "../../../modals/campaign-start";
import RelaunchModal from "../../../modals/campaign-relaunch";
import UpdateModal from "../../../modals/campaign-update";

const StepFour = ({ id, data, errors, onChangeStep, haveChanges, setChangedData }) => {
	const [launch, setLaunch] = useState(false);
	const [startModalOpen, setStartModalOpen] = useState(false);
	const [pauseModalOpen, setPauseModalOpen] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [relaunchMoldaOpen, setRelaunchMoldaOpen] = useState(false);
	const [updateMoldaOpen, setUpdateMoldaOpen] = useState(false);
	const { actionLoading, actionError} = useSelector(state => state.campaigns);
	const dispatch = useDispatch();
	const history = useHistory();

	const openModalConfirm = () => {
		setConfirmModal(true);
	};

	const closeModalConfirm = () => {
		setConfirmModal(false);
	};

	const requestUpdateDate = useMemo(
		() => {
			let obj = {
					integration_type_system_name: data?.platform,
					integration_ad_account_id: data?.adAccount?.id,
					name: data?.name,
					audience_id: data?.audience?.id,
					budget: data?.budget,
					start_datetime: data?.start_datetime,
					creative_template_id: data?.creative?.id,
					budget_type_id: data?.budget_type_id?.id,
					target_id: data?.target?.id,
					is_use_similar_audience: data?.is_use_similar_audience,
					country_ids: data?.countries?.map((el)=>el.id),
					platform_parameters: {
						page_id: data?.platform_parameters?.id || data?.platform_parameters?.page_id,
					},
					age_from: data?.age_from,
					age_to: data?.age_to,
					gender_type_id: data.gender_type?.id || data.gender_type
			}
			if(obj.budget_type_id !== 2){
				obj.end_datetime = data?.end_datetime;
			}
			if(obj?.target_id === 2){
				obj.platform_parameters = {
					...obj.platform_parameters,
					conversion_type_id: data?.conversion_type_id,
					pixel_id: data?.pixel_id,
					conversion_id: data?.conversion_id
				}
			}
			return obj;
		},
		[data]
	);

	const handleOpenModalUpdate = () => {
		setUpdateMoldaOpen(true);
	};

	const handleCloseModalUpdate = () => {
		setUpdateMoldaOpen(false);
	};

	const successEdit = () => {
		setChangedData({});
	};

	const onUpdateCompaign = () => {
		const requestData = {
			...requestUpdateDate,
			is_launch: false
		};
		dispatch(actions.editCompaign(id, requestData, handleCloseModalUpdate, successEdit));
	};

	const onConfirmCompaign = (islaunch, successCb) => {
		const requestData = {
			...requestUpdateDate,
			is_launch: islaunch
		};
		dispatch(actions.editCompaign(id, requestData, closeModalConfirm, successCb || successEdit, islaunch ? "confirm" : "subConfirm"));
	};

	const handleOpenModalStart = () => {
		setStartModalOpen(true);
	};

	const handleCloseModalStart = () => {
		setStartModalOpen(false);
	};

	const handleStart = () => {
		const requestData = {
			...requestUpdateDate,
			is_launch: true
		};
		dispatch(actions.editCompaign(id, requestData, handleCloseModalStart, successEdit, "start"));
	};

	const handleOpenModalPause = () => {
		setPauseModalOpen(true);
	};

	const handleCloseModalPause = () => {
		setPauseModalOpen(false);
	};

	const handlePause = () => {
		dispatch(actions.pauseCompaign(id, handleCloseModalPause));
	};

	const handleOpenModalRelaunch = () => {
		setRelaunchMoldaOpen(true);
	};

	const handleCloseModalRelaunch = () => {
		setRelaunchMoldaOpen(false);
	};
	const sucseccRelaunch = ()=>{
		history.push(PATHS.cabinet.campaigns);
	}
	const handleRelaunch = data => {
		dispatch(actions.restartCompaign(id, data, sucseccRelaunch));
	};
	const successStart = () =>{
		setChangedData({});
		setTimeout(() => {
			history.push(PATHS.cabinet.campaigns);
		}, 0);
	}
	const saveAndClose = () => {
		setLaunch(false);
		onConfirmCompaign(false, successStart);
	};

	const saveAndStart = () => {
		setLaunch(true);
		onConfirmCompaign(true, successStart);
	};

	const onSubmitLaunch = () => {
		openModalConfirm();
	};

	const name = data?.name;

	return (
		<>
			<Stepreview
				title="Campaign overview"
				edit
				subTitle="Check and edit campaign parameters."
				errors={errors}
				onSubmit={onSubmitLaunch}
				onStart={handleOpenModalStart}
				onPause={handleOpenModalPause}
				onRelaunch={handleOpenModalRelaunch}
				changeStepTo={onChangeStep}
				onUpdate={handleOpenModalUpdate}
				data={data}
				haveChanges={haveChanges}
			/>
			<ConfirmModal
				name={name}
				launch={launch}
				loading={actionLoading.confirm}
				open={confirmModal}
				loadingSubAgree={actionLoading.subConfirm}
				onAgree={saveAndStart}
				onSubAgree={saveAndClose}
				onClose={closeModalConfirm}
			/>
			<PauseModal
				open={pauseModalOpen}
				loading={actionLoading.pause}
				onClose={handleCloseModalPause}
				onAgree={handlePause}
				name={name}
			/>
			<StartModal
				open={startModalOpen}
				loading={actionLoading.start}
				onClose={handleCloseModalStart}
				onAgree={handleStart}
				name={name}
			/>
			<RelaunchModal
				open={relaunchMoldaOpen}
				loading={actionLoading.restart}
				onClose={handleCloseModalRelaunch}
				onSubmit={handleRelaunch}
				data={data}
				errors={actionError}
				currency={data.currency}
			/>
			<UpdateModal
				open={updateMoldaOpen}
				loading={actionLoading.edit}
				onClose={handleCloseModalUpdate}
				onAgree={onUpdateCompaign}
				name={name}
			/>
		</>
	);
};

export default StepFour;
