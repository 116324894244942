import { takeEvery, put, all, call, select } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import actionsIntegration from "../integrations/actions";
import servicesIntegration from "../integrations/services";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";
import config from "app/config";

const dir = directory.en;

function* getReport({ params }) {
	try {
		const {
			reports: { filter }
		} = yield select();
		const response = yield call(services.getReports, {
			...params,
			...filter,
			["filter[campaign_id]"]: filter["filter[campaign_id]"] ? filter["filter[campaign_id]"].id : null
		});
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getReportSuccess(response.data.report));
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.getReportFailure(error?.response?.data?.errors || ""));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getReportFailure(""));
		}
	}
}

function* getCampaignByAdAccount({ params }) {
	try {
		const response = yield call(services.getCampaignByAdAccount, params);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getCampaignByAdAccountSuccess({
					count: response.data.count,
					items: response.data.items
				})
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getCampaignByAdAccountFailure(""));
	}
}

function* getDefaultReports({ params, integration_type }) {
	try {
		let platform = integration_type.find(el => el.id === params.integration_type_id);
		let date = params.date_preset;
		if (!platform) {
			platform = integration_type[0];
		}
		let campaign_id = params.campaign_id;

		const responseAdAccount = yield call(servicesIntegration.getAdAccounts);

		let getIdAdAccount = params.integration_ad_account_id;

		if (responseAdAccount.name === "Error") {
			throw responseAdAccount;
		} else if (responseAdAccount && !responseAdAccount.errors) {
			let dataAdAccount = responseAdAccount.data;
			const findADAccount =
				platform && dataAdAccount[platform?.system_name]?.ad_accounts?.find(el => el.id === +getIdAdAccount);
			if (!findADAccount) {
				getIdAdAccount = dataAdAccount[platform?.system_name]?.ad_accounts[0]?.id;
				date = config.ui.dateReport;
				campaign_id = "";
			}
			delete dataAdAccount.success;
			yield put(actionsIntegration.getAdAccountsSuccess(dataAdAccount));
		}
		const responseCampaign = yield call(services.getCampaignByAdAccount, {
			"filter[integration_ad_account_id]": getIdAdAccount,
			"filter[is_except_draft]": true
		});

		if (responseCampaign.name === "Error") {
			throw responseCampaign;
		} else if (responseCampaign && !responseCampaign.errors) {
			const findCampaign = responseCampaign.data.items?.find(el => el.id === campaign_id?.id);
			if (!findCampaign) {
				campaign_id = null;
				date = config.ui.dateReport;
			}
			yield put(
				actions.getCampaignByAdAccountSuccess({
					count: responseCampaign.data.count,
					items: responseCampaign.data.items
				})
			);
		}
		const hasCampaign = responseCampaign?.data?.items?.length;

		const requestParams = {
			"filter[integration_type_id]": platform?.id,
			"filter[integration_ad_account_id]": getIdAdAccount,
			"filter[campaign_id]": campaign_id?.id,
			"filter[date_preset]": date
		};

		yield put(actions.fetchFilterSuccess({ ...requestParams, "filter[campaign_id]": campaign_id }));

		if (hasCampaign && getIdAdAccount) {
			const responseReports = yield call(services.getReports, requestParams);

			if (responseReports.name === "Error") {
				throw responseReports;
			} else if (responseReports && !responseReports.errors) {
				yield put(actions.getReportSuccess(responseReports.data.report));
			}
		}
		yield put(actions.getDefaultReportsSuccess());
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getDefaultReportsFailure(""));
	}
}

function* fetchFilter({ data }) {
	try {
		yield put(actions.fetchFilterSuccess(data));
		yield put(actions.getReport());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.FETCH_GET_REPORT_REQUEST, getReport)]);
	yield all([takeEvery(types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_REQUEST, getCampaignByAdAccount)]);
	yield all([takeEvery(types.FETCH_FILTER_REPORTS_REQUEST, fetchFilter)]);
	yield all([takeEvery(types.FETCH_GET_DEFAULT_REPORT_REQUEST, getDefaultReports)]);
}
