import React from "react";
import style from "./style.module.scss";
import { CheckIcon, WarningIcon } from "app/icons";
import Select from "app/uikit/select";

const MatchTable = ({ form, options, data, onChangeSelect }) => {
	return (
		<div className={style.table}>
			<div className={style.table_inner}>
				{data?.map(row => {
					const onChange = (value,e) =>{
						onChangeSelect(value,form, e);
					};
					const name = `column_index_${[row.column_index]}`;
					const matched = form.values[name];
					return (
						<div className={style.row} key={row.column_index}>
							<div className={style.cell}>
								<p className={`sub-text sub-text_bold ${style.cell_title}`}>Data #{row.column_index}</p>
								<p className={`small-text ${style.text}`}>{row.visible_data}</p>
							</div>
							<div className={style.cell}>
								<p className={`small-text ${style.match_caption}`}>
									{(matched && (
										<>
											<CheckIcon /> Matched
										</>
									)) || (
										<>
											<WarningIcon /> Unmatched
										</>
									)}
								</p>
								<Select hasAllSelect fullWidth list={options} name={name} valiant="outlined" form={form} onChange={onChange} />
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MatchTable;
