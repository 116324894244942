import React from "react";
import {
	NotificationErrorIcon,
	NotificationInfoIcon,
	NotificationSuccessIcon,
	NotificationWarnIcon
} from "../../icons";

const CustomNotif = (props) => {
	const { variant, title, children } = props;

	const showNotifIcon = (variant) => {
		switch (variant) {
			case "success":
				return <NotificationSuccessIcon />;
			case "error" :
				return <NotificationErrorIcon />;
			case "warn":
				return <NotificationWarnIcon />;
			case "info":
				return <NotificationInfoIcon />;
			default:
				return "";
		}

	};

	return (
		<div className={`notification notification_${variant}`}>
			<div className="notification-icon">
				{showNotifIcon(variant)}
			</div>
			<div className="notification-text">
				{title && <p className="notification-title main-text main-text_bold">{title}</p>}
				{children && <p className="notification-text small-text">{children}</p>}
			</div>
		</div>
	);
};

export default CustomNotif;
