import api from "app/api";
import {
	mappedParams
} from "app/helper";

const API = api();


const getCampaigns = async params => {
	try {
		return await API.get("/account/campaigns", {
			params: {
				...mappedParams(params)
			}
		});
	} catch (error) {
		return error;
	}
};

const getCampaign = async id => {
	try {
		return await API.get(`/account/campaigns/${id}`);
	} catch (error) {
		return error;
	}
};

const createCompaign = async data => {
	try {
		return await API.post("/account/campaigns", data);
	} catch (error) {
		return error;
	}
};

const editCompaign = async ({
	id,
	data
}) => {
	try {
		return await API.put(`/account/campaigns/${id}`, data);
	} catch (error) {
		return error;
	}
};

const deleteCompaign = async id => {
	try {
		return await API.delete(`/account/campaigns/${id}`);
	} catch (error) {
		return error;
	}
};

const startCompaign = async id => {
	try {
		return await API.patch(`/account/campaigns/${id}/start`);
	} catch (error) {
		return error;
	}
};

const pauseCompaign = async id => {
	try {
		return await API.patch(`/account/campaigns/${id}/pause`);
	} catch (error) {
		return error;
	}
};

const stopCompaign = async id => {
	try {
		return await API.patch(`/account/campaigns/${id}/stop`);
	} catch (error) {
		return error;
	}
};

const restartCompaign = async ({
	id,
	data
}) => {
	try {
		return await API.patch(`/account/campaigns/${id}/restart`, data);
	} catch (error) {
		return error;
	}
};


const getAudiencesCompaign = async () => {
	try {
		return await API.get("/account/audience");
	} catch (error) {
		return error;
	}
};

const getTempaltesCompaign = async params => {
	try {
		return await API.get("/account/creative-templates", {
			params: {
				...mappedParams(params)
			}
		});
	} catch (error) {
		return error;
	}
};

export default {
	getCampaigns,
	getCampaign,
	createCompaign,
	editCompaign,
	deleteCompaign,
	startCompaign,
	pauseCompaign,
	stopCompaign,
	restartCompaign,
	getAudiencesCompaign,
	getTempaltesCompaign,
};