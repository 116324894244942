import React from "react";
import Item from "./TabPanel-element";

const TabPanel = ({ value, list, index, children }) => {
	return (
		<>
			{list?.map((elem, i) => (
				<Item key={i} value={value} index={i}>
					{elem}
				</Item>
			)) || (
				<Item value={value} index={index}>
					{children}
				</Item>
			)}
		</>
	);
};

export default TabPanel;