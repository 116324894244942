import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ChartInfo from "../ChartInfo";
import NotAnyActivateAccounts from "app/components/notAnyActivateAccounts";
import AsideInfo from "../infoAside";
import { settingsChartName, getTickScaleX, getHasData } from "./helper";
import { ResultIcon } from "app/icons";
import Loader from "app/components/loader";
import { hasAnyAdAcounts } from "app/helper";
import { dataInfo } from "../helper";
import NoReportsCampaign from "./components/NoReportsCampaign";
import NoReportsData from "./components/NoReportsData";
import NoSelectMetric from "./components/NoSelectMetric";
import style from "./style.module.scss";

const INITIALACTIVEITEMS = ["clicks_count", "cpc_values"];

const ReportContent = () => {
	const [activeItem, setActiveItem] = useState(INITIALACTIVEITEMS);
	const [firstRendering, setFirstRendering] = useState(true);
	const [statePlugs, setStatePlugs] = useState({
		hasActiveAccounts: false,
		hasCapmaigns: false,
		noData: false
	});
	const { adAccounts } = useSelector(state => state.integrations);
	const {
		reports: { spent_budget_currency_code, charts, ...restReports },
		reports,
		campaign,
		isLoading,
		isLoadingReports
	} = useSelector(state => state.reports);
	const { hasActiveAccounts, hasCapmaigns, noData } = statePlugs;
	const mappedReports = useMemo(
		() =>
			charts?.map(chart => ({
				data: chart?.points?.map(point => {
					return {
						x: point.x,
						y: point.y == null ? 0 : +(+point.y).toFixed(2)
					};
				}),
				id: chart?.system_name,
				name: chart?.name
			})),
		[charts, dataInfo]
	);

	const filteredReports = useMemo(() => activeItem?.map(elem => mappedReports?.find(el => el.id === elem)), [
		mappedReports,
		activeItem
	]);

	useEffect(() => {
		if (firstRendering && isLoadingReports) {
			setFirstRendering(false);
		}
	}, [isLoadingReports]);

	useEffect(() => {
		const activeArr = INITIALACTIVEITEMS.filter(elem=>(reports && !!reports[elem]));
		setActiveItem(activeArr);
	}, [reports]);

	useEffect(() => {
		if (!isLoading) {
			const { hasActiveAccounts } = hasAnyAdAcounts(adAccounts);
			const hasCapmaigns = !!campaign.items.length;
			const noData = getHasData(reports);
			setStatePlugs({
				hasActiveAccounts,
				hasCapmaigns,
				noData
			});
		}
	}, [isLoadingReports, isLoading]);

	const onChangeActive = name => {
		const hasActiveItem = activeItem.find(elem => elem === name);
		setActiveItem(state => {
			if (hasActiveItem) {
				return state.filter(elem => elem !== hasActiveItem);
			}
			return [name, ...state];
		});
	};

	if (!isLoadingReports && !firstRendering && !hasActiveAccounts) {
		return (
			<NotAnyActivateAccounts
				title="Activate connected accounts!"
				text={
					<>
						To see reports you have to activate your <br /> Ad account
					</>
				}
				textLink="Activate account"
				content={null}
			/>
		);
	}

	if (!isLoadingReports && !firstRendering && !hasCapmaigns) {
		return <NoReportsCampaign />;
	}
	if (!isLoadingReports && !firstRendering && !noData) {
		return <NoReportsData />;
	}

	return (
		<div className={style.container}>
			<Loader loading={isLoadingReports || firstRendering} delay={250}>
				<div className={style.mainBlock}>
					<div className={style.chartBlock}>
						{!!filteredReports?.length && (
							<div>
								<ResultIcon /> Results
							</div>
						)}
						{(filteredReports?.length &&
							filteredReports.map((el, i) => {
								if (!el?.data?.length) return null;
								return (
									<ChartInfo
										key={i}
										data={[el]}
										title={el.name}
										currency={spent_budget_currency_code}
										settings={{
											...settingsChartName[el.id],
											axisBottom: {
												orient: "bottom",
												format: "%Y-%m-%d",
												tickValues: getTickScaleX(el.data.length),
												tickPadding: 10
											}
										}}
									/>
								);
							})) || <NoSelectMetric />}
					</div>
				</div>
				<aside className={style.aside}>
					<AsideInfo
						onChangeActive={onChangeActive}
						activeItem={activeItem}
						data={{
							...restReports,
							spent_budget_currency_code
						}}
					/>
				</aside>
			</Loader>
		</div>
	);
};

export default ReportContent;