import types from "./types";
import { PLATFORMS } from "app/emun/platforms";

export const initialState = {
	integrations: {},
	isLoading: true,
	error: "",
	isConnecting: false,
	isActivating: false,
	isDeleting: false,
	adAccounts:{

	},
	adAccountsLoading: false
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_INTEGRATION_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case types.GET_INTEGRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: "",
				integrations: action.data
			};
		case types.GET_INTEGRATION_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error
			};
		case types.FETCH_GET_AD_ACCOUNTS_REQUEST:
			return {
				...state,
				adAccountsLoading: true
			};
		case types.FETCH_GET_AD_ACCOUNTS_SUCCESS:
			return {
				...state,
				adAccounts: action.data,
				adAccountsLoading: false
			};
		case types.FETCH_GET_AD_ACCOUNTS_FAILURE:
			return {
				...state,
				adAccountsLoading: false
			};
		case types.CONNECT_INTEGRATION_REQUEST:
			return {
				...state,
				isConnecting: true
			};
		case types.CONNECT_INTEGRATION_SUCCESS:
		case types.CONNECT_INTEGRATION_FAILURE:
			return {
				...state,
				isConnecting: false
			};
		case types.DELETE_INTEGRATION_ACCOUNT_REQUEST:
			return {
				...state,
				isDeleting: true
			};
		case types.DELETE_INTEGRATION_ACCOUNT_SUCCESS:
			let newStateAccount = {
				...state,
				isDeleting: false
			};
			if (action.data.integration_type === PLATFORMS.facebook_ads) {
				const integrationsAccountMapped = newStateAccount?.integrations?.facebook_ads?.accounts.filter(
					el => el.id !== action.data.id
				);
				if (
					newStateAccount.integrations &&
					newStateAccount.integrations.facebook_ads &&
					newStateAccount.integrations.facebook_ads.accounts
				) {
					newStateAccount.integrations.facebook_ads.accounts = integrationsAccountMapped;
				}
			}
			return newStateAccount;
		case types.DELETE_INTEGRATION_ACCOUNT_FAILURE:
			return {
				...state,
				isDeleting: false
			};
		case types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_REQUEST:
			return {
				...state,
				isActivating: true
			};
		case types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_FAILURE:
			return {
				...state,
				isActivating: false
			};
		case types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_SUCCESS:
			const { ad_account } = action.data;
			let newState = {
				...state,
				isActivating: false
			};
			if (action.data.integration_type === PLATFORMS.facebook_ads) {
				const integrationsMapped = state.integrations?.facebook_ads?.accounts?.map(el => {
					let mewEl = el;
					if(mewEl?.ad_accounts?.length){
						mewEl.ad_accounts = mewEl.ad_accounts.map(elements => {
							if (elements.id === ad_account.id) {
								return ad_account;
							}
							return elements;
						});
					}
					return mewEl;

				});
				if (
					newState.integrations &&
					newState.integrations.facebook_ads &&
					newState.integrations.facebook_ads.accounts
				) {
					newState.integrations.facebook_ads.accounts = integrationsMapped;
				}
			}
			return newState;

		default:
			return state;
	}
};

export default reducer;
