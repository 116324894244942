export default {
	CONNECT_INTEGRATION_REQUEST: "CONNECT_INTEGRATION_REQUEST",
	CONNECT_INTEGRATION_SUCCESS: "CONNECT_INTEGRATION_SUCCESS",
	CONNECT_INTEGRATION_FAILURE: "CONNECT_INTEGRATION_FAILURE",

	GET_INTEGRATION_REQUEST: "GET_INTEGRATION_REQUEST",
	GET_INTEGRATION_SUCCESS: "GET_INTEGRATION_SUCCESS",
	GET_INTEGRATION_FAILURE: "GET_INTEGRATION_FAILURE",

	DELETE_INTEGRATION_ACCOUNT_REQUEST: "DELETE_INTEGRATION_ACCOUNT_REQUEST",
	DELETE_INTEGRATION_ACCOUNT_SUCCESS: "DELETE_INTEGRATION_ACCOUNT_SUCCESS",
	DELETE_INTEGRATION_ACCOUNT_FAILURE: "DELETE_INTEGRATION_ACCOUNT_FAILURE",

	FETCH_GET_AD_ACCOUNTS_REQUEST: "FETCH_GET_AD_ACCOUNTS_REQUEST",
	FETCH_GET_AD_ACCOUNTS_SUCCESS: "FETCH_GET_AD_ACCOUNTS_SUCCESS",

	CHANGE_ACTIVE_INTEGRATION_ACCOUNT_REQUEST: "CHANGE_ACTIVE_INTEGRATION_ACCOUNT_REQUEST",
	CHANGE_ACTIVE_INTEGRATION_ACCOUNT_SUCCESS: "CHANGE_ACTIVE_INTEGRATION_ACCOUNT_SUCCESS",
	CHANGE_ACTIVE_INTEGRATION_ACCOUNT_FAILURE: "CHANGE_ACTIVE_INTEGRATION_ACCOUNT_FAILURE"
};
