import types from "./types";

const actions = {
	getUsers: params => ({
		type: types.GET_USERS_REQUEST,
		params
	}),
	getUsersSuccess: data => ({
		type: types.GET_USERS_SUCCESS,
		data
	}),
	getUsersFailure: error => ({
		type: types.GET_USERS_FAILURE,
		error
	}),

	getUser: id => ({
		type: types.GET_USER_REQUEST,
		id
	}),
	getUserSuccess: data => ({
		type: types.GET_USER_SUCCESS,
		data
	}),
	getUserFailure: error => ({
		type: types.GET_USER_FAILURE,
		error
	}),

	getUserData: (actionName, params) => ({
		type: types.GET_USER_DATA_REQUEST,
		params,
		actionName
	}),
	getUserDataSuccess: data => ({
		type: types.GET_USER_DATA_SUCCESS,
		data
	}),
	getUserDataFailure: error => ({
		type: types.GET_USER_DATA_FAILURE,
		error
	}),


	getUserIntegrations: id => ({
		type: types.GET_USER_INTEGRAIONS_REQUEST,
		id,
		actionName: "integrations"
	}),
	getUserIntegrationsSuccess: (actionName, data) => ({
		type: types.GET_USER_INTEGRAIONS_SUCCESS,
		actionName,
		data
	}),
	getUserIntegrationsFailure: (actionName, error)  => ({
		type: types.GET_USER_INTEGRAIONS_FAILURE,
		actionName,
		error
	}),

	deleteItems: (ids, cb) => ({
		type: types.DELETE_USERS_REQUEST,
		ids,
		cb
	}),
	deleteItemsSuccess: data => ({
		type: types.DELETE_USERS_SUCCESS,
		data
	}),
	deleteItemsFailure: error => ({
		type: types.DELETE_USERS_FAILURE,
		error
	}),

	fetchNewUser: (data, cb) => ({
		type: types.FETCH_NEW_USER_REQUEST,
		data,
		cb
	}),
	fetchNewUserSuccess: () => ({
		type: types.FETCH_NEW_USER_SUCCESS
	}),
	fetchNewUserFailure: error => ({
		type: types.FETCH_NEW_USER_FAILURE,
		error
	}),

	fetchEditUser: (id, data, cb) => ({
		type: types.FETCH_EDIT_USER_REQUEST,
		data,
		id,
		cb
	}),

	fetchEditUserSuccess: () => ({
		type: types.FETCH_EDIT_USER_SUCCESS
	}),
	fetchEditUserFailure: error => ({
		type: types.FETCH_EDIT_USER_FAILURE,
		error
	}),

	fetchResetPasswordUser: (id, cb) => ({
		type: types.FETCH_RESET_PASSWORD_USER_REQUEST,
		id,
		cb
	}),
	fetchResetPasswordUserSuccess: () => ({
		type: types.FETCH_RESET_PASSWORD_USER_SUCCESS
	}),
	fetchResetPasswordUserFailure: () => ({
		type: types.FETCH_RESET_PASSWORD_USER_FAILURE
	}),

	getUsersExport: ids => ({
		type: types.FETCH_GET_USERS_EXPORT_REQUEST,
		ids
	}),

	getUsersExportProgress: () => ({
		type: types.FETCH_GET_USERS_EXPORT_PROGRESS_REQUEST,
	}),

	getStatistic: () => ({
		type: types.GET_USERS_STATISTIC
	}),

	getStatisticSuccess: data => ({
		type: types.GET_USERS_STATISTIC_SUCCESS,
		data
	}),

	fetchUserShareAudience: (internal_audience, id, cb) => ({
		type: types.FETCH_USER_SHARE_AUDIENCE_REQUEST,
		internal_audience,
		id,
		cb,
		actionName: "shareAudience"
	}),

	fetchUserShareAudienceSuccess: data => ({
		type: types.FETCH_USER_SHARE_AUDIENCE_SUCCESS,
		data
	}),

	fetchUserShareAudienceFailure: data => ({
		type: types.FETCH_USER_SHARE_AUDIENCE_FAILURE,
		data
	}),

	fetchUserShareCreative: (creative_template, id, cb) => ({
		type: types.FETCH_USER_SHARE_CREATIVE_REQUEST,
		creative_template,
		id,
		cb,
		actionName: "shareCreative"
	}),

	fetchUserShareCreativeSuccess: data => ({
		type: types.FETCH_USER_SHARE_CREATIVE_SUCCESS,
		data
	}),

	fetchUserShareCreativeFailure: data => ({
		type: types.FETCH_USER_SHARE_CREATIVE_FAILURE,
		data
	}),

	resetUserAudienceMatchedFile: () => ({
		type: types.RESET_USER_AUDIENCE_MATCHED_FILE
	}),

	fetchFilter: data => ({
		type: types.FETCH_FILTER_USERS_REQUEST,
		data
	}),

	fetchFilterSuccess: data => ({
		type: types.FETCH_FILTER_USERS_SUCCESS,
		data
	}),

	fetchUserFilter: (data, actionName) => ({
		type: types.FETCH_FILTER_USER_REQUEST,
		data,
		actionName
	}),
	fetchUserFilterSuccess: (data, actionName) => ({
		type: types.FETCH_FILTER_USER_SUCCESS,
		data,
		actionName
	}),

	fetchPagination: data => ({
		type: types.FETCH_PAGINATION_USERS_REQUEST,
		data
	}),
	fetchPaginationSuccess: data => ({
		type: types.FETCH_PAGINATION_USERS_SUCCESS,
		data
	}),

	fetchUserPagination: (data, actionName) => ({
		type: types.FETCH_PAGINATION_USER_REQUEST,
		data,
		actionName
	}),
	fetchUserPaginationSuccess: data => ({
		type: types.FETCH_PAGINATION_USER_SUCCESS,
		data
	}),

	resetTableParams: () => ({
		type: types.RESET_USERS_TABLE_PARAMS
	}),
	resetUserData: () => ({
		type: types.RESET_USER_TABLE_DATA_PARAMS
	})
};
export default actions;
