export const getActivatingAdAccount = (el, current, isActivating) => isActivating && current[el];
export const mappedData = (data, id) =>
	data.map(el => ({
		id: el.id,
		userInfo: {
			id: el.account_id,
			name: el.name
		},
		status: { status: el.status, status_reason: el.status_reason },
		sum: el.amount_spent,
		activation: { activation: el.activation_status, account_id: el.id, id: id, status: el.status }
	}));

export const getTextInfo = status => {
	const { link, message } = status;
	if (!message) return "";
	const findFirst = message.indexOf("{{");
	const findLast = message.indexOf("}}");
	let part = [];
	if (findFirst !== -1 && findLast !== -1) {
		part.push(message.slice(0, findFirst));
		part.push(message.slice(findFirst + 2, findLast));
		part.push(message.slice(findLast + 2));
	}
	return (
		<span>
			{(part.length && link && (
				<>
					{part[0]}
					<a href={link}>{part[1]}</a>
					{part[2]}
				</>
			)) ||
				message}
		</span>
	);
};
