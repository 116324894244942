import React from "react";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import style from "./style.module.scss";
import Tooltip from "./tooltip";

const ChartInfo = ({ data, settings, title, currency }) => {
	const maxValue = Math.max.apply(
		Math,
		data[0].data.map(o => o.y)
	);
	const onlyOne = data[0]?.data?.length === 1;
	let mdata = data;

	return (
		<div className={style.chart}>
			<div className={style.title}>{title}</div>
			<ResponsiveLine
				data={mdata}
				height={300}
				margin={{ top: 20, right: 30, bottom: 50, left: 40 }}
				yScale={{ type: "linear", stacked: true, max: maxValue > 0 ? "auto" : 1 }}
				xScale={{ type: "time", format: "%Y-%m-%d", useUTC: false, precision: "day" }}
				xFormat="time:%Y-%m-%d"
				colors={["hsl(10, 77%, 66%)"]}
				curve="linear"
				axisTop={null}
				axisRight={null}
				lineWidth={2}
				axisBottom={{
					orient: "bottom",
					format: "%Y-%m-%d",
					tickValues: "every 1 month",
					tickPadding: 10
				}}
				axisLeft={{
					orient: "right",
					tickSize: 0,
					tickPadding: 10,
					tickRotation: 0,
					legendOffset: -40,
					legendPosition: "middle"
				}}
				tooltip={point => <Tooltip point={point} currency={currency} />}
				defs={[
					linearGradientDef("clicks_count", [
						{ offset: 0, color: "rgba(235, 125, 104)", opacity: 1 },
						{ offset: 40, color: "rgba(235, 125, 104)", opacity: 0.6 },
						{ offset: 80, color: "rgba(235, 125, 104)", opacity: 0.8 },
						{ offset: 100, color: "rgba(235, 125, 104)", opacity: 0.1 }
					]),
					linearGradientDef("cpc_values", [
						{ offset: 0, color: "rgba(95, 116, 190)", opacity: 1 },
						{ offset: 40, color: "rgba(95, 116, 190)", opacity: 0.6 },
						{ offset: 80, color: "rgba(95, 116, 190)", opacity: 0.8 },
						{ offset: 100, color: "rgba(95, 116, 190)", opacity: 0.1 }
					])
				]}
				fill={[
					{ match: { id: "clicks_count" }, id: "clicks_count" },
					{ match: { id: "cpr_values" }, id: "cpr_values" }
				]}
				enableGridX={false}
				enablePoints={onlyOne}
				pointSize={10}
				pointColor={{ from: "color", modifiers: [] }}
				pointBorderColor={{ from: "serieColor", modifiers: [] }}
				pointLabelYOffset={-12}
				enableArea={true}
				useMesh={true}
				legends={[]}
				animate={false}
				{...settings}
			/>
		</div>
	);
};

export default ChartInfo;
