import React from "react";
import { Link } from "react-router-dom";
import EmptyPlaceholder from "app/components/empty-placeholder";
import EmptySearchBase from "app/uikit/table/emptySearch";
import PATHS from "PATHS";

export const EmptySearch = () => (
	<EmptySearchBase
		text={
			<>
				No creatives have been found
				<br />
				by your request.
			</>
		}
	/>
);

export const EmptyCreative = ({ platform }) => (
	<EmptyPlaceholder
		title="No creatives have been created or added."
		subtitle="Create your first creative or add one from Templates"
		content={
			<Link
				to={PATHS.cabinet.createCreative.replace(":platform", platform)}
				className="button button_gradient button_medium m-l--12"
			>
				Add new creative
			</Link>
		}
	/>
);