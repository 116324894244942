import React, {useRef} from "react";
import style from "./style.module.scss";
import toastify from "app/utils/toastify";

const TemplatePost = ({ text }) => {
    const ref = useRef();

	const copy = () =>{
        var r = document.createRange();
        r.selectNode(ref.current);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
		toastify.info('copied');
    }
	
	return <div className={style.wrapper}>
		<div className={style.card}>
			<div className={style.card__header}>
				<h2>Generated result</h2>
				<button onClick={copy} className={style.card__copy}>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.3334 0.833344H3.33335C2.41669 0.833344 1.66669 1.58334 1.66669 2.50001V14.1667H3.33335V2.50001H13.3334V0.833344ZM12.5 4.16668H6.66669C5.75002 4.16668 5.00835 4.91668 5.00835 5.83334L5.00002 17.5C5.00002 18.4167 5.74169 19.1667 6.65835 19.1667H15.8334C16.75 19.1667 17.5 18.4167 17.5 17.5V9.16668L12.5 4.16668ZM6.66669 17.5V5.83334H11.6667V10H15.8334V17.5H6.66669Z" fill="#64738C"/>
						</svg>
						<span>Copy</span>
				</button>
			</div>
			<div className={style.card__body} ref={ref}>
				{text}
			</div>
		</div>
	</div>;
};

export default TemplatePost;
