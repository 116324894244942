import React from "react";
import { Redirect } from "react-router-dom";
import localStore from "app/helper/local-store";
import PATHS from "PATHS";

const NotFoundRouter = () => {
	if (localStore.getAccessToken()) {
		return <Redirect from="*" to={PATHS.pageNotFound} />
	}
	return <Redirect from="*" to={PATHS.auth.login} />
};
export default NotFoundRouter;