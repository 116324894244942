import React, { useCallback, useEffect } from "react";

import { useFormik } from "formik";
import debounce from "lodash.debounce";

import Button from "app/uikit/button/active-button";
import Alert from "app/uikit/alert";

import { getErrorKeys } from "../../helper";

import style from "./style.module.scss";
import Creatives from "./Creatives";
import validationSchema from "./validationSchema";

const StepThree = ({
	onSubmit,
	params,
	edit,
	creatives,
	currentCreatives,
	onChangeFilter,
	loading,
	removeErrors,
	errors
}) => {
	const shouldChooseElem = !currentCreatives && errors?.creative;
	const pagination = {
		total: params.total,
		limit: params.limit,
		page: params.page
	};
	const handleSubmit = values => {
		if (edit && currentCreatives && values.creative === currentCreatives.id) {
			onSubmit({}, true);
		} else {
			onSubmit(values);
		}
		if (removeErrors) {
			removeErrors(Object.keys(values));
		}
	};
	const formik = useFormik({
		initialValues: {
			creative: currentCreatives?.id || (!shouldChooseElem && creatives[0]?.id) || ""
		},
		validationSchema: validationSchema,
		initialErrors: { creative: errors?.creative },
		enableReinitialize: true,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		const dirtiFields = formik.status?.dirtyFields;
		if (dirtiFields && edit) {
			onSubmit(formik.values, false, true);
			let errorsKey = getErrorKeys(dirtiFields, errors);
			removeErrors(errorsKey);
		}
	}, [formik.values, formik.status?.dirtyFields]);

	const delayedSearch = useCallback(
		debounce(v => onChangeFilter(v), 500),
		[debounce]
	);

	const onPaginationChange = data => {
		onChangeFilter(data);
	};

	const onSearch = value => {
		delayedSearch({ "filter[search]": value });
	};

	const onRefresh = () => {
		onChangeFilter({ "filter[search]": undefined });
	};

	return (
		<>
			<div className={style.step}>
				<p className="title-subheading title-subheading_bold m-b--4">Creative template</p>
				<p className="text-main m-b--24">Choose a creative template for your campaign or create a new one</p>
				<Creatives
					onSearch={onSearch}
					creatives={creatives}
					params={params}
					pagination={pagination}
					onPaginationChange={onPaginationChange}
					currentCreatives={currentCreatives}
					loading={loading}
					onRefresh={onRefresh}
					form={formik}
					shouldChooseElem={shouldChooseElem}
					name="creative"
				/>
			</div>
			<div className="campaign-footer">
				<Alert type="error" text={formik.errors.creative} className={style.error} />
				<Button onClick={formik.handleSubmit} disabled={!!formik.errors.creative} size="medium" colorVariant="gradient">
					Next Step
				</Button>
			</div>
		</>
	);
};

export default StepThree;
