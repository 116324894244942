import React from "react";
import { FormHelperText} from "@material-ui/core";


export default function FormError({ error, text, className}) {
	return (
        <>
            {error && Boolean(error) && text && <FormHelperText className={className} error={true}>{text}</FormHelperText>}
        </>
	);
};