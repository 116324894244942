import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

const CreativeItem = props => {
	return (
		<div className={style.label}>
			<div className={style.banner}>
				<img src={props?.preview} alt="preview" />
			</div>
			<div className={style.text}>
				<p className="small-text small-text_bold">{props.title}</p>
				<p className={classNames("small-text", style.text_ellipsis)}>{props.description}</p>
			</div>
			<div className={style.date}>
				<p>{props.created ? "Created" : "Last changed"}:&nbsp;{props.date}</p>
			</div>
		</div>
	);
};

export default CreativeItem;