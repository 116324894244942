import React from "react";
import style from "./style.module.scss";
import Pagination from "./pagination";
import MenuSize from "./menu-size";

const Footer = ({ pagination, paginationChange }) => {
	return (
		<>
			{(pagination && (
				<div className={style.footer}>
					<Pagination onChange={paginationChange} pagination={pagination} />
					<MenuSize limit={pagination?.limit} onChange={paginationChange} />
				</div>
			)) ||
				null}
		</>
	);
};

export default Footer;
