import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "app/components/modal";
import Success from "./success";
import Upload from "./upload-step";
import Match from "./match-step";

const ModalStep = ({ onClose, open }) => {
	const {
		matchedFilesData: { columns, unmatched_columns_count, missed_rows_count, message, is_can_upload_contacts },
		uploadedAudience: { added_to_repository_count,
			added_to_repository_percent,
			not_updated_count,
			not_updated_percent,
			updated_in_repository_count,
			updated_in_repository_percent },
		audienceTemporaryData,
		creatingAudience,
		createAudienceError
	} = useSelector(state => state.uploadAuciences);
	const { contact_column_types } = useSelector(state => state.handBooks);
	const [step, setStape] = useState(1);

	useEffect(() => {
		if (open) {
			setStape(1);
		}
	}, [open]);

	const getParamsForModal = useMemo(() => {
		switch (step) {
			case 1:
				return {
					title: "Upload new spreadsheet",
					component: <Upload nextStep={2} onClose={onClose} changeStep={setStape} />
				};
			case 2:
				return {
					title: "Upload new spreadsheet",
					component: (
						<Match
							data={{
								columns,
								unmatched_columns_count,
								message,
								missed_rows_count,
								audienceTemporaryData,
								loading: creatingAudience,
								errors: createAudienceError,
								canCreate: is_can_upload_contacts
							}}
							currentStep={step}
							nextStep={3}
							onClose={onClose}
							changeStep={setStape}
							options={contact_column_types}
						/>
					)
				};
			case 3:
				return {
					component: <Success 
						onClose={onClose} 
						added_to_repository_count={added_to_repository_count}
						added_to_repository_percent={added_to_repository_percent}
						not_updated_count={not_updated_count}
						not_updated_percent={not_updated_percent}
						updated_in_repository_count={updated_in_repository_count}
						updated_in_repository_percent={updated_in_repository_percent}
					/>
				};
			default:
				return {
					title: "",
					component: ""
				};
		}
	}, [
		onClose,
		step,
		creatingAudience,
		createAudienceError,
		columns,
		unmatched_columns_count,
		is_can_upload_contacts,
		message,
		missed_rows_count,
		audienceTemporaryData,
		contact_column_types
	]);

	return (
		<Modal title={getParamsForModal.title} onClose={onClose} open={open}>
			{getParamsForModal.component}
		</Modal>
	);
};

export default ModalStep;
