import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StepTwoBase from "../../../steps/step-2";
import actions from "app/store/campaign/actions";

const StepTwo = ({ data, onChangeStep, nextStep, onChangeDate, ...rest }) => {
	const dispatch = useDispatch();
	const {
		audiences: { items }
	} = useSelector(state => state.campaigns);
	const { currency } = data;
	const options =
		items.map(el => ({
			name: el.name,
			id: el.id
		})) || [];

	useEffect(() => {
		dispatch(actions.getAudiences());
	}, [dispatch]);

	const onSubmit = (data,shouldNotUpdate ,shouldNotoNextStep) => {
		if(!!Object.keys(data).length){
			if(!shouldNotUpdate){
				onChangeDate({ ...data, currency});
			}
		}
		if(!shouldNotoNextStep){
			onChangeStep(nextStep);
		}
	};

	return <StepTwoBase edit onSubmit={onSubmit} data={data} listAudience={options} currency={currency}  {...rest}  />;
};

export default StepTwo;
