import React, { useEffect } from "react";
import DropZone from "app/components/dropzone";
import { useFormik } from "formik";
import Input from "app/uikit/input";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import { getBase64Content } from "app/helper/helper.files";
import { handlerErrors } from "app/helper/handlerErrors";

const UploadSteps = ({ onClose, onSubmit, loading, errors }) => {
	const handleSubmit = async values => {
		const value = {
			name: values.name,
			file: {
				name: values.file[0].name,
				content: await getBase64Content(values.file[0]),
				mime: values.file[0].type
			},
			fileData: values.file[0]
		};
		onSubmit(value);
	};
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			file: [],
			name: ""
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});
	const handleChangeFiles = async file => {
		const uploadFileName = file[0]?.name.replace(/(\.(csv|xls|xlsx))$/, "");
		await formik.setFieldValue("name", uploadFileName);
		formik.validateField("name");
	};

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<DropZone
				fullWidth
				onChange={handleChangeFiles}
				type="sheet"
				name="file"
				form={formik}
				label={
					<p className="upload-label main-text_bold">
						<span>1</span>Upload your file
					</p>
				}
				showInfoTextType={!formik.values.file?.length}
			/>
			{(!!formik.values.file?.length && (
				<>
					<div className="upload-next">
						<p className="upload-label main-text_bold">
							<span>2</span>Name your spreadsheet
						</p>
						<Input name="name" form={formik} fullWidth label="Spreadsheet name" required />
					</div>
				</>
			))}
			<Error error={errors} />
			<div className="modal-footer">
				<Button onClick={onClose} size="medium" colorVariant="ghost">
					Cancel
				</Button>
				<Button type="submit" loading={loading} size="medium" colorVariant="gradient">
					Match
				</Button>
			</div>
		</form>
	);
};

export default UploadSteps;
