import React from "react";
import { Tooltip } from "@material-ui/core";
import style from "./style.module.scss";
import { Info as IconInfo } from "app/icons";

const Info = ({ info, placement = "right", ...rest }) => (
	<Tooltip title={info} placement={placement} {...rest}>
		<div className={style.info}>
			<IconInfo />
		</div>
	</Tooltip>
);
export default Info;
