import React from "react";
import Info from "./info";
import style from "./style.module.scss";
import { getValueWithType } from "./helper";
import {dataInfo} from '../helper';

const InfoAside = ({ data = {}, activeItem, onChangeActive, currency }) => {
	return (
		<div className={style.container}>
			{dataInfo?.map((el, i) => {
				const { icon, description, type } = el;
				const value = data[el.name];
				const isActive = activeItem.find(elem => elem === el.name);
				const onClick = ()=>{
					if(!value) return;
					onChangeActive(el.name);
				};
				return (
					<div key={i} className={style.infoBlock}>
						<Info
							icon={icon}
							currency={currency}
							description={description}
							active={isActive}
							nativeValue={value}
							onClick={onClick}
							value={getValueWithType(type, value, data.spent_budget_currency_code)}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default InfoAside;
