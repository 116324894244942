import React from "react";
import { Input, FormHelperText, InputAdornment } from "@material-ui/core";
import { FormControl, InputLabel, FormError} from "../form-components";
import classNames from "classnames";
import useStyles from "./style";
import Alert from "app/uikit/alert";

const InpurDefaul = props => {
	const {
		className,
		wrapperClassName,
		labelClassName,
		info,
		label,
		type = "text",
		variant,
		form,
		error,
		errorText,
		infoPlacement,
		name,
		icon,
		iconPosition,
		currencyAdornment,
		fullWidth,
		required,
		warning,
		labelContent,
		...rest
	} = props;
	const classes = useStyles();

	return (
		<FormControl variant={variant} fullWidth={fullWidth} className={wrapperClassName}>
			<InputLabel
				name={name}
				info={info}
				labelContent={labelContent}
				infoPlacement={infoPlacement}
				required={required}
				label={label}
				className={labelClassName}
			/>
			<Input
				id={name}
				type={type}
				name={name}
				className={classNames(classes.input, error && classes.input_error, className)}
				{...rest}
				endAdornment={
					iconPosition === "end" && (
						<InputAdornment
							className={classNames(classes.iconStyles, currencyAdornment ? classes.currencyAdornment : "")}
							position={iconPosition}
						>
							{icon}
						</InputAdornment>
					)
				}
				startAdornment={
					iconPosition === "start" && (
						<InputAdornment className={classes.iconStyles} position={iconPosition}>
							{icon}
						</InputAdornment>
					)
				}
			/>
			<FormError error={error} text={errorText}/>
			{!(error && Boolean(error)) && warning && (
				<FormHelperText error={true} className={classes.warning} component="div">
					{!(error && Boolean(error)) && warning && (
						<>
							<Alert type="warning" text={warning} />
						</>
					)}
				</FormHelperText>
			)}
		</FormControl>
	);
};
export default InpurDefaul;
