import React from "react";
import {Edit, Download, Delete} from 'app/icons'

const menuTableFile = (id,onChange)=>([
    {
        key: 'details',
        id: id,
        onChange: onChange,
        render: ()=>(<div className="with-icon"><Edit/> Details</div>)
    },
    {
        key: 'download',
        id: id,
        onChange: onChange,
        render: ()=>(<div className="with-icon"><Download/> Download</div>)
    },
    {
        key: 'delete',
        id: id,
        onChange: onChange,
        render: ()=>(<div className="with-icon highlighted"><Delete/> Delete</div>)
    }   
]);

export default menuTableFile;