import types from "./types";
import config from "app/config/config";
import { stateRequest, getPaginationAfterDelete, getDefaultPagination, getFilter, getPagination } from "../helper";

export const initialState = {
	tableItems: {
		audiences: {
			count: 0,
			items: []
		},
		marketplace: {
			count: 0,
			items: []
		}
	},

	pagination: {
		audiences: {
			total: 0,
			limit: config.ui.pagination.size,
			page: 1
		},
		marketplace: {
			total: 0,
			limit: 30,
			page: 1
		}
	},

	filter: {
		audiences: {},
		marketplace: {
			sort_by: "name",
			sort_direction: "asc"
		}
	},

	isLoading: {
		audiences: {
			table: false,
			delete: false
		},
		marketplace: {
			table: false
		},
		contact: false,
		composeAudience: false
	},
	error: {
		composeAudience: ""
	}
};

const reducer = function (state = initialState, action) {
	const actionName = action.actionName;
	switch (action.type) {
		case types.GET_AUDIENCES_REQUEST:
		case types.GET_MARKETPLACE_REQUEST:
		case types.FETCH_DELETE_AUDIENCES_REQUEST:
		case types.FETCH_COMPOSE_AUDIENCE_REQUEST:
			return {
				...state,
				...stateRequest(state, action, "request")
			};
		case types.GET_AUDIENCES_FAILURE:
		case types.GET_MARKETPLACE_FAILURE:
		case types.FETCH_DELETE_AUDIENCES_FAILURE:
		case types.FETCH_COMPOSE_AUDIENCE_FAILURE:
			return {
				...state,
				...stateRequest(state, action, "failure")
			};
		case types.FETCH_COMPOSE_AUDIENCE_SUCCESS:
			return {
				...state,
				...stateRequest(state, action, "success")
			};
		case types.GET_AUDIENCES_SUCCESS:
		case types.GET_MARKETPLACE_SUCCESS:
			return {
				...state,
				tableItems: { ...state.tableItems, [actionName]: action.data },
				pagination: {
					...state.pagination,
					[actionName]: { ...state.pagination[actionName], total: action?.data?.count || 0 }
				},
				...stateRequest(state, action, "success")
			};
		case types.FETCH_PAGINATION_AUDIENCES_SUCCESS:
			return {
				...state,
				pagination: getPagination(state,action.data,actionName)
			};

		case types.FETCH_FILTER_AUDIENCES_SUCCESS:
			return {
				...state,
				...stateRequest(state, action, "success"),
				pagination: getDefaultPagination(state, actionName),
				filter: getFilter(state, action.data, actionName)
			};
		case types.FETCH_DELETE_AUDIENCES_SUCCESS:
			return {
				...state,
				...stateRequest(state, action, "success"),
				pagination: getPaginationAfterDelete(state, action.data, "tableItems", action.actionName, action.actionName)
			};

		case types.RESET_AUDIENCES_TABLE_PARAMS: {
			return {
				...state,
				pagination: initialState.pagination,
				filter: initialState.filter
			};
		}

		default:
			return state;
	}
};

export default reducer;
