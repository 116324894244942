import React from "react";
import style from "./style.module.scss";
import { CheckIcon } from "../../icons";
import classNames from "classnames/bind";
import { getListItemStatus } from "./helper";
import { WarningIcon } from "app/icons";

const stepsList = [
	{
		step: "platform",
		title: "Integration platform"
	},
	{
		step: "adAccount",
		title: "Ad account"
	},
	{
		step: "details",
		title: "Campaign details"
	},
	{
		step: "creative",
		title: "Creative template"
	},
	{
		step: "review",
		title: "Review"
	}
];

const getStatus = (status, edit, value, currentStep, step) => {
	if (status) return status;
	if (edit || value) {
		return "done";
	}
	if (currentStep === step) {
		return "pending";
	}
};

const CampaignsAside = props => {
	const cx = classNames.bind(style);
	const { step = 0, data = {}, edit, onChangeStep } = props;
	return (
		<div className={style.aside}>
			<div className={style["aside-inner"]}>
				<p className={style.title}>Steps</p>
				<ul className={style["step-list"]}>
					{stepsList.map((stepItem, i) => {
						const { value, error, status, canToMove } = data[stepItem.step] || {};
						const statusItem = getStatus(status, edit, value, step, i);
						return (
							<li key={stepItem.title} className={cx("step-list-item")}>
								<div className={cx("step-list-item__content")}>
									{(error && <WarningIcon color="#EF5E39" />) || <CheckIcon color={getListItemStatus(statusItem)} />}
									<span
										data-step={i}
										className={cx({ "step-list-item__link": canToMove })}
										onClick={(canToMove && onChangeStep) || (() => {})}
									>
										{stepItem.title}
									</span>
								</div>
								{value && !error && <p className={cx("step-list-item__desc")}>{value}</p>}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default CampaignsAside;
