export const ClicksIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.81806 7.86442C5.81806 5.34564 7.87841 3.29646 10.4109 3.29646C12.9435 3.29646 15.0038 5.34564 15.0038 7.86442C15.0038 8.29355 14.9437 8.71865 14.825 9.128L16.08 9.48785C16.2325 8.96156 16.3099 8.41535 16.3099 7.86447C16.3099 4.62944 13.6636 1.99756 10.4109 1.99756C7.15827 1.99756 4.51202 4.62944 4.51202 7.86447C4.51202 9.30032 5.04123 10.6832 6.00221 11.7584L6.97834 10.8953C6.23015 10.0582 5.81806 8.98178 5.81806 7.86442Z"
			fill="#EB7D68"
		/>
		<path
			d="M14.1046 8.31637C14.1235 8.16241 14.1331 8.01043 14.1331 7.86469C14.1331 5.82339 12.4634 4.16269 10.4109 4.16269C8.35849 4.16269 6.68872 5.82339 6.68872 7.86469C6.68872 8.69835 6.9626 9.4866 7.48074 10.1442L8.50872 9.34302C8.17246 8.91627 7.99471 8.40505 7.99471 7.86469C7.99471 6.53963 9.07859 5.46164 10.4109 5.46164C11.7432 5.46164 12.827 6.53963 12.827 7.86469C12.827 7.95916 12.8209 8.05529 12.8081 8.15855L14.1046 8.31637Z"
			fill="#EB7D68"
		/>
		<path
			d="M18.3776 11.7815C17.7645 11.7815 17.2675 12.2758 17.2675 12.8856H16.8322V11.696C16.8322 11.0863 16.3351 10.5919 15.722 10.5919C15.1089 10.5919 14.6119 11.0863 14.6119 11.696V12.8856H14.1765V10.6095C14.1765 9.99977 13.6795 9.50544 13.0664 9.50544C12.4533 9.50544 11.9563 9.99977 11.9563 10.6095V12.8856H11.5209V7.86512C11.5209 7.25535 11.0239 6.76102 10.4108 6.76102C9.79772 6.76102 9.30068 7.25535 9.30068 7.86512V16.0398H8.86534V13.3738C8.86534 12.7641 8.36831 12.2697 7.75521 12.2697C7.14211 12.2697 6.64508 12.7641 6.64508 13.3738V17.4374C6.64508 19.8924 8.65328 21.8897 11.1217 21.8897H15.0112C17.4796 21.8897 19.4878 19.8924 19.4878 17.4374V12.8856C19.4878 12.2758 18.9907 11.7815 18.3776 11.7815Z"
			fill="#EB7D68"
		/>
	</svg>
);
export const MoneyIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12.31 11.14C10.54 10.69 9.97 10.2 9.97 9.47C9.97 8.63 10.76 8.04 12.07 8.04C13.45 8.04 13.97 8.7 14.01 9.68H15.72C15.67 8.34 14.85 7.11 13.23 6.71V5H10.9V6.69C9.39 7.01 8.18 7.99 8.18 9.5C8.18 11.29 9.67 12.19 11.84 12.71C13.79 13.17 14.18 13.86 14.18 14.58C14.18 15.11 13.79 15.97 12.08 15.97C10.48 15.97 9.85 15.25 9.76 14.33H8.04C8.14 16.03 9.4 16.99 10.9 17.3V19H13.24V17.33C14.76 17.04 15.96 16.17 15.97 14.56C15.96 12.36 14.07 11.6 12.31 11.14Z"
			fill="#EB7D68"
		/>
	</svg>
);

export const EyeIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12.31 11.14C10.54 10.69 9.97 10.2 9.97 9.47C9.97 8.63 10.76 8.04 12.07 8.04C13.45 8.04 13.97 8.7 14.01 9.68H15.72C15.67 8.34 14.85 7.11 13.23 6.71V5H10.9V6.69C9.39 7.01 8.18 7.99 8.18 9.5C8.18 11.29 9.67 12.19 11.84 12.71C13.79 13.17 14.18 13.86 14.18 14.58C14.18 15.11 13.79 15.97 12.08 15.97C10.48 15.97 9.85 15.25 9.76 14.33H8.04C8.14 16.03 9.4 16.99 10.9 17.3V19H13.24V17.33C14.76 17.04 15.96 16.17 15.97 14.56C15.96 12.36 14.07 11.6 12.31 11.14Z"
			fill="#EB7D68"
		/>
	</svg>
);

export const ImpressionsIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.01 1.99818C6.48999 1.99818 2.01999 6.45387 2.01999 11.9439C2.01999 17.434 6.48999 21.8896 12.01 21.8896C17.54 21.8896 22.02 17.434 22.02 11.9439C22.02 6.45387 17.54 1.99818 12.01 1.99818ZM12.02 19.9005C7.59999 19.9005 4.01999 16.3399 4.01999 11.9439C4.01999 7.5479 7.59999 3.98733 12.02 3.98733C16.44 3.98733 20.02 7.5479 20.02 11.9439C20.02 16.3399 16.44 19.9005 12.02 19.9005ZM15.52 10.9493C16.35 10.9493 17.02 10.283 17.02 9.45748C17.02 8.63199 16.35 7.96562 15.52 7.96562C14.69 7.96562 14.02 8.63199 14.02 9.45748C14.02 10.283 14.69 10.9493 15.52 10.9493ZM8.51999 10.9493C9.34999 10.9493 10.02 10.283 10.02 9.45748C10.02 8.63199 9.34999 7.96562 8.51999 7.96562C7.68999 7.96562 7.01999 8.63199 7.01999 9.45748C7.01999 10.283 7.68999 10.9493 8.51999 10.9493ZM12.02 17.4141C14.05 17.4141 15.82 16.3101 16.77 14.679C16.96 14.3508 16.72 13.9331 16.33 13.9331H7.70999C7.32999 13.9331 7.07999 14.3508 7.26999 14.679C8.21999 16.3101 9.98999 17.4141 12.02 17.4141Z"
			fill="#EB7D68"
		/>
	</svg>
);

export const PeopleIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9 11.9439C10.93 11.9439 12.5 10.3824 12.5 8.4629C12.5 6.54338 10.93 4.9819 9 4.9819C7.07 4.9819 5.5 6.54338 5.5 8.4629C5.5 10.3824 7.07 11.9439 9 11.9439ZM9 6.97105C9.83 6.97105 10.5 7.63741 10.5 8.4629C10.5 9.2884 9.83 9.95476 9 9.95476C8.17 9.95476 7.5 9.2884 7.5 8.4629C7.5 7.63741 8.17 6.97105 9 6.97105ZM9.05 16.9168H4.77C5.76 16.4195 7.47 15.9222 9 15.9222C9.11 15.9222 9.23 15.9321 9.34 15.9321C9.68 15.2061 10.27 14.6094 10.98 14.132C10.25 14.0027 9.56 13.9331 9 13.9331C6.66 13.9331 2 15.0967 2 17.4141V18.9059H9V17.4141C9 17.245 9.02 17.0759 9.05 16.9168ZM16.5 14.4303C14.66 14.4303 11 15.4349 11 17.4141V18.9059H22V17.4141C22 15.4349 18.34 14.4303 16.5 14.4303ZM17.71 12.6202C18.47 12.1926 19 11.3869 19 10.4521C19 9.07954 17.88 7.96562 16.5 7.96562C15.12 7.96562 14 9.07954 14 10.4521C14 11.3869 14.53 12.1926 15.29 12.6202C15.65 12.8191 16.06 12.9385 16.5 12.9385C16.94 12.9385 17.35 12.8191 17.71 12.6202Z"
			fill="#EB7D68"
		/>
	</svg>
);

export const FrequencyIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21 4H3C1.9 4 1 4.9 1 6V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V6C23 4.9 22.1 4 21 4ZM3 19V6H11V19H3ZM21 19H13V6H21V19ZM14 9.5H20V11H14V9.5ZM14 12H20V13.5H14V12ZM14 14.5H20V16H14V14.5Z" fill="#EB7D68"/>
	</svg>
);
