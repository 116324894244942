import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import BaseAvatar from "app/components/avatar";
import Button from "app/uikit/button/active-button";
import Loader from "app/components/loader";
import { CompassIcon, WarningIcon } from "app/icons";
import { Alert } from "@material-ui/lab";
import ProfileTabBox from "app/components/profile-tab-box";
import PaymentMethodList from "app/components/payment-method-list";
import UserProfileWrapper from "app/components/user-profile-layout";
import Table from "app/uikit/table";
import ModalResetPassword from "../modals/reset-password";
import ModalEditProfile from "../modals/edit-profile";
import actions from "app/store/users/actions"
import columns from "./columns";

const data = [
	{
		date: "",
		description: "",
		period: "",
		key:'key',
		payment_method: {
			type: "",
			lastDigits: ""
		},
		total: {
			sum: "",
			currency: ""
		}
	}
];

const ProfileTab = props => {
	const {
		user,
		profileLoading,
		actionLoading,
		actionErrors
	} = useSelector(state => state.users);

	const dispatch = useDispatch();

	const {
		match: { params }
	} = props;

	const [modalResetPassword, setModalResetPassword] = useState(false);

	const [modalEditProfile, setModalEditProfile] = useState(false);

	const { email, created_at, full_name, avatar } = user;

	useEffect(() => {
		dispatch(actions.getUser(params.id));
	}, [dispatch]);

	const handleOpenModalResetPassword = () => {
		setModalResetPassword(true);
	};

	const handleCloseModalResetPassword = () => {
		setModalResetPassword(false);
	};

	const handleOpenModalEditProfile = () => {
		setModalEditProfile(true);
	};

	const handleCloseModalEditProfile = () => {
		setModalEditProfile(false);
	};

	const onSubmitEditProfile = data => {
		dispatch(actions.fetchEditUser(params.id, data, handleCloseModalEditProfile));
	};
	
	const onResetPassword = () => {
		dispatch(actions.fetchResetPasswordUser(params.id, handleCloseModalResetPassword));
	};

	return (
		<UserProfileWrapper date={created_at}>
			<Loader loading={profileLoading}>
				<div className="profile-grid">
					<ProfileTabBox className="profile-user">
						<BaseAvatar name={full_name} src={avatar?.url} />
						<p className="title-h3 profile-user-name">{full_name}</p>
						<p className="main-text profile-user-email">{email}</p>
						<div className="profile-item-footer">
							<Button colorVariant="danger" size="medium" disabled>
								Delete
							</Button>
							<Button colorVariant="ghost" onClick={handleOpenModalEditProfile} size="medium">
								Edit
							</Button>
						</div>
					</ProfileTabBox>

					<ProfileTabBox className="profile-tariff" title="Tariff plan">
						<div className="profile-tariff-heading">
							<div className="profile-tariff-heading__icon">
								<CompassIcon />
							</div>
							<div className="profile-tariff-heading__price">
								<span className="title-h3">$0</span> / month
							</div>
						</div>
						<p className="title-h3 profile-tariff__name">Base</p>
						<p className="text-main profile-tariff__description">We'll provide your tariff plan description a bit later</p>
						<div className="profile-item-footer">
							<Button colorVariant="ghost" size="medium">
								Cancel subscription
							</Button>
						</div>
					</ProfileTabBox>

					<ProfileTabBox title="Password">
						<div className="alert">
							<Alert severity="warning" icon={false}>
								<WarningIcon />
								<span className="small-text">
									A new password will be sent to the user on his email. Please make sure you really want to reset user's
									password.
								</span>
							</Alert>
						</div>
						<div className="profile-item-footer">
							<Button colorVariant="ghost" onClick={handleOpenModalResetPassword} size="medium">
								Reset password
							</Button>
						</div>
					</ProfileTabBox>

					<ProfileTabBox autoHeight className="profile-payment" title="Payment method">
						<PaymentMethodList rows={[]} />
					</ProfileTabBox>

					<div className="profile-history">
						<Table
							title={<p className="title-subheading title-subheading_bold">Payment history</p>}
							// toolbar={
							// 	<p className="small-text">
							// 		Next payment at <strong>24 February 2021</strong>
							// 	</p>
							// }
							// loading={isLoading}
							loading={profileLoading}
							columns={columns}
							data={data}
							// footer={
							// 	<Footer
							// 		pagination={{
							// 			current_page: 1,
							// 			total: 13,
							// 			per_page: 5
							// 		}}
							// 	/>
							// }
						/>
					</div>
				</div>
			</Loader>
			<ModalEditProfile
				errors={actionErrors}
				loading={actionLoading}
				onSubmit={onSubmitEditProfile}
				data={{ full_name, email }}
				onClose={handleCloseModalEditProfile}
				open={modalEditProfile}
			/>
			<ModalResetPassword
				loading={actionLoading}
				onAgree={onResetPassword}
				name={full_name}
				onClose={handleCloseModalResetPassword}
				open={modalResetPassword}
			/>
		</UserProfileWrapper>
	);
};

export default withRouter(ProfileTab);
