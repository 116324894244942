import React, { useLayoutEffect, useCallback } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import actions from "app/store/campaign/actions";

import StepThreeBase from "../../../steps/step-3";

const StepThree = ({ onChangeStep, step, ...rest }) => {
	const dispatch = useDispatch();
	const {
		creatives: { items, params },
		creativesLoading,
	} = useSelector(state => state.campaigns);

	useLayoutEffect(() => {
		dispatch(actions.getTempaltes(params));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const onChangeFilter = useCallback( value =>  {
		dispatch(actions.getTempaltes({ ...params, ...value, offset: null }));
	},[dispatch,params]);

	const onSubmit = ({creative},shouldNotUpdate,shouldNotoNextStep) => {
		if(!shouldNotUpdate){
			const findCreative = items.find(el=>el.id === +creative);
			dispatch(actions.setDataCreateCampaigns({ creative: findCreative}));
		}
		if(!shouldNotoNextStep){
			onChangeStep(++step);
		}
	};

	return (
		<StepThreeBase
			onSubmit={onSubmit}
			creatives={items}
			loading={creativesLoading}
			onChangeFilter={onChangeFilter}
			params={params}
			{...rest}
		/>
	);
};

export default StepThree;
