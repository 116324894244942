export const PERMISSIONS = {
    "admin_users": 'admin_users',
    "admin_sheets": 'admin_sheets',
    "account_sheets": 'account_sheets',
    "account_integrations": 'account_integrations',
    "account_creatives": 'account_creatives',
    "account_campains": 'account_campains',
    "account_reports": 'account_reports',
    "account_content_generation": 'account_content_generation',
    "admin_general_settings": 'admin_general_settings',
    "admin_contact_categories": 'admin_contact_categories',
    "admin_statistic": 'admin_statistic'
};