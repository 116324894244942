import React, { useEffect } from "react";
import { withFormik } from "formik";
import { validationSchemaProfile } from "./validationSchema.js";
import Input from "app/uikit/input";
import { handlerErrors } from "app/helper/handlerErrors";
import DropZone from "app/components/dropzone/dropZoneAvatar";


const ProfileCard = props => {
	const {
		onChange,
		validate,
		onValidate,
		isValid,
		values,
		error,
		loading,
		dirty,
		handleChange,
		touched,
		handleSubmit,
		errors,
		setFieldValue,
		setFieldTouched,
		setErrors,
		resetForm,
		initialValues,
		avatar
	} = props;
	const form = { values, handleChange, touched, errors, setFieldValue, setFieldTouched };

	useEffect(() => {
		if (!onChange) return;
		if (dirty) {
			onChange({ profile: values });
		} else {
			onChange({ profile: null });
		}
		const isDirty = Object.keys(values).some(el => values[el]);
		if (!isDirty) {
			resetForm();
			onValidate({});
		}
	}, [values, dirty]);

	useEffect(() => {
		onValidate({ profile: isValid });
	}, [isValid, values]);

	useEffect(() => {
		if (validate) {
			handleSubmit();
		}
	}, [validate]);

	useEffect(() => {
		handlerErrors(error, setErrors, initialValues);
	}, [error, setErrors]);

	useEffect(() => {
		if (!loading && !error) {
			resetForm({
				values: {
					name: props.name || "",
					email: props.email || "",
					avatar: []
				}
			});
		}
	}, [error, loading]);

	return (
		<div className="rounded-box">
			<h3 className="title-subheading title-subheading_bold title-for-box m-b--24">Profile settings</h3>
			<DropZone fullWidth name="avatar" form={form} src={avatar?.url} />
			<Input fullWidth name="name" form={form} label="Full name" required />
			<Input fullWidth name="email" form={form} type="email" label="Email" required />
		</div>
	);
};

export default withFormik({
	mapPropsToValues: props => {
		return {
			name: props.name || "",
			email: props.email || "",
			avatar: []
		};
	},
	validationSchema: validationSchemaProfile
})(ProfileCard);
