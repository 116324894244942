import React, { useMemo, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Button from "app/uikit/button/active-button";
import Select from "app/uikit/select";
import Autocomplete from "app/uikit/autocomplete";
import style from "./style.module.scss";
import { getPlatformList } from "app/helper/platform_helper";
import actions from "app/store/reports/actions";
import config from "app/config";
import { PLATFORMS } from "app/emun/platforms";
import serviceCampaign from "app/store/campaign/services";
import {FilterContainer, FilterItem} from "app/components/filter-block";

const ReportsFilter = () => {
	const dispatch = useDispatch();
	const [filterCampaing, setFilterCampaing] = useState({});
	const { integration_type, facebook_ads_date_presets } = useSelector(state => state.handBooks);
	const { adAccounts } = useSelector(state => state.integrations);
	const { isLoading, filter } = useSelector(state => state.reports);
	const listPlatform = getPlatformList(integration_type, true);

	const handleSubmit = values => {
		const { integration_type_name, integration_ad_account_id, campaign_id, date_preset } = values;
		const integration_type_id = integration_type.find(el => el.system_name === integration_type_name);
		let requesData = {
			"filter[integration_type_id]": integration_type_id?.id,
			"filter[integration_ad_account_id]": integration_ad_account_id,
			"filter[campaign_id]": { id: campaign_id.id, name: campaign_id.name },
			"filter[date_preset]": date_preset
		};
		if (!requesData["filter[campaign_id]"].id) delete requesData["filter[campaign_id]"];
		localStorage.setItem("reportFilter", JSON.stringify(requesData));
		dispatch(actions.fetchFilter(requesData));
	};

	const initialValues = {
		integration_type_name: PLATFORMS.facebook_ads,
		integration_ad_account_id: filter["filter[integration_ad_account_id]"] || "",
		campaign_id: filter["filter[campaign_id]"] || { id: "", name: "All campaigns" },
		date_preset: filter["filter[date_preset]"] || config.ui.dateReport
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (integration_type.length) {
			dispatch(actions.getDefaultReports(initialValues, integration_type));
		}
	}, [integration_type, dispatch]);

	const accontsFormated = adAccounts && adAccounts[formik.values.integration_type_name];
	const accountsList = accontsFormated?.ad_accounts?.map(el => ({ value: el.id, text: el.name })) || [];

	const dateList = useMemo(() => facebook_ads_date_presets?.map(el => ({ value: el.alias, text: el.name })), [
		facebook_ads_date_presets
	]);

	const handleChangeIntegrationAccount = id => {
		formik.setFieldValue("integration_ad_account_id", id);
		formik.setFieldValue("campaign_id", { id: "", name: "All campaigns" });
		dispatch(
			actions.getCampaignByAdAccount({
				"filter[integration_ad_account_id]": id,
				"filter[is_except_draft]": true,
				limit: 10
			})
		);
	};
	useEffect(() => {
		if (formik.values.integration_ad_account_id !== filterCampaing["filter[integration_ad_account_id]"]) {
			setFilterCampaing({
				"filter[integration_ad_account_id]": formik.values.integration_ad_account_id,
				"filter[is_except_draft]": true
			});
		}
	}, [formik.values.integration_ad_account_id]);

	return (
		<FilterContainer>
			<FilterItem  className={style.filterItem}>
				<Select name="integration_type_name" form={formik} list={listPlatform} fullWidth />
			</FilterItem>
			<FilterItem className={style.filterItem}>
				<Select
					name="integration_ad_account_id"
					list={accountsList}
					form={formik}
					onChange={handleChangeIntegrationAccount}
					fullWidth
					placeholder="Your Ad account"
				/>
			</FilterItem>
			<FilterItem className={style.filterItem}>
				<Autocomplete
					required
					fullWidth
					withFilter
					disablePortal
					name="campaign_id"
					initialOptions={[{ id: "", name: "All campaigns" }]}
					filter={filterCampaing}
					action={serviceCampaign.getCampaigns}
					form={formik}
					placeholder="Search target campaign by it's name"
					noOptionsText="No campaigns have been found by your request"
				/>
			</FilterItem>
			<FilterItem className={style.filterItem}>
				<Select name="date_preset" form={formik} list={dateList} hasAllSelect fullWidth />
			</FilterItem>
			<FilterItem className={style.filterItem}>
				<Button loading={isLoading} fullWidth onClick={formik.handleSubmit} size="medium" colorVariant="gradient">
					Refresh
				</Button>
			</FilterItem>
		</FilterContainer>
	);
};

export default ReportsFilter;