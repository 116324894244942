import types from "./types";
import config from "app/config/config";
import { mappedOffset, getRecalkPagination } from "../helper";

export const initialState = {
	employees: {
		count: 0,
		items: []
	},
	error: "",
	isLoading: false,
	deleteLoading: false,
	actionUserLoading: false,
	actionedUser: false,
	actionUserError: "",
	filter: {
		sort_by: "updated_at",
		sort_direction: "desc"
	},
	pagination: {
		total: 0,
		limit: config.ui.pagination.size,
		page: 1
	}
};

const reducer = function (state = initialState, action) {
	const baseValues = {
		...state,
		isLoading: false,
		error: ""
	};
	switch (action.type) {
		case types.GET_EMPLOYEES_REQUEST:
			return {
				...baseValues,
				isLoading: true
			};
		case types.GET_EMPLOYEES_SUCCESS:
			return {
				...baseValues,
				employees: action.data,
				pagination: { ...state.pagination, total: action?.data?.count || 0 }
			};
		case types.GET_EMPLOYEES_FAILURE:
			return {
				...baseValues,
				error: action.error
			};
		case types.DELETE_EMPLOYEES_REQUEST:
			return {
				...state,
				deleteLoading: true
			};
		case types.DELETE_EMPLOYEES_SUCCESS:
			const pagination = getRecalkPagination(state, state.pagination,action.data,'employees');
			return {
				...state,
				pagination:pagination,
				deleteLoading: false
			};
		case types.DELETE_EMPLOYEES_FAILURE:
			return {
				...state,
				deleteLoading: false
			};

		case types.FETCH_NEW_EMPLOYEE_REQUEST:
		case types.FETCH_EDIT_EMPLOYEE_REQUEST:
			return {
				...state,
				actionUserLoading: true,
				actionedUser: false
			};
		case types.FETCH_NEW_EMPLOYEE_SUCCESS:
		case types.FETCH_EDIT_EMPLOYEE_SUCCESS:
			return {
				...state,
				actionedUser: true,
				actionUserLoading: false,
				actionUserError: ""
			};
		case types.FETCH_NEW_EMPLOYEE_FAILURE:
		case types.FETCH_EDIT_EMPLOYEE_FAILURE:
			return {
				...state,
				actionUserLoading: false,
				actionUserError: action.error
			};
		case types.FETCH_PAGINATION_EMPLOYEE_SUCCESS:
			return {
				...state,
				pagination: { ...state.pagination, ...action.data, offset: mappedOffset(action.data, state.pagination)}
			};
		default:
			return state;
	}
};

export default reducer;
