import React from "react";
import Radio from "app/uikit/radio";
import { FormError } from "app/uikit/form-components";
import style from "./style.module.scss";
import cl from "classnames/bind";

const LIST = [
	{
		name: "intersection",
		label: "Intersection of audiences"
	},
	{
		name: "union",
		label: "Combining audiences"
	}
];
const ModalChoseAudience = ({ form, name }) => {
	const handleChange = e => {
		form.setFieldValue(name, e.target.value);
	};
	return (
		<div className={style.container}>
			<div className={style.contant}>
				{LIST.map(elem => (
					<div key={elem.name} className={style.item}>
						<label className={style.label} htmlFor={elem.name}>
							<Radio value={elem.name} name={name} id={elem.name} onChange={handleChange} checked={form.values[name] === elem.name} />
							<span className={cl({ "font--700": form.values[name] === elem.name })}>{elem.label}</span>
						</label>
					</div>
				))}
			</div>
			
			<FormError
				className={style.error}
				error={(form.touched[name]) && Boolean(form.errors[name])}
				text={(form.touched[name]) && form.errors[name]}
			/>
		</div>
	);
};

export default ModalChoseAudience;
