import React from "react";
import { CampaignCreatedIcon } from "../../../../../icons";
import ConfirmModal from "../../../../../components/modal/confirm-modal.jsx";

const ModalCampaignCreate = ({ onClose, open,onSubAgree, onAgree,loadingSubAgree, loading, name }) => {
	return (
		<div>
            <ConfirmModal
				open={open}
				title="Start campaign?"
				showCloseIcon
				icon={<CampaignCreatedIcon />}
				loadingSubAgree={loadingSubAgree}
				loading={loading}
				onClose={onClose}
				onAgree={onAgree}
				onSubAgree={onSubAgree}
				text={<>Would you like to start this campaign: <br/> <strong>{name}</strong> ?</>}
				textAgree="Save & Start"
				textClose="Save & Close"
			/>
		</div>
	);
};

export default ModalCampaignCreate;
