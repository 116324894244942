import React, { useRef, useState, useEffect } from "react";
import style from "./style.module.scss";
import reactions from "assets/img/reactions.png";
import planet from "assets/img/planet.png";
import { domainFromUrl } from "app/helper";
import bannerDesktop from "assets/img/default-banner_desktop.png";
import bannerMobile from "assets/img/default-banner_mobile.png";
import Button from "@material-ui/core/Button";
import cs from "classnames/bind";
import Loader from "app/components/loader";
import { RobotIcon } from "app/icons";

const DesktopAdPreview = ({ description, banner, webSite, headline, nameAction, loadingImg, isMobile }) => {
	const [openDesc, setOpenDesc] = useState(false);
	const [lotOfText, setLotOftext] = useState(false);
	const refWrapper = useRef();
	const redDesc = useRef();
	const cx = cs.bind(style);
	const showMore = !openDesc && lotOfText;
	const onOpenText = () => {
		setOpenDesc(true);
	};

	useEffect(() => {
		if (!refWrapper.current && !redDesc.current) return;
		setLotOftext(refWrapper.current.clientHeight < redDesc.current.clientHeight);
	}, [refWrapper.current?.clientHeight, redDesc.current?.clientHeight, isMobile]);

	const newWebSite = webSite && !/^https?:\/\//i.test(webSite) ? 'http://' + webSite : webSite || webSite;
	
	return (
		<div className={cx("mockup", { mockup_mobile: isMobile })}>
			<div className={style.mockup_heading}>
				<div className={style.mockup_heading_page}>
					<div className={style.mockup_heading_page__photo}>
						<RobotIcon />
					</div>
					<div className={style.mockup_heading_page__info}>
						<p className={style.mockup_heading_page__info_title}>Your page name</p>
						<p className={style.mockup_heading_page__info_caption}>
							Sponsored &bull; <img src={planet} alt="planet icon" />
						</p>
					</div>
				</div>
				<div className={cx("mockup_heading_holder", { "mockup_heading_holder-open": openDesc })} ref={refWrapper}>
					<Button onClick={onOpenText} className={cx("mockup_heading_seeMore", { "mockup_heading_seeMore-show": showMore })}>
						...See More
					</Button>
					<p ref={redDesc} className={cs(style.mockup_heading_text, style.mockup_heading_text_short)}>
						<span className={style.description}>{description || "Primary Text of your creative"}</span>
					</p>
				</div>
			</div>
			<div className={style.mockup_banner}>
				<Loader height={254} position="relative" loading={loadingImg}>
					<img className={style.mockup_banner_img} src={banner || (isMobile ? bannerMobile : bannerDesktop)} alt="banner" />
				</Loader>
			</div>
			<div className={style.mockup_cta}>
				<div className={style.mockup_cta_text}>
					{(newWebSite && (
						<a href={newWebSite} rel="noreferrer" target="_blank" className={style.mockup_cta__link}>
							{domainFromUrl(newWebSite) || "EXAMPLESITE.COM"}
						</a>
					)) || <span className={style.mockup_cta__link}>"EXAMPLESITE.COM"</span>}
					<p className={style.mockup_cta__title}>{headline || "Headline"}</p>
					<p className={style.mockup_cta__caption}>News Feed Link Description</p>
				</div>
				{(newWebSite && (
					<a href={newWebSite} rel="noreferrer" target="_blank" className={style.mockup_cta_button}>
						{nameAction}
					</a>
				)) || <span className={style.mockup_cta_button}>{nameAction}</span>}
			</div>
			<div className={style.mockup_reactions}>
				<img src={reactions} alt="reactions" />
			</div>
		</div>
	);
};

export default DesktopAdPreview;
