import React, { useEffect, useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { InputLabel, FormControl, Tooltip, Slider } from "@material-ui/core";
import { Info } from "app/icons";
import classNames from "classnames";
import debounce from "lodash.debounce";

const useStyles = makeStyles({
	formControl: {
		width: props => (props.fullWidth ? "100%" : "auto"),
		margin: 0,
		marginTop: "16px",
		marginBottom: "3px"
	},
	label: {
		transform: "none",
		position: "static",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: "14px",
		lineHeight: "22px",
		color: "#56637B",
		marginBottom: "7px",
		"& + .MuiInput-formControl": {
			margin: 0
		},
		"&.Mui-focused": {
			color: "#38465D"
		}
	},
	info: {
		marginLeft: "10px",
		fontSize: "0px",
		lineHeight: "0px",
		cursor: "pointer"
	}
});
const PrettoSlider = withStyles({
	root: {
		color: "#EC856A",
		height: 8
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		marginTop: -8,
		marginLeft: -12,
		"&:focus, &:hover, &$active": {
			boxShadow: "inherit"
		}
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 4px)"
	},
	track: {
		height: 8,
		borderRadius: 4
	},
	rail: {
		height: 8,
		borderRadius: 4
	}
})(Slider);

const InpurDefaul = props => {
	const {
		className,
		wrapperClassName,
		labelClassName,
		info,
		label,
		infoPlacement = "right",
		name,
		icon,
		fullWidth,
		required,
		onChange,
		variant,
		value,
		...rest
	} = props;
	const classes = useStyles({ fullWidth });
	const [currentValue, setCurrentValue] = useState(0);

	const delayed = useCallback(
		debounce(v => onChange(v), 200),
		[debounce]
	);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	const handleChange = (event, newValue) => {
		delayed(newValue);
		setCurrentValue(newValue);
	};

	return (
		<FormControl variant={variant} className={classNames(classes.formControl, wrapperClassName)}>
			{(label || info) && (
				<InputLabel className={classNames(classes.label, labelClassName)} htmlFor={name}>
					{
						<>
							{label}
							{required && <span className="required">&nbsp;*</span>}
						</>
					}
					{info && (
						<Tooltip title={info} placement={infoPlacement}>
							<div className={classes.info}>
								<Info />
							</div>
						</Tooltip>
					)}
				</InputLabel>
			)}
			<PrettoSlider
				valueLabelDisplay="auto"
				aria-label="custom thumb label"
				id={name}
				value={currentValue}
				name={name}
				onChange={handleChange}
				{...rest}
			/>
		</FormControl>
	);
};
export default InpurDefaul;