import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "app/store/integrations/actions";
import Button from "app/uikit/button/active-button";
import fb from "./initial-fb";

const fbId = 1;

const ConnectFaceBook = ({ add, className, loading, children, size, colorVariant, disabled }) => {
	const { integration_type } = useSelector(state => state.handBooks);
	const dispatch = useDispatch();
	const onLogin = async () => {
		try {
			const {
				authResponse: { accessToken },
				status
			} = await fb.login();
			const name = integration_type.find(el => el.id === fbId);
			const requestData = {
				access_token: accessToken
			};
			if(status !== 'connected') return;
			if (add !== undefined) {
				dispatch(actions.connectIntegration({ ...requestData, integrationId: add }));
			} else {
				dispatch(
					actions.connectIntegration({
						...requestData,
						integration_type_system_name: name?.system_name
					})
				);
			}
		} catch (e) {}
	};
	return (
		<Button disabled={disabled} onClick={onLogin} loading={loading} size={size} colorVariant={colorVariant} className={className}>
			{children}
		</Button>
	);
};

export default ConnectFaceBook;
