import React, { useState, useEffect } from "react";
import { Fade, Modal, Backdrop } from "@material-ui/core";
import Alert from "app/uikit/alert";
import Button from "../../uikit/button/active-button";
import ButtonActive from "../../uikit/button/active-button";
import cl from "classnames";

const CustomModal = ({
	open: openProps,
	iconTitle,
	onClose,
	title,
	showCloseIcon,
	children,
	resetForm,
	size,

	onSend = () => {},
	closeBtnText,
	buttonClose,
	hideButtonAgree,
	buttonAgree,
	buttonAgreeText,
	disabled,
	className,
	classNameFooter,
	classNameFooterInner,
	loadingAgree,
	footer,
	error
}) => {
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		if (onClose) {
			return onClose(false);
		}
		setOpen(false);
	};
	useEffect(() => {
		setOpen(openProps);
		if (openProps) {
			resetForm && resetForm();
		}
	}, [openProps, resetForm]);

	return (
		<Modal
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			open={open}
			className="modal"
			disableEnforceFocus={true}
			disableRestoreFocus={true}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div>
					<div className="modal-outer">
						<div className={cl("modal-inner", className, { 
							"modal-inner--middle": size === "middle",
							"modal-inner--auto": size === 'auto' 
							})}>
							{title && (
								<h2 className="title-subheading title-subheading_bold modal-title">
									{iconTitle && <div className="title-subheading-icon">{iconTitle}</div>} <span>{title}</span>
								</h2>
							)}
							<div className="modal-content">{children}</div>
							{showCloseIcon && <button className="modal-close-icon" onClick={handleClose}></button>}
							{
								footer && <div className={cl("modal-footer", "modal-footer--static", classNameFooter, { "modal-footer_with-warning": error })}>
								{error && <Alert type="error" text={error} />}
									<div className={cl(classNameFooterInner,"modal-footer__inner")}>
										{buttonClose || (
											<Button classNameWrapper="modal-footer__btn" onClick={handleClose} size="large" colorVariant="ghost">
												{closeBtnText || "Cancel"}
											</Button>
										)}
										{buttonAgree || !hideButtonAgree && (
											<ButtonActive loading={loadingAgree} classNameWrapper="modal-footer__btn" size="large" colorVariant="gradient" onClick={onSend} disabled={disabled}>
												{buttonAgreeText || "Send request"}
											</ButtonActive>
										)}
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default CustomModal;