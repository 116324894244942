import React from "react";

const Statistic = ({data})=>{
    if(!data || !Object.keys(data).length) return null;
    const {all_time, last_day, last_month} = data;
    return (
        <>
            <span className="main-text__multiple-items">
                Total: <strong>{all_time}</strong> users
            </span>{" "}
            <span className="main-text__multiple-items">
                30 days: <strong>{last_month}</strong> users
            </span>{" "}
            <span className="main-text__multiple-items">
                Today: <strong>{last_day}</strong> users
            </span>
        </>
    )
}

export default Statistic;