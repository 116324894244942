import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgressBase from "@material-ui/core/CircularProgress";
import classNames from "classnames/bind";

const useStyles = makeStyles({
	wrapper: {
		position: props => props.position || "relative",
		height: props => props.height || props.size,
		backgroundColor: props => props.colorBg || "transparent",
		animation: props =>
			`fade-in 0s linear backwards infinite ${props.delayLoader !== undefined ? props.delayLoader : 0.25}s`,
		"& .MuiCircularProgress-indeterminate": {
			animation: props =>
				`MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite ${
					props.delayLoader !== undefined ? props.delayLoader : 0.25
				}s`
		},
		"& .MuiCircularProgress-svg": {
			animation: props =>
				`fade-in 0s linear backwards infinite ${props.delayLoader !== undefined ? props.delayLoader : 0.25}s`
		}
	},
	wrapperCenter: {
		position: "absolute",
		zIndex: 1250,
		top: 0,
		left: 0,
		width: "100%",
		height: "100%"
	},
	buttonProgress: {
		color: props => props.color || "#fff",
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: props => -(props.size / 2) || -12,
		marginLeft: props => -(props.size / 2) || -12
	}
});

const getVariant = variant => {
	switch (variant) {
		case "black":
			return {
				color: "#000",
				colorBg: "rgba(0,0,0,0.03)"
			};
		default:
			return {};
	}
};

const getSize = size => {
	switch (size) {
		case "big":
			return 50;
		default:
			return size;
	}
};

const CircularProgress = ({ height, color, colorBg, size = 24, wrapper, position, delayLoader, variant, loading, ...rest }) => {
	const mappedSize = useMemo(() => getSize(size), [size]);
	const formatedVariant = getVariant(variant);
	const classes = useStyles({
		height,
		color: formatedVariant.color || color,
		colorBg: formatedVariant.colorBg || colorBg,
		position,
		mappedSize,
		delayLoader
	});
	const cx = classNames.bind(classes);
	if(loading !== undefined && !loading){
		return null;
	}
	if (wrapper || height) {
		return (
			<div className={cx("wrapper", { wrapperCenter: position === "center" })}>
				<CircularProgressBase disableShrink size={mappedSize} className={classes.buttonProgress} {...rest} />
			</div>
		);
	} else {
		return <CircularProgressBase disableShrink size={mappedSize} className={classes.buttonProgress} {...rest} /> ;
	}
};

export default CircularProgress;