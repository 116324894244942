import React from "react";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles({
	root: {
		"&:hover": {
			backgroundColor: "none"
		},
		'& MuiIconButton-label':{
			position: "relative",
		},
		"& input":{
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%'
		}
	},
	icon: {
		width: 16,
		height: 16,
		border: "1px solid #D4D8E2",
		borderRadius: 3,
		backgroundColor: "#fff",
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5"
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)"
		}
	},
	checkedIcon: {
		backgroundColor: "#EB7D68",
		borderColor: "#EB7D68",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		borderRadius: 3,
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.13 7.62L10.91 0.839996L11.81 1.74L4.13 9.42L0.559998 5.85L1.46 4.95L4.13 7.62Z' fill='white'/%3E%3C/svg%3E%0A\")",
			backgroundPosition: "1px 2px",
			backgroundRepeat: "no-repeat",
			content: '""'
		},
		"input:hover ~ &": {
			backgroundColor: "#EB7D68"
		}
	}
});

const CheckboxDefault = props => {
	const classes = useStyles();
	const { className, classNameIcon, ...rest } = props;
	return (
		<Checkbox
			type="checkbox"
			className={classNames(classes.root, className)}
			icon={<span className={classNames(classes.icon, classNameIcon)} />}
			checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
			color="default"
			{...rest}
		/>
	);
};

export default CheckboxDefault;