import React from "react";
import { withRouter } from "react-router";
import UserProfileWrapper from "app/components/user-profile-layout";

const ReportsUser = props => {
	return (
		<UserProfileWrapper>
			<div>
				3301
			</div>
		</UserProfileWrapper>
	);
};

export default withRouter(ReportsUser);
