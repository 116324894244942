import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Bar } from "react-chartjs-2";
import CircularProgress from "app/uikit/CircularProgress";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import actions from "app/store/dashboard/actions";

const VerticalBar = () => {
	const {
		registrations: { points },
		isLoading
	} = useSelector(state => state.dashboard);
	const dispatch = useDispatch();

	const data = useMemo(() => {
		let tempData = {
			labels: [],
			datasets: [
				{
					label: "",
					data: [],
					backgroundColor: ["#EB7D68"],
					borderWidth: 0.2
				}
			]
		};
		points?.forEach(el => {
			tempData.labels.push(el.x);
			tempData.datasets[0].data.push(el.y);
		});
		return tempData;
	}, [points]);

	useEffect(() => {
		dispatch(actions.resetRegistrations());
	}, [dispatch]);
	return (
		<div className={style.chart}>
			<Bar
				data={data}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					},
					scales:{
						y: {
							ticks: {
								beginAtZero: true,
								callback: function(value) {if (value % 1 === 0) {return value;}}
							},
							title: {
								display: true,
								text: 'Registration count',
								padding: 4,
								font: {
									size: 16
								}
							},
						}
					}

				}}
			/>
			<CircularProgress
				loading={!!isLoading.registrations || !!isLoading.registrationsRefresh}
				variant="black"
				height="100%"
				size="big"
				position="center"
			/>
		</div>
	);
};

export default VerticalBar;
