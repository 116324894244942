import React from "react";
import Radio from "app/uikit/radio";
import { FormControlLabel, RadioGroup} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {FormControl} from 'app/uikit/form-components';
import Info from "app/components/info";

const useStyles = makeStyles({
    formControl:{
        display:"flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        "& .MuiFormControlLabel-root":{
            margin: 0,
            marginRight: 24
        }
    },
	radio: {
		margin: 0,
		marginTop: "16px",
		marginBottom: "3px",
        "& .MuiButtonBase-root": {
            padding: 0,
            marginRight: 12,
            "&:hover":{
                backgroundColor: 'transparent'
            }
        },
	}
});

const ChooseBudget = ({name, form, setBudgetType, removeErrors, disabled, info, list})=>{
    const classes = useStyles();

    const handleChangeField = async e =>{
        if(!disabled) return;
        const value = e.target.value;
        await form.setFieldValue(name,value);
        form.setStatus({
            ...form.status,
            dirtyFields:{
                ...form.status?.dirtyFields,
                [name]: true
            }
        })
        setBudgetType(+value);
        form.setFieldValue('end_datetime',null);

        if(+value === 2){
            form.setFieldError('end_datetime', '');
            form.setFieldValue("end_datetime", null);
        }else{
            form.setFieldValue("end_datetime", form.initialValues.end_datetime, true);
        }
        removeErrors(['end_datetime']);
    };
    return (
        <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup
                value={form.values[name]}
				onChange={handleChangeField}
                disabled={!disabled}
				name={name}
                row
            >
                {list?.map(elem=>(
                    <FormControlLabel
                        className={classes.radio}
                        key={elem.id}
                        name={elem.name}
                        value={elem.id}
                        control={<Radio disabled={!disabled} checked={+form.values[name] === elem.id } />}
                        label={elem.name}
                        labelPlacement="end"
                    />
                ))}
            </RadioGroup>
            {info && <Info info={info} />}
        </FormControl>
    )
}
export default ChooseBudget;