import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    formControl: {
        width: props => (props.fullWidth ? "100%" : "auto"),
        margin: 0,
        marginBottom: "16px"
    },
    inputLabel:{
        transform: "none !important",
		position: "static !important",
		display: "flex !important",
		alignItems: "center !important",
		fontSize: "14px !important",
		lineHeight: "22px !important",
		color: "#56637B !important",
		marginBottom: "8px !important",
		"&.label-with-link": {
			display: "flex",
			justifyContent: "space-between",
		
			"& a": {
				color: "#5F74BE",
				fontSize: "inherit",
				fontWeight: 500,
				order: 3
			}
		},
		"& + .MuiInput-formControl": {
			margin: 0
		},
		"&.Mui-focused": {
			color: "#38465D !important",
		}
    },
});

export default useStyles;


