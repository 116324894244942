import React from "react";
import { IconSpreadsheet, IconUploadSpreadsheet } from "app/icons";
import ChooseCreateNewList from "app/components/steps-upload-audiece/create-new-list";
import { STEP_UPLOAD_AUDIENCES_TYPES } from "app/emun";

const items = [
	{
		name: STEP_UPLOAD_AUDIENCES_TYPES.uploadedSheet,
		icon: <IconSpreadsheet />,
		text: "Choose existing list",
		subText: <>Choose one of the already <br /> uploaded lists</>
	},
	{
		name: STEP_UPLOAD_AUDIENCES_TYPES.uploadSheet,
		icon: <IconUploadSpreadsheet />,
		text: "Upload spreadsheet",
		subText: <>Valid file formats are: <br /> .csv .xls.</>
	}
];

const CreateNewList = ({ onClose, changeStep, nextStep }) => {
	const handleSubmit = values => {
		changeStep(nextStep, values.metod);
	};

	return (
		<ChooseCreateNewList
			subTitle="You can select an already loaded audience or download it now."
			items={items}
			onClose={onClose}
			onSubmit={handleSubmit}
			initialValues={{
				metod: STEP_UPLOAD_AUDIENCES_TYPES.uploadedSheet
			}}
		/>
	);
};
export default CreateNewList;