import types from "./types";

const actions = {
	getCampaigns: () => ({
		type: types.FETCH_GET_CAMPAIGNS_REQUEST
	}),
	getCampaignsSuccess: data => ({
		type: types.FETCH_GET_CAMPAIGNS_SUCCESS,
		data
	}),
	getCampaignsFailure: () => ({
		type: types.FETCH_GET_CAMPAIGNS_FAILURE
	}),

	getCompaign: (id,cb) => ({
		type: types.FETCH_GET_CAMPAIGN_REQUEST,
		id,
		cb
	}),
	getCompaignSuccess: data => ({
		type: types.FETCH_GET_CAMPAIGN_SUCCESS,
		data
	}),
	getCompaignFailure: error => ({
		type: types.FETCH_GET_CAMPAIGN_FAILURE,
		error
	}),

	editCompaign: (id, data, cb, cbSussess, actionName) => ({
		type: types.FETCH_EDIT_CAMPAIGN_REQUEST,
		data,
		id,
		cb,
		cbSussess,
		actionName: actionName || "edit"
	}),
	editCompaignSuccess: data => ({
		type: types.FETCH_EDIT_CAMPAIGN_SUCCESS,
		data
	}),

	editCompaignFailure: data => ({
		type: types.FETCH_EDIT_CAMPAIGN_FAILURE,
		data
	}),

	startCompaign: (id, cb, shoudlGetCampaigns) => ({
		type: types.FETCH_START_CAMPAIGN_REQUEST,
		id,
		cb,
		actionName: "start",
		shoudlGetCampaigns

	}),
	startCompaignSuccess: data => ({
		type: types.FETCH_START_CAMPAIGN_SUCCESS,
		data
	}),

	startCompaignFailure: data => ({
		type: types.FETCH_START_CAMPAIGN_FAILURE,
		data
	}),

	pauseCompaign: (id, cb, shoudlGetCampaigns) => ({
		type: types.FETCH_PAUSE_CAMPAIGN_REQUEST,
		id,
		cb,
		actionName: "pause",
		shoudlGetCampaigns
	}),
	pauseCompaignSuccess: data => ({
		type: types.FETCH_PAUSE_CAMPAIGN_SUCCESS,
		data
	}),

	pauseCompaignFailure: data => ({
		type: types.FETCH_PAUSE_CAMPAIGN_FAILURE,
		data
	}),

	restartCompaign: (id, data, cb, shoudlGetCampaigns) => ({
		type: types.FETCH_RESTART_CAMPAIGN_REQUEST,
		id,
		data,
		cb,
		actionName: "restart",
		shoudlGetCampaigns
	}),
	restartCompaignSuccess: data => ({
		type: types.FETCH_RESTART_CAMPAIGN_SUCCESS,
		data
	}),

	restartCompaignFailure: data => ({
		type: types.FETCH_RESTART_CAMPAIGN_FAILURE,
		data
	}),

	deleteItems: (ids, cb) => ({
		type: types.FETCH_DELETE_CAMPAIGNS_REQUEST,
		ids,
		cb
	}),
	deleteItemsSuccess: data => ({
		type: types.FETCH_DELETE_CAMPAIGNS_SUCCESS,
		data
	}),
	deleteItemsFailure: error => ({
		type: types.FETCH_DELETE_CAMPAIGNS_FAILURE,
		error
	}),

	fetchPagination: data => ({
		type: types.FETCH_PAGINATION_CAMPAIGNS_REQUEST,
		data
	}),
	fetchPaginationSuccess: data => ({
		type: types.FETCH_PAGINATION_CAMPAIGNS_SUCCESS,
		data
	}),

	fetchFilter: data => ({
		type: types.FETCH_FILTER_CAMPAIGNS_REQUEST,
		data
	}),
	fetchFilterSuccess: data => ({
		type: types.FETCH_FILTER_CAMPAIGNS_SUCCESS,
		data
	}),

	resetTableParams: () => ({
		type: types.RESET_CAMPAIGNS_TABLE_PARAMS
	}),

	setDataCreateCampaigns: data => ({
		type: types.SET_DATA_CREATE_CAMPAIGNS,
		data
	}),

	getAudiences: () => ({
		type: types.GET_CAMPAIGNS_AUDIENCES_REQUEST
	}),
	getAudiencesSuccess: data => ({
		type: types.GET_CAMPAIGNS_CAMPAIGNS_SUCCESS,
		data
	}),

	getTempaltes: params => ({
		type: types.GET_CAMPAIGNS_CREATIVES_REQUEST,
		params
	}),
	getTempaltesSuccess: data => ({
		type: types.GET_CAMPAIGNS_CREATIVES_SUCCESS,
		data
	}),
	getTempaltesFailure: data => ({
		type: types.GET_CAMPAIGNS_CREATIVES_FAILURE,
		data
	}),

	createCompaign: (data, cbSussess, cbFailur, actionName) => ({
		type: types.CREATE_CAMPAIGNS_REQUEST,
		data,
		cbSussess,
		cbFailur,
		actionName: actionName || "create"
	}),
	createCompaignSuccess: data => ({
		type: types.CREATE_CAMPAIGNS_SUCCESS,
		data
	}),
	createCompaignFailure: data => ({
		type: types.CREATE_CAMPAIGNS_FAILURE,
		data
	}),
	resetDraftCompaign: () => ({
		type: types.RESTE_DRAFT_CAMPAIGN
	})
};
export default actions;
