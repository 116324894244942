import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import actions from "app/store/campaign/actions";
import PATHS from "PATHS";

import Stepreview from "../../../steps/step-review";
import CrreateModal from "../../../modals/campaign-create";

const StepFour = ({ onChangeStep, errors, setHasNotSaveData, setStepsExtended }) => {
	const [modalOpen, setMoldaOpen] = useState(false);
	const dispatch = useDispatch();
	const { createCompaign, actionLoading } = useSelector(state => state.campaigns);
	const history = useHistory();
	const openModalConfirm = () => {
		setMoldaOpen(true);
	};

	const closeModalConfirm = () => {
		setMoldaOpen(false);
	};

	useEffect(()=>{
		setStepsExtended(true);
	},[setStepsExtended])

	const successCreate = () => {
		setHasNotSaveData(false);
		history.push(PATHS.cabinet.campaigns);
	};
	const failureCreate = () => {
		closeModalConfirm();
	};

	const onCreateCompaign = islaunch => {
		let requestData = {
			integration_type_system_name: createCompaign?.platform,
			integration_ad_account_id: createCompaign?.adAccount?.id,
			name: createCompaign?.name,
			audience_id: createCompaign?.audience?.id,
			budget: createCompaign?.budget,
			start_datetime: createCompaign?.start_datetime,
			creative_template_id: createCompaign?.creative?.id,
			budget_type_id: createCompaign?.budget_type_id?.id,
			target_id: createCompaign?.target?.id,
			is_use_similar_audience: createCompaign?.is_use_similar_audience,
			country_ids: createCompaign?.countries?.map((el)=>el.id),
			platform_parameters: {
				page_id: createCompaign?.platform_parameters?.id,
			},
			age_from: createCompaign?.age_from,
			age_to: createCompaign?.age_to,
			gender_type_id: createCompaign.gender_type?.id || createCompaign.gender_type,
			is_launch: islaunch
		};
		if(requestData.budget_type_id !== 2){
			requestData.end_datetime = createCompaign?.end_datetime;
		}
		if(requestData?.target_id === 2){
			requestData.platform_parameters = {
				...requestData.platform_parameters,
				conversion_type_id: createCompaign?.conversion_type_id,
				pixel_id: createCompaign?.pixel_id,
				conversion_id:createCompaign?.conversion_id
			}
		}
		dispatch(actions.createCompaign(requestData, successCreate, failureCreate, !islaunch && "subCreate"));
	};

	const saveAndClose = () => {
		onCreateCompaign(false);
	};

	const saveAndStart = () => {
		onCreateCompaign(true);
	};

	const onSubmit = () => {
		openModalConfirm();
	};

	const changeStepTo = step => {
		onChangeStep(step);
	};

	return (
		<>
			<Stepreview changeStepTo={changeStepTo} errors={errors} onSubmit={onSubmit} data={createCompaign} />
			<CrreateModal
				name={createCompaign?.name}
				loadingSubAgree={actionLoading.subCreate}
				loading={actionLoading.create}
				open={modalOpen}
				onAgree={saveAndStart}
				onSubAgree={saveAndClose}
				onClose={closeModalConfirm}
			/>
		</>
	);
};

export default StepFour;
