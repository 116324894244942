import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserLayout from "app/components/includs/user-layout";
import actions from "app/store/integrations/actions";
import ConnectFaceBook from "app/components/Sdk-facebook";
import IntegrationsCard from "app/components/integration-card";
import { PLATFORMS } from "app/emun/platforms";
import Button from "app/uikit/button/active-button";
import PATHS from "PATHS";
import PageTitle from "../../../components/page-title";

const ScreenIntegrations = () => {
	const {
		integrations: { facebook_ads: { accounts, ad_account_counts } = {} },
		isLoading,
		isConnecting
	} = useSelector(state => state.integrations);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.getIntegrations());
	}, [dispatch]);

	return (
		<UserLayout>
			<PageTitle
				title="Integrations"
				subtitle="Connect your tools to launch advertising campaigns."
			/>

			<div className="cards-wrapper">
				<IntegrationsCard
					loading={isLoading}
					allAccounts={accounts}
					title="Facebook Ads"
					activeAcount={ad_account_counts?.active}
					connectedAccounts={ad_account_counts?.with_access}
					variant={PLATFORMS.facebook_ads}
					button={
						(accounts?.length && (
							<Link to={PATHS.cabinet.editFbIntegrations} className="button button_ghost button_small userflow-fbaccount-edit">
								Edit
							</Link>
						)) || (
							<ConnectFaceBook size="small" className="userflow-fbaccount-connect" colorVariant="ghost" loading={isConnecting}>
								Connect
							</ConnectFaceBook>
						)
					}
				/>
				<IntegrationsCard
					title="Linkedin Ads"
					variant={PLATFORMS.linkedin}
					button={
						<Button size="small" disabled={true} colorVariant="ghost">
								Connect
							</Button>
					}
				/>
				<IntegrationsCard
					title="Google Ads"
					variant={PLATFORMS.google_ads}
					button={
						<Button size="small" disabled={true} colorVariant="ghost">
								Connect
							</Button>
					}
				/>
			</div>
		</UserLayout>
	);
};

export default ScreenIntegrations;
