import React, { useLayoutEffect, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserLayout from "app/components/includs/user-layout";
import actions from "app/store/campaign/actions";
import PATHS from "PATHS";
import PageTitle from "app/components/page-title";
import EditCampaing from "./edit-campaign";
import DeleteModal from "../../modals/delete-campaign";
import Button from "app/uikit/button";

const NewCampaign = props => {
	const { isLoading, campaign, actionError, deleteLoading } = useSelector(state => state.campaigns);
	const [isChanged, setIsChanged] = useState(false);
	const { match: { params } = {} } = props;
	const history = useHistory();
	const dispatch = useDispatch();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const handleCloseModalDelete = () => {
		setDeleteModalOpen(false);
	};
	const toCampaign = () => {
		setIsChanged(false);
		history.push(PATHS.cabinet.campaigns);
	};
	const handleOpenModaDelete = () => {
		setDeleteModalOpen(true);
	};

	const handleDelete = () => {
		dispatch(actions.deleteItems(params.id, toCampaign));
	};

	useEffect(() => {
		dispatch(actions.getCompaign(params.id));
		return () => {
			dispatch(actions.resetDraftCompaign());
		};
	}, [dispatch]);

	useLayoutEffect(() => {
		if (!params.platform) {
			toCampaign();
		}
	}, [params.platform]);
	return (
		<UserLayout headerBackLink={PATHS.cabinet.campaigns} headerBackLinkTitle="Campaigns">
			<PageTitle
				title="Editing campaign"
				titleAddingContent={
					<Button onClick={handleOpenModaDelete} size="small" colorVariant="ghost">
						Delete
					</Button>
				}
			/>
			<EditCampaing
				isChanged={isChanged}
				setIsChanged={setIsChanged}
				actionError={actionError}
				params={params}
				loading={isLoading}
				data={campaign}
			/>
			<DeleteModal
				open={deleteModalOpen}
				loading={deleteLoading}
				onClose={handleCloseModalDelete}
				onAgree={handleDelete}
				name={campaign.name}
			/>
		</UserLayout>
	);
};

export default withRouter(NewCampaign);
