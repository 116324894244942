import React, { useLayoutEffect, useMemo } from "react";
import { withRouter } from "react-router";
import UserProfileWrapper from "app/components/user-profile-layout";
import columns from "../../../cabinet/campaigns/columns";
import { useDispatch, useSelector } from "react-redux";
import { converingData } from "../../../cabinet/creatives/helper";
import Table from "../../../../components/table";
import actions from "app/store/users/actions";
import {DATA_ACTION} from "app/emun"

const actionName = DATA_ACTION.campaigns;


const CampaingsUser = props => {
	const { dataUserLoading, dataUser, dataUserPagination, dataUserFilter, users: { items: userItems }, user } = useSelector(
		state => state.users
	);
	const {
		match: { params }
	} = props;
	const dispatch = useDispatch();

	const currentUser = userItems?.find(el => el.id === +params.id) || user;

	const { created_at } = currentUser;


	useLayoutEffect(() => {
		dispatch(actions.resetUserData());
		dispatch(actions.fetchUserFilter({'filter[user_owner_id]': params.id},actionName));
		if(!created_at){
			dispatch(actions.getUser(params.id));
		}
	}, [dispatch]);


	const onSearch = data =>{
		dispatch(actions.fetchUserFilter({'filter[search]': data},actionName));
	}

	const onPagination = data =>{
		dispatch(actions.fetchUserPagination(data,actionName));
	}

	const formateColumns = useMemo(()=>(columns()),[columns])

	return (
		<UserProfileWrapper date={created_at}>
			<Table
				scroll={{ x: 1104 }}
				filter={dataUserFilter}
				title="Lists of campaigns"
				emptyDataText="No Campaigns have been found by your request."
				loading={dataUserLoading}
				columns={formateColumns}
				data={dataUser.items}
				onChangePagination={onPagination}
				onChangeSearch={onSearch}
				settingsData={{
					check:{
						showId: true,
						hide: true
					},
					menu: {
						hide: true
					}
				}}
				hideElements={{
					delete: true,
					unSelect: true,
					check: true
				}}
				action={actions}
				pagination={dataUserPagination}
				textAgree="OK"
				textClose="Cancel"
			/>
		</UserProfileWrapper>
	);
};

export default withRouter(CampaingsUser);
