import yup from "app/yup";
import directory from "app/directory/errors";
const dir = directory.en;

const validationSchema = yup.object({
	product_name: yup.string()
		.min(1, dir.minLength.replace("#{number}", 1))
		.max(25, dir.maxLength.replace("#{number}", 25))
		.required(dir.required),
	description: yup.string()
		.min(15, dir.minLength.replace("#{number}", 15))
		.max(300, dir.maxLength.replace("#{number}", 300))
		.required(dir.required),
	interested: yup.string()
		.min(3, dir.minLength.replace("#{number}", 3))
		.max(35, dir.maxLength.replace("#{number}", 35))
});

export default validationSchema;