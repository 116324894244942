import React, { useState, useEffect } from "react";
import Switch from "app/uikit/switch";
import cl from "classnames";

const ColumnSwitch = ({ activation, id, account_id, onChange, loading, status, canChange}) => {
	const [active, setActive] = useState({
		isDirty: false,
		active: false
	});
	useEffect(() => {
		if(!loading){
			setActive(state => ({ ...state, active: activation?.id === 1 }));
		}
	}, [activation]);

	const onChangeActive = (value) => {
		setActive(state => ({ ...state, active: value }));
	}

	const handleChange = e => {
		const target = e.target;
		const id = target.dataset.id;
		const account_id = target.dataset.accountId;
		const checked = target.checked;
		onChange(id, account_id, checked, onChangeActive);
	};
	const isDisabled = !canChange || loading || (activation?.id === 2 && status?.id !== 1004);
	return (
		<div className={cl("switch switch_light", { switch_disabled: isDisabled })}>
			<Switch
				onChange={handleChange}
				className={cl({"userflow-adaccount-enable": !active.active && !isDisabled, "userflow-adaccount-disabled":isDisabled, "userflow-adaccount-active": active.active})}
				inputProps={{ "data-id": id, "data-account-id": account_id }}
				active={active.active}
			/>
		</div>
	);
};

export default ColumnSwitch;
