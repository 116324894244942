import React, { useState, useLayoutEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "app/components/table";
import ModalUploadAudience from "./components/modals/upload";
import ModalCategoryAudience from "./components/modals/create-edit";
import UserLayout from "app/components/includs/user-layout";
import PageTitle from "../../../components/page-title";
import Button from "app/uikit/button/active-button";
import actions from "app/store/categories-audience/actions";
import { getTableItemsById } from "app/helper";
import { DeleteCategoryAydienceIcon } from "app/icons";
import columns from "./columns";
import ToolbarHeader from "./components/ToolbarHeader";
import { useMemo } from "react";

const ScreenCategoriesAudience = () => {
	const {
		isLoading,
		pagination,
		categories: { items, count },
		filter,
	} = useSelector(state => state.categoriesAudience);
	const [modalUploadOpen, setModalUploadOpen] = useState(false);
	const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
	const [currentCtegory, setCurrentCtegory] = useState('');
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		dispatch(actions.getCategoriesAudience());
		return () => {
			dispatch(actions.resetTableParams());
		};
	}, [dispatch]);

	const handleOpenModalUpload = () => {
		setModalUploadOpen(true);
	};

	const handleCloseModalUpload = useCallback(() => {
		setModalUploadOpen(false);
	},[]);

	const handleOpenModalCategory = () => {
		setModalCategoryOpen(true);
	};

	const handleCloseModalCategory = useCallback(() => {
		setModalCategoryOpen(false);
	},[]);

	const handleChangeEditItem = (id, key) => {
		switch (key) {
			case "edit":
				setCurrentCtegory(items.find(elem=>elem.id === +id));
				handleOpenModalCategory();
				return;
			default:
				return;
		}
	};
	const deleteAudiences = ids => {
		const names = getTableItemsById(ids, items).map(el => el?.name);
		const namesMap = names.join(", ");
		return (
			<>
				Are you sure that you want to delete this categor{names.length > 1 && "ies" || "y"} : <br />
				<span className="small-text_bold">{namesMap} </span>
			</>
		);
	};
	const onChangePublic = (isPublic, id) => {
		dispatch(actions.fetchPublic(id, { is_visible: isPublic }));
	};

	const formatedData = useMemo(
		() =>
			items.map(elem => ({
				...elem,
				is_visible: (
					<Button
						loading={isLoading.public[elem.id]}
						fullWidth
						size="small"
						colorVariant={(elem.is_visible && "ghost") || "gradient"}
						onClick={() => {
							onChangePublic(!elem.is_visible, elem.id);
						}}
					>
						{(elem.is_visible && "Remove from public") || "Add to public"}
					</Button>
				)
			})),
		[items, isLoading]
	);
	
	const onNewCategory = useCallback(()=>{
		handleOpenModalCategory()
		setCurrentCtegory('');
	},[]);
	
	// const onAddPublic = seleted =>{

	// }
	// const onRemovePublic = seleted =>{

	// }

	return (
		<UserLayout>
			<PageTitle
				title="Categories for Marketplace"
				subtitle={`We have ${count} Categories.`}
				showAddingContent={true}
				subtitleAddingContent={
					<ToolbarHeader actionUpload={handleOpenModalUpload} actionCreate={onNewCategory} />
				}
			/>
			<Table
				data={formatedData}
				columns={columns}
				title="Lists of Categories"
				pagination={pagination}
				action={actions}
				loading={isLoading.table}
				filter={filter}
				emptySearchText="No categories have been found by your request"
				// toolbar={{
				// 	elementList:[
				// 		{
				// 			order: 0,
				// 			text: "Add to public",
				// 			loading: isLoading.table,
				// 			onClick: onAddPublic
				// 		},
				// 		{
				// 			order: 0,
				// 			text: "Remove from public",
				// 			loading: isLoading.table,
				// 			onClick: onRemovePublic
				// 		}
				// 	]
				// }}
				settingsData={{
					menu: {
						onChange: handleChangeEditItem
					}
				}}
				titleDelete={{
					one: "Delete category?",
					many: "Delete categories?"
				}}
				deleteIcon={<DeleteCategoryAydienceIcon />}
				deleteText={deleteAudiences}
				deleteLoading={isLoading.delete}
			/>

			<ModalUploadAudience open={modalUploadOpen} onClose={handleCloseModalUpload} />
			<ModalCategoryAudience data={currentCtegory} open={modalCategoryOpen} onClose={handleCloseModalCategory} />
		</UserLayout>
	);
};

export default ScreenCategoriesAudience;
