import React from "react";
import { Link } from "react-router-dom";
import PATHS from "PATHS";
import { Email } from "app/icons";

const emailVerification = () => {
	return (
		<div className="login-box text-centered">
			<div className="verification-icon m-b--24">
				<Email />
			</div>
			<h1 className="title-main m-b--16">Email verification</h1>
			<p className="m-b--24 main-text">
				Please click on the link that has just been sent to your email account to finish registration process.
			</p>
			<Link to={PATHS.auth.login} className="button button_gradient button_large full-width">
				Go to login
			</Link>
		</div>
	);
};

export default emailVerification;
