import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import actionIntegration from "app/store/integrations/actions";
import actions from "app/store/campaign/actions";

import StepOneBase from "../../../steps/step-1";

const StepOne = ({ onChangeStep, step, platform, ...rest }) => {
	const { adAccounts } = useSelector(state => state.integrations);
	const {createCompaign} = useSelector(state => state.campaigns);
	const dispatch = useDispatch();
	const adAccountsList = adAccounts[platform];
	
	useEffect(() => {
		dispatch(actionIntegration.getAdAccounts());
	}, [dispatch]);


    const onSubmit = (values,shouldNotUpdate, shouldNotoNextStep) =>{
		if(!shouldNotUpdate){
			dispatch(actions.setDataCreateCampaigns(values));
		}
		if(!shouldNotoNextStep){
			onChangeStep(++step);
		}
    };
	
    return <StepOneBase onSubmit={onSubmit} data={createCompaign} listAdAccounts={adAccountsList?.ad_accounts} {...rest}/>
};

export default StepOne;
