import {getRole} from "./helper";

export default [
	{
		title: "Full name",
		dataIndex: "full_name",
		key: "full_name",
		render: value => <div className="text-lines text-lines--word">{value}</div>
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		render: value => <div className="text-lines text-lines--word">{value}</div>
	},
	{
		title: "Role",
		dataIndex: "role",
		key: "role",
		render: (elem) => getRole(elem.id,elem.name)
	}
];
