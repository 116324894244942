import React from "react";
import StatusTable from "./components/status";
import Pill from "app/components/pills";
import {
	PauseIcon,
	PlayIcon,
	StopIcon,
	RestartIconSmall,
	ProblemIcon,
	PlayIconCircle,
	Edit,
	PasswordShown,
	PauseIconCircle,
	DeleteIcon
} from "../../../icons";

export const Status = ({ type, text }) => {
	switch (type) {
		case 1:
			return <Pill color="orange" text={text} icon={<PlayIcon />} />;
		case 2:
			return <Pill color="gray" text={text} icon={<PauseIcon />} />;
		case 3:
			return <Pill color="gray" text={text} icon={<StopIcon />} />;
		case 4:
			return <Pill color="gray" text={text} icon={<Edit />} />;
		case 5:
			return <Pill color="gray" text={text} icon={<PasswordShown />} />;
		case 6:
			return <Pill color="red" text={text} icon={<ProblemIcon />} />;
		case 7:
			return <Pill color="gray" text={text} icon={<DeleteIcon />} />;
		default:
			return null;
	}
};

export const getStatusTable = (type, text, record, actions = {}, isCanStart, isCanRestart) => {
	const { pause, start, restart, edit } = actions;
	const hasActions = actions && !!Object.keys(actions).length;
	const handlePause = () => {
		pause(record);
	};
	const handleRestart = () => {
		restart(record);
	};
	const handleStart = () => {
		start(record);
	};
	const handleEdit = () => {
		edit(record);
	};
	switch (type) {
		case 1:
			return (
				<StatusTable
					color="orange"
					text={text}
					icon={<PlayIcon />}
					iconAction={<PauseIconCircle />}
					action={hasActions && handlePause}
				/>
			);
		case 2:
			return (
				<StatusTable
					color="gray"
					text={text}
					icon={<PauseIcon />}
					iconAction={<PlayIconCircle />}
					action={hasActions && isCanStart && handleStart}
				/>
			);
		case 3:
			return (
				<StatusTable
					color="gray"
					text={text}
					icon={<StopIcon />}
					iconAction={<RestartIconSmall />}
					action={hasActions && isCanRestart && handleRestart}
				/>
			);
		case 4:
			return (
				<StatusTable
					color="gray"
					text={text}
					icon={<Edit />}
					iconAction={<PlayIconCircle />}
					action={hasActions && isCanStart && handleStart}
				/>
			);
		case 5:
			return (
				<StatusTable
					color="gray"
					text={text}
					icon={<PasswordShown />}
					iconAction={<PauseIconCircle />}
					action={hasActions && handlePause}
				/>
			);
		case 6:
			return (
				<StatusTable
					color="red"
					text={text}
					icon={<ProblemIcon />}
					iconAction={<Edit color="#B3BECB" />}
					action={hasActions && handleEdit}
				/>
			);
		case 7:
			return (
				<StatusTable
					color="gray"
					text={text}
					icon={<DeleteIcon />}
					iconAction={<Edit color="#B3BECB" />}
					action={hasActions && handleEdit}
				/>
			);
		default:
			return;
	}
};

const validate = (validationSchema, el, data) => {
	const promise = validationSchema.validateAt(el, data);
	return new Promise(function (resolve, reject) {
		promise.then(
			function () {
				resolve({});
			},
			function (err) {
				if (err.name === "ValidationError") {
					resolve({
						[el]: err.message
					});
				} else {
					reject(err);
				}
			}
		);
	});
};

const checkErrors = (schema, validationSchema, data) => schema.map(el => validate(validationSchema, el, data));

export const validateData = (schema, validationSchema, data, cb) => {
	let errors = {};
	const errorPromise = checkErrors(schema, validationSchema, data);
	Promise.all(errorPromise).then(values => {
		values.map(el => {
			for (let mark in el) {
				errors[mark] = el[mark];
			}
		});
		cb(errors);
	});
};

const formateErrorsForFaceBook = (obj, name, value) => {
	switch (name) {
		case "audience_id":
			obj.audience = value && value[0];
			break;
		case "name":
			obj.name = value && value[0];
			break;
		case "budget":
			obj.budget = value && value[0];
			break;
		case "start_datetime":
			obj.start_datetime = value && value[0];
			break;
		case "end_datetime":
			obj.end_datetime = value && value[0];
			break;
		case "integration_type_system_name":
			obj.platform = value && value[0];
			break;
		case "integration_ad_account_id":
			obj.adAccount = value && value[0];
			break;
		case "platform_parameters.page_id":
			obj.page_id = value && value[0];
			break;
		case "platform_parameters.conversion_id":
		case "platform_parameters.conversion_type_id":
			obj.conversion_id = value && value[0];
			break;
		case "platform_parameters.pixel_id":
			obj.pixel_id = value && value[0];
			break;
		// case "platform_parameters.conversion_event_location":
		// 	obj.conversion_event_location = value && value[0];
		// 	break;
		case "creative_template_id":
			obj.creative = value && value[0];
			break;
		default:
			obj[name] = value && value[0];
	}
};

export const formateNameErrorsForFaceBook = actionError => {
	let newErrors = {};
	for (let mark in actionError) {
		formateErrorsForFaceBook(newErrors, mark, actionError[mark]);
	}
	return newErrors;
};

export const removeErrorsByKeys = (errors, keys) => {
	let newError = errors;
	keys.forEach(el => {
		delete newError[el];
	});
	return newError;
};

export const getErrorKeys = (obj1, obj2) => {
	if (!obj1 || !obj2) return [];
	let keys = [];
	Object.keys(obj1).map(el => {
		if (obj2[el] !== undefined) {
			keys.push(el);
		}
	});
	return keys;
};
