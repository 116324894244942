import { useMemo } from "react";

const useFormatedSelectList = (data, cb, allSelect) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => {
		let list = [];
		if (allSelect) {
			list.push({ text: allSelect === true ? "All" : allSelect, value: "" });
		}
		return (list = [...list, ...data?.map(elem => (cb && cb(elem)) || { text: elem.name, value: elem.id })]);
	}, [data]);
};

export default useFormatedSelectList;
