import React from "react";
import { CheckIcon, CircleErrorIcon, Info, WarningIcon } from "app/icons";
import { Tooltip } from "@material-ui/core";
import { getTextInfo } from "../helper";

const Status = props => {
	const { status, status_reason, loading } = props;
	const infoText = getTextInfo(status_reason);

	if (loading) {
		return (
			<div className="ads-status">
				<WarningIcon/>
				<span className="ads-status-text small-text">In review</span>
				<Tooltip title={infoText} placement="right" interactive className="ads-tooltip">
					<div>
						<Info />
					</div>
				</Tooltip>
			</div>
		);
	}
	switch (status?.id) {
		case 1:
		case 1004:
			return (
				<div className="ads-status">
					<CheckIcon />
					<span className="ads-status-text small-text">{status.name}</span>
				</div>
			);
		default:
			return (
				<div className="ads-status">
					<CircleErrorIcon />
					<span className="ads-status-text small-text">{status.name}</span>
					<Tooltip title={infoText} placement="right" interactive className="ads-tooltip">
						<div>
							<Info />
						</div>
					</Tooltip>
				</div>
			);
	}
};

export default Status;
