import React, { useEffect, useState } from "react";
import UserLayout from "app/components/includs/user-layout";
import PATHS from "PATHS";
import { useDispatch, useSelector } from "react-redux";
import actions from "app/store/integrations/actions";
import { DeleteFbAdsAccountIcon, DeleteFbAdsProfileIcon } from "app/icons";
import ConnectFaceBook from "app/components/Sdk-facebook";
import ConfirmModal from "app/components/modal/confirm-modal.jsx";
import { useHistory } from "react-router-dom";
import { PLATFORMS } from "app/emun/platforms";
import Loader from "app/components/loader";
import PATH from "PATHS";
import IntegraionsTable from "app/components/integraions-table";
import PageTitle from "../../../../../components/page-title";

const EditFacebookIntegrations = () => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [disconnectModal, setdisconnectModal] = useState(false);
	const [currentActivingAd, setCurrentActivingAd] = useState({});
	const [currentDeleteUser, setCurrentDeleteUser] = useState({});
	const [disconnectAction, setDisconnectAction] = useState();
	const {
		integrations: {
			facebook_ads,
			facebook_ads: { id: facebook_ads_id, accounts = [], ad_account_counts: { active } = {} } = {}
		},
		isConnecting,
		isActivating,
		isLoading,
		isDeleting
	} = useSelector(state => state.integrations);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.getIntegrations());
	}, [dispatch]);

	useEffect(() => {
		if (!isLoading && !isDeleting) {
			if (!accounts.length) {
				history.push(PATH.cabinet.integrations);
			}
		}
	}, [isDeleting, isLoading, accounts]);

	const handleOpenDeleteModal = () => {
		setDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModal(false);
	};

	const handleOpenDisconnectModal = () => {
		setdisconnectModal(true);
	};

	const handleCloseDisconnectModal = () => {
		setdisconnectModal(false);
	};

	const deleteProfile = id => {
		setCurrentDeleteUser({
			id: id,
			integration: facebook_ads_id,
			integration_type: PLATFORMS.facebook_ads
		});
		handleOpenDeleteModal(true);
	};

	const onDisconnectAgree = () => {
		disconnectAction();
		handleCloseDisconnectModal();
	};

	const onDeleteAgree = () => {
		dispatch(actions.deleteIntegrationAccount(currentDeleteUser, handleCloseDeleteModal));
	};

	const onActiveAccount = (id, account_id, checked, setChecked) => {
		const data = {
			ad_account: account_id,
			integration: facebook_ads_id,
			integration_type: PLATFORMS.facebook_ads,
			account: id,
			activation_status_id: checked ? 1 : 2
		};

		const actionActivating = () => {
			if (checked) {
				setChecked(checked);
			}
			dispatch(actions.changeActiveIntegrationAccount(data, setCurrentActivingAd, setChecked));
		};
		switch (data.activation_status_id) {
			case 1:
				actionActivating();
				return;
			case 2:
				handleOpenDisconnectModal();
				setDisconnectAction(() => actionActivating);
				return;
			default:
				return;
		}
	};

	return (
		<UserLayout headerBackLink={PATHS.cabinet.integrations} headerBackLinkTitle="Integrations">
			<PageTitle
				title="Facebook Ads"
				content={
					<div className="subtitle-block m-b--32">
						{(accounts?.length && (
							<>
								<p className="main-text">
									You have {accounts?.length} profiles and {active} active ad account on Facebook Ads.
								</p>
								<ConnectFaceBook
									loading={isConnecting}
									add={facebook_ads_id}
									size="medium"
									colorVariant="gradient"
									className="m-l--12"
								>
									Add profile
								</ConnectFaceBook>
							</>
						)) ||
							null}
					</div>
				}
			/>
			<Loader loading={isLoading} delay>
				<IntegraionsTable
					canChange
					data={{ facebook_ads }}
					isActivating={isActivating}
					currentActivingAd={currentActivingAd}
					switchChangeAction={onActiveAccount}
					deleteButtonAction={deleteProfile}
				/>
			</Loader>

			<ConfirmModal
				open={deleteModal}
				title="Delete Facebook account?"
				icon={<DeleteFbAdsAccountIcon />}
				loading={isDeleting}
				onClose={handleCloseDeleteModal}
				onAgree={onDeleteAgree}
				text="Deleting Facebook account would stop your campaigns and remove reports for all related Ads accounts. Are you sure?"
				textAgree="OK"
				textClose="Cancel"
			/>

			<ConfirmModal
				open={disconnectModal}
				title="Disconnect Facebook Ads account?"
				icon={<DeleteFbAdsProfileIcon />}
				loading={isDeleting}
				onClose={handleCloseDisconnectModal}
				onAgree={onDisconnectAgree}
				textAgree="OK"
				textClose="Cancel"
				text="Disconnecting Facebook Ads account would stop campaigns and remove reports for this Ads account. Are you sure?"
			/>
		</UserLayout>
	);
};

export default EditFacebookIntegrations;
