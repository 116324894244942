import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { FormHelperText } from "@material-ui/core";
import {FormControl} from '../form-components';
import useStyles from "./style";
import { DatePickerIcon } from "../../icons";
import classNames from "classnames";

const DataPicker = ({
	minDate,
	name,
	variant,
	wrapperClassName,
	value,
	onChange,
	label,
	error,
	errorText,
	fullWidth,
	format,
	formatHolder = "DD MMMM, yyyy",
	...rest
}) => {
	const classes = useStyles({ fullWidth });
	return (
		<FormControl variant={variant} fullWidth={fullWidth} name={name} className={wrapperClassName}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
					autoOk
					className={classNames(classes.date_picker, error && classes.input_error)}
					disableToolbar
					variant="inline"
					format={formatHolder}
					name={name}
					id={name}
					label={label}
					value={value}
					onChange={onChange}
					minDate={minDate}
					onFocus={e => e.target.blur()}
					KeyboardButtonProps={{
						"aria-label": "change date"
					}}
					keyboardIcon={error ? <DatePickerIcon color="#EF5E39" /> : <DatePickerIcon />}
					PopoverProps={{ className: classes.popover }}
					{...rest}
				/>
			</MuiPickersUtilsProvider>
			{error && Boolean(error) && errorText && (
				<FormHelperText error={true} >
					{errorText}
				</FormHelperText>
			)}
		</FormControl>
	);
};
export default DataPicker;
