import React, { useMemo, useState, useCallback } from "react";
import Chip from "app/uikit/chip";
import Search from "app/components/SearchField";
import { FormControl, InputLabel, FormError } from "app/uikit/form-components";
import debounce from "lodash.debounce";
import Button from "app/uikit/button";
import style from "./style.module.scss";
import cl from "classnames/bind";

function filter(val, list) {
	let matched_terms = [];
	list.forEach(item => {
		if (item.name.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
			matched_terms.push(item);
		}
	});
	return matched_terms;
}

const ChooseTags = ({ data, name, action, placeholder, form }) => {
	const [valueSearch, setValueSearch] = useState("");
	const [listSearch, setListSearch] = useState(null);
	const cx = cl.bind(style);
	const tags = form.values[name];

	const dataTags = (listSearch !== null && listSearch) || data?.tags;

	const onAddTag = values => {
		const arrayTags = [...tags];
		const index = tags.findIndex(elem => elem.id === values.id);
		if (index > -1) {
			arrayTags.splice(index, 1);
		} else {
			arrayTags.push(values);
		}
		form.setFieldValue(name, arrayTags);
	};

	const onSelectAll = () => {
		const isSearch = listSearch !== null && listSearch.length;
		let newTags = [...tags];
		if(isSearch){
			listSearch.forEach( item =>{
				if(!newTags.find(elem=>elem.id === item.id)){
					newTags.push(item);
				}
			});
		}
		form.setFieldValue(name, isSearch && newTags || data.tags);
	};

	const onResetSearch = () => {
		setValueSearch('');
		setListSearch(null);
	};

	const hasSelectedBySearch = useMemo(()=>valueSearch && listSearch?.filter(elem=>!tags?.find(elements => elements.id === elem.id)),[valueSearch, listSearch, tags]);

	const selectAllDisebled =  valueSearch && !hasSelectedBySearch?.length || tags.length === data?.tags?.length;

	const delayed = useCallback(
		debounce(async v => {
			const res = await action({'filter[search]':v, limit: 5000});
			setListSearch(res.data.items);
		}, 500),
		[debounce]
	);


	const onSearch = useCallback(async e => {
		const value = e.target.value.replace(/^ +/mg, '');
		setValueSearch(value);
		if(action){
			if(value && value.length >= 2 ){
				return delayed(value);
			}
			setListSearch(null);
			return;
		}
		const list = filter(value, data.tags);
		setListSearch(list);
	},[]);

	const onReset = ()=>{
		form.setFieldValue(name, []);
	}


	const onDelete = (index,id) => {
		const arrayTags = [...tags];
		arrayTags.splice(index, 1);
		form.setFieldValue(name, arrayTags);
	}

	const showTagsList = action ? valueSearch && valueSearch.length >= 2 && dataTags : dataTags;

	return (
		<div className={style.container}>
			<FormControl fullWidth className={style.formControl}>
				<InputLabel name="tags" required label="Category tags" />
				<Chip list={tags} onDelete={onDelete} onReset={onReset} />
			</FormControl>
			<Search wrapperClassName={style.search} fullWidth name="search" placeholder={placeholder} onChange={onSearch} value={valueSearch} required />
			<div className={style.container_tags}>
				{(dataTags?.length === 0 && (
					<div className={style.empty_block}>
						<div className={style.emptySearch}>No tags have been found by your request.</div>
						<Button size="small" onClick={onResetSearch} colorVariant="ghost">
							Reset search
						</Button>
					</div>
				)) || showTagsList && (
					<div className={style.tags_content}>
						{dataTags?.map(elem => (
							<div key={elem.id} className={style.tag}>
								<div
									onClick={() => onAddTag(elem)}
									className={cx("tag__btn", { "tag__btn--active": tags?.find(elements => elements.id === elem.id) })}
								></div>
								<p className={style.tag__name}>{`${elem?.name} (${elem.contacts_count})`}</p>
							</div>
						))}
					</div>
				)}
			</div>
			{Array.isArray(dataTags) && dataTags?.length !== 0 && showTagsList &&(
				<Button size="small" onClick={onSelectAll} disabled={selectAllDisebled} colorVariant="gradient">
					Select all tags {listSearch?.length || data.tags?.length}
				</Button>
			)}
			<FormError
				className={style.error}
				error={(form.touched[name]) && Boolean(form.errors[name])}
				text={(form.touched[name]) && form.errors[name]}
			/>
		</div>
	);
};

export default ChooseTags;