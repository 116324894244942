import React from "react";
import Nodata from "app/components/no-data";

const EmptyData = ({ loading, content, text }) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				textAlign: "center",
				paddingTop: "50px",
				paddingBottom: "50px"
			}}
		>
			{!loading && (content || <Nodata text={text} />) }
		</div>
	);
};

export default EmptyData;