import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "app/components/modal";
import CreateNewList from "./steps/create-new-list";
import UploadStep from "./steps/upload-steps";
import UploadExistingAudience from "./steps/upload-existing-audience";
import Success from "./steps/success";
import MatchColumns from "./steps/match-columns";
import actionsUploadAudiences from "app/store/uploadAuciences/actions";

import { STEP_UPLOAD_AUDIENCES_TYPES } from "app/emun";

const ModalStep = ({ onClose, open, reloadAudience, userId, getAudiencesUser }) => {
	const dispatch = useDispatch();
	const {
		matchedFilesData: { columns, unmatched_columns_count, missed_rows_count, message, is_can_create_audience, is_can_upload_contacts },
		uploadedAudience: { created_rows_count, created_rows_percent },
		audienceTemporaryData,
		creatingAudience,
		createAudienceError
	} = useSelector(state => state.uploadAuciences);

	const [step, setStep] = useState(1);
	const [typeStep, setTypeStep] = useState("");

	const changeStep = (steps, type) => {
		if (!step) return;
		setStep(steps);
		if (type) {
			setTypeStep(type);
		}
	};
	useEffect(() => {
		if (open) {
			setStep(1);
			setTypeStep("");
			dispatch(actionsUploadAudiences.resetDataAudiences());
		}
	}, [open]);

	const getParamsForModal = useMemo(() => {
		const Step = typeStep ? `${typeStep}-${step}` : step;
		switch (Step) {
			case 1:
				return {
					title: "Share your audience with the user",
					component: <CreateNewList nextStep={1} onClose={onClose} changeStep={changeStep} />
				};
			case `${STEP_UPLOAD_AUDIENCES_TYPES.uploadSheet}-1`:
				return {
					title: "Upload new audience",
					component: <UploadStep userId={userId} nextStep={2} onClose={onClose} changeStep={changeStep} />
				};
			case `${STEP_UPLOAD_AUDIENCES_TYPES.uploadSheet}-2`:
				return {
					title: "Upload new audience",
					component: (
						<MatchColumns
							data={{
								columns,
								unmatched_columns_count,
								canCreate: is_can_create_audience || is_can_upload_contacts,
								message,
								missed_rows_count,
								audienceTemporaryData,
								loading: creatingAudience,
								errors: createAudienceError
							}}
							stepValue={3}
							currentStep={2}
							userId={userId}
							nextStep="success"
							onClose={onClose}
							getAudiencesUser={getAudiencesUser}
							changeStep={changeStep}
						/>
					)
				};
			case `${STEP_UPLOAD_AUDIENCES_TYPES.uploadedSheet}-1`:
				return {
					title: "Choose the audience",
					component: <UploadExistingAudience userId={userId} reloadAudience={reloadAudience} onClose={onClose} />
				};
			case `${STEP_UPLOAD_AUDIENCES_TYPES.uploadSheet}-success`:
				return {
					component: <Success onClose={onClose} countRow={created_rows_count} rowPercent={created_rows_percent} />
				};
			default:
				return {
					title: "",
					component: ""
				};
		}
	}, [
		changeStep,
		onClose,
		step,
		typeStep,
		creatingAudience,
		createAudienceError,
		columns,
		unmatched_columns_count,
		is_can_create_audience,
		message,
		missed_rows_count,
		audienceTemporaryData
	]);

	return (
		<Modal title={getParamsForModal.title} onClose={onClose} open={open}>
			{getParamsForModal.component}
		</Modal>
	);
};

export default ModalStep;
