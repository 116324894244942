import yup from "app/yup";
import { ErrorName, ErrorEmail,ErrorRequired } from "app/errors-shcema"; 

const validationSchema = yup.object({
	name: ErrorName,
	surname: ErrorName,
	email: ErrorEmail,
	role_id: ErrorRequired
});

export default validationSchema;
