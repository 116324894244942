import React from "react";
import Button from "app/uikit/button/active-button";
import style from "./style.module.scss";

const ToolbarHeader = ({ actionUpload, actionCreate }) => (
	<div className={style.container}>
		<Button size="medium" colorVariant="ghost" onClick={actionUpload}>
			Upload spreadsheet
		</Button>
		<Button size="medium" colorVariant="gradient" onClick={actionCreate}>
			Create categories
		</Button>
	</div>
);

export default ToolbarHeader;