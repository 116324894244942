import React from "react";
import { CampaignsPlaceholderIcon, IdeaIcon } from "../../icons";
import { Link } from "react-router-dom";
import PATHS from "../../../PATHS";
import ButtonActive from "../../uikit/button/active-button";

const NotAnyActivateAccounts = ({ title, text, textLink, content }) => {
	return (
		<div className="accounts">
			<div className="accounts-outer-integrations">
				<div className="accounts-to-integrations">
					<div className="accounts-to-integrations-box">
						<div className="accounts-icon">
							<IdeaIcon />
						</div>
						<h2 className="main-text main-text_bold m-b--8">{title || "Сonnect integration!"}</h2>
						<p className="small-text m-b--24">
							{text || "To create a new campaign you have to connect integration and activate at least one Ad account"}
						</p>
						<div className="accounts-button">
							<Link to={PATHS.cabinet.integrations} className="button button_ghost button_small">
								{textLink || "To integrations"}
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="accounts-create">
				{content !== undefined ? content : (
					<>
						<CampaignsPlaceholderIcon />
						<div className="accounts-create-button">
							<ButtonActive colorVariant="gradient" size="medium" disabled>
								Create new campaign
							</ButtonActive>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default NotAnyActivateAccounts;