import { takeEvery, put, all, call } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

const dir = directory.en;

function* getStatisticRegistrations({actionName, params}) {
	try {
		const response = yield call(services.getStatisticRegistrations, params);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getStatisticRegistrationsSuccess(actionName, response.data.chart || []));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getStatisticRegistrationsFailure(actionName,""));
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_STATISTIC_REGISTRATIONS_REQUEST, getStatisticRegistrations)]);
}
