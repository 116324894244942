import React, { useLayoutEffect, useState } from "react";
import { withRouter } from "react-router";
import UserProfileWrapper from "app/components/user-profile-layout";
import columns from "../../../cabinet/creatives/columns";
import { useDispatch, useSelector } from "react-redux";
import { converingData } from "../../../cabinet/creatives/helper";
import Table from "../../../../components/table";
import actions from "app/store/users/actions";
import { getPlatformList } from "app/helper/platform_helper";
import { DATA_ACTION } from "app/emun";
import Button from "app/uikit/button";
import ModalAsigneeCreative from "./modal-sharing-creative";
import Select from "app/uikit/select";

const TemplatesUser = props => {
	const {
		dataUserLoading,
		dataUser,
		dataUserPagination,
		dataUserFilter,
		users: { items: userItems },
		user
	} = useSelector(state => state.users);
	const { integration_type } = useSelector(state => state.handBooks);
	const [openModalAsigneeCreative, setOpenModalAsigneeCreative] = useState(false);
	const {
		match: { params }
	} = props;
	const dispatch = useDispatch();

	const actionName = DATA_ACTION.creatives;

	const currentUser = userItems?.find(el => el.id === +params.id) || user;

	const { created_at } = currentUser;

	const getCreativesUser = () => {
		dispatch(actions.fetchUserFilter({ "filter[user_owner_id]": params.id }, actionName));
	};

	useLayoutEffect(() => {
		dispatch(actions.resetUserData());
		getCreativesUser();
		if (!created_at) {
			dispatch(actions.getUser(params.id));
		}
	}, [dispatch]);

	const onSearch = data => {
		dispatch(actions.fetchUserFilter({ "filter[search]": data }, actionName));
	};

	const onPagination = data => {
		dispatch(actions.fetchUserPagination(data, actionName));
	};

	const onChangeStatusFilter = value => {
		dispatch(actions.fetchUserFilter({ "filter[integration_type_id]": value }, actionName));
	};

	const mappedData = converingData(dataUser.items);
	const options = [...getPlatformList(integration_type), { text: "All platforms", value: "" }] || [];

	const onOpenModalSharingCreative = () => {
		setOpenModalAsigneeCreative(true);
	};
	const onCloseModalSharingCreative = () => {
		setOpenModalAsigneeCreative(false);
	};
	
	return (
		<>
			<UserProfileWrapper
				date={created_at}
				showAddingContent={true}
				subtitleAddingContent={
					<div>
						<Select
							hasAllSelect
							list={options}
							onChange={onChangeStatusFilter}
							value={dataUserFilter?.creatives["filter[integration_type_id]"]}
							className="m-r--24"
							name="select-users"
						/>
						<Button size="medium" colorVariant="gradient" onClick={onOpenModalSharingCreative}>
							Assignee to user
						</Button>
					</div>
				}
			>
				<Table
					scroll={{ x: 1104 }}
					filter={dataUserFilter}
					title="Lists of creatives"
					emptyDataText="No Creatives have been found by your request."
					loading={dataUserLoading}
					columns={columns}
					data={mappedData}
					onChangePagination={onPagination}
					onChangeSearch={onSearch}
					settingsData={{
						check: {
							showId: true,
							hide: true
						},
						menu: {
							hide: true
						}
					}}
					hideElements={{
						delete: true,
						unSelect: true,
						check: true
					}}
					action={actions}
					pagination={dataUserPagination}
				/>
			</UserProfileWrapper>
			<ModalAsigneeCreative
				open={openModalAsigneeCreative}
				userId={params.id}
				reloadCratives={getCreativesUser}
				onClose={onCloseModalSharingCreative}
			/>
		</>
	);
};

export default withRouter(TemplatesUser);
