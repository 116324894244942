import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useFormik } from "formik";
import Button from "app/uikit/button/active-button";
import LabelContent from "./LabelContent";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";

const ChooseCreateNewList = ({ onClose, initialValues, onSubmit, items, subTitle }) => {
	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit
	});
	return (
		<div className="modal-step-one">
			<p className="small-text m-b--24">{subTitle}</p>
			<form onSubmit={formik.handleSubmit} noValidate>
				<FormControl className="radio">
					<RadioGroup name="upload-sheet">
						{items?.map(el => (
							<FormControlLabel
								key={el.name}
								value={el.name}
								control={
									<Radio
										name="metod"
										checked={formik.values.metod === el.name}
										value={el.name}
										onChange={formik.handleChange}
										color="primary"
									/>
								}
								label={<LabelContent icon={el.icon} text={el.text} subtext={el.subText} />}
							/>
						))}
					</RadioGroup>
				</FormControl>
				<Error error={formik?.errors?.metod} />
				<div className="modal-footer">
					<Button onClick={onClose} size="medium" colorVariant="ghost">
						Cancel
					</Button>
					<Button type="submit" size="medium" colorVariant="gradient">
						Continue
					</Button>
				</div>
			</form>
		</div>
	);
};
export default ChooseCreateNewList;