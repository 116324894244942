import types from "./types";

const actions = {

	getInfo: params => ({
		type: types.GET_INFO_USER_REQUEST,
		params
	}),
	getInfoSuccess: data => ({
		type: types.GET_INFO_USER_SUCCESS,
		data
	}),
	getInfoFailure: error => ({
		type: types.GET_INFO_USER_FAILURE,
		error
	}),

	resetInfo: () => ({
		type: types.RESET_INFO_USER
	}),

	changeInfo: data => ({
		type: types.CHANGE_INFO_USER_REQUEST,
		data
	}),
	changeSuccess: data => ({
		type: types.CHANGE_INFO_USER_SUCCESS,
		data
	}),
	changeFailure: error => ({
		type: types.CHANGE_INFO_USER_FAILURE,
		error
	}),

	trainingPassed: () => ({
		type: types.TRAINING_PASSED
	})

};
export default actions;