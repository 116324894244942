import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
// import { useSelector } from "react-redux";
import style from "./style.module.scss";
import Aside from "./aside";
import Header from "./header";
import Footer from "./footer";
import TermsAndPrivacy from "../terms-and-privacy";
// import Onboarding from "../onboarding";

const UserLayout = ({navList, header, headerBackLink, headerBackLinkTitle, children}) => {
	// const { user:{ is_first_login} ={}} = useSelector(state => state.user);
	return (
		<>
			<div className="page-wrapper">
				<Aside />
				<div className="page-main">
					<Header
						userHeader
						list={navList}
						className={header}
						headerBackLink={headerBackLink}
						headerBackLinkTitle={headerBackLinkTitle}
					/>
					<main className="main">{children}</main>
					<Footer className="m-t--auto p-x--24">
						<Container maxWidth={false}>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item>
									<span className={style.text}>&copy; 2021 Tenoris Inc.</span>
								</Grid>
								<Grid item>
									<TermsAndPrivacy />
								</Grid>
							</Grid>
						</Container>
					</Footer>
				</div>
			</div>
			{/* <Onboarding show={is_first_login} /> */}
		</>
	);
};

export default UserLayout;