import React from "react";
import style from "./style.module.scss";
import cl from "classnames";

const Header = ({ title, toolbar, className }) => {
	return (
        <div className={style.header}>
            <div className={cl(style.header__content, className)}>
                <h3 className={style.header__title}>{title}</h3>
                <div className={style.header__ctrl}>{toolbar}</div>
            </div>
        </div>
    )
};

export default Header;