import api from "app/api";

const API = api();

const fetchMatchAudience = async data => {
	try {
		return await API.post("/account/audience/get-matches", data);
	} catch (error) {
		return error;
	}
};

const fetchMatchCategoryAudience = async data => {
	try {
		return await API.post("/admin/marketplace/contacts/get-matches", data);
	} catch (error) {
		return error;
	}
};

const fetchMatchUserAudience = async ({id,...rest}) => {
	try {
		return await API.post(`/admin/users/${id}/audience/get-matches`, {...rest});
	} catch (error) {
		return error;
	}
};

const fetchCreateAudience = async data => {
	try {
		return await API.post("/account/audience", data);
	} catch (error) {
		return error;
	}
};

const fetchCreateUserAudience = async ({id,...rest}) => {
	try {
		return await API.post(`/admin/users/${id}/audience`, {...rest});
	} catch (error) {
		return error;
	}
};

const fetchCreateCategoryAudience = async ({id,...rest}) => {
	try {
		return await API.post("/admin/marketplace/contacts", {...rest});
	} catch (error) {
		return error;
	}
};

export default {
	fetchMatchAudience,
	fetchCreateAudience,
	fetchCreateUserAudience,
	fetchMatchUserAudience,
	fetchMatchCategoryAudience,
	fetchCreateCategoryAudience
};