import { takeEvery, put, all, call } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

const dir = directory.en;

function* getGeneralSettings() {
	try {
		const response = yield call(services.getGeneralSettings);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getGeneralSettingsSuccess(response.data.settings || []));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getGeneralSettingsFailure(""));
	}
}

function* updateGeneralSettings({data}) {
	try {
		const response = yield call(services.updateGeneralSettings,data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			toastify.success(response?.data?.message);
			yield put(actions.getGeneralSettings());
			yield put(actions.updateGeneralSettingsSuccess(response.data || []));
		}
	} catch (error) {
		if(error?.response?.status === 422){
			yield put(actions.updateGeneralSettingsFailure(error?.response?.data?.errors || ''));
		}else if(error?.response?.status === 403){
			yield put(actions.updateGeneralSettingsFailure(error?.response?.data?.message));
		}else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.updateGeneralSettingsFailure(''));
		}
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_GENERAL_SETTINGS_REQUEST, getGeneralSettings)]);
	yield all([takeEvery(types.UPDATE_GENERAL_SETTINGS_REQUEST, updateGeneralSettings)]);
}
