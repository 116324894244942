export const stateRequest = (state, action, requestName) => {
	let loading = false;
	let error = "";
	const property = action.propertyName;
	switch (requestName) {
		case "request":
			loading = true;
			break;
		case "failure":
			error = action.error;
			break;
		default:
			break;
	}
	const defaultLoading = { ...state.isLoading, [action.actionName]: loading };
	return {
		isLoading:
			(property &&
				(((property === true || property === "id") && {
					...state.isLoading,
					[action.actionName]: {
						...state.isLoading[action.actionName],
						[action.id]: loading
					}
				}) || {
					...state.isLoading,
					[action.actionName]: {
						...state.isLoading[action.actionName],
						[property]: loading
					}
				})) ||
			defaultLoading,
		error: { ...state.error, [action.actionName]: error }
	};
};

export const getPaginationAfterDelete = (state, data, itemName,paginationName,property) => {
	let newPagination = state.pagination;
	if ((property && (data >= state[itemName][property].items.length)) ?? data >= state[itemName].items.length) {
		if(property){
			newPagination[property].page = 1;
			newPagination[property].offset = null;
		}else if(paginationName){
			newPagination[paginationName].page = 1;
			newPagination[paginationName].offset = null;
		}else{
			newPagination.page = 1;
			newPagination.offset = null;
		}
	}
	return newPagination;
};

export const mappedOffset = (data, pg) => {
	if (data?.limit || data?.page === 1) {
		return null;
	} else if (data.page) {
		return (data.page - 1) * pg.limit;
	} else {
		return pg.offset;
	}
};

export const getRecalkPagination = (state, pagination, data,target)=>{
	let newPagination = pagination;

	if (data >= state[target].items.length) {
		newPagination.page = 1;
		newPagination.offset = null;
	}
	return newPagination;
}

export const getPagination = (state,data, actionName) => {
	let value = {...state.pagination};
	if(actionName){
		value[actionName] ={
			...state.pagination[actionName],
			...data,
			offset: mappedOffset(data, state.pagination[actionName])
		}

	}else{
		value = {
			...state.pagination,
			...data,
			offset: mappedOffset(data, state.pagination)
		}
	}
	return value;
}



export const getDefaultPagination = (state, actionName) => {
	let value = {...state.pagination};
	if(actionName){
		value[actionName] = {
			...state.pagination[actionName],
			page: 1,
			offset: null
		}
	}else{
		value.page = 1,
		value.offset = null
	}
	return value;
}

export const getFilter = (state,data, actionName) =>{
	let value = {...state.filter};
	if(actionName){
		value[actionName] = {
			...state.filter[actionName],
			...data
		}
	}else{
		value = {...state.filter, ...data}
	}
	return value;
}