import yup from "app/yup";
import directory from "app/directory/errors";

const dir = directory.en;

export const ErrorNameNoRequired = yup
	.string()
	.min(2, dir.minLength.replace("#{number}", 2))
	.max(40, dir.maxLength.replace("#{number}", 40))
	.test(
		"Standalone diacritical marks are not allowed",
		"Standalone diacritical marks are not allowed",
		value => !/[À-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]/.test(value)
	)
	.test(
		"^~_={}[]|<> characters are not allowed",
		"^~_={}[]|<> characters are not allowed",
		// eslint-disable-next-line no-useless-escape
		value => !/[\^<>~_={}\[\]\|]/gi.test(value)
	)
	.test(
		"Cannot start with punctuation",
		"Cannot start with punctuation",
		// eslint-disable-next-line no-useless-escape
		value => !/^[\\\/\!\.\?\-\*\(\)\,\;\:]/gi.test(value)
	)
	.test(
		"Cannot have consecutive punctuation except of three full-stops ...",
		"Cannot have consecutive punctuation except of three full-stops ...",
		value => {
			// eslint-disable-next-line no-useless-escape
			const matches = value?.match(/[\.\,;:]{2,}/gi) || [];
			const res = matches.filter(el => el !== "...");
			return !res.length;
		}
	);

export const ErrorName = ErrorNameNoRequired.required(dir.required);
	
export const ErrorEmail = yup.string().email(dir.email).required(dir.required);
export const ErrorPassword = yup
	.string()
	.min(6, dir.password.replace("#{number}", 6))
	.matches(/[A-Z]/g, dir.password.replace("#{number}", 6))
	.required(dir.required);
export const ErrorPassword_confirmation = yup
	.string()
	.min(6, dir.password.replace("#{number}", 6))
	.matches(/[A-Z]/g, dir.password.replace("#{number}", 6))
	.oneOf([yup.ref("password"), null], dir.passwordMatch)
	.required(dir.required);
export const ErrorFileRequired = yup.mixed().test("fileRequired", dir.requiredFile, value => {
	if (value.length) return true;
});
export const ErrorArrayRequired = yup.mixed().test("ArrayRequired", dir.required, value => {
	if (value.length) return true;
});
export const ErrorRequired = yup.string().required(dir.required);

export const ErrorComment = yup
	.string()
	.min(10, dir.minLength.replace("#{number}", 10))
	.max(1360, dir.maxLength.replace("#{number}", 1360))
	.required(dir.required);

export const ErrorPhone = yup.string().required(dir.required);

export const audienceData = yup
	.string()
	.min(2, dir.minLength.replace("#{number}", 2))
	.max(256, dir.maxLength.replace("#{number}", 256));

export const ErrorBudget = yup.number().min(1, "Must be greater than 1").required(dir.required);
