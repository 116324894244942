import React from "react";

import Autocomplete from "./autocomplete";
export default function AutocompleteBase(props) {
	const { name, form, showError, validateField, ...rest } = props;
	if (form) {
		const handleChange = async value => {
			await form.setFieldValue(name, value);
			if (validateField) {
				await form.validateField(name);
			}
			form.setStatus({
				...form.status,
				dirtyFields: {
					...form.status?.dirtyFields,
					[name]: true
				}
			});
		};
		return (
			<Autocomplete
				name={name}
				value={form.values[name]}
				onChange={handleChange}
				error={(form.touched[name] || showError) && Boolean(form.errors[name])}
				errorText={(form.touched[name] || showError) && form.errors[name]}
				{...rest}
			/>
		);
	}
	return <Autocomplete name={name || ""} {...rest} />;
}

AutocompleteBase.defaultProps = {
	list: [],
	label: ""
};
