import types from "./types";

const actions = {
	getEmployees: params => ({
		type: types.GET_EMPLOYEES_REQUEST,
		params
	}),
	getEmployeesSuccess: data => ({
		type: types.GET_EMPLOYEES_SUCCESS,
		data
	}),
	getEmployeesFailure: error => ({
		type: types.GET_EMPLOYEES_FAILURE,
		error
	}),


	deleteItems:  (ids, cb) => ({
		type: types.DELETE_EMPLOYEES_REQUEST,
		ids,
		cb
	}),
	deleteItemsSuccess: data => ({
		type: types.DELETE_EMPLOYEES_SUCCESS,
		data
	}),
	deleteItemsFailure: error => ({
		type: types.DELETE_EMPLOYEES_FAILURE,
		error
	}),

	
	fetchNewEmployee: (data) => ({
		type: types.FETCH_NEW_EMPLOYEE_REQUEST,
		data
	}),
	fetchNewEmployeeSuccess: () => ({
		type: types.FETCH_NEW_EMPLOYEE_SUCCESS
	}),
	fetchNewEmployeeFailure: error => ({
		type: types.FETCH_NEW_EMPLOYEE_FAILURE,
		error
	}),

	fetchEditEmployee: (data,id) => ({
		type: types.FETCH_EDIT_EMPLOYEE_REQUEST,
		data,
		id
	}),
	fetchEditEmployeeSuccess: () => ({
		type: types.FETCH_EDIT_EMPLOYEE_SUCCESS
	}),
	fetchEditEmployeeFailure: error => ({
		type: types.FETCH_EDIT_EMPLOYEE_FAILURE,
		error
	}),

	fetchPagination: (data) => ({
		type: types.FETCH_PAGINATION_EMPLOYEE_REQUEST,
		data
	}),
	fetchPaginationSuccess: data => ({
		type: types.FETCH_PAGINATION_EMPLOYEE_SUCCESS,
		data
	}),
};
export default actions;
