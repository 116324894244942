import React from "react";
import EmptyPlaceholder from "app/components/empty-placeholder";
import { NoDataIcon } from "./icons";

const NoReportsData = () => (
	<EmptyPlaceholder
		icon={<NoDataIcon />}
		title="No аctivity during selected date range"
		subtitle="Please select a different date range and try your search again."
	/>
);

export default NoReportsData;
