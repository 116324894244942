import api from "app/api";
import { mappedParams } from "app/helper";
import {urlEncodeQueryParams} from "app/helper"
import download from "app/utils/download";

const API = api();

const getUsers = async params => {
	try {
		return await API.get("/admin/users", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const getUser = async id => {
	try {
		return await API.get(`/admin/users/${id}`);
	} catch (error) {
		return error;
	}
};

const deleteUsers = async ids => {
	try {
		return await API.delete("/admin/users/delete-multiple", { params: { ids } });
	} catch (error) {
		return error;
	}
};

const fetchNewUsers = async data => {
	try {
		return await API.post("/admin/users", data);
	} catch (error) {
		return error;
	}
};

const fetchEditUser = async ({ id, data }) => {
	try {
		return await API.put(`/admin/users/${id}`, data);
	} catch (error) {
		return error;
	}
};

const fetchResetPasswordUser = async (id) => {
	try {
		return await API.patch(`/admin/users/${id}/reset-password`);
	} catch (error) {
		return error;
	}
};

const getUsersExport = async data => {
	try {
		return download(`/admin/users/export?${urlEncodeQueryParams(data)}`);
	} catch (error) {
		return error;
	}
};

const getUsersExportProgress = async data => {
	try {
		return download(`/admin/users/export-progress?${urlEncodeQueryParams(data)}`);
	} catch (error) {
		return error;
	}
};

const getUserTemplate = async params => {
	try {
		return await API.get("/admin/creative-templates", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const getUserAudience = async params => {
	try {
		return await API.get("/admin/audience", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const getUserCampaigns = async params => {
	try {
		return await API.get("/admin/campaigns", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const getUserReports = async params => {
	try {
		return await API.get("/admin/reports", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const getStatistic = async () => {
	try {
		return await API.get("/admin/statistic");
	} catch (error) {
		return error;
	}
};

const fetchUserShareAudience = async (internal_audience,data) => {
	try {
		return await API.post(`/admin/internal-audience/${internal_audience}/share`, data);
	} catch (error) {
		return error;
	}
};

const fetchUserShareCreative = async (creative_template,data) => {
	try {
		return await API.post(`/admin/creative-templates/${creative_template}/share`, data);
	} catch (error) {
		return error;
	}
};

const getUserIntegrations = async user => {
	try {
		return await API.get(`/admin/users/${user}/integrations`);
	} catch (error) {
		return error;
	}
};

export default {
	getUsers,
	deleteUsers,
	fetchNewUsers,
	fetchEditUser,
	getStatistic,
	getUsersExport,
	fetchResetPasswordUser,
	getUser,
	getUserTemplate,
	getUserAudience,
	getUserCampaigns,
	getUserReports,
	fetchUserShareAudience,
	fetchUserShareCreative,
	getUsersExportProgress,
	getUserIntegrations
};
