const admin = "admin";
const account = "account";

const creatives = "/creatives";

const campaigns = "/campaigns";
const createCampaing = `${campaigns}/create`;

const PATHS = {
	auth: {
		login: "/login",
		registration: "/registration",
		emailVerification: "/email-verification",
		passwordRecovery: "/password-recovery",
		verificationCode: "/verification-code",
		newPassword: "/new-password",
		errorSendPwEmail: "/error-sending-reset-email",
		errorServerUnavailable: "/error-server-unavailable"
	},
	cabinet: {
		audiences: "/audiences",
		marketplace: "/marketplace",

		integrations: "/integrations",
		editFbIntegrations: "/integrations/facebook_ads",

		creatives: creatives,
		createCreative: `${creatives}/:platform/create`,
		creativeEdit: `${creatives}/:platform/edit/:id`,

		campaigns: "/campaigns",
		campaingEdit: `${campaigns}/:platform/edit/:id`,
		createCampaing: createCampaing,
		createCampaingSteps: `${createCampaing}/:platform/step/:step`,


		reports: "/reports",
		teams: "/teams",
		contentGenetarion: "/content_genetarion"
	},
	adminPanel: {
		users: `/${admin}/users`,
		userAccount: `/${admin}/users/:id/account`,
		userReport: `/${admin}/users/:id/report`,
		userCreative: `/${admin}/users/:id/creatives`,
		userAudiences: `/${admin}/users/:id/audiences`,
		userCampaigns: `/${admin}/users/:id/campaigns`,
		userIntegrations: `/${admin}/users/:id/integrations`,
		employees: `/${admin}/employees`,
		settings: `/${admin}/settings`,
		categoriesAudience: '/categoriesaudience',
		infoDashboard: '/info-dashboard'
	},
	account: {
		profile: `/${account}/profile`,
		subsciption: `/${account}/subsciption`,
		payments: `/${account}/payments`
	},
	pageNotFound: "/page-not-found"
};

export default PATHS;
