import React from 'react';
import Pill from 'app/components/pills';


export const getRole = (type,text)=>{
    switch(type) {
        case 1:
        case 3:
            return <Pill color="gray" text={text}/>
        case 2:
            return <Pill color="orange" text={text}/>
        case 4:
            return <Pill color="blue" text={text}/>
        default:
            return;
    }
};