import React from "react";
import Button from "./index";
import CircularProgress from "app/uikit/CircularProgress";
import cl from "classnames";

const ButtonActive = props => {
	const { loading, disabled, classNameWrapper, ...rest } = props;
	return (
		<div className={cl(
			"relative d-inline-block",
			classNameWrapper,
			{ "d-block": props.fullWidth }
		)}>
			<Button disabled={loading || disabled} {...rest}>
				{props.children}
			</Button>
			{loading && <CircularProgress delayLoader={0} size={24} />}
		</div>
	);
};

export default ButtonActive;
