import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import actions from "app/store/campaign/actions";

import StepTwoBase from "../../../steps/step-2";

const StepTwo = ({ onChangeStep, step, currency, ...rest }) => {
	const dispatch = useDispatch();
	const {audiences:{items},createCompaign} = useSelector(state => state.campaigns);

    const options = items.map(el => ({
		name: el.name,
		id: el.id
	})) || [];

    useEffect(() => {
        dispatch(actions.getAudiences());
    }, [dispatch]);

    const onSubmit = (values, shouldNotUpdate, shouldNotoNextStep) =>{
        if(!shouldNotUpdate){
            dispatch(actions.setDataCreateCampaigns({ ...values, currency}));
        }
        if(!shouldNotoNextStep){
			onChangeStep(++step);
		}
    };

    return <StepTwoBase onSubmit={onSubmit} data={createCompaign} listAudience={options} currency={currency} {...rest}/>
};

export default StepTwo;
