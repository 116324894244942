import { takeEvery, put, all, call, select } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

const dir = directory.en;

function* getCategoriesAudience({ params, actionName }) {
	try {
		const {
			categoriesAudience: { pagination, filter }
		} = yield select();
		const response = yield call(services.getCategoriesAudience, { ...pagination, ...filter, ...params });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getCategoriesAudienceSuccess(
					actionName,
					{
						count: response.data.count,
						items: response.data.items
					}));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getCategoriesAudienceFailure(actionName, "" ));
	}
};

function* fetchCreateCategoryAudience({ data, cb, actionName }) {
	try {
		const response = yield call(services.fetchCreateCategoryAudience, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchCreateCategoryAudienceSuccess(actionName));
			yield put(actions.getCategoriesAudience());
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchCreateCategoryAudienceFailure(actionName,error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchCreateCategoryAudienceFailure(actionName,error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchCreateCategoryAudienceFailure(actionName,""));
		}
	}
};

function* getTags({actionName}) {
	try {
		const response = yield call(services.getTags);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getTagsSuccess(actionName,response.data));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getTagsFailure(actionName,{
			errors: ""
		}));
	}
};

function* fetchPublic({id,isPublic, propertyName, actionName}) {
	try {
		const response = yield call(services.fetchPublic, id, isPublic);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchPublicSuccess(id,actionName,propertyName));
			yield put(actions.getCategoriesAudience());
			toastify.success(response?.data?.message);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.fetchPublicFailure(id,"",actionName,propertyName));
	}
};

function* fetchEditCategoryAudience({id, data, cb, actionName }) {
	try {
		const response = yield call(services.fetchEditCategoryAudience, id, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchEditCategoryAudienceSuccess(actionName));
			yield put(actions.getCategoriesAudience());
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchEditCategoryAudienceFailure(actionName,error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchEditCategoryAudienceFailure(actionName,error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchEditCategoryAudienceFailure(actionName,""));
		}
	}
};

function* deleteItems({ ids, cb, actionName }) {
	try {
		const response = yield call(services.deleteItems, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const arrIds = ids?.split(",");
			yield put(actions.deleteItemsSuccess(actionName,arrIds?.length));
			cb && cb();
			toastify.success(response?.data?.message);
			yield put(actions.getCategoriesAudience());
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.deleteItemsFailure(actionName, {
			errors: ""
		}));
	}
}

function* fetchPagination({ data }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(params));
		yield put(actions.getCategoriesAudience());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchFilter({ data }) {
	try {
		yield put(actions.fetchFilterSuccess(data));
		yield put(actions.getCategoriesAudience());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_CATEGORIES_AUDIENCE_REQUEST, getCategoriesAudience)]);
	yield all([takeEvery(types.FETCH_CREATE_CATEGORIES_AUDIENCE_REQUEST, fetchCreateCategoryAudience)]);
	yield all([takeEvery(types.GET_CATEGORIES_AUDIENCE_TAGS_REQUEST, getTags)]);
	yield all([takeEvery(types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_REQUEST, fetchPublic)]);
	yield all([takeEvery(types.FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST, deleteItems)]);
	yield all([takeEvery(types.FETCH_EDIT_CATEGORIES_AUDIENCE_REQUEST, fetchEditCategoryAudience)]);
	yield all([takeEvery(types.FETCH_FILTER_CATEGORIES_AUDIENCE_REQUEST, fetchFilter)]);
	yield all([takeEvery(types.FETCH_PAGINATION_CATEGORIES_AUDIENCE_REQUEST, fetchPagination)]);
}
