import { getStatusTable } from "./helper";
import { formateDate } from "app/helper";

const columns = actions => [
	{
		title: "Campaign name",
		dataIndex: "name",
		key: "name",
		fixed: 'left',
		width: 200,
		render: value =><div className="text-lines text-lines--1">{value}</div>
	},
	{
		title: "Objective",
		dataIndex: "target",
		key: "target",
		width: 200,
		render: value =><div className="text-lines text-lines--1">{value?.name}</div>
	},
	{
		title: "Spent",
		dataIndex: "amount_spent",
		key: "amount_spent",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	}, 
	{
		title: "Impressions",
		dataIndex: "impressions_count",
		key: "impressions_count",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	},
	{
		title: "Clicks",
		dataIndex: "clicks_count",
		key: "clicks_count",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	},
	{
		title: "CPC",
		dataIndex: "cost_per_inline_link_click",
		key: "cost_per_inline_link_click",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	},
	{
		title: "Results",
		dataIndex: "results_count",
		key: "results_count",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	},
	{
		title: "CPR",
		dataIndex: "cpr",
		key: "cpr",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	},
	{
		title: "CTR",
		dataIndex: "website_ctr",
		key: "website_ctr",
		width: 120,
		render: value =><div className="text-lines">{value}</div>
	},
	{
		title: "Start date",
		dataIndex: "start_datetime",
		key: "start_datetime",
		width: 125,
		render: value => formateDate(value)
	},
	{
		title: "End date",
		dataIndex: "end_datetime",
		key: "end_datetime",
		width: 125,
		render: value => formateDate(value)
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		fixed: 'right',
		width: 190,
		render: (value, record) =>
			getStatusTable(value?.id, value?.name, record, actions, record.is_can_start, record.is_can_restart)
	}
];

export default columns;
