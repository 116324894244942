import React from "react";
import BaseAvatar from "app/components/avatar";
import { Tooltip } from "@material-ui/core";
import { CloseIcon, Info } from "app/icons";
import ConnectFaceBook from "app/components/Sdk-facebook";
import Status from "./status";
import Switch from "./switch";
import Button from "app/uikit/button/active-button";
import { getActivatingAdAccount, getTextInfo, mappedData } from "../helper";
import { PLATFORMS_NAME } from "app/emun/platforms";
import { Typography } from "@material-ui/core";

const FacebookAdsTable = ({
	data: { name, data: { id: adsId, accounts, ad_account_counts:{active} ={} } } = {},
		canChange,
		showPromotePages,
		isActivating,
		currentActivingAd,
		deleteButtonAction,
		switchChangeAction,
		showTitle
}) => {
	return (
		<>
			{showTitle && (
				<>
					<Typography variant="h3" gutterBottom>
						{PLATFORMS_NAME[name]}
					</Typography>
					<Typography className="m-b--40">
						You have {accounts?.length} profiles and {active} active ad account on Facebook Ads.
					</Typography>
				</>
			)}
			{accounts?.length && accounts.map((el, k) => {
				const { full_name, avatar_url, status_reason, id, status, promote_pages } = el;
				const data = mappedData(el.ad_accounts, id);
				const statusId = status?.id;
				const infoTextBtn = getTextInfo(status_reason);

				const reconnectClass = statusId === 3 ? "ads-table-title_reconnect" : "";
				const givePermissionClass = statusId === 2 || statusId === 3 ? "ads-table-title_with-issues" : "";
				const hasContentClass = data.length ? "ads-table-title_has-content" : "";

				return (
					<div className="wrap-ads-table">
						<div className="ads-table" key={el?.ad_accounts?.id || k}>
							<div className={`ads-table-title ${reconnectClass} ${givePermissionClass} ${hasContentClass}`}>
								<div className="ads-table-title__user-info table-title_with-avatar">
									<BaseAvatar src={avatar_url} name={full_name} />
									<p className="title-subheading title-subheading_bold ads-table-title__caption">{full_name}</p>
								</div>
								{(statusId === 2 || statusId === 3) && (
									<>
										<Tooltip title={infoTextBtn} placement="top" interactive className="ads-tooltip">
											<div>
												<Info />
												Hint
											</div>
										</Tooltip>
										<ConnectFaceBook disabled={!canChange} add={adsId} size="small" colorVariant="error" className="m-r--18">
											{(statusId === 2 && "Give permission") || (statusId === 3 && "Reconnect")}
										</ConnectFaceBook>
									</>
								)}
								{deleteButtonAction && (
									<div className="ads-table-title-button-wrapper">
										<Button
											size="small"
											className="remove-button"
											onClick={() => {
												deleteButtonAction(id);
											}}
										>
											<CloseIcon />
										</Button>
									</div>
								)}
							</div>
							{data.map((elements, i) => {
								const activatingAdAccount = getActivatingAdAccount(elements.id, currentActivingAd, isActivating);
								return (
									<div className="ads-table-row" key={i}>
										<div className="ads-table-cell ads-table-cell__name">
											<p className="main-text ads-table__user-name">{elements?.userInfo?.name}</p>
											<p className="small-text ads-table__user-id">ID: {elements?.userInfo?.id}</p>
										</div>
										<div className="ads-table-cell ads-table-cell__status">
											<Status {...elements.status} loading={activatingAdAccount} />
										</div>
										<div className="ads-table-cell ads-table-cell__sum">
											<span className="small-text">{elements.sum}</span>
										</div>
										<div className="ads-table-cell ads-table-cell__checkbox">
											<Switch
												canChange={canChange}
												{...elements.activation}
												loading={activatingAdAccount}
												onChange={switchChangeAction}
											/>
										</div>
									</div>
								);
							})}
						</div>
						{showPromotePages && (
							<div className="ads-table ads-table--short">
								<div className={`ads-table-title ${reconnectClass} ${givePermissionClass} ${hasContentClass}`}>
									<div className="ads-table-title__user-info table-title_with-avatar">
										<p className="title-subheading title-subheading_bold ads-table-title__caption"> Promote page</p>
									</div>
								</div>
								{(promote_pages?.length &&
									promote_pages.map((elements, i) => (
										<div className="ads-table-row ads-table-row--full" key={i}>
											<div className="ads-table-cell ads-table-cell__name">
												<p className="main-text ads-table__user-name">{elements.name}</p>
												<p className="small-text ads-table__user-id">ID: {elements.id}</p>
											</div>
										</div>
									))) || (
									<div className="ads-table-row ads-table-row--full">
										<div className="ads-table-cell ads-table-cell__name">
											<p className="main-text ads-table__empty">This user have no available pages</p>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				);
			}) || <div className="m-b--24">This user have no connected integrations</div>}
		</>
	);
};

export default FacebookAdsTable;
