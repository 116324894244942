import React from "react";

const ContentBox = ({ children, title }) => (
	<div className="rounded-box">
		<h3 className="title-subheading title-subheading_bold title-for-box m-b--24">{title}</h3>
		{children}
	</div>
);

export default ContentBox;
