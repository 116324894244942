import types from "./types";
import config from "app/config/config";
import { mappedOffset } from "../helper";


export const initialState = {
	campaigns: {
		items: [],
		count: 0
	},
	campaign: {},
	createCompaign: {},
	audiences: {
		items: [],
		count: 0
	},
	creatives: {
		items: [],
		count: 0,
		params: {
			total: 0,
			limit: config.ui.pagination.size,
			page: 1
		}
	},

	pagination: {
		total: 0,
		limit: config.ui.pagination.size,
		page: 1
	},

	actionLoading: {
		start: false,
		pause: false,
		create: false,
		subCreate: false,
		stop: false,
		restart: false,
		edit: false,
		confirm: false,
		subConfirm: false
	},
	actionError: "",

	filter: {},
	error: "",
	isLoading: true,

	deleteLoading: false,

	creativesLoading: false,
	creativesError: ""
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.FETCH_GET_CAMPAIGNS_REQUEST:
		case types.FETCH_GET_CAMPAIGN_REQUEST:
			return {
				...state,
				error: "",
				isLoading: true
			};
		case types.FETCH_GET_CAMPAIGNS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: "",
				campaigns: action.data,
				pagination: { ...state.pagination, total: action?.data?.count || 0 }
			};
		case types.FETCH_GET_CAMPAIGN_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: "",
				campaign: action.data
			};
		case types.FETCH_GET_CAMPAIGNS_FAILURE:
		case types.FETCH_GET_CAMPAIGN_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error
			};

		case types.GET_CAMPAIGNS_CAMPAIGNS_SUCCESS:
			return {
				...state,
				audiences: action.data
			};
		case types.GET_CAMPAIGNS_CREATIVES_REQUEST:
			return {
				...state,
				creativesLoading: true,
				creativesError: ""
			};

		case types.GET_CAMPAIGNS_CREATIVES_SUCCESS:
			return {
				...state,
				creativesLoading: false,
				creativesError: "",
				creatives: action.data
			};

		case types.GET_CAMPAIGNS_CREATIVES_FAILURE:
			return {
				...state,
				creativesLoading: false,
				creativesError: action.data
			};
		case types.FETCH_DELETE_CAMPAIGNS_REQUEST:
			return {
				...state,
				deleteLoading: true
			};
		case types.FETCH_DELETE_CAMPAIGNS_SUCCESS:
			let newPagination = state.pagination;

			if (action.data >= state.campaigns.items.length) {
				newPagination.page = 1;
				newPagination.offset = null;
			}
			return {
				...state,
				pagination: newPagination,
				deleteLoading: false
			};
		case types.FETCH_DELETE_CAMPAIGNS_FAILURE:
			return {
				...state,
				deleteLoading: false
			};

		case types.FETCH_START_CAMPAIGN_REQUEST:
		case types.FETCH_PAUSE_CAMPAIGN_REQUEST:
		case types.FETCH_EDIT_CAMPAIGN_REQUEST:
		case types.CREATE_CAMPAIGNS_REQUEST:
		case types.FETCH_RESTART_CAMPAIGN_REQUEST:
			return {
				...state,
				actionLoading: { ...state.actionLoading, [action.actionName]: true },
				actionError: ""
			};
		case types.FETCH_START_CAMPAIGN_SUCCESS:
		case types.FETCH_PAUSE_CAMPAIGN_SUCCESS:
		case types.FETCH_EDIT_CAMPAIGN_SUCCESS:
		case types.CREATE_CAMPAIGNS_SUCCESS:
		case types.FETCH_RESTART_CAMPAIGN_SUCCESS:
			return {
				...state,
				actionLoading: { ...state.actionLoading, [action.data.actionName]: false },
				actionError: ""
			};
		case types.FETCH_START_CAMPAIGN_FAILURE:
		case types.FETCH_PAUSE_CAMPAIGN_FAILURE:
		case types.FETCH_EDIT_CAMPAIGN_FAILURE:
		case types.CREATE_CAMPAIGNS_FAILURE:
		case types.FETCH_RESTART_CAMPAIGN_FAILURE:
			return {
				...state,
				actionLoading: { ...state.actionLoading, [action.data.actionName]: false },
				actionError: action.data.errors
			};

		case types.SET_DATA_CREATE_CAMPAIGNS:
			return {
				...state,
				createCompaign: { ...state.createCompaign, ...action.data }
			};
		case types.FETCH_PAGINATION_CAMPAIGNS_REQUEST:
			return {
				...state,
				pagination: { ...state.pagination, ...action.data, offset: mappedOffset(action.data, state.pagination) }
			};
		case types.FETCH_FILTER_CAMPAIGNS_SUCCESS:
			return {
				...state,
				pagination: { ...state.pagination, limit: initialState.pagination.limit, page: 1, offset: null },
				filter: { ...state.filter, ...action.data }
			};
		case types.RESET_CAMPAIGNS_TABLE_PARAMS: {
			return {
				...state,
				pagination: initialState.pagination,
				filter: initialState.filter
			};
		}
		case types.RESTE_DRAFT_CAMPAIGN:
			return {
				...state,
				createCompaign: initialState.createCompaign,
				creatives: initialState.creatives
			};
		default:
			return state;
	}
};

export default reducer;
