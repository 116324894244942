import yup from "app/yup";
import { ErrorName, ErrorEmail, ErrorPassword, ErrorPassword_confirmation } from "app/errors-shcema";

export const validationSchemaProfile = yup.object({
	name: ErrorName, 
	email: ErrorEmail,
});

export const validationSchemaPrivacy = yup.object({
	old_password: ErrorPassword, 
	password: ErrorPassword,
	password_confirmation: ErrorPassword_confirmation
});