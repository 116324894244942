import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionIntegration from "app/store/integrations/actions";
import StepOneBase from "../../../steps/step-1";

const StepOne = ({ data, onChangeStep, nextStep, onChangeDate, toWantStep,...rest}) => {
	const { adAccounts } = useSelector(state => state.integrations);
	const { platform } = data;
	const dispatch = useDispatch();
	const adAccountsList = adAccounts[platform];

	useEffect(() => {
		dispatch(actionIntegration.getAdAccounts());
	}, [dispatch]);

	const onSubmit = (values,shouldNotUpdate, shouldNotoNextStep) => {
		if(!shouldNotUpdate){
			onChangeDate(values);
		}
		if(!shouldNotoNextStep){
			onChangeStep(nextStep);
		}
	};
	return <StepOneBase edit onSubmit={onSubmit} data={data} listAdAccounts={adAccountsList?.ad_accounts} {...rest} />;
};

export default StepOne;
