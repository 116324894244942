import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useFormik } from "formik";
import { getIcon } from "app/components/integration-card/helper";
import Button from "app/uikit/button/active-button";
import { PLATFORMS } from "app/emun/platforms";

const LabelContent = props => {
	return (
		<div className="radio-label">
			<div className="radio-caption">
				<div className="radio-icon">{props.icon}</div>
				<div className="radio-text">
					<p className="main-text main-text_bold">{props.text}</p>
					<span className="small-text">{props.subtext}</span>
				</div>
			</div>
		</div>
	);
};

const StepChoosePlatform = ({ setPlatform }) => {
	const formik = useFormik({
		initialValues: {
			platform: PLATFORMS.facebook_ads
		},
		onSubmit: values => {
			setPlatform(values.platform);
		}
	});
	return (
		<>
			<div className="step-one">
				<p className="title-subheading title-subheading_bold m-b--4">Integration platform</p>
				<p className="text-main m-b--24">Select the integration for which you gonna create a campaign.</p>
				<form onSubmit={formik.handleSubmit} noValidate>
					<FormControl className="radio">
						<RadioGroup name="platform-type">
							<FormControlLabel
								value="facebook_ads"
								control={
									<Radio
										name="platform"
										value="facebook_ads"
										checked={formik.values.platform === "facebook_ads"}
										onChange={formik.handleChange}
										color="primary"
									/>
								}
								label={
									<LabelContent icon={getIcon("facebook_ads")} text="Facebook Ads" subtext="Create an outstanding campaign." />
								}
							/>
							<FormControlLabel
								disabled={true}
								control={
									<Radio
										name="platform"
										value="google_ads"
										checked={formik.values.platform === "google_ads"}
										onChange={formik.handleChange}
										color="primary"
									/>
								}
								label={<LabelContent icon={getIcon("google_ads")} text="Google Ad" subtext="Headline, description, result!" />}
							/>
							<FormControlLabel
								disabled={true}
								control={
									<Radio
										name="platform"
										value="linkedin"
										checked={formik.values.platform === "linkedin"}
										onChange={formik.handleChange}
										color="primary"
									/>
								}
								label={
									<LabelContent icon={getIcon("linkedin")} text="Linkedin Ads" subtext="Create an outstanding Linkedin Ads" />
								}
							/>
						</RadioGroup>
					</FormControl>
				</form>
			</div>
			<div className="campaign-footer">
				<Button onClick={formik.handleSubmit} size="medium" colorVariant="gradient">
					Next Step
				</Button>
			</div>
		</>
	);
};

export default StepChoosePlatform;
