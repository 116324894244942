import types from "./types";
import { stateRequest } from "../helper";

export const initialState = {
	error: {
		support: ""
	},
	isLoading: {
		support: false
	}
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.UPLOAD_SUPPORT_REQUEST:
			return {
				...state,
				...stateRequest(state, action, "request")
			};
		case types.UPLOAD_SUPPORT_FAILURE:
			return {
				...state,
				...stateRequest(state, action, "failure")
			};
		case types.UPLOAD_SUPPORT_SUCCESS:
			return {
				...state,
				...stateRequest(state, action, "success")
			};

		default:
			return state;
	}
};

export default reducer;