import React, { useEffect } from "react";
import Layout from "app/components/includs/layout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Input from "app/uikit/input";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import actions from "app/store/auth/actions";
import { Link } from "react-router-dom";
import PATHS from "PATHS";
import { handlerErrors } from "app/helper/handlerErrors";
import Error from "app/uikit/error";

const Login = () => {
	const { isLoading, error } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const handleSubmit = values => {
		dispatch(actions.fetchSignInRequest(values));
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: ""
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		return () => {
			dispatch(actions.fetchResetActionState());
		};
	}, [dispatch]);

	useEffect(() => {
		handlerErrors(error, formik.setErrors);
	}, [error, formik.setErrors]);

	return (
		<Layout>
			<div className="login-box">
				<h1 className="title-main">Login</h1>
				<form className="user-form m-b--32" onSubmit={formik.handleSubmit} noValidate>
					<Input fullWidth name="email" type="email" label="Email address" form={formik} required />
					<Input
						fullWidth
						required
						name="password"
						type="password"
						labelClassName="label-with-link"
						form={formik}
						label="Password"
						labelContent={
							<Link to={PATHS.auth.passwordRecovery}>Forgot password?</Link>
						}
					/>

					<Error className="m-b--8" error={error} />

					<Button
						type="submit"
						loading={isLoading}
						fullWidth
						// className="before-button-space"
						size="large"
						colorVariant="gradient"
					>
						Login
					</Button>
				</form>
				<div className="login-box-bottom">
					<p className="main-text">
						Don’t have an account yet?{" "}
						<Link to={PATHS.auth.registration} className="orange-link">
							Create an account
						</Link>
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default Login;
