import yup from "app/yup";
import directory from "app/directory/errors";
import {ErrorFileRequired  } from "app/errors-shcema";

const dir = directory.en;

const validationSchema = yup.object({
	creative_name: yup
		.string()
		.min(1, dir.password.replace("#{number}", 1))
		.max(40, dir.maxLength.replace("#{number}", 40))
		.test(
			'Standalone diacritical marks are not allowed',
			'Standalone diacritical marks are not allowed',
			(value) => !/[À-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]/.test(value)
		)
		.test(
			'^~_={}[]|<> characters are not allowed',
			'^~_={}[]|<> characters are not allowed',
			(value) => (!/[\^<>~_={}\[\]\|]/gi.test(value))
		)
		.test(
			'Cannot start with punctuation',
			'Cannot start with punctuation',
			// eslint-disable-next-line no-useless-escape
			(value) => (!/^[\\\/\!\.\?\-\*\(\)\,\;\:]/gi.test(value))
		)
		.test(
			'Cannot have consecutive punctuation except of three full-stops ...',
			'Cannot have consecutive punctuation except of three full-stops ...',
			(value) => {
				// eslint-disable-next-line no-useless-escape
				const matches = value?.match(/[\.\,;:]{2,}/gi) || [];
				const res = matches.filter(el=>(el !== '...'));
				return !res.length;
			}
		)
		.required(dir.required),
	description: yup
		.string()
		.min(1, dir.password.replace("#{number}", 1))
		.max(800, dir.maxLength.replace("#{number}", 800))
		.test(
			'Standalone diacritical marks are not allowed',
			'Standalone diacritical marks are not allowed',
			(value) => !/[À-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]/.test(value)
		)
		.test(
			'^~_={}[]|<> characters are not allowed',
			'^~_={}[]|<> characters are not allowed',
			// eslint-disable-next-line no-useless-escape
			(value) => (!/[\^<>~_={}\[\]\|]/gi.test(value))
		)
		.test(
			'Cannot start with punctuation',
			'Cannot start with punctuation',
			// eslint-disable-next-line no-useless-escape
			(value) => (!/^[\\\/\!\.\?\-\*\(\)\,\;\:]/gi.test(value))
		)
		.test(
			'Cannot have consecutive punctuation except of three full-stops ...',
			'Cannot have consecutive punctuation except of three full-stops ...',
			(value) => {
				// eslint-disable-next-line no-useless-escape
				const matches = value?.match(/[\.\,;:]{2,}/gi) || [];
				const res = matches.filter(el=>(el !== '...'));
				return !res.length;
			}
		)
		.required(dir.required),
	headline: yup.string().max(60, dir.maxLength.replace("#{number}", 60)),
	banner: ErrorFileRequired,
	website_url: yup.string().max(1024, dir.maxLength.replace("#{number}", 1024)).website(dir.webSite).required(dir.required),
	call_to_action: yup.string().required(dir.required)
});

export const checkWarnings = (values,setWarnings) => {
	let warn = {};
	if(values.creative_name.length > 25){
		warn.creative_name = 'No more than 25 characters are recommended'
	}else{
		warn.creative_name = ''
	}
	if(values.headline.length > 40){
		warn.headline = "No more than 40 characters are recommended"
	}else{
		warn.headline = ''
	}
	setWarnings(state=>({...state,...warn}));
}

export default validationSchema;