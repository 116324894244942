import React, { useState } from "react";
import { withRouter } from "react-router";
import { useFormik } from "formik";
import style from "./style.module.scss";
import Loader from "app/components/loader";
import UserLayout from "app/components/includs/user-layout";
import Input from "app/uikit/input";
import Select from "app/uikit/select";
import Button from "app/uikit/button/active-button";
import TemplatePost from "./post";
import Range from "app/uikit/range";

import validationSchema from "./validationSchema";

const maxLen = 2100;
const options = [
	{ text: "Facebook Ads Post", value: "facebook" },
	{ text: "Linkedin Ads Post", value: "linkedin" },
	{ text: "Google adwords Post", value: "Google" }
];

const AddCreative = () => {
	const [loading, setLoading] = useState(false);
	const [responsText, setResponsText] = useState("");
	const handleSubmit = async values => {
		setLoading(true);
		const res = await restartFetch({
			prompt: values.description,
			max_tokens: values.max_tokens,
			temperature: values.temperature,
			frequency_penalty: values.frequency_penalty,
			presence_penalty: values.presence_penalty,
			top_p: values.top_p,
			best_of: values.best_of
		});

		setLoading(false);
		setResponsText((res?.choices?.length && `${values.description} ${res?.choices[0].text}`) || "");
	};

	const restartFetch = async data => {
		try {
			const res = await fetch("https://api.openai.com/v1/engines/davinci/completions", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer sk-mk0NCIYWIBJFKM40eIHMCmTQiObmapg4PdQCUlGO"
				},
				body: JSON.stringify(data),
				
			});
			return await res.json();
		} catch (e) {}
		return;
	};

	const formik = useFormik({
		initialValues: {
			max_tokens: 64,
			temperature: 0.7,
			frequency_penalty: 0,
			presence_penalty: 0,
			top_p: 1,
			best_of: 1,
			description: "",
			company_name: "",
			call_to_action: "facebook"
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: handleSubmit
	});

	const procent = Math.round((responsText.length / maxLen) * 100);

	return (
		<UserLayout>
			<Loader delay>
				<div className={style.template_creation_wrapper}>
					<div className={style.template_creation_col}>
						<div>
							<h2 className="title-h2 m-b--40">AI content generation</h2>
							<form>
								<Select list={options} fullWidth className="m-b--24" form={formik} name="call_to_action" label="Content type" />
								<div className="m-b--24">
									<Input fullWidth name="company_name" label="Company name" wrapperClassName="m-b--24" />
								</div>
								<div className="m-b--24">
									<Input
										multiline
										rows={5}
										form={formik}
										fullWidth
										name="description"
										placeholder="e.g. Signum.ai is Marketing automation tool powered by AI..."
										label="Description"
										required
									/>
								</div>
								<div className="m-b--24">
									<Range
										form={formik}
										fullWidth
										name="max_tokens"
										info="The maximum number of tokens to generate. Requests can use up to 500 tokens shared between prompt and completion. (One token is roughly 4 characters for normal English text)"
										label="Response Length"
										min={64}
										max={500}
									/>
								</div>

								<div className="m-b--24">
									<Range
										form={formik}
										fullWidth
										info="Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive."
										name="temperature"
										label="Temperature"
										step={0.1}
										max={1}
										min={0}
									/>
								</div>
								<div className="m-b--24">
									<Range
										form={formik}
										fullWidth
										info={
											<>
												An alternative to sampling with temperature, called nucleus sampling, where the model considers the results
												of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability
												mass are considered.
												<br />
												<br />
												We generally recommend altering this or temperature but not both.
											</>
										}
										name="top_p"
										label="Top p"
										step={0.01}
										max={1}
										min={0}
									/>
								</div>
								<div className="m-b--24">
									<Range
										form={formik}
										fullWidth
										info="Number between 0 and 1 that penalizes new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim."
										name="frequency_penalty"
										label="Trequency penalty"
										step={0.01}
										max={1}
										min={0}
									/>
								</div>
								<div className="m-b--24">
									<Range
										form={formik}
										fullWidth
										info="Number between 0 and 1 that penalizes new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics."
										name="presence_penalty"
										label="Presence penalty"
										step={0.01}
										max={1}
										min={0}
									/>

									<Range
										form={formik}
										fullWidth
										info={
											<>
												Generates best_of completions server-side and returns the "best" (the one with the lowest log probability
												per token). Results cannot be streamed.
												<br />
												<br />
												When used with n, best_of controls the number of candidate completions and n specifies how many to return –
												best_of must be greater than n.
												<br />
												<br />
												Note: Because this parameter generates many completions, it can quickly consume your token quota. Use
												carefully and ensure that you have reasonable settings for max_tokens and stop.
											</>
										}
										name="best_of"
										label="Best of"
										step={1}
										max={20}
										min={1}
									/>
								</div>

								<Button loading={loading} onClick={formik.handleSubmit} className="button button_gradient button_medium">
									Content generation
								</Button>
							</form>
							<div className={style.progress}>
								<p className={style.progress__text}>
									<span>{responsText.length}</span>/{maxLen} characters used
								</p>
								<div className={style.progress__block}>
									<div className={style.progress__treck} style={{ width: procent > 100 ? "100%" : `${procent}%` }}></div>
								</div>
							</div>
						</div>
					</div>
					<TemplatePost text={responsText} />
				</div>
			</Loader>
		</UserLayout>
	);
};
export default withRouter(AddCreative);
