import React from "react";

import { useSelector } from "react-redux";
import moment from "moment";

import CreativeItem from "app/components/CreativeItem";
import { getPlatformIcon } from "app/helper/platform_helper";
import { PLATFORMS_NAME } from "app/emun/platforms";
import Button from "app/uikit/button";
import { RelaunchIcon, PauseIcon, PlayIcon } from "app/icons";
import { formateDate, getListWithCategory } from "app/helper";
import config from "app/config"

import { Status } from "../../helper";

import style from "./style.module.scss";
import CheckBlock from "./checkBlock";

const getDateErros = (date, value, valueSecond) => {
	if (!date || !value) return date;
	const splitDate = date.split("#{date}");
	if (splitDate.length >= 2) {
		return (
			<>
				{splitDate[0]}
				<strong>"{moment(value).format("DD MMMM YYYY")}"</strong>
				{splitDate[1]}
				{splitDate[2] && (
					<>
						<strong>"{moment(valueSecond).format("DD MMMM YYYY")}"</strong> {splitDate[2]}
					</>
				)}
			</>
		);
	} else {
		return date;
	}
};

const StepReview = ({
	data,
	changeStepTo,
	errors = {},
	title,
	subTitle,
	onStart,
	haveChanges,
	onRelaunch,
	onPause,
	onSubmit,
	onUpdate
}) => {
	const { campaign_conversion_types, facebook_standard_conversions, gender_types } = useSelector(state => state.handBooks);
	const canPauseAndUpdat = data?.status?.id === 1 || data?.status?.id === 5;
	const canUpdate = data?.status && data?.status?.id !== 4 && data?.status?.id !== 6;
	const canRelaunch = data?.status?.id === 3;
	const canLaunch = !data?.status || data?.status?.id === 4 || data?.status?.id === 6;
	const canStart = data?.status?.id === 2;
	const platform = data.platform && (
		<>
			{getPlatformIcon(data.platform)} {PLATFORMS_NAME[data.platform]}
		</>
	);

	const canEditAdAccount = !data?.status || data?.status?.id === 4;

	//убран кастомный выбор
	const formatedListConversionTypes = getListWithCategory(campaign_conversion_types && [campaign_conversion_types[0]], [
		facebook_standard_conversions
	]);
	const onChangeAdAccount = () => {
		changeStepTo(1);
	};

	const onChangeDetails = () => {
		changeStepTo(2);
	};

	const onChangeCreative = () => {
		changeStepTo(3);
	};

	const haveErrors = !!Object.keys(errors).length;

	const conversionIventType = formatedListConversionTypes?.find(elem=>elem?.category?.value === data?.conversion_type_id)
	const conversionValueIventType = conversionIventType?.list?.find(elem=>elem.value === data?.conversion_id)?.text;
	const isConversion = data?.target?.id === 2;

	const isDailyBudget = data?.budget_type_id?.id === 2;

	const ageArrayErrors = [errors?.age_from, errors?.age_to].filter(el=>el);

	return (
		<>
			<div className={style["step-five"]}>
				<div className={style.header}>
					<div>
						<p className="title-subheading title-subheading_bold m-b--4">{title || "Final campaign review"}</p>
						<p className="text-main m-b--24">
							{subTitle || "Check and be sure that you have chosen the correct parameters."}
						</p>
					</div>
					<div>
						<Status type={data.status?.id} text={data.status?.name} />
					</div>
				</div>

				<CheckBlock
					title="Integration platform"
					itemsCheckList={[
						{
							itemValue: platform,
							error: errors?.platform
						}
					]}
				/>

				<CheckBlock
					title="Ad account"
					editStep={canEditAdAccount && onChangeAdAccount}
					itemsCheckList={[
						{
							itemName: "Ad account",
							itemValue: data?.adAccount?.name,
							error: errors?.adAccount
						},
						{
							itemName: "Facebook page",
							itemValue: data?.platform_parameters?.name || data?.platform_parameters?.page_name,
							error: errors?.page_id
						},
						{
							itemName: "Campaign Objective",
							itemValue: data?.target?.name,
							error: errors?.target
						}
					]}
				/>

				<CheckBlock
					title="Campaign details"
					editStep={onChangeDetails}
					itemsCheckList={[
						{
							itemName: "Campaings name",
							itemValue: data?.name,
							error:  errors?.name
						},
						{
							itemName: "Target audience",
							itemValue: data?.audience?.name,
							error: errors?.audience
						},
						{
							itemName: "Look a like",
							itemValue: data?.is_use_similar_audience ? "Turn on" : "Turn off",
							error: errors?.is_use_similar_audience
						},
						{
							itemName: "Genders",
							itemValue: (gender_types.find(el=>el.id === data?.gender_type || el.id === data?.gender_type?.id)?.name) || "All",
							error: errors?.is_use_similar_audience
						},
						{
							itemName: "Age",
							itemValue: `${data?.age_from || config.minAge} - ${data?.age_to || config.maxAge}` || '',
							error:  ageArrayErrors?.length && ageArrayErrors
						},
						{
							itemName: "Targeting countries",
							itemValue: data?.countries,
							error: errors?.countries,
							hide: !data?.countries?.length
						},
						{
							itemName: data.budget_type_id?.name,
							itemValue: data?.budget && `${data?.budget} ${data?.currency}`,
							error: errors?.budget
						},
						{
							itemName: "Start date",
							itemValue: data?.start_datetime && moment(data?.start_datetime).format("DD MMMM, YYYY"),
							error: getDateErros(errors.start_datetime, data?.start_datetime)
						},
						{
							itemName: "End date",
							itemValue: data?.end_datetime && moment(data?.end_datetime).format("DD MMMM, YYYY"),
							error: getDateErros(errors.end_datetime, data?.end_datetime, data?.start_datetime),
							hide: isDailyBudget
						},
						{
							itemName: "Conversion event location",
							itemValue: "Website",
							error: errors?.conversion_event_location,
							hide: !isConversion
						},
						{
							itemName: "Pixel",
							itemValue:  data?.adAccount?.pixels?.find(elem=>elem.id === data?.pixel_id)?.name || '',
							error: errors?.pixel_id,
							hide: !isConversion
						},
						{
							itemName: "Conversion event",
							itemValue: `${conversionIventType?.category?.name || ''} ${conversionValueIventType && "(" + conversionValueIventType + ")" || ''}` || '',
							error: errors?.conversion_id,
							hide: !isConversion
						}
					]}
				/>
				<CheckBlock
					itemsCheckList={[
						{
							render: (
								<div className="template-wrp">
									<CreativeItem
										preview={data?.creative?.fields?.banner?.url}
										title={data?.creative?.fields?.creative_name}
										description={data?.creative?.fields?.description}
										date={formateDate(data?.creative?.created_at)}
									/>
								</div>
							),
							error: errors?.creative
						}
					]}
					title="Creative template"
					editStep={onChangeCreative}
				/>
			</div>
			<div className="campaign-footer">
				{canPauseAndUpdat && (
					<Button onClick={onPause} size="medium" colorVariant="blue">
						<span className="m-r--6">Pause</span> <PauseIcon />
					</Button>
				)}
				{canUpdate && (
					<Button onClick={onUpdate} disabled={haveErrors || !haveChanges} size="medium" colorVariant="gradient">
						<span className="m-r--6">Update</span> <RelaunchIcon />
					</Button>
				)}
				{canRelaunch && (
					<Button onClick={onRelaunch} disabled={!data.is_can_restart} size="medium" colorVariant="gradient">
						<span className="m-r--6">Duplicate & Relaunch</span> <RelaunchIcon />
					</Button>
				)}
				{canLaunch && (
					<Button onClick={onSubmit} disabled={haveErrors} size="medium" colorVariant="gradient">
						Confirm & Launch
					</Button>
				)}
				{canStart && (
					<Button onClick={onStart} disabled={haveErrors} size="medium" colorVariant="gradient">
						<span className="m-r--6">Start</span> <PlayIcon />
					</Button>
				)}
			</div>
		</>
	);
};

export default StepReview;
