import yup from "app/yup";
import directory from "app/directory/errors";

const dir = directory.en;

const validationSchema = yup.object({
	ad_keywords: yup.array().max(10,'Not more 10'),
	stop_keywords: yup.array().max(2,'Not more 2'),
	description: yup.string().min(2, dir.minLength.replace('#{number}',2)).max(256, dir.maxLength.replace('#{number}',256)).required(dir.required)

});

export default validationSchema;