import React, { useCallback } from "react";
import style from "./style.module.scss";

const MarketplaceItem = ({ data: { name, image } = {}, data, onClick }) => {
	const onChose = useCallback(() => {
		onClick(data);
	}, [data]);

	return (
		<div className={style.item} onClick={onChose}>
			<div className={style.item__img}>
				<img src={image?.url} alt={image?.name} />
			</div>
			<p className={style.item__desc}>{name}</p>
		</div>
	);
};

export default MarketplaceItem;