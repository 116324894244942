import { takeEvery, put, all, call, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import services from "./services";
import types from "./types";
import actions from "./actions";
import { getErrorsKeys } from "app/helper/handlerErrors";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";
import {CREATIVE_ACTION_MAP} from 'app/emun';
import PATHS from "PATHS";

const dir = directory.en;

function* getCreatives({ target = CREATIVE_ACTION_MAP[0] ,params }) {
	try {
		const {
			creatives: { pagination, filter }
		} = yield select();
		const response = yield call(services.getCreatives, { ...params, ...pagination[target], ...filter[target] });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getCreativesSuccess({
					data:{
						count: response.data.count,
						items: response.data.items,
					},
					target: target
				})
			);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.getCreativesFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.getCreativesFailure(error?.response?.data?.message));
		} else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getCreativesFailure(""));
		}
	}
}
function* getTemplates({ params }) {
	try {
		const {
			creatives: { pagination, filter }
		} = yield select();
		const response = yield call(services.getTemplates, { ...params, ...pagination.templates, ...filter.templates });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getTemplatesSuccess({
					count: response.data.count,
					items: response.data.items
				})
			);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.getTemplatesFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.getTemplatesFailure(error?.response?.data?.message));
		} else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getTemplatesFailure(""));
		}
	}
}


function* getCreative({id}) {
	try {
		const response = yield call(services.getCreative, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getCreativesuccess(response?.data?.creative_template));
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			yield put(actions.getCreativeFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put (push(PATHS.cabinet.creatives))
		}else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getCreativeFailure(""));
		}
	}
}

function* createCreative({ data, cb }) {
	try {
		const response = yield call(services.createCreative, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.createCreativeSuccess());
			toastify.success(response?.data?.message);
		}
		cb && cb();
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.createCreativeFailure(getErrorsKeys(error?.response?.data?.errors) || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.createCreativeFailure(error?.response?.data?.message));
		} else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.createCreativeFailure(""));
		}
	
	}
}

function* deleteItems({ target, ids, cb }) {
	try {
		const response = yield call(services.deleteCreatives, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const arrIds = ids?.split(",");
			yield put(actions.deleteItemsSuccess({target,data:arrIds?.length}));
			cb && cb();
			toastify.success(response?.data?.message);
			yield put(actions.getCreatives(target));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.deleteItemsFailure(""));
	}
}

function* editCreative({ data, id, cb }) {
	try {
		const response = yield call(services.editCreative, { data, id });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.editCreativeSuccess());
			toastify.success(response.data.message);
		}
		cb && cb();
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.editCreativeFailure(getErrorsKeys(error?.response?.data?.errors) || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.editCreativeFailure(error?.response?.data?.message));
		}else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.editCreativeFailure(""));
		}
		
	}
}
function* generateContent({ data }) {
	try {
		const response = yield call(services.generateContent,data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.generateContentSuccess(response.data?.items));
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.generateContentFailure(getErrorsKeys(error?.response?.data?.errors) || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.generateContentFailure(error?.response?.data?.message));
		}else{
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.generateContentFailure(""));
		}
	}
}

function* publicCreative({ data,id, cb }) {
	try {
		const response = yield call(services.publicCreative, { data, id });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.publicCreativeSuccess({id}));
			toastify.success(response.data.message);
			cb && cb();
			yield all(CREATIVE_ACTION_MAP.map(element => put(actions.getCreatives(element))));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.publicCreativeFailure({id}));
	}
}

function* copyTemplate({ ids, cb }) {
	try {
		const response = yield call(services.copyTemplate, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.copyTemplateSuccess({ids}));
			toastify.success(response.data.message);
			cb && cb();
			yield put(actions.getCreatives(CREATIVE_ACTION_MAP[0]));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.copyTemplateFailure({ids}));
	}
}

function* fetchPagination({ target, data }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess({target,data:params}));
		yield put(actions.getCreatives(target));
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchFilter({target, data }) {
	try {
		yield put(actions.fetchFilterSuccess({target,data}));
		yield put(actions.getCreatives(target));
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.FETCH_GET_CREATIVES_REQUEST, getCreatives)]);
	yield all([takeEvery(types.FETCH_GET_TEMPLATES_REQUEST, getTemplates)]);
	yield all([takeEvery(types.FETCH_GET_CREATIVE_REQUEST, getCreative)]);
	yield all([takeEvery(types.FETCH_DELETE_CREATIVE_REQUEST, deleteItems)]);
	yield all([takeEvery(types.FETCH_CREATE_CREATIVE_REQUEST, createCreative)]);
	yield all([takeEvery(types.FETCH_GENERATE_CONTENT_CREATIVE_REQUEST, generateContent)]);
	yield all([takeEvery(types.FETCH_PUBLIC_CREATIVE_REQUEST, publicCreative)]);
	yield all([takeEvery(types.FETCH_COPY_CREATIVES_REQUEST, copyTemplate)]);
	yield all([takeEvery(types.FETCH_EDIT_CREATIVE_REQUEST, editCreative)]);
	yield all([takeEvery(types.FETCH_PAGINATION_CREATIVE_REQUEST, fetchPagination)]);
	yield all([takeEvery(types.FETCH_FILTER_CREATIVE_REQUEST, fetchFilter)]);
}
