import types from "./types";
import localStore from "app/helper/local-store";
const tokenStorage = localStore.getAccessToken();

export const initialState = {
	token: tokenStorage,
	passwordChanged: false,
	ccountRegistered: false,
	error: "",
	isLoading: false
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.FETCH_SIGN_IN_REQUEST:
		case types.FETCH_SIGN_UP_REQUEST:
		case types.FETCH_PASSWORD_RECOVERY_REQUEST:
		case types.FETCH_NEW_PASSWORD_REQUEST:
			return {
				...state,
				isLoading: true,
				passwordChanged: false,
				error: ""
			};
		case types.FETCH_SIGN_IN_SUCCESS:
			return {
				...state,
				...action.data,
				isLoading: false
			};
		case types.FETCH_SIGN_UP_SUCCESS:
			return{
				...state,
				isLoading: false,
				error: "",
				ccountRegistered: true
			}
		case types.FETCH_PASSWORD_RECOVERY_SUCCESS:
		case types.FETCH_NEW_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
				passwordChanged: true,
				error: ""
			};
		case types.FETCH_SIGN_IN_FAILURE:
		case types.FETCH_SIGN_UP_FAILURE:
		case types.FETCH_NEW_PASSWORD_FAILURE:
		case types.FETCH_PASSWORD_RECOVERY_FAILURE:
			return {
				...state,
				isLoading: false,
				passwordChanged: false,
				ccountRegistered: false,
				error: action.error
			};
		case types.FETCH_RESET_ACTION_STATE:
			return {
				...state,
				isLoading: false,
				passwordChanged: false,
				ccountRegistered: false,
				error: ""
			};
		// case types.FETCH_LOGOUT_SUCCESS:
		// case types.FETCH_LOGOUT_FAILURE:
		// 	return initialState;
		default:
			return state;
	}
};

export default reducer;