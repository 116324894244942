import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "rc-table";
import CircularProgress from "app/uikit/CircularProgress";
import Header from "app/uikit/table/header";
import EmptyData from "app/uikit/table/EmptyData";
import Toolbar from "app/uikit/table/toolbar";
import Tabs from "app/components/tabs/Tabs";
import TabPanel from "app/components/tabs/TabPanel";
import ConfirmDelete from "../modal/confirm-modal.jsx";
import { getTableItemsById, getCheckedItem } from "app/helper";
import useColumnsMenu from "app/hooks/useColumnsMenu";
import useColumnsCheck from "app/hooks/useColumnsCheck";
import debounce from "lodash.debounce";
import Footer from "app/uikit/table/footer";
import EmptySearch from "app/uikit/table/emptySearch";
import styleTable from "app/uikit/table/style.module.scss";
import cl from "classnames";
import { useHistory } from "react-router";

const TabsTable = ({
	size,
	pagination: paginationMain,
	loading,
	paginationChange,
	title,
	toolbar,
	tableClassName,
	titleClassName,
	footer,
	emptySearchComponent,
	tabsList,
	action,
	data: dataMain,
	filter: filterMain,
	tab,
	setTab,
	initialTab,

	onChangeDelete,
	onChangeUnselect,
	onChangeSearch,
	onChangeDownload,
	onChangePagination,

	menuOnChange: menuOnChangeMain,

	getCurrentTab,

	deleteIcon: deleteIconMain,
	deteTitle: deelteTitleMain,
	textAgree: textAgreeMain,
	textClose: textCloseMAin,
	deleteText: deleteTextMain,

	emptySearchText: emptySearchTextMain,
	emptyDataText: emptyDataTextMAin,
	onHasData: onHasDataMain,
	emptyData: emptyDataMain
}) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [deteTitleValue, setDeleTitleValue] = useState("");
	const [currentTab, setCurrentTab] = useState(initialTab ?? 0);
	const [currentSelected, setCurrentSelected] = useState("");
	const [firstRender, setFirstRender] = useState(false);
	const infoTabName = tabsList[tab || currentTab].name;
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		columns,
		data = dataMain && dataMain[infoTabName],
		pagination = paginationMain && paginationMain[infoTabName],
		filter = filterMain && filterMain[infoTabName],
		onHasData = onHasDataMain,
		emptyData = emptyDataMain,
		emptyDataText = emptyDataTextMAin,
		emptySearchText = emptySearchTextMain,
		path,
		settings: {
			check: { showId: dataShowId, hide: hideCheck } = {},
			menu: {
				component: menuComponent,
				onChange: menuOnChange = menuOnChangeMain,
				render: menuRender,
				hide: hideMenu,
				renderWithHandler: menuRenderWithHandler,
				iconDelete,
				renderIconDelete
			} = {},
			toolbar: {
				delete: showToolbarDelete = true,
				search: showToolbarSearch = true,
				unSelect: showToolbarUnselect = true,
				download: showToolbarDownload
			} = {},
			delete: {
				icon: deleteIcon = deleteIconMain,
				title: deteTitle = deelteTitleMain,
				text: deleteText = deleteTextMain,
				textAgree = textAgreeMain,
				textClose = textCloseMAin
			} = {}
		} = {},
		onRequest
	} = useMemo(() => tabsList[tab || currentTab], [tab, currentTab, tabsList]);

	useEffect(()=>{
		setFirstRender(true);
	},[]);

	useEffect(()=>{
		if(firstRender && path){
			history.replace(path);	
		}
	},[path]);	
	
	const menuHandleChange = (id, key) => {
		switch (key) {
			case "delete":
				if (!id) return;
				changeDelete(id);
				break;
			default:
				return menuOnChange && menuOnChange(id, key);
		}
	};

	const mappedData = useMemo(() => data?.items?.map(el => ({ ...el, key: el.id })), [data]);

	const {
		columnsWithCheck,
		ctrl: { checked, setChecked }
	} = useColumnsCheck(columns, mappedData, dataShowId, hideCheck);

	const columnsWithMenu = useColumnsMenu(
		columnsWithCheck,
		data,
		menuHandleChange,
		menuComponent,
		menuRender,
		hideMenu,
		menuRenderWithHandler,
		iconDelete,
		renderIconDelete
	);

	useEffect(() => {
		setCurrentSelected(getCheckedItem(checked));
	}, [checked]);

	const mappedDeleteText = useMemo(() => {
		const names = getTableItemsById(currentSelected, data?.items).map(el => el?.name);
		const namesMap = names.join(", ");
		if (typeof deleteText !== "function") {
			return (
				<>
					{(names.length > 1 && (deleteText?.many || "Items")) || deleteText?.one || "Item"}:{" "}
					<span className="small-text_bold">{namesMap} </span>
				</>
			);
		}
		if (deleteText) {
			return deleteText(currentSelected);
		}
	}, [currentSelected, deleteText]);

	const handleChangeTabs = (_, newValue) => {
		if(setTab){
			setTab(newValue);
		}else{
			setCurrentTab(newValue);
		}
		getCurrentTab && getCurrentTab(newValue, tabsList[newValue]);
	};

	useEffect(() => {
		firstRender && onRequest && onRequest();
	}, [onRequest]);

	const stateHasData = useMemo(() => {
		let hasData = false;
		let emptySearch = false;

		if (data?.items?.length) {
			hasData = true;
		}

		if ((filter && filter["filter[search]"]) !== undefined && !data?.items?.length) {
			emptySearch = true;
		}

		return {
			hasData,
			emptySearch
		};
	}, [filter, data]);

	useEffect(() => {
		if (onHasData) {
			onHasData(stateHasData.hasData);
		}
	}, [stateHasData.hasData, onHasData]);

	const onDeleteAgree = () => {
		if (onChangeDelete) {
			return onChangeDelete(currentSelected, handleCloseDeleteModal, infoTabName);
		}
		if (action) {
			dispatch(action.deleteItems(currentSelected, handleCloseDeleteModal, infoTabName));
		}
	};

	const handleCloseDeleteModal = () => {
		setDeleteModal(false);
	};

	const handleOpenDeleteModal = () => {
		setDeleteModal(true);
	};

	const changeDelete = useCallback(id => {
		handleOpenDeleteModal();
		setCurrentSelected(id);
	},[]);

	useEffect(() => {
		const arr = currentSelected.split(",");
		if (typeof deteTitle === "object") {
			if (arr.length <= 1) {
				setDeleTitleValue(deteTitle?.one);
			} else {
				setDeleTitleValue(deteTitle?.many);
			}
		} else {
			setDeleTitleValue(deteTitle);
		}
	}, [currentSelected, deteTitle]);

	const delayedSearch = useCallback(
		debounce(v => {
			if (onChangeSearch) {
				return onChangeSearch(v, infoTabName);
			}
			if (action) {
				dispatch(action.fetchFilter({ "filter[search]": v }, infoTabName));
			}
		}, 500),
		[infoTabName]
	);

	const changeSearch = useCallback(
		value => {
			delayedSearch(value);
		},
		[delayedSearch]
	);

	const changeUnselect = useCallback(() => {
		setChecked("unselect");
	}, [setChecked]);

	const hanldeChangeDelete = useCallback(() => {
		if (!currentSelected) return;
		changeDelete(currentSelected);
	}, [changeDelete, currentSelected]);

	const onPaginationChange = useCallback(
		data => {
			if (onChangePagination) {
				return onChangePagination(data, infoTabName);
			}
			if (action) {
				dispatch(action.fetchPagination(data, infoTabName));
			}
		},
		[action, dispatch, infoTabName, onChangePagination]
	);

	const handleChangeDownload = useCallback(() => {
		if (!currentSelected) return;
		onChangeDownload(currentSelected);
	}, [currentSelected, onChangeDownload]);

	const emptyDataFormated = <EmptyData content={emptyData} text={emptyDataText} loading={loading[infoTabName]?.table} />;

	return (
		<>
			<div className={cl("table", tableClassName)}>
				<Header
					title={<Tabs withCount list={tabsList} data={dataMain} value={tab || currentTab} onChange={handleChangeTabs} />}
					className={titleClassName}
					toolbar={
						<Toolbar
							selected={checked}
							onChangeDelete={showToolbarDelete && hanldeChangeDelete}
							onChangeUnselect={showToolbarUnselect && changeUnselect}
							onChangeSearch={showToolbarSearch && changeSearch}
							onChangeDownload={showToolbarDownload && handleChangeDownload}
							searchValue={filter && filter["filter[search]"]}
							{...toolbar}
						/>
					}
				/>
				{tabsList?.map((elem, i) => (
					<TabPanel key={elem.name} value={tab || currentTab} index={i}>
						{(elem.wrapComponent &&
							((stateHasData.emptySearch && (emptySearchComponent || <EmptySearch text={emptySearchText} />)) ||
								(!stateHasData.hasData && emptyDataFormated))) ||
							(elem.wrapComponent &&
								React.cloneElement(
									elem.wrapComponent,
									{},
									mappedData?.map((elements, k) => React.cloneElement(elem.componentItem, { key: k, data: elements }))
								)) ||
							(!stateHasData.hasData && !stateHasData.emptySearch && emptyDataFormated) || (
								<Table
									sticky
									columns={columnsWithMenu}
									data={mappedData}
									emptyText={stateHasData.emptySearch && (emptySearchComponent || <EmptySearch text={emptySearchText} />)}
								/>
							)}
					</TabPanel>
				))}
				<div className={styleTable.table_footer}>
					<Footer pagination={pagination} paginationChange={onPaginationChange} />
				</div>
				{!!loading[infoTabName]?.table && (
					<CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" />
				)}
			</div>
			<ConfirmDelete
				icon={deleteIcon}
				title={deteTitleValue}
				text={mappedDeleteText}
				loading={!!loading[infoTabName]?.delete}
				open={deleteModal}
				onClose={handleCloseDeleteModal}
				onAgree={onDeleteAgree}
				textAgree={textAgree}
				textClose={textClose}
			/>
		</>
	);
};

export default TabsTable;