export default {
	FETCH_GET_REPORT_REQUEST: "FETCH_GET_REPORT_REQUEST",
	FETCH_GET_REPORT_SUCCESS: "FETCH_GET_REPORT_SUCCESS",
	FETCH_GET_REPORT_FAILURE: "FETCH_GET_REPORT_FAILURE",

	FETCH_FILTER_REPORTS_REQUEST: "FETCH_FILTER_REPORTS_REQUEST",
	FETCH_FILTER_REPORTS_SUCCESS: "FETCH_FILTER_REPORTS_SUCCESS",

	FETCH_GET_DEFAULT_REPORT_REQUEST: "FETCH_GET_DEFAULT_REPORT_REQUEST",
	FETCH_GET_DEFAULT_REPORT_SUCCESS: "FETCH_GET_DEFAULT_REPORT_SUCCESS",
	FETCH_GET_DEFAULT_REPORT_FAILURE: "FETCH_GET_DEFAULT_REPORT_FAILURE",
	
	FETCH_GET_CAMPAIGN_BY_ADACCOUNT_REQUEST: "FETCH_GET_CAMPAIGN_BY_ADACCOUNT_REQUEST",
	FETCH_GET_CAMPAIGN_BY_ADACCOUNT_SUCCESS: "FETCH_GET_CAMPAIGN_BY_ADACCOUNT_SUCCESS",
	FETCH_GET_CAMPAIGN_BY_ADACCOUNT_FAILURE: "FETCH_GET_CAMPAIGN_BY_ADACCOUNT_FAILURE"
};