export const PLATFORMS = {
	"facebook_ads": "facebook_ads",
	"lemist": "lemist",
	"linkedin": "linkedin",
	"prospectin": "prospectin",
	"google_ads": "google_ads"
};

export const PLATFORMS_NAME = {
	"facebook_ads": "Facebook ads",
	"lemist": "lemist",
	"linkedin": "Linkedin",
	"prospectin": "prospectin",
	"google_ads": "Google ads"
};