import React, { useMemo } from "react";
import Table from "./components/table";
import { Typography } from "@material-ui/core";

const FacebookAdsTable = ({ data, ...rest }) => {
	const mappedData = useMemo(() => Object.keys(data).map(el => ({ name: el, data: data[el] })) || []);
	return (
		(mappedData.length && mappedData.map(el => <Table {...rest} data={el} key={el.name} />)) || (
			<Typography>This user have no connected integrations</Typography>
		)
	);
};

export default FacebookAdsTable;