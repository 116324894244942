import api from "app/api";

const API = api();

const getInfo = async params => {
	try {
		return await API.get("/account/user");
	} catch (error) {
		return error;
	}
};

const changeInfo = async data => {
	try {
		return await API.put("/account/user",data);
	} catch (error) {
		return error;
	}
};


export default {
	getInfo,
	changeInfo
};