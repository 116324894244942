import React from "react";
import style from "../includs/style.module.scss";

const LINKS = [
	{
		path: "https://signum.ai/terms-of-use/",
		name: "Terms of Use"
	},
	{
		path: "https://signum.ai/privacy-policy/",
		name: "Privacy Policy"
	}
];

const TermsAndPrivacy = () => (
	<ul className={style.list}>
		{LINKS.map(elem => (
			<li className={style.ListItem} key={elem.name}>
				<a rel="noreferrer" className={style.ListLink} href={elem.path} target="_blank">
					{elem.name}
				</a>
			</li>
		))}
	</ul>
);

export default TermsAndPrivacy;
