import { takeEvery, put, all, call, select } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

const dir = directory.en;

function* getAudiences({ params,propertyName, actionName }) {
	try {
		const {
			people: { pagination, filter }
		} = yield select();
		const response = yield call(services.getAudiences, { ...pagination[actionName], ...filter[actionName], ...params });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getAudiencesSuccess({
					count: response.data.count,
					items: response.data.items
				},actionName,propertyName)
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getAudiencesFailure("",actionName,propertyName));
	}
}

function* getMarketPlace({ params,propertyName, actionName }) {
	try {
		const {
			people: { pagination, filter }
		} = yield select();
		const response = yield call(services.getMarketplace, { ...pagination[actionName], ...filter[actionName], ...params });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getMarketPlaceSuccess({
					count: response.data.count,
					items: response.data.items
				},actionName,propertyName)
			);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getMarketPlaceFailure("",actionName,propertyName));
	}
}

function* fetchComposeAudience({ data, cb, actionName }) {
	try {
		const response = yield call(services.fetchComposeAudience, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchComposeAudienceSuccess(actionName));
			toastify.success( response?.data?.message)
			cb && cb();
			yield put(actions.getAudiences());
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchComposeAudienceFailure(actionName,error?.response?.data?.errors || ""	));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchComposeAudienceFailure(actionName,""));
		}
	}
}


function* deleteItems({ ids, cb, propertyName, actionName }) {
	try {
		const response = yield call(services.deleteAudiences, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const arrIds = ids?.split(",");
			yield put(actions.deleteItemsSuccess(arrIds?.length,actionName,propertyName));
			cb && cb();
			toastify.success(response?.data?.message);
			yield put(actions.getAudiences());
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.deleteItemsFailure("",actionName,propertyName));
	}
}

function* fetchEditName({ id, data, cb }) {
	try {
		const response = yield call(services.fetchEditName, id, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchEditNameSuccess());
			cb && cb();
			toastify.success(response?.data?.message);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchEditNameFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchEditNameFailure(error?.response?.data?.message));
		}
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.fetchEditNameFailure(""));
	}
}

function* getTemplateAudieces() {
	try {
		toastify.info(dir.downLoadFile);
		yield call(services.getTemplateAudieces);
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
	}
}

function* fetchPagination({ data, actionName }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(data,actionName));
		switch(actionName){
			case 'audiences':
				yield put(actions.getAudiences());
				break;
			case 'marketplace':
				yield put(actions.getMarketPlace());
				break;
			default:
				break;
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchFilter({ data, actionName }) {
	try {
		yield put(actions.fetchFilterSuccess(data,actionName));
		switch(actionName){
			case 'audiences':
				yield put(actions.getAudiences());
				break;
			case 'marketplace':
				yield put(actions.getMarketPlace());
				break;
			default:
				break;
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_AUDIENCES_REQUEST, getAudiences)]);
	yield all([takeEvery(types.GET_MARKETPLACE_REQUEST, getMarketPlace)]);

	yield all([takeEvery(types.FETCH_DELETE_AUDIENCES_REQUEST, deleteItems)]);

	yield all([takeEvery(types.FETCH_GET_TEMPLATE_AUDIENCES_REQUEST, getTemplateAudieces)]);

	yield all([takeEvery(types.FETCH_COMPOSE_AUDIENCE_REQUEST, fetchComposeAudience)]);

	// yield all([takeEvery(types.FETCH_EDIT_NAME_AUDIENCE_REQUEST, fetchEditName)]);

	yield all([takeEvery(types.FETCH_PAGINATION_AUDIENCES_REQUEST, fetchPagination)]);
	yield all([takeEvery(types.FETCH_FILTER_AUDIENCES_REQUEST, fetchFilter)]);
}
