import React from "react";
import RabioBase from '@material-ui/core/Radio';
import useStyles from "./style";
import cl from "classnames/bind";

const Radio = ({className, disabled, ...rest}) => {
	const classes = useStyles();
    return (
        <RabioBase 
            disableRipple 
            className={cl(classes.root, className)} 
            checkedIcon={<span className={cl(classes.icon, classes.checkedIcon, disabled && classes.iconDisabled )} />}
            icon={<span className={classes.icon} />}
            {...rest}
        />
    )
};

export default Radio;