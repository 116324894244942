export default {
	GET_CATEGORIES_AUDIENCE_REQUEST: "GET_CATEGORIES_AUDIENCE_REQUEST",
	GET_CATEGORIES_AUDIENCE_SUCCESS: "GET_CATEGORIES_AUDIENCE_SUCCESS",
	GET_CATEGORIES_AUDIENCE_FAILURE: "GET_CATEGORIES_AUDIENCE_FAILURE",

	FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST: "FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_DELETE_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_DELETE_CATEGORIES_AUDIENCE_SUCCESS",
	FETCH_DELETE_CATEGORIES_AUDIENCE_FAILURE: "FETCH_DELETE_CATEGORIES_AUDIENCE_FAILURE",

	FETCH_CREATE_CATEGORIES_AUDIENCE_REQUEST: "FETCH_CREATE_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_CREATE_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_CREATE_CATEGORIES_AUDIENCE_SUCCESS",
	FETCH_CREATE_CATEGORIES_AUDIENCE_FAILURE: "FETCH_CREATE_CATEGORIES_AUDIENCE_FAILURE",

	FETCH_EDIT_CATEGORIES_AUDIENCE_REQUEST: "FETCH_EDIT_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_EDIT_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_EDIT_CATEGORIES_AUDIENCE_SUCCESS",
	FETCH_EDIT_CATEGORIES_AUDIENCE_FAILURE: "FETCH_EDIT_CATEGORIES_AUDIENCE_FAILURE",

	FETCH_PUBLIC_CATEGORIES_AUDIENCE_REQUEST: "FETCH_PUBLIC_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_PUBLIC_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_PUBLIC_CATEGORIES_AUDIENCE_SUCCESS",
	FETCH_PUBLIC_CATEGORIES_AUDIENCE_FAILURE: "FETCH_PUBLIC_CATEGORIES_AUDIENCE_FAILURE",

	FETCH_PAGINATION_CATEGORIES_AUDIENCE_REQUEST: "FETCH_PAGINATION_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_PAGINATION_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_PAGINATION_CATEGORIES_AUDIENCE_SUCCESS",

	FETCH_FILTER_CATEGORIES_AUDIENCE_REQUEST: "FETCH_FILTER_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_FILTER_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_FILTER_CATEGORIES_AUDIENCE_SUCCESS",

	GET_CATEGORIES_AUDIENCE_TAGS_REQUEST: "GET_CATEGORIES_AUDIENCE_TAGS_REQUEST",
	GET_CATEGORIES_AUDIENCE_TAGS_SUCCESS: "GET_CATEGORIES_AUDIENCE_TAGS_SUCCESS",
	GET_CATEGORIES_AUDIENCE_TAGS_FAILURE: "GET_CATEGORIES_AUDIENCE_TAGS_FAILURE",

	FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST: "FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_DELETE_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_DELETE_CATEGORIES_AUDIENCE_SUCCESS",
	FETCH_DELETE_CATEGORIES_AUDIENCE_FAILURE: "FETCH_DELETE_CATEGORIES_AUDIENCE_FAILURE",

	RESET_CATEGORIES_AUDIENCE_TABLE_PARAMS: "RESET_CATEGORIES_AUDIENCE_TABLE_PARAMS"
};
