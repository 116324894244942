import React from "react";
import { useSelector, useDispatch } from "react-redux";
import actionsUploadAudience from "app/store/uploadAuciences/actions";
import UploadStep from "app/components/steps-upload-audiece/upload-steps";

const UploadFile = ({ onClose, changeStep, nextStep, userId }) => {
	const { uploadingAudience, uploadAudienceError } = useSelector(state => state.uploadAuciences);
	const dispatch = useDispatch();

	const onNextStep = () => {
		changeStep(nextStep);
	};

	const onSubmit = async values => {
		dispatch(actionsUploadAudience.fetchMatchUserAudience({...values, id: userId}, onNextStep));
	};
	return (
		<UploadStep onClose={onClose} loading={uploadingAudience} errors={uploadAudienceError} onSubmit={onSubmit} />
	);
};

export default UploadFile;
