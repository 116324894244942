import api from "app/api";
import {mappedParams} from "app/helper";

const API = api();

const getEmployees = async params => {
	try {
		return await API.get("/admin/employees", {params:{...mappedParams(params)}});
	} catch (error) {
		return error;
	}
};


const deleteEmployees = async ids => {
	try {
		return await API.delete("/admin/employees/delete-multiple",{params:{ids}});
	} catch (error) {
		return error;
	}
};

const fetchNewEmployees = async data => {
	try {
		return await API.post("/admin/employees",data);
	} catch (error) {
		return error;
	}
};

const fetchEditEmployee = async ({id,data}) => {
	try {
		return await API.put(`/admin/employees/${id}`, data);
	} catch (error) {
		return error;
	}
};


export default {
	getEmployees,
	deleteEmployees,
	fetchNewEmployees,
	fetchEditEmployee
};