import React from "react";
import { Link } from "react-router-dom";
import Layout from "app/components/includs/layout";
import PATHS from "PATHS";
import { Email } from "app/icons";

const EmailVerification = () => {
	return (
		<Layout>
			<div className="login-box text-centered">
				<div className="verification-icon m-b--24">
					<Email />
				</div>
				<h1 className="title-main m-b--24">Your mail has been successfully confirmed</h1>
				<Link to={PATHS.auth.login} className="button button_gradient button_large full-width">Go to login</Link>
			</div>
		</Layout>
	);
};

export default EmailVerification;
