import React from "react";
import { withRouter } from "react-router";
import UserLayout from "app/components/includs/user-layout";
import PageTitle from "app/components/page-title";
import PATHS from "PATHS";
import { NavLink } from "react-router-dom";
import {formateDate} from "app/helper";

const tabsLinks = id => [
	{
		path: PATHS.adminPanel.userAccount.replace(":id", id),
		title: "Profile"
	},
	{
		path: PATHS.adminPanel.userReport.replace(":id", id),
		title: "Report"
	},
	{
		path: PATHS.adminPanel.userAudiences.replace(":id", id),
		title: "Audiences"
	},
	{
		path: PATHS.adminPanel.userCreative.replace(":id", id),
		title: "Creatives"
	},
	{
		path: PATHS.adminPanel.userCampaigns.replace(":id", id),
		title: "Campaigns"
	},
	{
		path: PATHS.adminPanel.userIntegrations.replace(":id", id),
		title: "Integrations"
	}
];

const UserProfileWrapper = props => {
	const {
		match: {
			params: { id }
		}
	} = props;
	const { children, subtitleAddingContent, showAddingContent, date } = props;

	return (
		<UserLayout headerBackLink={PATHS.adminPanel.users} headerBackLinkTitle="Users">
			<PageTitle
				title="User account"
				subtitle={`Was registered ${(date && formateDate(date)) || ""}`}
				showAddingContent={showAddingContent}
				subtitleAddingContent={subtitleAddingContent}
			/>

			<div className="profile-nav">
				{tabsLinks(id).map(tab => (
					<NavLink to={tab.path} className="profile-nav__link" key={tab.title}>
						{tab.title}
					</NavLink>
				))}
			</div>

			<div className="profile-wrapper">{children}</div>
		</UserLayout>
	);
};

export default withRouter(UserProfileWrapper);
