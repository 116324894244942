import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import ChoosePlatform from "./choose-platform";
import FaceBook from "./facebook";
import { PLATFORMS } from "app/emun/platforms";
import actions from "app/store/campaign/actions";
import PATHS from "PATHS";

const CreateCampaing = () => {
	const [platform, setPlatform] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();

	const changePlatform = platform => {
		dispatch(actions.setDataCreateCampaigns({ platform }));
		setPlatform(platform);
	}

	const toCreateCompaign = () => {
		history.push(PATHS.cabinet.createCampaing);
	};

	if (!platform) {
		return <ChoosePlatform setPlatform={changePlatform} />;
	}

	switch (platform) {
		case PLATFORMS.facebook_ads:
			return <FaceBook />;
		default:
			return toCreateCompaign();
	}
};

export default withRouter(CreateCampaing);
