import { useMemo } from "react";
import Modal from "app/components/modal";
import FbPrevies from "../../create-creative/facebook_ads/creative-post";
import style from "./style.module.scss";

const ModalPreviewCreative = ({
	open,
	isAdmin,
	isTemplate,
	loading,
	onClose,
	onCopy,
	onPublicCteative,
	data,
	actionList
}) => {

	const handleOnPublic = () => {
		onPublicCteative(data.id, !data.is_public, onClose);
	};

	const handleCopy = () => {
		onCopy(data.id, onClose);
	};

	const nameAction = useMemo(() => actionList?.find(elem => elem.value === data?.fields?.call_to_action)?.text, [
		actionList,
		data
	]);

	const buttonAgreeData = useMemo(() => {
		if (isTemplate) {
			return {
				text: "+ Add to my creatives",
				action: handleCopy,
				loading: loading.copy[data.id],
				showBtn: true
			};
		}
		if (isAdmin) {
			let obj = {
				action: handleOnPublic,
				loading: loading.public[data.id],
				text: "Add to public templates",
				showBtn: true
			};
			if (data.is_public) {
				obj.text = "Remove from public";
			}
			return obj;
		}
		return {};
	}, [data, isAdmin, isTemplate, loading]);

	return (
		<Modal
			footer
			onSend={buttonAgreeData.action}
			onClose={onClose}
			buttonAgreeText={buttonAgreeData.text}
			classNameFooter={style.modal__footer}
			classNameFooterInner="modal-footer_centered"
			className={style.modal}
			open={open}
			loadingAgree={buttonAgreeData.loading}
			hideButtonAgree={!buttonAgreeData.showBtn}
		>
			<div className={style.modal__body}>
				<p className={style.title}>Preview</p>
				<FbPrevies
					description={data?.fields?.description}
					banner={data?.fields?.banner?.url}
					webSite={data?.fields?.website_url}
					headline={data?.fields?.headline}
					nameAction={nameAction}
				/>
			</div>
		</Modal>
	);
};

export default ModalPreviewCreative;