import types from "./types";

const actions = {
	getIntegrations: () => ({
		type: types.GET_INTEGRATION_REQUEST
	}),
	getIntegrationsSuccess: data => ({
		type: types.GET_INTEGRATION_SUCCESS,
		data
	}),
	getIntegrationsFailure: () => ({
		type: types.GET_INTEGRATION_FAILURE
	}),

	getAdAccounts: () => ({
		type: types.FETCH_GET_AD_ACCOUNTS_REQUEST
	}),
	getAdAccountsSuccess: data => ({
		type: types.FETCH_GET_AD_ACCOUNTS_SUCCESS,
		data
	}),
	getAdAccountsFailure: () => ({
		type: types.FETCH_GET_AD_ACCOUNTS_FAILURE
	}),

	connectIntegration: data => ({
		type: types.CONNECT_INTEGRATION_REQUEST,
		data
	}),
	connectIntegrationSuccess: () => ({
		type: types.CONNECT_INTEGRATION_SUCCESS
	}),
	connectIntegrationFailure: () => ({
		type: types.CONNECT_INTEGRATION_FAILURE
	}),

	deleteIntegrationAccount: (data, cb) => ({
		type: types.DELETE_INTEGRATION_ACCOUNT_REQUEST,
		data,
		cb
	}),
	deleteIntegrationAccountSuccess: data => ({
		type: types.DELETE_INTEGRATION_ACCOUNT_SUCCESS,
		data
	}),
	deleteIntegrationAccountFailure: () => ({
		type: types.DELETE_INTEGRATION_ACCOUNT_FAILURE
	}),

	changeActiveIntegrationAccount: (data, cb, changeActive) => ({
		type: types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_REQUEST,
		data,
		cb,
		changeActive
	}),
	changeActiveIntegrationAccountSuccess: data => ({
		type: types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_SUCCESS,
		data
	}),
	changeActiveIntegrationAccountFailure: () => ({
		type: types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_FAILURE
	})
};
export default actions;
