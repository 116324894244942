import types from "./types";

export const initialState = {
	roles: [],
	integration_type: []
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_HANDBOOKS_SUCCESS:
			return {
				...state,
				...action.data
			};
		default:
			return state;
	}
};

export default reducer;