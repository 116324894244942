import React, { useLayoutEffect, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import UserProfileWrapper from "app/components/user-profile-layout";
import columns from "../../../cabinet/people/columns";
import ModalUploadAudience from "./modal-upload-audience";
import Table from "../../../../components/table";
import actions from "app/store/users/actions";
import Button from "app/uikit/button";
import { DATA_ACTION } from "app/emun";

const AudiencesUser = props => {
	const [modalUploadAudience, setModalUploadAudience] = useState(false);
	const {
		dataUserLoading,
		dataUser,
		dataUserPagination,
		dataUserFilter,
		users: { items: userItems },
		user
	} = useSelector(state => state.users);
	const {
		match: { params }
	} = props;
	const dispatch = useDispatch();
	const actionName = DATA_ACTION.audiences;

	const currentUser = userItems?.find(el => el.id === +params.id) || user;

	const { created_at } = currentUser;

	const getAudiencesUser = () =>{
		dispatch(actions.fetchUserFilter({ "filter[user_owner_id]": params.id }, actionName));
	}

	useLayoutEffect(() => {
		dispatch(actions.resetUserData());
		getAudiencesUser();
		if (!created_at) {
			dispatch(actions.getUser(params.id));
		}
	}, [dispatch]);

	const onSearch = data => {
		dispatch(actions.fetchUserFilter({ "filter[search]": data }, actionName));
	};

	const onPagination = data => {
		dispatch(actions.fetchUserPagination(data, actionName));
	};

	const onOpenModalUploadAudience = () => {
		setModalUploadAudience(true);
	};

	const onCloseModalUploadAudience = () => {
		setModalUploadAudience(false);
	};

	return (
		<>
			<UserProfileWrapper
				showAddingContent={true}
				subtitleAddingContent={
					<Button size="medium" colorVariant="gradient" onClick={onOpenModalUploadAudience}>
						Assignee to user
					</Button>
				}
				date={created_at}
			>
				<Table
					scroll={{ x: 1104 }}
					filter={dataUserFilter}
					title="Lists of audiences"
					emptyDataText="No audiences have been found by your request"
					loading={dataUserLoading}
					columns={columns}
					data={dataUser.items}
					onChangePagination={onPagination}
					onChangeSearch={onSearch}
					settingsData={{
						check: {
							showId: true,
							hide: true
						},
						menu: {
							hide: true
						}
					}}
					hideElements={{
						delete: true,
						unSelect: true,
						check: true
					}}
					action={actions}
					pagination={dataUserPagination}
					textAgree="OK"
					textClose="Cancel"
				/>
			</UserProfileWrapper>
			<ModalUploadAudience open={modalUploadAudience} getAudiencesUser={getAudiencesUser} userId={params.id} reloadAudience={getAudiencesUser} onClose={onCloseModalUploadAudience} />
		</>
	);
};

export default withRouter(AudiencesUser);
