import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Modal from "app/components/modal";
import Input from "app/uikit/input";
import ChooseTags from "./choose-tags";
import { validationSchema } from "./validationSchema";
import { handlerErrors } from "app/helper/handlerErrors";
import action from "app/store/people/actions";
import services from "app/store/categories-audience/services";
import AaudiencComposition from "./AaudiencComposition";

const Icon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="40" height="40" rx="4" fill="#DCEEFF" />
		<path
			d="M20 19.7772C22.2682 19.7772 24.1072 17.5437 24.1072 14.7886C24.1072 12.0335 23.5033 9.80005 20 9.80005C16.4966 9.80005 15.8928 12.0335 15.8928 14.7886C15.8929 17.5437 17.7318 19.7772 20 19.7772Z"
			fill="#5F74BE"
		/>
		<path
			d="M27.7486 27.1927C27.6727 22.3932 27.0458 21.0256 22.2492 20.1599C22.2492 20.1599 21.5739 21.0203 20.0002 21.0203C18.4264 21.0203 17.7512 20.1599 17.7512 20.1599C13.0069 21.0161 12.3419 22.3635 12.2545 27.0367C12.2474 27.4183 12.2441 27.4384 12.2428 27.394C12.243 27.4771 12.2434 27.6306 12.2434 27.8983C12.2434 27.8983 13.3854 30.2004 20.0002 30.2004C26.615 30.2004 27.7572 27.8983 27.7572 27.8983C27.7572 27.7263 27.7573 27.6066 27.7574 27.5253C27.7561 27.5527 27.7534 27.4996 27.7486 27.1927Z"
			fill="#5F74BE"
		/>
		<path
			d="M25.6998 18.8867C27.5421 18.8867 29.0355 17.0727 29.0355 14.8351C29.0355 12.5974 28.5452 10.7834 25.6998 10.7834C25.2212 10.7834 24.8093 10.8349 24.4551 10.9311C25.1123 12.1426 25.2023 13.6142 25.2023 14.7888C25.2023 16.1188 24.8418 17.3886 24.1779 18.4399C24.6342 18.7249 25.1513 18.8867 25.6998 18.8867Z"
			fill="#5F74BE"
		/>
		<path
			d="M31.9929 24.9089C31.9311 21.0109 31.422 19.9002 27.5263 19.197C27.5263 19.197 26.9779 19.8958 25.6997 19.8958C25.6469 19.8958 25.5956 19.8942 25.5452 19.8919C26.3573 20.2581 27.0961 20.7679 27.6402 21.514C28.5807 22.8037 28.797 24.5362 28.8416 27.0815C31.4626 26.5636 31.9997 25.4821 31.9997 25.4821C31.9997 25.3411 31.9997 25.2445 32 25.1784C31.9989 25.2016 31.9968 25.1599 31.9929 24.9089Z"
			fill="#5F74BE"
		/>
		<path
			d="M14.3002 18.8866C14.8487 18.8866 15.3657 18.7248 15.8223 18.4399C15.1583 17.3886 14.7978 16.1188 14.7978 14.7888C14.7978 13.6141 14.8879 12.1425 15.5449 10.9311C15.1907 10.8349 14.7789 10.7834 14.3002 10.7834C11.4548 10.7834 10.9646 12.5974 10.9646 14.8351C10.9646 17.0727 12.458 18.8866 14.3002 18.8866Z"
			fill="#5F74BE"
		/>
		<path
			d="M14.4547 19.8924C14.4044 19.8947 14.3531 19.8963 14.3001 19.8963C13.022 19.8963 12.4736 19.1975 12.4736 19.1975C8.57795 19.9006 8.06881 21.0113 8.00711 24.9094C8.00309 25.1605 8.00105 25.2021 8 25.1788C8.00012 25.2449 8.00025 25.3416 8.00025 25.4825C8.00025 25.4825 8.53746 26.564 11.1582 27.082C11.2031 24.5367 11.4192 22.8042 12.3599 21.5144C12.9039 20.7685 13.6426 20.2586 14.4547 19.8924Z"
			fill="#5F74BE"
		/>
	</svg>
);

// combining


const ModalChoseAudience = ({ onClose, open }) => {
	const { isLoading, error } = useSelector(state => state.people);
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			tag_ids: [],
			name: "",
			composition_mode: "intersection"
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			dispatch(action.fetchComposeAudience({ ...values, tag_ids: values.tag_ids?.map(elem => elem.id) }, onClose));
		}
	});

	useEffect(() => {
		handlerErrors(error.composeAudience, formik.setErrors);
	}, [error.composeAudience, formik.setErrors]);

	return (
		<Modal
			title="Generate audience"
			size="middle"
			iconTitle={<Icon />}
			onClose={onClose}
			open={open}
			buttonAgreeText="Generate audience"
			loadingAgree={isLoading.composeAudience}
			onSend={formik.handleSubmit}
			disabled={!formik.values.tag_ids.length}
			resetForm={formik.resetForm}
			footer
		>
			<ChooseTags placeholder="Please enter at least two characters" name="tag_ids" form={formik} action={services.getTags} />
			<AaudiencComposition name="composition_mode" form={formik} />
			<Input fullWidth name="name" label="Audience name" form={formik} required />
		</Modal>
	);
};

export default ModalChoseAudience;