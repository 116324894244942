import React from "react";
import { EmptyPlaceholder } from "app/icons";

const NoData = ({text}) => (
	<div
		style={{ padding: "35px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
	>
		<div
			style={{
				maxWidth: "411px",
				marginBottom: "25px"
			}}
		>
			<EmptyPlaceholder />
		</div>
		<p className="title-h4">{text || "No data are available"}</p>
	</div>
);

export default NoData;
