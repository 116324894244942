import yup from "app/yup";
import directory from "app/directory/errors";
import { ErrorName, ErrorBudget } from "app/errors-shcema";
import moment from "moment";

const dir = directory.en;
const today = moment().format("YYYY-MM-DD");

const validateStarDatePast = yup
	.date()
	.min(today, "Start date #{date} shouldn’t be in the past.")
	.required(dir.required);
const validateStarDate = yup.date().required(dir.required);

const validationSchema = (notValidateStartDate, notValidateEndDate, isConversion) =>{
	let obj = {
		name: ErrorName,
		audience: yup.object().nullable().required(dir.required),
		budget: ErrorBudget,
		start_datetime: notValidateStartDate ? validateStarDatePast : validateStarDate,
		age_from: yup.number().when(
			"age_to",
			(value, schema) => value ? schema.max(value, dir.minAge) : schema
		),
		age_to: yup.number().when(
			"age_from",
			(value, schema) => value ? schema.min(value, dir.maxAge) : schema
		)
	}
	if(!notValidateEndDate){
		obj.end_datetime =  yup
		.date()
		.when(
			"start_datetime",
			(start_datetime, schema) =>
				start_datetime && schema.min(start_datetime, "End date #{date} shouldn't be earlier then Start date #{date}.")
		)
		.test("min-date-start", "End date #{date} shouldn’t be in the past.", value => !moment(today).isAfter(value))
		.required(dir.required)
	}
	if(isConversion){
		obj.conversion_id = yup.string().required(dir.required);
		obj.pixel_id = yup.string().required(dir.required);
		obj.conversion_event_location = yup.string().required(dir.required);
	}
	return yup.object().shape(obj,['age_from','age_to']);
}

export default validationSchema;