export default {
	FETCH_MATCH_AUDIENCE_REQUEST: "FETCH_MATCH_AUDIENCE_REQUEST",
	FETCH_MATCH_AUDIENCE_SUCCESS: "FETCH_MATCH_AUDIENCE_SUCCESS",
	FETCH_MATCH_AUDIENCE_FAILURE: "FETCH_MATCH_AUDIENCE_FAILURE",

	FETCH_CREATE_AUDIENCE_REQUEST: "FETCH_CREATE_AUDIENCE_REQUEST",
	FETCH_CREATE_AUDIENCE_SUCCESS: "FETCH_CREATE_AUDIENCE_SUCCESS",
	FETCH_CREATE_AUDIENCE_FAILURE: "FETCH_CREATE_AUDIENCE_FAILURE",

	FETCH_MATCH_CATEGORIES_AUDIENCE_REQUEST: "FETCH_MATCH_CATEGORIES_AUDIENCE_REQUEST",
	FETCH_MATCH_CATEGORIES_AUDIENCE_SUCCESS: "FETCH_MATCH_CATEGORIES_AUDIENCE_SUCCESS",
	FETCH_MATCH_CATEGORIES_AUDIENCE_FAILURE: "FETCH_MATCH_CATEGORIES_AUDIENCE_FAILURE",
	
	RESET_DATA_AUDIENCES: "RESET_DATA_AUDIENCES"
};
