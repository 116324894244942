import React from "react";
import style from "./style.module.scss";
import Checkbox from "app/uikit/checkbox";
import Button from "app/uikit/button/active-button";
import { formateDate } from "app/helper";
import { getPlatformIcon } from "app/helper/platform_helper";
import { Edit, DeleteIcon, EyeIcon } from "app/icons";

const BodyTableCreative = ({
	data,
	checked,
	onChangeChecked,
	onEdit,
	onDelete,
	isTemplate,
	isAdmin,
	loading,
	onPreview,
	onPublicCteative,
	onCopyCreative
}) => {
	const onChangeCheck = e => {
		const check = e.target.checked;
		onChangeChecked({ [data.id]: check });
	};

	const handlePriview = e => {
		const closest = e.target.closest("[data-target]");
		const isBody = e.target?.dataset?.target === "body" || closest?.dataset?.target === "body";
		if (!isBody) return;
		onPreview(data);
	};
	return (
		<div className={style.creative} onClick={handlePriview}>
			<div className={style.creative__header}>
				<Checkbox onChange={onChangeCheck} checked={!!checked[data?.id]} />
				{(isTemplate && data?.copies_count && (
					<p className={style.creative__copied_count}>You added: {data.copies_count}</p>
				)) ||
					(!isTemplate && (
						<div className={style.creative__toolbar}>
							<button
								onClick={() => {
									onEdit(data.id);
								}}
							>
								<Edit color="#B3BECB" />
							</button>
							<button
								onClick={() => {
									onDelete(data.id);
								}}
							>
								<DeleteIcon color="#B3BECB" />
							</button>
						</div>
					))}
			</div>
			<div className={style.creative__body} data-target="body">
				<div className={style.creative__img}>
					<img src={data?.fields?.banner?.url} alt="Banner" />
					<div className={style.creative__img_eyes}>
						<EyeIcon />
					</div>
				</div>
				<div className={style.creative__content}>
					<h3 className={style.creative__title}>{data?.fields?.creative_name}</h3>
					<p className={style.creative__desc}>{data?.fields?.description}</p>
				</div>
			</div>
			<div className={style.creative__footer}>
				<div className={style.creative__footer_inner}>
					<div className={style.creative__footer_platform}>
						<div className={style.creative__footer_platform_icon}>{getPlatformIcon(data?.integration_type?.system_name)}</div>
						<p>{data?.integration_type?.name}</p>
					</div>
					<p className={style.creative__footer_date}>{formateDate(data?.created_at)}</p>
				</div>
				{(isTemplate && (
					<Button
						fullWidth
						loading={loading.copy[data.id]}
						classNameWrapper={style.creative__footer_btn}
						size="small"
						colorVariant="gradient"
						onClick={() => {
							onCopyCreative(data.id, !data.is_public);
						}}
					>
						+ Add to my creatives
					</Button>
				)) ||
					(isAdmin && (
						<Button
							fullWidth
							loading={loading.public[data.id]}
							classNameWrapper={style.creative__footer_btn}
							size="small"
							colorVariant={(data.is_public && "ghost") || "gradient"}
							onClick={() => {
								onPublicCteative(data.id, !data.is_public);
							}}
						>
							{(data.is_public && "Remove from public") || "Add to public templates"}
						</Button>
					)) ||
					null}
			</div>
		</div>
	);
};

export default BodyTableCreative;
