import React from "react";
import Table from "rc-table";
import CircularProgress from "app/uikit/CircularProgress";
import Header from "./header";
import EmptyData from './EmptyData';
import style from "./style.module.scss";
import cl from "classnames";

const TableBase = props => {
	const {
		size,
		pagination,
		loading,
		paginationChange,
		title,
		toolbar,
		tableClassName,
		titleClassName,
		emptyDataText,
		footer,
		emptyText,
		emptySearchComponent,
		...rest
	} = props;

	return (
		<div className={cl('table',tableClassName)}>
			<Header className={titleClassName} title={title} toolbar={toolbar} />
			<Table sticky {...rest} emptyText={(emptyText && emptySearchComponent) || <EmptyData text={emptyDataText} loading={loading}/>} />
			{footer && <div className={style.table_footer}>{footer}</div>}
			{loading && <CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" />}
		</div>
	);
};

export default TableBase;
