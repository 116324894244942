import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MatchColumnsAudiece from "app/components/steps-upload-audiece/match-columns";
import actionsUploadAuciences from "app/store/uploadAuciences/actions";

const MatchColumns = ({ changeStep, currentStep, getAudiencesUser, nextStep, userId, data, ...rest }) => {
	const dispatch = useDispatch();
	const [errorsMatching, setErrorsMatching] = useState(false);
	const { audience_column_types } = useSelector(state => state.handBooks);
	const {
		columns,
		unmatched_columns_count,
		canCreate,
		message,
		missed_rows_count,
		audienceTemporaryData,
		loading,
		errors
	} = data;


	const onNextStep = () => {
		changeStep(nextStep);
		getAudiencesUser();
	};

	const onBack = () => {
		changeStep(currentStep - 1);
	};

	const handleSubmit = data => {
		dispatch(actionsUploadAuciences.fetchCreateUserAudience({...data, id:userId}, onNextStep));
	};

	const onMissedWarnings = () => {
		setTimeout(() => {
			setErrorsMatching(true);
		}, 0);
	};
	return (
		<MatchColumnsAudiece
			columns={columns}
			data={data}
			loading={loading}
			errors={errors}
			onSubmit={handleSubmit}
			onBack={onBack}
			errorsMatching={errorsMatching}
			onMissedWarnings={onMissedWarnings}
			audienceTemporaryData={audienceTemporaryData}
			audience_column_types={audience_column_types}
			canCreate={canCreate}
			missed_rows_count={missed_rows_count}
			unmatched_columns_count={unmatched_columns_count}
			message={message}
			{...rest}
		/>
	);
};

export default MatchColumns;