import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	date_picker: {
		width: props => (props.fullWidth ? "100%" : "auto"),
		"& .MuiInputBase-input": {
			pointerEvents: "none !important",
			cursor: "default"
		},
		"& .MuiFormHelperText-root": {
			display: "none !important"
		},
		"& .MuiInputLabel-root": {
			fontSize: 14,
			lineHeight: "22px",
			color: "#56637B",
			marginBottom: 7,
			transform: "none",
			position: "static"
		},
		"& .MuiInput-root": {
			margin: 0,
			border: "1px solid #E8EAEF",
			borderRadius: 6,
			minHeight: 50,
			padding: "6px 0 6px 16px",
			transition: "0.2s",
			color: "#3E4555",
			fontSize: "14px",
			"&.Mui-focused": {
				borderColor: "#CFD3DE"
			},
			"&.MuiInput-underline:before,&.MuiInput-underline:after": {
				content: "none"
			},
			"& .MuiInput-input": {
				padding: 0,
				color: "#3E4555"
			}
		}
	},
	input_error: {
		borderColor: "#EF5E39",
		"& .MuiInput-root": {
			border: "1px solid #EF5E39"
		}
	},
	popover: {
		"& .MuiPickersDay-daySelected": {
			backgroundColor: "#EB7D68"
		}
	}
});

export default useStyles;
