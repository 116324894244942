import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CircleWarningIcon } from "app/icons";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundImage: "linear-gradient(260deg, #EB7D68 0%, #F3B078 100%);",
		backgroundSize: "150%, 100%",
		backgroundPositionX: "30%",
		borderRadius: 4,
		fontWeight: 600,
		fontSize: 16,
		lineHeight: "58px",
		padding: "0px 30px",
		color: "#fff",
		boxShadow: "0px 4px 20px rgba(235, 125, 104, 0.5) !important",
		transition: ".5s",
		textTransform: "none",
		"&:hover": {
			backgroundPositionX: "100%"
		},
		"&:disabled": {
			cursor: "not-allowed",
			opacity: "0.8",
			pointerEvents: "auto",
			color: "rgba(255,255,255,0.5)"
		},
		"&:disabled ~ div": {
			cursor: "not-allowed"
		}
	}
}));


const getButtonVariant = variant => {
	switch (variant) {
		case "danger":
			return "button_danger";
		case "borderless":
			return "button_borderless";
		case "gradient":
			return "button_gradient";
		case "ghost":
			return "button_ghost";
		case "blue":
			return "button_blue";
		case "error":
			return "button_error";
		default:
			return "";
	}
};

const getButtonSize = size => {
	switch (size) {
		case "large":
			return "button_large";
		case "medium":
			return "button_medium";
		case "small":
			return "button_small";
		default:
			return "";
	}
};

const ButtonDefault = props => {
	const { className, variant, colorVariant, size, square, disabled, onClick, ...rest } = props;
	const classes = useStyles();
	return (
		<Button
			variant={variant}
			className={classNames(
				"button",
				classes.root,
				className,
				getButtonVariant(colorVariant),
				getButtonSize(size),
				square && "button_square"
			)}
			disabled={disabled}
			onClick={onClick}
			{...rest}
		>
			{(colorVariant === "error" && <CircleWarningIcon className="button_icon" />) || null}
			{props.children}
		</Button>
	);
};

ButtonDefault.defaultProps = {
	className: "",
	variant: "contained",
	disabled: false,
	onClick: () => {}
};

export default ButtonDefault;