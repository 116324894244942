import React from "react";
import CircularProgress from "./index";

const CircleProgressBox = ({loading}) => {
	return (
		<>
			{(loading && (
				<CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" />
			)) || null}
		</>
	);
};

export default CircleProgressBox;