import types from "./types";

const actions = {
	getStatisticRegistrations: (params, actionName = "registrations") => ({
		type: types.GET_STATISTIC_REGISTRATIONS_REQUEST,
		params,
		actionName
	}),
	getStatisticRegistrationsSuccess: (actionName, data) => ({
		type: types.GET_STATISTIC_REGISTRATIONS_SUCCESS,
		actionName,
		data
	}),
	getStatisticRegistrationsFailure: (actionName, error) => ({
		type: types.GET_STATISTIC_REGISTRATIONS_FAILURE,
		actionName,
		error
	}),
	resetRegistrations: () => ({
		type: types.RESET_REGISTRATIONS,
	})
};
export default actions;
