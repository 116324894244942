import React from "react";
import style from "./style.module.scss";
import cl from 'classnames/bind';

const FilterReports = ({ icon, description, value, active, nativeValue, onClick, valueAll }) => {
	const cs = cl.bind(style);
	return (
		<div onClick={onClick} className={cs("info",{'info--empty-value':!nativeValue, 'info--active':active})}>
			<div className={style.info__wrapIcon}>{icon}</div>
			<div className={style.info__inner}>
				<div className={style.info__content}>
					<p className={style.info__value}>
						{
							(!nativeValue ? "—" : <>{value} {valueAll && <span>/{valueAll}</span>}</>)
						}
					</p>
					<p className={style.info__desc}>
						{description}
					</p>
				</div>
			</div>
		</div>
	);
};

export default FilterReports;
