import api from "app/api";

const API = api();


const getStatisticRegistrations = async params => {
	try {
		return await API.get("/admin/statistic/registrations",{params:params});
	} catch (error) {
		return error;
	}
};


export default {
	getStatisticRegistrations
};