import { takeEvery, put, all, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import { PLATFORMS } from "app/emun/platforms";
import PATH from "PATHS";
import directory from "app/directory/errors";

const dir = directory.en;

function* getIntegrations() {
	try {
		const response = yield call(services.getIntegrations);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			let data = { ...response?.data };
			delete data.success;
			yield put(actions.getIntegrationsSuccess(data));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getIntegrationsFailure(true));
	}
}

function* connectIntegration({ data }) {
	try {
		let response = null;
		if (data.integrationId) {
			response = yield call(services.connectAdditionalIntegration, data);
		} else {
			response = yield call(services.connectIntegration, data);
		}
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			toastify.success(response?.data?.message);
			yield put(actions.getIntegrations());
			if (!data.integrationId) {
				switch (data.integration_type_system_name) {
					case PLATFORMS.facebook_ads:
						yield put(push(PATH.cabinet.editFbIntegrations));
						break;
					default:
						break;
				}
			}
			yield put(actions.connectIntegrationSuccess(""));
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			if (error?.response?.data?.errors) {
				const err = error.response.data.errors;
				for (let mark in err) {
					toastify.error(err[mark]);
				}
			}
			yield put(actions.connectIntegrationFailure(error?.response?.data?.errors || ""));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.connectIntegrationFailure(""));
		}
	}
}

function* getAdAccounts() {
	try {
		const response = yield call(services.getAdAccounts);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			delete response?.data?.success;
			yield put(actions.getAdAccountsSuccess(response?.data));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getAdAccountsFailure(""));
	}
}

function* deleteIntegrationAccount({ data, cb }) {
	try {
		const response = yield call(services.deleteIntegrationAccount, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.deleteIntegrationAccountSuccess(data));
			toastify.success(response?.data?.message);
		}
		cb && cb();
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.deleteIntegrationAccount);
		yield put(actions.deleteIntegrationAccountFailure(""));
	}
}

function* changeActiveIntegrationAccount({ data, cb, changeActive }) {
	try {
		cb(state => ({ ...state, [data.ad_account]: true }));
		const response = yield call(services.changeActiveAdsAccount, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.changeActiveIntegrationAccountSuccess({ ...response?.data, integration_type: data.integration_type })
			);
			toastify.success(response?.data?.message);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.changeActiveIntegrationAccountFailure(""));
		changeActive && changeActive(state => ({ ...state, active: !state.active }));
	}
	cb(state => ({ ...state, [data.ad_account]: false }));
}

export default function* root() {
	yield all([takeEvery(types.GET_INTEGRATION_REQUEST, getIntegrations)]);
	yield all([takeEvery(types.FETCH_GET_AD_ACCOUNTS_REQUEST, getAdAccounts)]);
	yield all([takeEvery(types.CONNECT_INTEGRATION_REQUEST, connectIntegration)]);
	yield all([takeEvery(types.DELETE_INTEGRATION_ACCOUNT_REQUEST, deleteIntegrationAccount)]);
	yield all([takeEvery(types.CHANGE_ACTIVE_INTEGRATION_ACCOUNT_REQUEST, changeActiveIntegrationAccount)]);
}
