import types from "./types";

export const initialState = {
	uploadingAudience: false,
	uploadAudienceError: "",
	uploadedAudience: {},

	creatingAudience: false,
	createAudienceError: "",

	matchedFilesData: [],
	audienceTemporaryData: { file: [], name: "" }
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.FETCH_MATCH_AUDIENCE_REQUEST:
			return {
				...state,
				uploadingAudience: true,
				uploadAudienceError: "",
				audienceTemporaryData: {
					file: action.data.fileData,
					name: action.data.name
				}
			};

		case types.FETCH_MATCH_AUDIENCE_SUCCESS:
			return {
				...state,
				matchedFilesData: action.data,
				uploadingAudience: false,
				uploadAudienceError: ""
			};

		case types.FETCH_MATCH_AUDIENCE_FAILURE:
			return {
				...state,
				uploadingAudience: false,
				uploadAudienceError: action.error
			};

		case types.FETCH_CREATE_AUDIENCE_REQUEST:
			return {
				...state,
				creatingAudience: true,
				createAudienceError: ""
			};

		case types.FETCH_CREATE_AUDIENCE_SUCCESS:
			return {
				...state,
				uploadedAudience: action.data,
				creatingAudience: false,
				createAudienceError: ""
			};

		case types.FETCH_CREATE_AUDIENCE_FAILURE:
			return {
				...state,
				creatingAudience: false,
				createAudienceError: action.error
			};
		case types.RESET_DATA_AUDIENCES:
			return {
				...state,
				uploadingAudience: initialState.uploadingAudience,
				uploadAudienceError: initialState.uploadAudienceError,
				matchedFilesData: initialState.matchedFilesData,
				audienceTemporaryData: initialState.audienceTemporaryData
			};

		default:
			return state;
	}
};

export default reducer;
