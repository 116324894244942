import React, { useEffect, useState, useMemo } from "react";

import { Prompt } from "react-router-dom";

import CampaignsAside from "app/components/campaigns-aside";
import { PLATFORMS_NAME } from "app/emun/platforms";
import Loader from "app/components/loader";
import config from "app/config";

import style from "../../style.module.scss";
import validationSchema from "../../validationSchema";
import { validateData, formateNameErrorsForFaceBook, removeErrorsByKeys } from "../../helper";


import Steps from "./steps";

const NewCampaignFacebook = props => {
	const [step, setStep] = useState(4);
	const [changedData, setChangedData] = useState({});
	const [errors, setErrors] = useState({});
	const [errorsFromBack, setErrorsFromBack] = useState({});
	const { params, data, actionError, loading, isChanged, setIsChanged } = props;
	const platform = data.integration_type?.system_name;

	const mappedData = {
		status: data.status,
		creative: changedData.creative || data.creative_template,
		platform: changedData.platform || platform,
		adAccount: changedData.adAccount || data.integration_ad_account,
		platform_parameters: changedData.platform_parameters || data.platform_parameters,
		audience: changedData.audience || data.audience,
		currency: changedData.currency || data.integration_ad_account?.currency,
		name: changedData.name || data.name,
		budget: changedData.budget || data.budget,
		start_datetime: changedData.start_datetime || data.start_datetime,
		end_datetime: changedData.end_datetime !== undefined ? changedData.end_datetime : data.end_datetime,
		budget_type_id: changedData.budget_type_id || data.budget_type,
		is_can_restart: data.is_can_restart,
		target: changedData?.target || data.target,
		conversion_event_location: changedData?.conversion_event_location || data.platform_parameters?.conversion_event_location,
		conversion_type_id: changedData?.conversion_type_id || data.platform_parameters?.conversion_type_id,
		pixel_id: changedData?.pixel_id || data.platform_parameters?.pixel_id,
		conversion_id: changedData?.conversion_id || data.platform_parameters?.conversion_id,
		is_use_similar_audience: changedData?.is_use_similar_audience ?? data.is_use_similar_audience,
		countries: changedData?.countries ?? data.countries,
		gender_type: (changedData?.gender_type?.id || changedData?.gender_type) ?? data.gender_type,
		age_to: changedData?.age_to || data.age_to || config.maxAge,
		age_from: changedData?.age_from || data.age_from || config.minAge,
	};
	const onChangeDate = data => {
		setChangedData(state => ({ ...state, ...data }));
	};
	const shouldValidateStartDate = mappedData?.status && mappedData?.status?.id !== 4;
	const shouldValidateEndDate = mappedData?.budget_type_id?.id === 2;
	const isConversion = mappedData?.target?.id === 2;

	useEffect(() => {
		const Changed = !!Object.keys(changedData).length;
		setIsChanged(Changed);
	}, [changedData]);


	const validationField = useMemo(()=>{
		const array = ["platform", "adAccount", "creative", "audience", "name", "budget", "platform_parameters", "start_datetime", "age_from", "age_to"];
		if(!shouldValidateEndDate){
			array.push("end_datetime");
		}
		if(isConversion){
			array.push("conversion_id");
			array.push("pixel_id");
			// array.push("conversion_event_location");
		}
		return array;
	},[isConversion,shouldValidateEndDate])

	useEffect(() => {
		validateData(
			validationField,
			validationSchema(shouldValidateStartDate, shouldValidateEndDate, isConversion),
			mappedData,
			setErrors
		);
	}, [data, changedData]);

	useEffect(() => {
		let newErrors = formateNameErrorsForFaceBook(actionError);
		setErrorsFromBack(newErrors);
	}, [actionError]);

	const removeErrors = keys => {
		if (Object.keys(errorsFromBack).length) {
			setErrorsFromBack(state => removeErrorsByKeys(state, keys));
		}
		if (Object.keys(errors).length) {
			setErrors(state => removeErrorsByKeys(state, keys));
		}
	};

	const formatedErrors = { ...errors, ...errorsFromBack };

	const dataAside = {
		platform: {
			value: (platform && PLATFORMS_NAME[platform]) || "",
			error: formatedErrors.platform,
			status: "done",
			canToMove: false
		},
		adAccount: {
			value: mappedData.adAccount?.name,
			error: formatedErrors.adAccount || formatedErrors.platform_parameters,
			status: "done",
			canToMove: mappedData?.status?.id === 4 || !mappedData?.status
		},
		details: {
			value:
				(mappedData.budget && `${mappedData.budget} ${mappedData.currency || ""}, ${mappedData.audience?.name || ""}`) ||
				"",
			error: !!formatedErrors.audience || !!formatedErrors.name || !!formatedErrors.budget || !!formatedErrors.start_datetime || !!formatedErrors.end_datetime || formatedErrors?.age_from || formatedErrors?.age_to  || isConversion && (
				formatedErrors.conversion_id || formatedErrors.pixel_id || formatedErrors.conversion_event_location 
			),
			status: "done",
			canToMove: true
		},
		creative: {
			value: mappedData.creative?.fields?.creative_name,
			error: !!formatedErrors.creative,
			status: "done",
			canToMove: true
		},
		review: {
			toched: true,
			error: !!Object.keys(formatedErrors).length,
			status: "done",
			canToMove: true
		}
	};

	const onChangeStepAside = e => {
		const step = e.target.dataset.step;
		setStep(+step);
	};

	return (
		<Loader loading={loading} delay>
			<div className={style["campaign-wrp"]}>
				<Prompt when={isChanged} message="message" />
				<CampaignsAside step={+step} onChangeStep={onChangeStepAside} data={dataAside} edit />
				<Steps
					haveChanges={!!Object.keys(changedData).length}
					setHasNotSaveData={setIsChanged}
					setChangedData={setChangedData}
					id={params.id}
					step={+step}
					data={mappedData}
					errors={formatedErrors}
					onChangeDate={onChangeDate}
					onChangeStep={setStep}
					removeErrors={removeErrors}
				/>
			</div>
		</Loader>
	);
};

export default NewCampaignFacebook;
