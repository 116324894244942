import BaseAvatar from "../../../components/avatar";
import React from "react";
import { CreativeThumbnailIcon } from "../../../icons";
import { getPlatformIcon } from "app/helper/platform_helper";
import {formateDate} from "app/helper";

const columns = [
	{
		title: "Creative",
		dataIndex: "creative",
		key: "creative",
		render: val => (
			<div className="template-cell">
				<div className="template-cell__preview">
					{(val?.banner && <img src={val?.banner} alt="banner preview" />) || <CreativeThumbnailIcon />}
				</div>
				<div className="template-cell-text">
					<p className="template-cell-text__title small-text small-text_bold m-b--4">{val.title}</p>
					<p className="template-cell-text__caption small-text">{val.description}</p>
				</div>
			</div>
		)
	},
	{
		title: "Platform",
		dataIndex: "platform",
		key: "platform",
		render: platform => (
			<div className="platform-cell">
				<div className="card-icon">{getPlatformIcon(platform.type)}</div>
				<span className="small-text">{platform.name}</span>
			</div>
		),
		width: 190
	},
	{
		title: "Owner",
		dataIndex: "owner",
		key: "owner",
		render: val => (
			<div className="owner-cell">
				<BaseAvatar src={val.avatar} name={val.text} />
				<span className="small-text">{val.name}</span>
			</div>
		),
		width: 240
	},
	{
		title: "Last activity",
		dataIndex: "last_activity",
		key: "last_activity",
		render: date => <span className="small-text">{formateDate(date)}</span>,
		width: 160
	}
];

export default columns;
