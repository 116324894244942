import React from "react";
import { Link } from "react-router-dom";
import PATHS from "PATHS";
import { Lock } from "app/icons";

const RecoverySuccess = () => {
	return (
		<div className="login-box text-centered">
			<div className="verification-icon m-b--24">
				<Lock />
			</div>
			<h1 className="title-main m-b--16">Successfully changed</h1>
			<p className="m-b--24 main-text">
				Our congratulations! Your password was successfully changed. Please, go to login page and try again.
			</p>
			<Link to={PATHS.auth.login} className="button button_gradient button_large full-width">
				Go to login
			</Link>
		</div>
	);
};

export default RecoverySuccess;