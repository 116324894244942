import React from "react";
import { EmptyCreativesSearchPlaceholder } from "app/icons";

const EmptySearch = ({ icon, text }) => (
	<div
		style={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			textAlign: "center",
			paddingTop: "50px",
			paddingBottom: "50px"
		}}
	>
		<div style={{ maxWidth: "411px" }}>
			{icon || <EmptyCreativesSearchPlaceholder />}
		</div>

		<p className="title-h4 m-t--40">
			{text || (
				<>
					No Items have been found
					<br />
					by your request.
				</>
			)}
		</p>
	</div>
);

export default EmptySearch;
