import React from "react";
import Input from "app/uikit/input";
import { Search as IconSearch } from "app/icons";
import classnames from "classnames";
import style from "./style.module.scss";

const Search = ({ value, className, variant, wrapperClassName, placeholder="Search", ...rest }) => {
	return (
		<Input
			placeholder={placeholder}
			wrapperClassName={classnames(
				variant === "table" && style.wrap_search_input_table,
				wrapperClassName,
				"search-input_global",
				{ "search-input_global-active": !!value }
			)}
			className={classnames((variant === "table" && style.search_input_table) || style, className)}
			value={value}
			icon={<IconSearch />}
			iconPosition="start"
			{...rest}
		/>
	);
};

export default Search;
