import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Input from "app/uikit/input";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import Passwords from "app/components/passwords";
import actions from "app/store/auth/actions";
import { handlerErrors } from "app/helper/handlerErrors";
import Error from "app/uikit/error";

const Registration = () => {
	const { isLoading, error } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const handleSubmit = values => {
		dispatch(actions.fetchSignUpRequest(values));
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			password: "",
			password_confirmation: ""
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			handleSubmit(values);
		}
	});

	useEffect(() => {
		handlerErrors(error, formik.setErrors);
	}, [error, formik.setErrors]);

	return (
		<div className="login-box">
			<h1 className="title-main">Create an account</h1>
			<form className="user-form m-b--32" onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
				<Input fullWidth name="name" form={formik} label="Name" required />
				<Input
					inputProps={{ autoComplete: "new-email" }}
					fullWidth
					name="email"
					type="email"
					form={formik}
					label="Email address"
					required
				/>
				<Passwords form={formik} />
				<Error error={error} />
				<Button type="submit" loading={isLoading} fullWidth className="before-button-space" size="large" colorVariant="gradient">
					Create new account
				</Button>
			</form>
			<div className="login-box-bottom">
				<p className="main-text">
					Already have an account?{" "}
					<Link to="login" className="orange-link">
						Login
					</Link>
				</p>
				<span className="login-box-bottom__confirmation">
					By pressing “Create new account” you confirm that you agree with{" "}
					<a target="_blank" href="https://signum.ai/terms-of-use/">terms of use</a> and&nbsp;
					<a target="_blank" href="https://signum.ai/privacy-policy/">privacy policy</a>
				</span>
			</div>
		</div>
	);
};

export default Registration;
