import React, { useLayoutEffect, useMemo } from "react";
import { withRouter } from "react-router";
import UserProfileWrapper from "app/components/user-profile-layout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "app/components/loader";
import IntegraionsTable from "app/components/integraions-table";

import actions from "app/store/users/actions";

const CampaingsUser = props => {
	const {
		isLoading,
		userIntegraions,
		users: { items: userItems },
		user
	} = useSelector(state => state.users);
	const {
		match: { params }
	} = props;
	const dispatch = useDispatch();

	const currentUser = useMemo(() => userItems?.find(el => el.id === +params.id) || user, [user, userItems]);

	const { created_at } = currentUser;

	useLayoutEffect(() => {
		dispatch(actions.resetUserData());
		dispatch(actions.getUserIntegrations(params.id));
		if (!created_at) {
			dispatch(actions.getUser(params.id));
		}
	}, [dispatch]);

	return (
		<UserProfileWrapper date={created_at}>
			<Loader loading={isLoading} delay>
				<IntegraionsTable showPromotePages showTitle data={userIntegraions || {}} />
			</Loader>
		</UserProfileWrapper>
	);
};

export default withRouter(CampaingsUser);
