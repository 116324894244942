import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Modal from "app/components/modal";
import Autocomplete from "app/uikit/autocomplete";
import Button from "app/uikit/button/active-button";
import actions from "app/store/users/actions";
import servicesCreative from "app/store/creatives/services";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import { handlerErrors } from "app/helper/handlerErrors";

const UploadExistingAudience = ({ onClose, open, reloadCratives, userId }) => {
	const { errorsAction, isLoadingAction } = useSelector(state => state.users);
	const dispatch = useDispatch();

	const successShared = () => {
		reloadCratives();
		onClose();
	};

	const onSubmit = async values => {
		dispatch(actions.fetchUserShareCreative(values?.creative_template?.id, userId, successShared));
	};
	const formik = useFormik({
		initialValues: {
			creative_template: ""
		},
		validationSchema: validationSchema,
		onSubmit
	});

	useEffect(() => {
		handlerErrors(errorsAction.shareCreative, formik.setErrors);
	}, [errorsAction.shareCreative, formik.setErrors]);

	useEffect(() => {
		if(open){
			formik.resetForm();
		}
	}, [open]);

	return (
		<Modal title="Choose the creative you want for sharing" onClose={onClose} open={open}>
			<form onSubmit={formik.handleSubmit} noValidate>
				<Autocomplete
					required
					fullWidth
					largeItemOption
					action={servicesCreative.getCreatives}
					label="Template"
					name="creative_template"
					form={formik}
					formateData={data =>
						data.map(el => ({
							id: el.id,
							name: el.fields?.creative_name,
							imgSrc: el.fields?.banner?.url,
							description: el.fields?.description
						}))
					}
					placeholder="Search target template by it's name"
					noOptionsText="No template have been found by your request"
				/>
				<Error error={errorsAction.shareCreative} />
				<div className="modal-footer">
					<Button onClick={onClose} size="medium" colorVariant="ghost">
						Cancel
					</Button>
					<Button type="submit" loading={!!isLoadingAction.shareCreative} size="medium" colorVariant="gradient">
						Assignee to user
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default UploadExistingAudience;
