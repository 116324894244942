import React from "react";
import Input from "./input";

const InputBase = props => {
	const { name, form, showError, validateField, handleChange, ...rest } = props;
	if (form && Object.keys(form).length) {
		const handleChangeField = async e =>{
			const value = e.target.value;
			if(handleChange){
				await handleChange(value,name,e)
			}else{
				await form.setFieldValue(name,value);
			}
			if(validateField){
				await form.validateField(name);
			}
			form.setStatus({
				...form.status,
				dirtyFields:{
					...form.status?.dirtyFields,
					[name]: true
				}
			})
		}
		return (
			<Input
				value={form.values[name]}
				onChange={handleChangeField}
				error={(form.touched[name] || showError) && Boolean(form.errors[name])}
				errorText={(form.touched[name] || showError) && form.errors[name]}
				name={name}
				form={form}
				{...rest}
			/>
		);
	} else {
		return <Input name={name} {...props} />;
	}
};
export default InputBase;