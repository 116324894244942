import React from "react";
import { Route, Redirect } from "react-router-dom";
import localStore from "app/helper/local-store";
import PATHS from "PATHS";

const PrivateRoute = ({ Component, permission, permissions, ...rest }) => {
	if (localStore.getAccessToken()) {
		if (permission) {
			if (!permissions || !Object.keys(permissions).length) {
				localStore.clear();
				return <Redirect to={PATHS.auth.login} />;
			}
			if (permissions && permission && permissions[permission]) {
				return <Route {...rest} component={Component} />;
			}
		} else {
			return <Route {...rest} component={Component} />;
		}
	}
	return <Redirect to={PATHS.auth.login} />;
};

export default PrivateRoute;