import api from "app/api";
import {mappedParams} from "app/helper";

const API = api();

const getReports = async params => {
	try {
		return await API.get("/account/reports", {params:{...mappedParams(params)}});
		// return await API.get("/account/reports/mocks", {params:{...mappedParams(params)}});
	} catch (error) {
		return error;
	}
};

const getCampaignByAdAccount = async params => {
	try {
		return await API.get("/account/campaigns", {params:{...mappedParams(params)}});
	} catch (error) {
		return error;
	}
};

export default {
	getReports,
	getCampaignByAdAccount
};