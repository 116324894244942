import React from "react";

const ProfileTabBox = (props) => {

	const { children, className, title, autoHeight, titleAsBlock } = props;
	return (
		<div
			className={`profile-item ${className} ${autoHeight ? "profile-item_auto-height" : ""}`}
		>
			{title && <div className="block-title">
				<p className="title-subheading title-subheading_bold">{title}</p>
			</div>}

			{titleAsBlock &&
			<div className="block-title">
				{titleAsBlock}
			</div>}
			{children}
		</div>
	);
};

export default ProfileTabBox;
