import { MIME_FILES } from "app/emun";
import directory from "app/directory/errors";

const dir = directory.en;

// перед название code нужно указывать в начале custom-
// пример custom-name-too-large

export const ValidateFileSheet = size => {
	return file => {
		if (file.size > (size || 5000000)) {
			return {
				code: "custom-name-too-large",
				message: `${dir.fileLarge} 5mb`
			};
		}
		const allow = MIME_FILES.text.find(el => el === file.type);
		if (allow) return null;

		return {
			code: "custom-name-invalid-format",
			message: "Document must be in the format csv | xlsx | xls "
		};
	};
};

export const ValidateFileImg = (size,accept, text) => {
	const mimes = accept || MIME_FILES.img;
	return file => {
		if (file.size > (size || 25000000)) {
			return {
				code: "custom-name-too-large",
				message: `${dir.fileLarge} ${size / 1000000 || 25}Mb`
			};
		}
		const allow = mimes.find(el => el === file.type);
		if (allow) return null;

		return {
			code: "custom-name-too-large",
			message: `Photo must be in the format ${text || 'png | jpeg | jpg'}`
		};
	};
};