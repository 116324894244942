import React, {useLayoutEffect, useState, useCallback} from "react";
import CircularProgress from "app/uikit/CircularProgress";
import debounce from "lodash.debounce";

const Loder = props => {
	const { loading: propsLoading, children, delay, ...rest } = props;
	const [loading, setLoading] = useState(true);
	const delayed = useCallback(
		debounce(v => setLoading(v), (Number.isInteger(delay) && delay) || 500),
		[debounce]
	);
	useLayoutEffect(()=>{
		if(delay){
			delayed(propsLoading);
		}else{
			setLoading(propsLoading)
		}
	},[propsLoading, delayed, setLoading, delay]);

	if(loading){
		return <CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" {...rest} />
	}
	return children
};

export default Loder;