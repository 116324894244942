import React from "react";
import { InputLabel as InputLabelBase } from "@material-ui/core";
import useStyles from "./style";
import Info from "app/components/info";
import cl from "classnames";

export default function InputLabel({ label, labelContent, required, name, info, infoPlacement, className, ...rest }) {
	const classes = useStyles();
	if (!label) return null;
	return (
		<InputLabelBase id={name} htmlFor={name} className={cl(classes.inputLabel, className)}  {...rest}>
			{
				<>
					{label}
					{required && <span className="required">&nbsp;*</span>}
				</>
			}
			{labelContent}
			{info && <Info info={info} placement={infoPlacement} />}
		</InputLabelBase>
	);
}
