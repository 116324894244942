import React from "react";
import EmptyPlaceholder from "app/components/empty-placeholder";
import { NoSelectMetricts } from "./icons";
import style from "./style.module.scss";

const NoSelectMetric = () => (
	<EmptyPlaceholder
		className={style.noMetricContainer}
		icon={<NoSelectMetricts />}
		title="Select one or more metric"
		subtitle={<>Click on one or more metric to display diagrams <br /> with detailed activity for selected date range.</>}
	/>
);

export default NoSelectMetric;