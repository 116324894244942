import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "app/components/includs/layout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Input from "app/uikit/input";
import actions from "app/store/auth/actions";
import history from "app/routers/history";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import { handlerErrors } from "app/helper/handlerErrors";
import Error from "app/uikit/error";

const PasswordRecovery = () => {
	const { isLoading, error } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const handleSubmit = (values, { resetForm })  => {
		dispatch(actions.fetchPasswordRecoveryRequest(values, history, resetForm));
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: ""
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		handlerErrors(error, formik.setErrors);
	}, [error, formik.setErrors]);

	useEffect(()=>{
		return ()=>{
			dispatch(actions.fetchResetActionState());
		}
	},[dispatch]);

	return (
		<Layout>
			<div className="login-box">
				<h1 className="title-main m-b--16">Password recovery</h1>

				<form className="user-form m-b--32" onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
					<Input fullWidth name="email" type="email" label="Email address" form={formik} required />
					<Error error={error} />
					<Button type="submit" loading={isLoading} fullWidth className="before-button-space" size="large" colorVariant="gradient">
						Send email
					</Button>
				</form>

				<div className="login-box-bottom">
					<p className="main-text">
						Did you remember your password?{" "}
						<Link to="login" className="orange-link">
							Back to login
						</Link>
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default PasswordRecovery;
