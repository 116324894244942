import React, { useEffect } from "react";
import { useFormik } from "formik";
import Autocomplete from "app/uikit/autocomplete";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import { handlerErrors } from "app/helper/handlerErrors";

const UploadExistingAudience = ({ onClose, onSubmit, onActionAudience, onActionCompanyName, loading, errors }) => {
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			internal_audience: ""
		},
		validationSchema: validationSchema,
		onSubmit
	});

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<Autocomplete
				required
				validateField
				fullWidth
				disablePortal={false}
				action={onActionAudience}
				label="Target audience"
				name="internal_audience"
				form={formik}
				placeholder="Search target audience by it's name"
				noOptionsText="No audiences have been found by your request"
			/>
			{/* <Autocomplete
					required
					validateField
					fullWidth
					disablePortal={false}
					// action={onActionCompanyName}
					label="Company name"
					name="companyName"
					form={formik}
					placeholder="Search target audience by it's name"
					noOptionsText="No audiences have been found by your request"
				/> */}
			<Error error={errors} />
			<div className="modal-footer">
				<Button onClick={onClose} size="medium" colorVariant="ghost">
					Cancel
				</Button>
				<Button type="submit" loading={loading} size="medium" colorVariant="gradient">
					Assignee to user
				</Button>
			</div>
		</form>
	);
};

export default UploadExistingAudience;
