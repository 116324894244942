import moment from "moment";

import localStore from "app/helper/local-store";

export function parseQuery(queryString) {
	let query = {};
	let pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
	for (let i = 0; i < pairs.length; i++) {
		let pair = pairs[i].split("=");
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
	}
	return query;
}
export function urlEncodeQueryParams(data) {
	const params = Object.keys(data).map(key =>
		data[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}` : ""
	);
	return params.filter(value => !!value).join("&");
}

export const setInfo = user => {
	localStore.setInfo(user);
};

export const isFormValid = form => !form.dirty || !!Object.keys(form.errors).length;

export const getCheckedItem = checked => {
	const ids = { ...checked };
	delete ids["id-all"];
	for (let mark in ids) {
		if (!ids[mark]) {
			delete ids[mark];
		}
	}
	const mapperIds = Object.keys(ids)
		.map(el => el.slice(3))
		.join(",");
	return mapperIds;
};

export const mappedParams = params => {
	if (!params) {
		return {};
	}
	let newParams = { ...params, limit: params.limit };
	delete newParams.total;
	delete newParams.page;
	for (let mark in newParams) {
		if (!newParams[mark]) {
			delete newParams[mark];
		}
	}
	return newParams;
};

export const domainFromUrl = url => {
	let result;
	let match;
	// eslint-disable-next-line no-useless-escape
	if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im))) {
		result = match[1];
		// eslint-disable-next-line no-useless-escape
		if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
			result = match[1];
		}
	}
	return result;
};

export const getTableItemsById = (ids, data) => {
	if (ids && Number.isInteger(ids)) {
		return data.find(el => el.id === ids);
	}
	const elems = Array.isArray(ids) && ids || (ids && ids.split(",")) || [];
	return elems.map(elem => data.find(el => el.id === +elem));
};

export const checkeSamePath = (path, lastLocation) => lastLocation && lastLocation.pathname.indexOf(path) === -1;

export const formateDate = (value = "") => moment(value).format("DD MMM, YYYY");


export const compare = function (obj1, obj2) {
	for (let p in obj1) {
		if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
		switch (typeof (obj1[p])) {
			case 'object':
				if (!Object.compare(obj1[p], obj2[p])) return false;
				break;
			case 'function':
				if (typeof (obj2[p]) == 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
				break;
			default:
				if (obj1[p] !== obj2[p]) return false;
		}
	}
	for (let p in obj2) {
		if (typeof (obj1[p]) == 'undefined') return false;
	}
	return true;
};


export const hasAnyAdAcounts = adAccounts =>{
	let obj = {
		hasActiveAccounts: false,
		canCreateCampaign: false
	}

	for(let mark in adAccounts){
		if(adAccounts[mark]?.ad_accounts?.length) {
			obj.hasActiveAccounts = true
		}
	}

	for(let mark in adAccounts){
		adAccounts[mark]?.ad_accounts?.forEach(el=>{
			if(el.is_can_create_campaigns){
				obj.canCreateCampaign = true;
			}
		})
	}
	
	return obj;
}

export const getListWithCategory = (namesList,lists)=>{
    return namesList?.map((elem,i)=>(
		{
			category:{
			name: elem.name,
			value: elem.id
		},
		list: lists[i]?.map((elem => ({ text: elem.name, value: elem.id, info: elem.description }))) || []
	}))
}