import types from "./types";

const actions = {
    getCreatives: (target,params) => ({
        type: types.FETCH_GET_CREATIVES_REQUEST,
        params,
        target
    }),
    getCreativesSuccess: data => ({
        type: types.FETCH_GET_CREATIVES_SUCCESS,
        data
    }),
    getCreativesFailure: error => ({
        type: types.FETCH_GET_CREATIVES_FAILURE,
        error
    }),

    getTemplates: params => ({
        type: types.FETCH_GET_TEMPLATES_REQUEST,
        params
    }),
    getTemplatesSuccess: data => ({
        type: types.FETCH_GET_TEMPLATES_SUCCESS,
        data
    }),
    getTemplatesFailure: error => ({
        type: types.FETCH_GET_TEMPLATES_FAILURE,
        error
    }),

    getCreative: (id) => ({
        type: types.FETCH_GET_CREATIVE_REQUEST,
        id
    }),
    getCreativesuccess: data => ({
        type: types.FETCH_GET_CREATIVE_SUCCESS,
        data
    }),
    getCreativeFailure: error => ({
        type: types.FETCH_GET_CREATIVE_FAILURE,
        error
    }),

    createCreative: (data, cb) => ({
        type: types.FETCH_CREATE_CREATIVE_REQUEST,
        data,
        cb
    }),
    createCreativeSuccess: data => ({
        type: types.FETCH_CREATE_CREATIVE_SUCCESS,
        data
    }),

    createCreativeFailure: error => ({
        type: types.FETCH_CREATE_CREATIVE_FAILURE,
        error
    }),

    generateContent: data => ({
        type: types.FETCH_GENERATE_CONTENT_CREATIVE_REQUEST,
        data
    }),
    generateContentSuccess: data => ({
        type: types.FETCH_GENERATE_CONTENT_CREATIVE_SUCCESS,
        data
    }),
    generateContentFailure: error => ({
        type: types.FETCH_GENERATE_CONTENT_CREATIVE_FAILURE,
        error
    }),

    resetGenerateContent: () => ({
        type: types.RESET_GENERATE_CONTENT
    }),
    
    publicCreative: (id,data, cb) => ({
        type: types.FETCH_PUBLIC_CREATIVE_REQUEST,
        id,
        data,
        cb
    }),
    publicCreativeSuccess: data => ({
        type: types.FETCH_PUBLIC_CREATIVE_SUCCESS,
        data
    }),
    publicCreativeFailure: data => ({
        type: types.FETCH_PUBLIC_CREATIVE_FAILURE,
        data
    }),

    copyTemplate: (ids, cb) => ({
        type: types.FETCH_COPY_CREATIVES_REQUEST,
        ids,
        cb
    }),
    copyTemplateSuccess: data => ({
        type: types.FETCH_COPY_CREATIVES_SUCCESS,
        data
    }),
    copyTemplateFailure: data => ({
        type: types.FETCH_COPY_CREATIVES_FAILURE,
        data
    }),

    editCreative: (data, id, cb) => ({
        type: types.FETCH_EDIT_CREATIVE_REQUEST,
        data,
        id,
        cb
    }),
    editCreativeSuccess: data => ({
        type: types.FETCH_EDIT_CREATIVE_SUCCESS,
        data
    }),

    editCreativeFailure: error => ({
        type: types.FETCH_EDIT_CREATIVE_FAILURE,
        error
    }),


    deleteItems: (target,ids, cb) => ({
        type: types.FETCH_DELETE_CREATIVE_REQUEST,
        target,
        ids,
        cb
    }),
    deleteItemsSuccess: data => ({
        type: types.FETCH_DELETE_CREATIVE_SUCCESS,
        data
    }),
    deleteItemsFailure: error => ({
        type: types.FETCH_DELETE_CREATIVE_FAILURE,
        error
    }),

    

    fetchPagination: (target,data) => ({
        type: types.FETCH_PAGINATION_CREATIVE_REQUEST,
        target,
        data
    }),
    fetchPaginationSuccess: data => ({
        type: types.FETCH_PAGINATION_CREATIVE_SUCCESS,
        data
    }),



    fetchFilter: (target,data) => ({
        type: types.FETCH_FILTER_CREATIVE_REQUEST,
        data,
        target
    }),
    fetchFilterSuccess: data => ({
        type: types.FETCH_FILTER_CREATIVE_SUCCESS,
        data
    }),


    resetTableParams: () => ({
        type: types.RESET_CREATIVES_TABLE_PARAMS,
    }),
    resetcreateCreative: () => ({
        type: types.RESET_CREATE_CREATIVES,
    }),
};
export default actions;