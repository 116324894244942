import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "app/components/includs/layout";
import Registration from "app/components/auth/registration";
import Success from "app/components/auth/emailVerification";
import action from  "app/store/auth/actions"

const NewPassword = () => {
	const { ccountRegistered } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	useEffect(()=>{
		return ()=>{
			dispatch(action.fetchResetActionState());
		}
	},[dispatch]);

	return (
		<Layout>
			{(ccountRegistered && <Success />) || <Registration />}
		</Layout>
	);
};

export default NewPassword;