import yup from "app/yup";
import directory from "app/directory/errors";

const dir = directory.en;

const validationSchema = yup.object({
	adAccount: yup.object().nullable().required(dir.required),
	platform_parameters: yup.object().nullable().required(dir.required),
	target: yup.string().required(dir.required)
});

export default validationSchema;