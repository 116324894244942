import React, { useState, useEffect } from "react";
import UserLayout from "app/components/includs/user-layout";
import { useDispatch, useSelector } from "react-redux";
import Table from "app/components/table";
import ModalAddNewEmployee from "./modals/add-new-employee";
import actions from "app/store/employees/actions";
import columns from "./columns";
import { DeleteEmployeeIcon } from "app/icons";
import PageTitle from "app/components/page-title";
import { getTableItemsById } from "app/helper";

const Employees = () => {
	const {
		isLoading,
		pagination,
		deletedUsers,
		employees: { count: employeesCount, items: employeesItem },
		deleteLoading
	} = useSelector(state => state.employees);
	const dispatch = useDispatch();

	const [newEmplModal, setNewEmplModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [currentUserEdit, setCurrentUserEdit] = useState();

	useEffect(() => {
		dispatch(actions.getEmployees());
	}, [dispatch]);

	const deleteNames = ids => {
		const names = getTableItemsById(ids, employeesItem)
			.map(el => el?.full_name);
		const namesMap = names.join(", ");
		return (
			<>
				{names.length > 1 ?
					'Are you sure that you want to delete these employees' :
					'Are you sure that you want to delete this employee'
				}:
				<br />
				<span className="small-text_bold">{namesMap} </span>
			</>
		);
	};

	const handleOpenNewEmplModal = () => {
		setIsEdit(false);
		setNewEmplModal(true);
	};
	const handleOpenEditEmplModal = () => {
		setNewEmplModal(true);
	};

	const handleCloseNewEmplModal = () => {
		setNewEmplModal(false);
	};

	const handleChangeEditItem = (id, key) => {
		switch (key) {
			case "edit":
				setIsEdit(true);
				const user = employeesItem.find(elem => elem.id === +id);
				setCurrentUserEdit(user);
				handleOpenEditEmplModal();
				break;
			default:
				break;
		}
	};

	return (
		<UserLayout>
			<PageTitle
				title="Signum employees"
				subtitle={`We have ${employeesCount} employees.`}
				showButton
				buttonText="Add new employee"
				buttonAction={handleOpenNewEmplModal}
			/>
			<Table
				title="Lists of employees"
				loading={isLoading}
				columns={columns}
				data={employeesItem}
				settingsData={{
					menu: {
						onChange: handleChangeEditItem
					}
				}}
				pagination={pagination}
				action={actions}
				titleDelete={{
					one: "Delete employee?",
					many: "Delete employees?"
				}}
				hideElements={{
					search: true
				}}
				deleteIcon={<DeleteEmployeeIcon />}
				deleteText={deleteNames}
				deleteLoading={deleteLoading}
				deleted={deletedUsers}
			/>
			<ModalAddNewEmployee data={currentUserEdit} isEdit={isEdit} open={newEmplModal} onClose={handleCloseNewEmplModal} />
		</UserLayout>
	);
};

export default Employees;
