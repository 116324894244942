import { takeEvery, put, all, call, select } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

const dir = directory.en;

const getErrorMessage = (mes,mesSecond)=>{
	if(!mes) return;
	return `${mes} ${mesSecond || ''}`
}

const noticError = (messOne,messTwo)=> {
	toastify.error(getErrorMessage(messOne, messTwo) || dir.serverNotWorking,undefined,15000);
}

function* getCampaigns({ params }) {
	try {
		const {
			campaigns: { pagination, filter }
		} = yield select();
		const response = yield call(services.getCampaigns, { ...params, ...pagination, ...filter });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getCampaignsSuccess({
					count: response.data.count,
					items: response.data.items
				})
			);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.getCampaignsFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.getCampaignsFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getCampaignsFailure(""));
		}
	}
}

function* getCompaign({ id, cb }) {
	try {
		const response = yield call(services.getCampaign, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getCompaignSuccess(response?.data?.campaign));
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			yield put(actions.getCompaignFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			yield put(actions.getCompaignFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getCompaignFailure(""));
		}
	}
}

function* startCompaign({ id, cb, actionName, shoudlGetCampaigns }) {
	try {
		const response = yield call(services.startCompaign, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.startCompaignSuccess({actionName}));
			toastify.success(response?.data?.message);
			if(shoudlGetCampaigns){
				yield put(actions.getCampaigns());
			}else{
				yield put(actions.getCompaign(id));
			}
		}
	} catch (error) {
		noticError(error?.response?.data?.message, error?.response?.data?.integration_error_message);
		yield put(
			actions.startCompaignFailure({
				actionName,
				errors: ""
			})
		);
	}
	cb && cb();
}

function* restartCompaign({ id, data, cb, actionName, shoudlGetCampaigns}) {
	try {
		const response = yield call(services.restartCompaign, { id, data });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.restartCompaignSuccess({actionName}));
			toastify.success(response?.data?.message);
			cb && cb();
			if(shoudlGetCampaigns){
				yield put(actions.getCampaigns());
			}else{
				yield put(actions.getCompaign(id));
			}
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(
				actions.restartCompaignFailure({
					actionName,
					errors: error?.response?.data?.errors || ""
				})
			);
		} else {
			noticError(error?.response?.data?.message, error?.response?.data?.integration_error_message);
			yield put(
				actions.restartCompaignFailure({
					actionName,
					errors: ""
				})
			);
		}
	}
}

function* pauseCompaign({ id, cb, actionName, shoudlGetCampaigns }) {
	try {
		const response = yield call(services.pauseCompaign, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.pauseCompaignSuccess({actionName}));
			toastify.success(response?.data?.message);
			cb && cb();
			if(shoudlGetCampaigns){
				yield put(actions.getCampaigns());
			}else{
				yield put(actions.getCompaign(id));
			}
		}
	} catch (error) {
		noticError(error?.response?.data?.message, error?.response?.data?.integration_error_message);
		yield put(
			actions.pauseCompaignFailure({
				actionName,
				errors: ""
			})
		);
	}
}

function* editCompaign({ data, id, cb, cbSussess, actionName }) {
	try {
		const response = yield call(services.editCompaign, { data, id });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.editCompaignSuccess({actionName}));
			toastify.success(response?.data?.message);
			cb && cb();
			yield put(actions.getCompaign(id,cbSussess));
		}
	} catch (error) {
		cb && cb();
		if (error?.response?.status === 422) {
			return yield put(
				actions.editCompaignFailure({
					actionName,
					errors: error?.response?.data?.errors || ""
				})
			);
		} else {
			noticError(error?.response?.data?.message, error?.response?.data?.integration_error_message);
			yield put(
				actions.editCompaignFailure({
					actionName,
					errors: ""
				})
			);
		}
	}
}

function* deleteItems({ ids, cb }) {
	try {
		const response = yield call(services.deleteCompaign, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const arrIds = ids?.split(",");
			yield put(actions.deleteItemsSuccess(arrIds?.length));
			cb && cb();
			toastify.success(response?.data?.message);
			yield put(actions.getCampaigns());
		}
	} catch (error) {
		noticError(error?.response?.data?.message, error?.response?.data?.integration_error_message);
		yield put(actions.deleteItemsFailure(""));
	}
}

function* getAudieces() {
	try {
		const response = yield call(services.getAudiencesCompaign);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			let data = { ...response?.data };
			delete data.success;
			yield put(actions.getAudiencesSuccess(data));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
	}
}

function* getCreatives({ params }) {
	try {
		params.offset = (params.page - 1) * params.limit;
		const response = yield call(services.getTempaltesCompaign, params);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			let data = { ...response?.data };
			delete data.success;
			yield put(actions.getTempaltesSuccess({ ...data, params: { ...params, total: data.count } }));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
	}
}

function* createCompaign({ data, cbSussess, cbFailur, actionName }) {
	try {
		const response = yield call(services.createCompaign, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.createCompaignSuccess({actionName}));
			toastify.success(response?.data?.message);
			cbSussess && cbSussess();
		}
	} catch (error) {
		cbFailur && cbFailur();
		if (error?.response?.status === 422) {
			return yield put(
				actions.createCompaignFailure({
					actionName,
					errors: error?.response?.data?.errors || ""
				})
			);
		} else {
			noticError(error?.response?.data?.message, error?.response?.data?.integration_error_message);
			yield put(
				actions.createCompaignFailure({
					actionName,
					errors: ""
				})
			);
		}
	}
}

function* fetchPagination({ data }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(params));
		yield put(actions.getCampaigns());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
		yield put(actions.fetchPaginationFailure(""));
	}
}

function* fetchFilter({ data }) {
	try {
		yield put(actions.fetchFilterSuccess(data));
		yield put(actions.getCampaigns());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.FETCH_GET_CAMPAIGNS_REQUEST, getCampaigns)]);
	yield all([takeEvery(types.FETCH_GET_CAMPAIGN_REQUEST, getCompaign)]);
	yield all([takeEvery(types.FETCH_EDIT_CAMPAIGN_REQUEST, editCompaign)]);

	yield all([takeEvery(types.FETCH_DELETE_CAMPAIGNS_REQUEST, deleteItems)]);
	yield all([takeEvery(types.FETCH_START_CAMPAIGN_REQUEST, startCompaign)]);
	yield all([takeEvery(types.FETCH_PAUSE_CAMPAIGN_REQUEST, pauseCompaign)]);
	yield all([takeEvery(types.FETCH_RESTART_CAMPAIGN_REQUEST, restartCompaign)]);

	yield all([takeEvery(types.GET_CAMPAIGNS_AUDIENCES_REQUEST, getAudieces)]);
	yield all([takeEvery(types.GET_CAMPAIGNS_CREATIVES_REQUEST, getCreatives)]);
	yield all([takeEvery(types.CREATE_CAMPAIGNS_REQUEST, createCompaign)]);
	yield all([takeEvery(types.FETCH_PAGINATION_CAMPAIGNS_REQUEST, fetchPagination)]);
	yield all([takeEvery(types.FETCH_FILTER_CAMPAIGNS_REQUEST, fetchFilter)]);
}
