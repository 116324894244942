import { ClicksIcon, MoneyIcon, EyeIcon, ImpressionsIcon, PeopleIcon, FrequencyIcon } from "./icons";

export const dataInfo = [
	{
		name: "spent_budget_amount",
		icon: <MoneyIcon />,
		description: "Amount spend",
		type: "money"
	},
	{
		name: "clicks_count",
		icon: <ClicksIcon />,
		description: "Clicks"
	},
	{
		name: "cpc_values",
		icon: <EyeIcon />,
		description: "CPC",
		type: "money"
	},
	{
		name: "website_ctr",
		icon: <EyeIcon />,
		description: "CTR",
		type: "percent"
	},
	
	{
		name: "results",
		icon: <ClicksIcon />,
		description: "Results",
	},
	{
		name: "cpr_values",
		icon: <MoneyIcon />,
		description: "CPR",
		type: "money"
	},
	{
		name: "impressions",
		icon: <ImpressionsIcon />,
		description: "Impressions"
	},
	{
		name: "cpm",
		icon: <MoneyIcon />,
		description: "CPM",
		type: "money"
	},
	{
		name: "reach",
		icon: <PeopleIcon />,
		description: "Reach"
	},
	{
		name: "frequency",
		icon: <FrequencyIcon />,
		description: "Frequency"
	}
];
