import React from "react";
import { Link } from "react-router-dom";
import EmptyPlaceholder from "app/components/empty-placeholder";
import { NoCampaignIcon } from "./icons";
import PATHS from "PATHS";

const NoReportsCampaign = () => (
	<EmptyPlaceholder
		icon={<NoCampaignIcon />}
		title="No campaigns have been started on this account"
		subtitle="Create your first campaign."
		content={
			<Link className="button button_gradient button_medium" to={PATHS.cabinet.campaigns}>
				To campaigns
			</Link>
		}
	/>
);

export default NoReportsCampaign;
