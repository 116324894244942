import React from "react";
import style from "../style.module.scss";
import CampaignsAside from "../../../../components/campaigns-aside";
import StepChoosePlatform from "../steps/step-choose-platform";
import { withRouter } from "react-router";

const ChoosePlatform = ({setPlatform}) => {
	return (
		<div className={style["campaign-wrp"]}>
			<CampaignsAside />
			<div className={style["campaign-content"]}>
				<StepChoosePlatform setPlatform={setPlatform}/>
			</div>
		</div>
	);
};

export default withRouter(ChoosePlatform);
