import React from "react";
import { CampaignCreatedIcon } from "../../../../../icons";
import ConfirmModal from "../../../../../components/modal/confirm-modal.jsx";

const ModalCampaignUpdate = ({ onClose, open, onAgree, loading, name }) => {
	return (
		<div>
            <ConfirmModal
				open={open}
				title="Update campaign?"
				icon={<CampaignCreatedIcon />}
				loading={loading}
				onClose={onClose}
				onAgree={onAgree}
				text="This will save your changes on Facebook. Active campaigns may be reviewed again."
				textAgree="Yes, Update"
				textClose="No, decline"
			/>
		</div>
	);
};

export default ModalCampaignUpdate;
