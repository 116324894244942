import React from "react";
import Range from "./range";

const InputBase = props => {
	const { name, form } = props;
	if (form && Object.keys(form).length) {
        const handeForm = (values) =>{
            form.setFieldValue(name, values);
        };
		return (
			<Range
				value={form.values[name]}
				onChange={handeForm}
				error={form.touched[name] && Boolean(form.errors[name])}
				errorText={form.touched[name] && form.errors[name]}
				{...props}
			/>
		);
	} else {
		return <Range name={name} {...props} />;
	}
};
export default InputBase;