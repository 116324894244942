import api from "app/api";

const API = api();

const getIntegrations = async () => {
	try {
		return await API.get("/account/integrations");
	} catch (error) {
		return error;
	}
};

const connectIntegration = async data => {
	try {
		return await API.post("/account/integrations", data);
	} catch (error) {
		return error;
	}
};

const connectAdditionalIntegration = async data => {
	try {
		const id = data.integrationId;
		delete data.integrationId;
		return await API.post(`/account/integrations/${id}/accounts`, data);
	} catch (error) {
		return error;
	}
};

const deleteIntegrationAccount = async data => {
	try {
		const { integration, id } = data;
		return await API.delete(`/account/integrations/${integration}/accounts/${id}`);
	} catch (error) {
		return error;
	}
};

const changeActiveAdsAccount = async data => {
	try {
		const { integration, ad_account, account, activation_status_id } = data;
		return await API.patch(
			`/account/integrations/${integration}/accounts/${account}/ad-accounts/${ad_account}/activation-status`,
			{ activation_status_id }
		);
	} catch (error) {
		return error;
	}
};

const getAdAccounts = async () => {
	try {
		return await API.get("/account/integrations/ad-accounts");
	} catch (error) {
		return error;
	}
};

export default {
	getIntegrations,
	connectIntegration,
	deleteIntegrationAccount,
	connectAdditionalIntegration,
	changeActiveAdsAccount,
	getAdAccounts
};
