import api from "app/api";
import { mappedParams } from "app/helper";

const API = api();

const getCategoriesAudience = async params => {
	try {
		return await API.get("/admin/contact-categories", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const fetchCreateCategoryAudience = async data => {
	try {
		return await API.post("/admin/contact-categories", data);
	} catch (error) {
		return error;
	}
};

const getTags = async (params) => {
	try {
		return await API.get("/account/tags", { params: params} );
	} catch (error) {
		return error;
	}
};
const fetchPublic = async (id,data) => {
	try {
		return await API.patch(`/admin/contact-categories/${id}/is-visible`,data);
	} catch (error) {
		return error;
	}
};

const fetchEditCategoryAudience = async (id, data) => {
	try {
		return await API.put(`/admin/contact-categories/${id}`, data);
	} catch (error) {
		return error;
	}
};

const deleteItems = async ids => {
	try {
		return await API.delete("/admin/contact-categories/delete-multiple", { params: { ids } });
	} catch (error) {
		return error;
	}
};

export default {
	getCategoriesAudience,
	fetchCreateCategoryAudience,
	getTags,
	fetchPublic,
	deleteItems,
	fetchEditCategoryAudience,
};
