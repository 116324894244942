import React from "react";
import { useSelector, useDispatch } from "react-redux";
import actionsUploadAudience from "app/store/uploadAuciences/actions";
import UploadStep from "app/components/steps-upload-audiece/upload-steps";

const UploadFile = ({ onClose, changeStep, nextStep }) => {
	const { uploadingAudience, uploadAudienceError } = useSelector(state => state.uploadAuciences);
	const dispatch = useDispatch(); 

	const onNextStep = () => {
		changeStep(nextStep);
	};

	const handleChange = async values => {
		dispatch(actionsUploadAudience.fetchMatchAudience(values, onNextStep));
	};

	return (
		<UploadStep onClose={onClose} loading={uploadingAudience} errors={uploadAudienceError} onSubmit={handleChange} />
	);
};

export default UploadFile;
