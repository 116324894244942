import React from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "app/store/users/actions";
import UploadExistingAudienceStep from "app/components/steps-upload-audiece/upload-existing-audience";
import serviceAudience from "app/store/people/services";

const UploadExistingAudience = ({ onClose, reloadAudience, userId }) => {
	const { errorsAction, isLoadingAction } = useSelector(state => state.users);
	const dispatch = useDispatch();

	const successShared = ()=>{
		reloadAudience();
		onClose();
	}
	
	const onSubmit = async values => {
		dispatch(actions.fetchUserShareAudience(values?.internal_audience?.id, userId, successShared));
	};

	const onActionCompanyName = () => {
		console.log("send-company");
	};
	return (
		<UploadExistingAudienceStep
			onActionAudience={serviceAudience.getAudiences}
			onActionCompanyName={onActionCompanyName}
			onClose={onClose}
			loading={!!isLoadingAction.shareAudience}
			errors={errorsAction.shareAudience}
			onSubmit={onSubmit}
		/>
	);
};

export default UploadExistingAudience;