import yup from "app/yup";
import { ErrorName, ErrorEmail, ErrorPassword, ErrorPassword_confirmation } from "app/errors-shcema";

const validationSchema = yup.object({
	name: ErrorName,
	email: ErrorEmail,
	password: ErrorPassword,
	password_confirmation: ErrorPassword_confirmation
});

export default validationSchema;