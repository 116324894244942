import { takeEvery, put, all, call, select } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";
import localStore from "app/helper/local-store";
import {DATA_ACTION} from "app/emun"


const dir = directory.en;

function* getUsers({ params }) {
	try {
		const {
			users: { pagination, filter }
		} = yield select();
		const response = yield call(services.getUsers, { ...params, ...pagination, ...filter });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(
				actions.getUsersSuccess({
					count: response.data.count,
					items: response.data.items
				})
			);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.getUsersFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.getUsersFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.getUsersFailure(""));
		}
	}
}

function* getUser({ id }) {
	try {
		const response = yield call(services.getUser,id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getUserSuccess(response.data.user));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getUserFailure(""));
	}
}


function* getStatistic() {
	try {
		const response = yield call(services.getStatistic);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getStatisticSuccess(response.data.registrations));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
	}
}

function* deleteUsers({ ids, cb }) {
	try {
		const response = yield call(services.deleteUsers, ids);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			const arrIds = ids?.split(",");
			yield put(actions.deleteItemsSuccess(arrIds?.length));
			cb && cb();
			toastify.success(response?.data?.message);
			yield put(actions.getUsers());
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.deleteItemsFailure(""));
	}
}

function* fetchNewUser({ data, cb }) {
	try {
		const response = yield call(services.fetchNewUsers, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchNewUserSuccess());
			toastify.success(response.data.message);
			cb && cb();
			yield put(actions.getUsers());
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchNewUserFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchNewUserFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchNewUserFailure(""));
		}
	}
}

function* getUsersExport({ ids }) {
	try {
		toastify.info(dir.downLoadFile);
		yield call(services.getUsersExport, { ids, Authorization: localStore.getAccessToken() });
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
	}
}

function* getUsersExportProgress() {
	try {
		toastify.info(dir.downLoadFile);
		yield call(services.getUsersExportProgress, { Authorization: localStore.getAccessToken() });
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
	}
}

function* fetchEditUser({ data, id, cb }) {
	try {
		const response = yield call(services.fetchEditUser, { data, id });
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchEditUserSuccess());
			toastify.success(response.data.message);
			cb && cb();
			yield put(actions.getUser(id));
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchEditUserFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchEditUserFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchEditUserFailure(""));
		}
	}
}

function* fetchResetPasswordUser({ id, cb }) {
	try {
		const response = yield call(services.fetchResetPasswordUser, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchResetPasswordUserSuccess());
			toastify.success(response.data.message);
			cb && cb();
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.fetchResetPasswordUserFailure(""));
	}
}

function* getUserData({ params, actionName}) {
	try {
		let response = {};
		const {
			users: { dataUserPagination, dataUserFilter }
		} = yield select();
		const mappedParams = {...dataUserPagination, ...dataUserFilter[actionName], ...params};
		switch(actionName){
			case DATA_ACTION.creatives:
				response = yield call(services.getUserTemplate, mappedParams);
				break;
			case DATA_ACTION.campaigns:
				response = yield call(services.getUserCampaigns, mappedParams);
				break;
			case DATA_ACTION.audiences:
				response = yield call(services.getUserAudience, mappedParams);
				break;
			case DATA_ACTION.reports:
				response = yield call(services.getUserReports, mappedParams);
				break;
			default:
				return;
				break;
		}
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getUserDataSuccess({
				count: response.data.count,
				items: response.data.items
			}));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getUserDataFailure(""));
	}
}

function* getUserIntegrations({ id, actionName}) {
	try {
		const response = yield call(services.getUserIntegrations, id);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			delete response.data.success;
			yield put(actions.getUserIntegrationsSuccess(actionName,{...response.data}));
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getUserIntegrationsFailure(actionName,""));
	}
}

function* fetchUserShareAudience({ internal_audience, id, cb, actionName }) {
	try {
		const response = yield call(services.fetchUserShareAudience, internal_audience , {user_id:Number(id)});
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchUserShareAudienceSuccess({actionName}));
			toastify.success(response.data.message);
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchUserShareAudienceFailure({
				actionName,
				errors: error?.response?.data?.errors || ""
			}));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchUserShareAudienceFailure({
				actionName,
				errors: error?.response?.data?.message
			}));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchUserShareAudienceFailure({
				actionName,
				errors: ""
			}));
		}
	}
}

function* fetchUserShareCreative({ creative_template, id, cb, actionName }) {
	try {
		const response = yield call(services.fetchUserShareCreative, creative_template , {user_id:Number(id)});
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchUserShareCreativeSuccess({actionName}));
			toastify.success(response.data.message);
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchUserShareCreativeFailure({
				actionName,
				errors: error?.response?.data?.errors || ""
			}));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchUserShareCreativeFailure({
				actionName,
				errors: error?.response?.data?.message
			}));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchUserShareCreativeFailure({
				actionName,
				errors: ""
			}));
		}
	}
}

function* fetchPagination({ data }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchPaginationSuccess(params));
		yield put(actions.getUsers());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchUserPagination({ data, actionName }) {
	try {
		let params = data;
		if (data.limit) params.page = 1;
		yield put(actions.fetchUserPaginationSuccess(params));
		yield put(actions.getUserData(actionName));
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchFilter({ data }) {
	try {
		yield put(actions.fetchFilterSuccess(data));
		yield put(actions.getUsers());
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

function* fetchUserFilter({ data, actionName }) {
	try {
		yield put(actions.fetchUserFilterSuccess(data,actionName));
		yield put(actions.getUserData(actionName));
	} catch (error) {
		toastify.error(error?.response?.data?.message || error.message);
	}
}

export default function* root() {
	yield all([takeEvery(types.GET_USERS_REQUEST, getUsers)]);
	yield all([takeEvery(types.GET_USER_REQUEST, getUser)]);

	yield all([takeEvery(types.GET_USER_INTEGRAIONS_REQUEST, getUserIntegrations)]);

	yield all([takeEvery(types.GET_USER_DATA_REQUEST, getUserData)]);

	yield all([takeEvery(types.DELETE_USERS_REQUEST, deleteUsers)]);
	yield all([takeEvery(types.FETCH_NEW_USER_REQUEST, fetchNewUser)]);

	yield all([takeEvery(types.FETCH_USER_SHARE_AUDIENCE_REQUEST, fetchUserShareAudience)]);

	yield all([takeEvery(types.FETCH_USER_SHARE_CREATIVE_REQUEST, fetchUserShareCreative)]);

	yield all([takeEvery(types.FETCH_RESET_PASSWORD_USER_REQUEST, fetchResetPasswordUser)]);

	yield all([takeEvery(types.FETCH_EDIT_USER_REQUEST, fetchEditUser)]);

	yield all([takeEvery(types.FETCH_GET_USERS_EXPORT_REQUEST, getUsersExport)]);

	yield all([takeEvery(types.FETCH_GET_USERS_EXPORT_PROGRESS_REQUEST, getUsersExportProgress)]);

	yield all([takeEvery(types.GET_USERS_STATISTIC, getStatistic)]);

	yield all([takeEvery(types.FETCH_PAGINATION_USERS_REQUEST, fetchPagination)]);
	yield all([takeEvery(types.FETCH_PAGINATION_USER_REQUEST, fetchUserPagination)]);

	yield all([takeEvery(types.FETCH_FILTER_USERS_REQUEST, fetchFilter)]);
	yield all([takeEvery(types.FETCH_FILTER_USER_REQUEST, fetchUserFilter)]);
}
