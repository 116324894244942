import React from "react";
import Modal from "app/components/modal";
import { useSelector, useDispatch } from "react-redux";
import action from "app/store/general/actions";
import ContactUs from "app/components/modal/support";

const ContactModalStep = ({ onClose, open }) => {
	const { error, isLoading } = useSelector(state => state.general);
	const { user } = useSelector(state => state.user);

	const dispatch = useDispatch();

	const hanldeChange = values => {
		const visitortime = new Date();
		const visitortimezone = "GMT " + -visitortime.getTimezoneOffset()/60;
		dispatch(
			action.fetchSupport(
				{
					...values,
					locale: navigator.language,
					gmt:visitortimezone,
					form_name: "BOX support"
				},
				onClose
			)
		);
	};

	return (
		<Modal title="Ask support a question" onClose={onClose} open={open}>
			<ContactUs
				onClose={onClose}
				data={user}
				errors={error.support}
				loading={isLoading.support}
				onSubmit={hanldeChange}
			/>
		</Modal>
	);
};

export default ContactModalStep;
