import React from "react";
import StepOne from "./step-1";
import StepTwo from "./step-2";
import StepThree from "./step-3";
import StepReview from "./step-review";

const Steps = ({ step, ...rest }) => {
	switch (step) {
		case 1:
			return <StepOne nextStep={2} {...rest} />;
		case 2:
			return <StepTwo nextStep={3} {...rest} />;
		case 3:
			return <StepThree nextStep={4} {...rest} />;
		default:
			return <StepReview {...rest} />;
	}
};

export default Steps;
