import types from "./types";

const actions = {
	fetchSupport: (data, cb) => ({
		type: types.UPLOAD_SUPPORT_REQUEST,
		data,
		cb,
		actionName: "support"
	}),
	fetchSupportSuccess: (actionName, data) => ({
		type: types.UPLOAD_SUPPORT_SUCCESS,
		actionName,
		data
	}),
	fetchSupportFailure: (actionName, error) => ({
		type: types.UPLOAD_SUPPORT_FAILURE,
		actionName,
		error
	}),
};
export default actions;
