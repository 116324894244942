import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { DownArrow } from "app/icons";
import config from "app/config";
import cs from "classnames/bind";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex"
	},
	button: {
		"& .MuiButton-label": {
			display: "flex",
			textTransform: "initial",
			alignItems: "center",
			lineHeight: 1,
			fontWeight: "400"
		}
	},
	arrow: {
        fontSize: 0,
        transition: '0.2s'
    },
    arrowActive:{
        transform: 'rotate(180deg)'
    },
	name: {
		color: "#9298A8",
		marginRight: 5
	},
	value: {
		color: "#1B1E3F",
		marginRight: 6
	},
	paper: {
		marginRight: theme.spacing(2)
	},
	menuList: {
		minWidth: 40,
		padding: 8,
		borderRadius: 5
	},
	menuItem: {
		borderRadius: 5,
		transition: "0.3s",
		backgroundColor: "transparent",
		color: "#56637B",
		"&:hover": {
			backgroundColor: "#F7F8FA"
		}
	},
	poper:{
		zIndex: 10
	}
}));

export default function MenuListComposition({limit, onChange}) {
    const classes = useStyles();
    const cx = cs.bind(classes);
	const [open, setOpen] = useState(false);
	const [currentValue, setCurrentValue] = useState(config.ui.pagination.size);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleChange = event => {
		const current = event?.currentTarget?.dataset?.value || currentValue;
		handleClose(event);
		setCurrentValue(current);
		onChange({limit:current});
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
    }, [open]);

	useEffect(() => {
		setCurrentValue(limit || config.ui.pagination.size)
    }, [limit]);
    
	return (
		<div className={classes.root}>
			<Button
				ref={anchorRef}
				aria-controls={open ? "menu-list-grow" : undefined}
				className={classes.button}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<span className={classes.name}>Show:</span> <span className={classes.value}>{currentValue}</span>{" "}
				<div className={cx("arrow", {arrowActive: open})}>
					<DownArrow />
				</div>
			</Button>
			<Popper className={classes.poper} open={open} anchorEl={anchorRef.current} role={undefined} transition placement={"bottom-end"} disablePortal>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList className={classes.menuList} autoFocusItem={open} onKeyDown={handleListKeyDown}>
									{config.ui.tableSize.map(elem => (
										<MenuItem className={classes.menuItem} key={elem} data-value={elem} onClick={handleChange}>
											{elem}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
