import PATHS from "PATHS";

const config = {
	ui: {
		pagination: {
			size: 10,
		},
		tableSize: [10, 30, 50, 100],
		dateReport: "last_7d"
	},
	minAge: 13,
	maxAge: 65,
	roleDefault: 4,
	defaultPage:{
		link: PATHS.cabinet.marketplace,
		text: "Audiences",
		textRedirect: "Go to Audiences"
	}

};
export default config;