import React from "react";
import { Container, Grid } from "@material-ui/core";
import style from "./style.module.scss";
import Header from "./header";
import Wrapper from "./wrapper";
import Main from "./main";
import Footer from "./footer";
import TermsAndPrivacy from "../terms-and-privacy";

const Layout = props => (
	<Wrapper gray={props.gray}>
		<Header list={props.navList} className={props.header} />
		<Main>{props.children}</Main>
		<Footer>
			<Container maxWidth={false}>
				<Grid container justify="space-between" alignItems="center">
					<Grid item>
						<span className={style.text}>&copy; 2021 Tenoris Inc.</span>
					</Grid>
					<Grid item>
						<TermsAndPrivacy/>
					</Grid>
				</Grid>
			</Container>
		</Footer>
	</Wrapper>
);

export default Layout;
