import api from "app/api";

const API = api();


const getGeneralSettings = async () => {
	try {
		return await API.get("/account/general-settings");
	} catch (error) {
		return error;
	}
};


const updateGeneralSettings = async (data) => {
	try {
		return await API.put("/admin/general-settings",data);
	} catch (error) {
		return error;
	}
};


export default {
	getGeneralSettings,
	updateGeneralSettings
};