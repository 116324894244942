import React, { useEffect } from "react";
import { withFormik } from "formik";
import { validationSchemaPrivacy } from "./validationSchema.js";
import Input from "app/uikit/input";
import { handlerErrors } from "app/helper/handlerErrors";
import Passwords from "app/components/passwords";

const PrivacyCard = props => {
	const {
		onChange,
		validate,
		onValidate,
		isValid,
		error,
		loading,
		values,
		dirty,
		handleChange,
		touched,
		handleSubmit,
		errors,
		setErrors,
		initialValues,
		resetForm,
		setFieldValue,
		validateField,
		setStatus
	} = props;
	const form = { values, handleChange, touched, errors, setFieldValue, validateField, setStatus };

	useEffect(() => {
		if (!onChange) return;
		if (dirty) {
			onChange({ privacy: values });
		} else {
			onChange({ privacy: null });
		}
		const isDirty = Object.keys(values).some(el => values[el]);
		if (!isDirty) {
			resetForm();
		}
	}, [values, dirty]);

	useEffect(() => {
		onValidate({ privacy: isValid });
	}, [isValid, values]);

	useEffect(() => {
		if (validate) {
			handleSubmit();
		}
	}, [validate]);

	useEffect(() => {
		handlerErrors(error, setErrors, initialValues);
	}, [error, setErrors]);

	useEffect(() => {
		if (!loading && !error) {
			resetForm();
		}
	}, [error, loading]);

	return (
		<div className="rounded-box">
			<h3 className="title-subheading title-subheading_bold title-for-box m-b--24">Privacy</h3>
			<Input
				type="password"
				inputProps={{ autoComplete: "new-password" }}
				fullWidth
				name="old_password"
				form={form}
				label="Current password"
				required
			/>
			<Passwords inputProps={{ autoComplete: "off" }} form={form} />
		</div>
	);
};

export default withFormik({
	mapPropsToValues: () => {
		return {
			old_password: "",
			password: "",
			password_confirmation: ""
		};
	},
	displayName: "BasicForm",
	validationSchema: validationSchemaPrivacy
})(PrivacyCard);
