import React from "react";
import { MastercardPaymentIcon, VisaPaymentIcon } from "../../../../icons";

const getPaymentIcon = type => {
	switch (type) {
		case "mastercard":
			return <MastercardPaymentIcon />;
		case "visa":
			return <VisaPaymentIcon />;
		default:
			return "";
	}
};

export default [
	{
		title: "Date",
		dataIndex: "date",
		key: "full_name",
		render: val => <div className="profile-table-date">{val}</div>,
		minWidth: 120
	},
	{
		title: "Description",
		dataIndex: "description",
		key: "description",
		render: val => <div className="profile-table-description">{val}</div>,
		width: 200
	},
	{
		title: "Period",
		dataIndex: "period",
		key: "period",
		render: val => <div className="profile-table-period">{val}</div>,
		minWidth: 90
	},
	{
		title: "Payment method",
		dataIndex: "payment_method",
		key: "payment_method",
		render: val =>
			val.type && (
				<div className="profile-table-payment-method">
					<div className="profile-payment-item__icon profile-payment-item__icon_mc">{getPaymentIcon(val.type)}</div>
					****&nbsp;{val.lastDigits}
				</div>
			),
		width: 150
	},
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
		render: val => (
			<div className="profile-table-total">
				{val.sum}&nbsp;{val.currency}
			</div>
		),
		width: 160
	}
];
