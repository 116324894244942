import React from "react";
import EmptyPlaceholder from "../empty-placeholder";
import CircularProgress from "../../uikit/CircularProgress";

const ContentWithLoader = props => {
	const { isLoading, hasData, children, placeholderComponent, ...rest } = props;

	return (
		<>
			{(isLoading && !hasData && (
				<CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" />
			)) ||
				(!isLoading && !hasData && (placeholderComponent || <EmptyPlaceholder {...rest} />)) || <>{children}</>}
		</>
	);
};

export default ContentWithLoader;
