import React from "react";
import UserLayout from "../../../components/includs/user-layout";
import PageTitle from "../../../components/page-title";
import ReportsFilter from "./components/ReportsFilter";
import ReporsContent from './components/ReporsContent';


const ScreenReports = () => {
	return (
		<UserLayout>
			<PageTitle title="Reports" subtitle="Here you can get information about the status of your campaigns." />
			<ReportsFilter />
			<ReporsContent /> 
		</UserLayout>
	);
};

export default ScreenReports;