import React from "react";
import Layout from "app/components/includs/layout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Input from "app/uikit/input";
import validationSchema from "./validationSchema";
import actions from "app/store/auth/actions";
import Button from "app/uikit/button/active-button";

const VerificationCode = () => {
	const { isLoading } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const handleSubmit = values => {
		const requestData = {
			verificationCode: values.verificationCode
		};
		dispatch(actions.fetchSignInRequest(requestData));
	};
	const formik = useFormik({
		initialValues: {
			verificationCode: ""
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			handleSubmit(values);
		}
	});
	return (
		<Layout>
			<div className="login-box">
				<h1 className="title-main m-b--8">Verification Code</h1>
				<p className="main-text m-b--24">We have sent you verification code. Please, enter the code from your email.</p>
				<form className="user-form m-b--32" onSubmit={formik.handleSubmit} id="verificationForm" autoComplete="off">
					<Input fullWidth name="verificationCode" type="number" label="Verification code" form={formik} />

					<Button type="submit" loading={isLoading} fullWidth className="before-button-space">
						Verify
					</Button>
				</form>

				<div className="login-box-bottom">
					<p className="main-text">
						Didn't receive code? <button className="orange-link">Resend</button>
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default VerificationCode;
