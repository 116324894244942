import api from "app/api";

const API = api();

const fetchSupportRequest = async data => {
	try {
		return await API.post("/support/create-question", data);
	} catch (error) {
		return error;
	}
};


export default {
	fetchSupportRequest,
};