import React, { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FilterContainer, FilterItem } from "app/components/filter-block";
import Button from "app/uikit/button/active-button";
import DataPicker from "app/uikit/data-picker";
import actions from "app/store/dashboard/actions";
import style from "./style.module.scss";
import moment from "moment";

const initialValues = {
	date_from: moment().subtract(6, "d").format("YYYY-MM-DD"),
	date_to: moment().format("YYYY-MM-DD")
};

const today = moment();

const Filter = () => {
	const { isLoading } = useSelector(state => state.dashboard);
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues,
		onSubmit: values => dispatch(actions.getStatisticRegistrations(values, "registrationsRefresh"))
	});

	useEffect(() => {
		dispatch(actions.getStatisticRegistrations(initialValues));
	}, [dispatch]);

	const onChangeStartDate = (e, value) => {
		formik.setFieldValue("date_from", moment(value).format("YYYY-MM-DD"));
		if (moment(value).isAfter(formik.values.date_to)) {
			formik.setFieldValue("date_to", moment(value).add(1, "d").format("YYYY-MM-DD"));
		}
	};

	const onChangeEndDate = (e, value) => {
		formik.setFieldValue("date_to", moment(value).format("YYYY-MM-DD"));
		if (moment(value).isBefore(formik.values.date_from)) {
			formik.setFieldValue("date_from", moment(value).subtract(1, "d").format("YYYY-MM-DD"));
		}
	};

	return (
		<FilterContainer justifyContent="flex-end">
			<FilterItem className={style.item}>
				<DataPicker
					fullWidth
					InputAdornmentProps={{
						readOnly: true
					}}
					maxDate={today}
					name="date_from"
					value={formik.values.date_from}
					onChange={onChangeStartDate}
				/>
			</FilterItem>
			<FilterItem className={style.item}>
				<DataPicker
					fullWidth
					InputAdornmentProps={{
						readOnly: true
					}}
					maxDate={today}
					name="date_to"
					value={formik.values.date_to}
					onChange={onChangeEndDate}
				/>
			</FilterItem>

			<FilterItem className={style.item}>
				<Button
					loading={!!isLoading.registrationsRefresh}
					fullWidth
					onClick={formik.handleSubmit}
					size="medium"
					colorVariant="gradient"
				>
					Refresh
				</Button>
			</FilterItem>
		</FilterContainer>
	);
};

export default Filter;
