import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserLayout from "app/components/includs/user-layout";
import Error from "app/uikit/error";
import action from "app/store/user/actions";
import { Grid } from "@material-ui/core";
import { getBase64Content } from "app/helper/helper.files";
import Privacy from "./privacy-card";
import Profile from "./profile-card";
import PageTitle from "../../../components/page-title";

const ScreenProfile = () => {
	const [isChanged, setIsChanged] = useState(true);
	const [values, setValues] = useState({});
	const [validate, setValidate] = useState({});
	const [isValid, setIsValid] = useState({});
	const {
		user: { email, avatar, name },
		changeError,
		isChanging
	} = useSelector(state => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (values.profile || values.privacy) {
			setIsChanged(true);
		} else {
			setIsChanged(false);
		}
	}, [values]);

	useEffect(() => {
		if (!isChanging && !changeError) {
			setIsChanged(false);
			setValues({});
			setValidate({});
			setIsValid({});
		}
	}, [changeError, isChanging]);

	const onChangeValue = value => {
		setValues(state => ({ ...state, ...value }));
	};
	const onChangeValidation = data => {
		setIsValid(state => ({ ...state, ...data }));
	};

	const handleValidate = () => {
		if (values.profile && values.privacy) {
			setValidate({ profile: true, privacy: true });
		} else if (values.profile) {
			setValidate({ profile: true });
		} else if (values.privacy) {
			setValidate({ privacy: true });
		} else {
			setValidate({});
		}
	};

	const onSave = async () => {
		handleValidate();
		let data = {};
		if (values.profile) {
			if (isValid.profile) {
				data = { ...values.profile };
				if (values?.profile?.avatar?.length) {
					const mapAvatar = {
						name: values.profile?.avatar[0]?.name,
						content: await getBase64Content(values.profile?.avatar[0]),
						mime: values.profile?.avatar[0]?.type
					};
					data.avatar = mapAvatar;
				} else {
					delete data.avatar;
				}
			} else {
				return;
			}
		}
		if (values.privacy) {
			if (isValid.privacy) {
				if (!data.name) {
					data.name = name;
				}
				if (!data.email) {
					data.email = email;
				}
				data = { ...data, ...values.privacy };
			} else {
				return;
			}
		}
		onSubmit(data);
	};

	const onSubmit = (data) => {
		dispatch(action.changeInfo(data));
	};

	return (
		<UserLayout>
			<Prompt when={isChanged} message={"promptMessage"} />
			<PageTitle
				title="Account"
				subtitle="Manage your account settings here."
				showButton
				buttonLoading={isChanging}
				buttonDisabled={!isChanged}
				buttonAction={onSave}
				buttonText="Save changes"
			/>
			<Grid container className="account-columns-wrapper">
				<Grid item sm>
					<Profile
						validate={validate.profile}
						onValidate={onChangeValidation}
						onChange={onChangeValue}
						email={email}
						avatar={avatar}
						name={name}
						error={changeError}
						loading={isChanging}
					/>
				</Grid>
				<Grid item sm>
					<Privacy
						validate={validate.privacy}
						onValidate={onChangeValidation}
						onChange={onChangeValue}
						error={changeError}
						loading={isChanging}
					/>
				</Grid>
			</Grid>
			<Error error={changeError} />
		</UserLayout>
	);
};

export default ScreenProfile;
