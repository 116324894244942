import React from "react";
import classnames from "classnames";

const Pill = props => {
	const { color, text, icon } = props;
	// return <span className={`pill pill_${color || 'gray'} small-text`}>{icon && icon}{text}</span>
	return (
		<span className={classnames("small-text", "pill", `pill_${color || "gray"}`, icon && "pill_with-icon")}>
			{icon && icon}
			{text}
		</span>
	);
};

export default Pill;
