export const STEP_UPLOAD_AUDIENCES_TYPES = {
	uploadSheet: "uploadSheet",
	contact: "contact",
	uploadedSheet: "uploadedSheet"
};

export const MIME_FILES = {
	text: [
		"application/vnd.ms-excel",
		"application/msexcel",
		"application/x-msexcel",
		"application/x-ms-excel",
		"application/x-excel",
		"application/x-dos_ms_excel",
		"application/xls",
		"application/x-xls",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-excel",
		"text/csv"
	],
	img: ["image/png", "image/jpeg", "image/jpg"],
	imgs: ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"]

};

export const DATA_ACTION = {
	creatives: "creatives",
	campaigns: "campaigns",
	audiences: "audiences",
	reports: "reports",
	integrations: "integrations"
};

export const CREATIVE_ACTION_MAP = ["creatives", "templates"];

export const SERVER_NAME = {
	production: "production",
	stage: "stage",
	development: "development"
}