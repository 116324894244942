import React from "react";
import style from "./style.module.scss";
import { Logo as LogoIcon } from "app/icons";

const Logo = () => (
	<a href="http://signum.ai/" target="_blank" rel="noreferrer" className={style.logo}>
		<LogoIcon color="#3F4554"/>
	</a>
);

export default Logo;