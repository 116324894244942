import React, { useLayoutEffect, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "app/components/page-title";
import UserLayout from "app/components/includs/user-layout";
import Table from "./components/table";
import { PLATFORMS } from "app/emun/platforms";
import actions from "app/store/creatives/actions";
import Select from "app/uikit/select";
import { useLastLocation } from "react-router-last-location";
import { getPlatformIcon } from "app/helper/platform_helper";
import { checkeSamePath } from "../../../helper";
import { CREATIVE_ACTION_MAP } from "app/emun";
import {SERVER_NAME} from "app/emun"
import PATHS from "PATHS";

const CURRENTPLATFORM = PLATFORMS.facebook_ads;

const ScreenCreatives = () => {
	const [mounted, setMounted] = useState(false);
	const { isLoading, creatives, templates, pagination, filter } = useSelector(state => state.creatives);
	const { integration_type } = useSelector(state => state.handBooks);
	const { user } = useSelector(state => state.user);
	const [currentTab, setCurrentTab] = useState(0);
	const dispatch = useDispatch();
	const lastLocation = useLastLocation();

	useLayoutEffect(() => {
		if (checkeSamePath(PATHS.adminPanel.users, lastLocation)) {
			dispatch(actions.resetTableParams());
		}
		dispatch(actions.getCreatives(CREATIVE_ACTION_MAP[1]));
		setMounted(true);
	}, [dispatch, lastLocation]);

	const handleChangeTabs = (_, newValue) => {
		setCurrentTab(newValue);
	};

	const tabsList = useMemo(
		() =>{
			if(process.env.REACT_APP_SERVER_NAME === SERVER_NAME.development){
				return [
					{
						label: `My creatives ${creatives.count}`
					},
					{
						label: `Templates ${templates.count}`
					}
				]
			}else{
				return [
					{
						label: `My creatives ${creatives.count}`
					},
					// {
					// 	label: `Templates ${templates.count}`
					// }
				]
			}
		},
		[creatives, templates]
	);

	const actionName = useMemo(() => CREATIVE_ACTION_MAP[currentTab], [currentTab]);

	useEffect(() => {
		dispatch(actions.getCreatives(actionName));
	}, [actionName, dispatch]);

	const onChangeStatusFilter = value => {
		dispatch(actions.fetchFilter(actionName, { "filter[integration_type_id]": value }));
	};
	// const onChangeCategoriesFilter = value => {
	// 	dispatch(actions.fetchFilter(actionName, { "filter[categories]": value }));
	// };

	const optionsStatus = useMemo(()=>([
		...integration_type?.map(el => ({ text: el.name, value: el.id, icon: getPlatformIcon(el.system_name) })),
		{ text: "All platforms", value: "" }
	] || []),[integration_type])

	// const optionsCategories = useMemo(()=>([
	// 	...integration_type?.map(el => ({ text: el.name, value: el.id, icon: getPlatformIcon(el.system_name) })),
	// 	{ text: "All platforms", value: "" }
	// ] || []),[integration_type])

	return (
		<UserLayout>
			<PageTitle
				title="Creatives"
				subtitle="Create creatives for your ad campaigns."
				showAddingContent
				subtitleAddingContent={
					<>
						{/* <Select
							hasAllSelect
							list={optionsCategories}
							onChange={onChangeCategoriesFilter}
							value={filter[actionName]["filter[categories]"]}
							className="m-r--24 w-230"
							name="select-users"
						/> */}
						<Select
							hasAllSelect
							staticWidth
							list={optionsStatus}
							onChange={onChangeStatusFilter}
							value={filter[actionName]["filter[integration_type_id]"]}
							className="m-r--24"
							name="select-users"
						/>
						<Link
							to={PATHS.cabinet.createCreative.replace(":platform", CURRENTPLATFORM)}
							className="button button_gradient button_medium m-l--12"
						>
							Add new creative
						</Link>
					</>
				}
			/>
			<Table
				mounted={mounted}
				platform={CURRENTPLATFORM}
				actionName={actionName}
				action={actions}
				filter={filter[actionName]}
				data={creatives?.items}
				dataTemplate={templates?.items}
				loading={isLoading}
				tabsList={tabsList}
				pagination={pagination[actionName]}
				currentTab={currentTab}
				handleChangeTabs={handleChangeTabs}
				isAdmin={user?.roles[0]?.id === 2}
			/>
		</UserLayout>
	);
};

export default ScreenCreatives;