import React from "react";
import { FormHelperText, FormControlLabel, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "./checkbox-base";
import Info from "app/components/info";
import classNames from "classnames/bind";

const useStyles = makeStyles(theme => ({
	root: {
		marginRight: 0,
		marginLeft: -9,
		"&.MuiFormControlLabel-labelPlacementStart": {
			marginLeft: 0
		},
		"& .MuiBox-root": {
			color: "#3E4555",
			fontSize: "16px",
			lineHeight: "26px"
		}
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	error: {
		"& .MuiTypography-root": {
			color: theme.palette.error.main
		}
	},
	errorChecbox: {
		borderColor: theme.palette.error.main
	},
	wrapper: {
		margin: "0px"
	}
}));

const CheckboxWithLabel = props => {
	const classes = useStyles();
	const { label = "", helperText, error, labelClass, labelPlacement, wrapperClassName, infoLabel, info, infoPlacement, ...rest } = props;
	const cx = classNames.bind(classes);
	return (
		<div className={classNames(classes.wrapper,wrapperClassName)}>
		<div className={classes.container}>
			<FormControlLabel
				className={cx("root", labelClass, { error: error })}
				labelPlacement={labelPlacement || "end"}
				control={<Checkbox classNameIcon={cx({ errorChecbox: error })} {...rest} />}
				label={
					<Box component="span" fontSize={12}>
						{label}
					</Box>
				}
				infoLabel={infoLabel}
				infoPlacement={infoPlacement}
				{...rest}
			/>
			{info && <Info info={info} placement={infoPlacement} />}
		</div>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</div>
	);
};

export default CheckboxWithLabel;

CheckboxWithLabel.defaultProps = {
	helperText: "",
	error: false,
	label: ""
};
