import React from "react";
import { FormControl as FormControlBase } from "@material-ui/core";
import useStyles from "./style";
import cl from "classnames";

export default function FormControl({ fullWidth, children, className, ...rest }) {
	const classes = useStyles({fullWidth});
	return (
		<FormControlBase className={cl(classes.formControl, className)} {...rest}>
			{children}
		</FormControlBase>
	);
};