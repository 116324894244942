import React from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { PLATFORMS } from "app/emun/platforms";
import PATHS from "PATHS";
import Facebook from "../create-creative/facebook_ads";

const CrtCreative = props => {
	const {
		match: { params }
	} = props;
	const history = useHistory();
	
	switch (params.platform) {
		case PLATFORMS.facebook_ads:
			return <Facebook />;
		default:
			history.push(PATHS.cabinet.creatives)
			return null;
	}
};

export default withRouter(CrtCreative);