import React from "react";
import { CloseIcon, MastercardPaymentIcon, VisaPaymentIcon } from "../../icons";
import Button from "../../uikit/button/active-button";

const getPaymentType = (type) => {
	switch (type.toLowerCase()) {
		case "mastercard":
			return <MastercardPaymentIcon />;
		case "visa":
			return <VisaPaymentIcon />;
		default:
			return "";
	}
};

const PaymentMethodItem = (props) => {
	const { cardType, lastDigits, expirationDate, removeAction } = props;

	return (
		<div className="profile-payment-item">
			<div className="profile-payment-item__icon profile-payment-item__icon_mc">
				{getPaymentType(cardType)}
			</div>
			<div className="profile-payment-item__text">
				<p className="main-text main-text_bold">{cardType} (**** {lastDigits})</p>
				<p className="small-text">Expiration date {expirationDate}</p>
			</div>
			<Button className="remove-button" size="small" onClick={removeAction}>
				<CloseIcon />
			</Button>
		</div>
	);
};

const PaymentMethodList = (props) => {
	return (
		<div className="profile-payment-list">
			{props.rows.map((row, idx) => (
				<PaymentMethodItem
					key={row.cardType + idx}
					cardType={row.cardType}
					lastDigits={row.lastDigits}
					expirationDate={row.expirationDate}
					removeAction={() => {}}
				/>
			))}
		</div>
	);
};

export default PaymentMethodList;
