import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Modal from "app/components/modal";
import UploadStep from "./steps/upload-steps";
import Success from "./steps/success";
import MatchColumns from "./steps/match-columns";

const ModalStep = ({ step, onClose, changeStep, open }) => {
	const {
		matchedFilesData: { columns, unmatched_columns_count, missed_rows_count, message, is_can_create_audience },
		uploadedAudience: { created_rows_count, created_rows_percent },
		audienceTemporaryData,
		creatingAudience,
		createAudienceError
	} = useSelector(state => state.uploadAuciences);
	
	const getParamsForModal = useMemo(() => {
		switch (step) {
			case 1:
				return {
					title: "Upload new audience",
					component: <UploadStep nextStep={2} onClose={onClose} changeStep={changeStep} />
				};
			case 2:
				return {
					title: "Upload new audience",
					component: (
						<MatchColumns
							data={{
								columns,
								unmatched_columns_count,
								canCreate: is_can_create_audience,
								message,
								missed_rows_count,
								audienceTemporaryData,
								loading: creatingAudience,
								errors: createAudienceError
							}}
							stepValue={3}
							currentStep={2}
							nextStep={3}
							onClose={onClose}
							changeStep={changeStep}
						/>
					)
				};
			case 3:
				return {
					component: <Success onClose={onClose} countRow={created_rows_count} rowPercent={created_rows_percent} />
				};
			default:
				return {
					title: "",
					component: ""
				};
		}
	}, [
		changeStep,
		onClose,
		step,
		creatingAudience,
		createAudienceError,
		columns,
		unmatched_columns_count,
		is_can_create_audience,
		message,
		missed_rows_count,
		audienceTemporaryData
	]);

	return (
		<Modal title={getParamsForModal.title} onClose={onClose} open={open}>
			{getParamsForModal.component}
		</Modal>
	);
};

export default ModalStep;
