import React, {useEffect,useLayoutEffect} from "react";
import Input from "app/uikit/input";
import Switch from "app/uikit/switch";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import UserLayout from "app/components/includs/user-layout";
import action from "app/store/appSettings/actions";
import Button from "app/uikit/button/active-button";
import PageTitle from "../../../components/page-title";
import { Grid } from "@material-ui/core";
import Box from "app/components/content-box";
import Loading from "app/uikit/CircularProgress/loading";
import { handlerErrors } from "app/helper/handlerErrors";
import validationSchema from "./validationSchema";
import style from "./style.module.scss";

const ScreenAppSettings = () => {
	const dispatch = useDispatch();
	const {updating, loading, settings, error} = useSelector(state=>state.appSettings);
	const INITIAL_VALUES  = {
		technical_works_notification: settings?.technical_works_notification || "" ,
		is_show_technical_works_notification: settings?.is_show_technical_works_notification ?? false
	};
	const formik = useFormik({
		initialValues: INITIAL_VALUES,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			dispatch(action.updateGeneralSettings(values));
		}
	});

	useLayoutEffect(()=>{
		dispatch(action.getGeneralSettings());
	},[dispatch]);

	useEffect(() => {
		handlerErrors(error, formik.setErrors);
	}, [error, formik.setErrors]);

	const buttonDisabled = JSON.stringify(formik.values) === JSON.stringify(INITIAL_VALUES);


	const onChaneInput = e =>{
		const value = e.target.value;
		formik.setFieldValue(e.target.name,value.toUpperCase());
	};

	return (
		<UserLayout>
			<PageTitle title="App settings" subtitle="Manage app settings here." />
			<Grid container className="account-columns-wrapper">
				<Grid  item sm lg={8} xl={6}>
					<Box title="Alert management">
						<div className={style.block}>
							<Input
								multiline
								rows={2}
								fullWidth
								name="technical_works_notification"
								form={formik}
								onChange={onChaneInput}
								label="Technical notification"
								required
							/>
							<Switch wrapperClassName={style.switch} name="is_show_technical_works_notification" label="Show" form={formik} />
						</div>
						<Button
							size="medium"
							colorVariant="gradient"
							onClick={formik.handleSubmit}
							disabled={buttonDisabled}
							loading={updating}
						>
							Save
						</Button>
						<Loading loading={loading}/>
					</Box>
				</Grid>
			</Grid>
		</UserLayout>
	);
};

export default ScreenAppSettings;
