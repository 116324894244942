import React from "react";
import Button from "../../uikit/button/active-button";
import cl from "classnames";
import style from './style.module.scss';

const PageTitle = props => {
	const {
		title,
		subtitle,
		content,
		buttonText,
		showAddingContent,
		showButton,
		titleAddingContent,
		subtitleAddingContent,
		buttonAction,
		buttonDisabled,
		buttonLoading,
		className
	} = props;
	return (
		<>
			<div className={style.container}>
				<h2 className={cl(style.title,'title-h2')}>{title}</h2>
				{titleAddingContent}
			</div>
			{subtitle && (
				<div className={style.blockSubTitle}>
					<p className={cl(style.subTitle,'main-text')}>{subtitle}</p>
					<div className={style.addingContent}>
						{showButton && (
							<Button
								size="medium"
								colorVariant="gradient"
								className={cl("m-l--12", className)}
								onClick={buttonAction}
								disabled={buttonDisabled}
								loading={buttonLoading}
							>
								{buttonText}
							</Button>
						)}
						{showAddingContent && subtitleAddingContent}
					</div>
				</div>
			)}
			{content && content}
		</>
	);
};

export default PageTitle;
