import React from "react";
import Info from "app/components/info";
import { useDispatch } from "react-redux";
import actions from "app/store/people/actions";
import style from "./style.module.scss";
import { DownloadIcon } from "app/icons";
import cl from "classnames";

const DownloadTemplate = ({className, placement}) => {
	const dispatch = useDispatch();
	const onDownload = () => {
		dispatch(actions.getTemplateAudieces());
	};
	return (
		<div className={cl(style.container,className)}>
			<button onClick={onDownload} className={style.button}>
				<DownloadIcon />
				<span className={style.text}>Download template</span>
			</button>
			<Info
				placement={placement}
				info={
					<>
						You can download a sample audience document. <br />
						Stick to this format to avoid mistakes.
					</>
				}
			/>
		</div>
	);
};

export default DownloadTemplate;