import { takeEvery, put, all, call } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import directory from "app/directory/errors";

const dir = directory.en;


function* supportRequest({ data, cb, actionName }) {
	try {
		const response = yield call(services.fetchSupportRequest, data);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchSupportSuccess(actionName));
			toastify.success(response?.data?.message);
			cb && cb();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.fetchSupportFailure(actionName,error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.fetchSupportFailure(actionName,error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchSupportFailure(actionName,""));
		}
	}
}

export default function* root() {
	yield all([takeEvery(types.UPLOAD_SUPPORT_REQUEST, supportRequest)]);
}
