import React from "react";
import { useDispatch } from "react-redux";
import MatchColumnsAudiece from "app/components/steps-upload-audiece/match-columns";
import actionsUploadAuciences from "app/store/uploadAuciences/actions";
import actionPeople from "app/store/people/actions";

const MatchColumns = ({ changeStep, currentStep, nextStep, data, ...rest }) => {
	const dispatch = useDispatch();

	const onNextStep = () => {
		changeStep(nextStep);
		dispatch(actionPeople.getAudiences());
	};

	const onBack = () => {
		changeStep(currentStep - 1);
	};

	const handleSubmit = data => {
		dispatch(actionsUploadAuciences.fetchCreateAudience(data, onNextStep));
	};

	return (
		<MatchColumnsAudiece
			data={data}
			onSubmit={handleSubmit}
			onBack={onBack}
			{...rest}
		/>
	);
};

export default MatchColumns;