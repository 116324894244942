import types from "./types";
import { stateRequest } from "../helper";

export const initialState = {
	registrations: {},
	isLoading:{},
	error:{}
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_STATISTIC_REGISTRATIONS_REQUEST:
			return {
				...state,
				...stateRequest(state, action, "request")
			};
		case types.GET_STATISTIC_REGISTRATIONS_FAILURE:
			return {
				...state,
				...stateRequest(state, action, "failure")
			};
		case types.GET_STATISTIC_REGISTRATIONS_SUCCESS:
			return {
				...state,
				registrations: action.data,
				...stateRequest(state, action, "success")
			};
		case types.RESET_REGISTRATIONS:
			return {
				...state,
				registrations: initialState.registrations
			};
		default:
			return state;
	}
};

export default reducer;
