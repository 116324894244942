import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip as ChipBase } from "@material-ui/core";

const useStyles = makeStyles({
	root: {
		background: "#EB7D68",
		borderRadius: 4,
		paddingLeft: 8,
		paddingRight: 8,
		margin: 4,
		color: "#fff",
		height: 30,
		flexShrink: 0,
		"& .MuiChip-label": {
			padding: 0,
			lineHeight: 2
		},
		"&.MuiChip-deletable": {
			paddingRight: 0,
		},
		"&.MuiChip-deletable:focus": {
			background: "#EB7D68"
		},
		"& .MuiChip-deleteIcon": {
			height: 11,
			width: 10,
			margin: "0 11px 1px 7px",
			flexShrink: '0 !important',
			"&:hover": {
				opacity: 0.7,
				transition: "opacity 0.2s"
			}
		}
	},
	container_ghost:{
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		color: "#3E4555",
		fontSize: "16px",
		"&.Mui-focused": {
			borderColor: "#CFD3DE"
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiInput-input": {
			padding: 0,
			color: "#3E4555"
		},
		"& .MuiInputBase-inputMultiline": {
			paddingTop: 6,
			paddingBottom: 6,
			resize: "vertical",
			lineHeight: "26px"
		}
	},
	container: {
		border: "1px solid #E8EAEF",
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		borderRadius: 6,
		minHeight: 50,
		padding: "6px 8px",
		transition: "0.2s",
		color: "#3E4555",
		fontSize: "16px",
		"&.Mui-focused": {
			borderColor: "#CFD3DE"
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiInput-input": {
			padding: 0,
			color: "#3E4555"
		},
		"& .MuiInputBase-inputMultiline": {
			paddingTop: 6,
			paddingBottom: 6,
			resize: "vertical",
			lineHeight: "26px"
		}
	},
	reset: {
		fontWeight: 500,
		fontSize: 14,
		borderRadius: "4px",
		margin: 4,
		lineHeight: 22,
		color: '#38465D',
		height: 30,
		display: "flex",
		paddingLeft: 10,
		alignItems: "center",
		"& svg":{
			margin: '0 10px'
		},
		"&:hover":{
			backgroundColor: '#F7F8FA',
			borderColor: '#F7F8FA'
		}
	}
});
const DeleteIcon = (
	<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.66659 1.7735L8.72659 0.833496L4.99992 4.56016L1.27325 0.833496L0.333252 1.7735L4.05992 5.50016L0.333252 9.22683L1.27325 10.1668L4.99992 6.44016L8.72659 10.1668L9.66659 9.22683L5.93992 5.50016L9.66659 1.7735Z"
			fill="#fff"
		/>
	</svg>
);

const ResetIcon = (
	<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.66659 1.7735L8.72659 0.833496L4.99992 4.56016L1.27325 0.833496L0.333252 1.7735L4.05992 5.50016L0.333252 9.22683L1.27325 10.1668L4.99992 6.44016L8.72659 10.1668L9.66659 9.22683L5.93992 5.50016L9.66659 1.7735Z"
			fill="#38465D"
		/>
	</svg>
);

const Chip = ({ list, onDelete, onReset, variant, ...rest }) => {
	const classes = useStyles();

	const handleDelete = e =>{
		const target = e.target;
		const closest = target.closest(".MuiChip-root");
		if (!closest) return;
		const index = closest.dataset.index;
		if (index === undefined) return;
		onDelete && onDelete(index, closest.dataset.id);
	}
	if (list) {
		return (
			<div className={variant === 'bordered' && classes.container || classes.container_ghost }>
				{Array.isArray(list) && list.map((el, i) => (
					<ChipBase
						key={el + i}
						label={el?.name}
						data-index={i}
						data-id={el?.id}
						className={classes.root}
						deleteIcon={DeleteIcon}
						onDelete={onDelete ? handleDelete : undefined}
						{...rest}
					/>
				))}
				{onReset && !!list.length && <button type="button" className={classes.reset} onClick={onReset} > Reset selection {ResetIcon}</button> }
			</div>
		);
	}

	return <ChipBase className={classes.root} deleteIcon={DeleteIcon} {...rest} />;
};
export default Chip;
