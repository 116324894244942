import React, { useLayoutEffect, useEffect, useState, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

import CampaignsAside from "app/components/campaigns-aside";
import PATHS from "PATHS";
import actions from "app/store/campaign/actions";
import { PLATFORMS_NAME } from "app/emun/platforms";

import style from "../../style.module.scss";
import validationSchema from "../../validationSchema";
import { validateData, formateNameErrorsForFaceBook, removeErrorsByKeys } from "../../helper";

import Steps from "./steps";

const NewCampaignFacebook = () => {
	const [hasNotSaveData, setHasNotSaveData] = useState(true);
	const [step, setStep] = useState(1);
	const [errorsFromBack, setErrorsFromBack] = useState({});
	const [errors, setErrors] = useState({});
	const [stepsExtended, setStepsExtended] = useState(false);
	const dispatch = useDispatch();
	const {
		createCompaign: { creative, budget, currency, audience, adAccount, platform },
		createCompaign,
		actionError
	} = useSelector(state => state.campaigns);
	const history = useHistory();

	const toStep = step => {
		setStep(step++);
	};

	const shouldValidateEndDate = createCompaign?.budget_type_id?.id === 2;

	useLayoutEffect(() => {
		if (!platform) {
			history.push(PATHS.cabinet.campaigns);
		}
		return () => {
			dispatch(actions.resetDraftCompaign());
		};
	}, [dispatch, history, platform]);

	useEffect(() => {
		let newErrors = formateNameErrorsForFaceBook(actionError);
		setErrorsFromBack(newErrors);
	}, [actionError]);
	const isConversion = createCompaign?.target?.id === 2;

	const validationField = useMemo(()=>{
		const array = ["platform", "adAccount", "creative", "audience", "name", "budget", "platform_parameters", "start_datetime", "age_from", "age_to"];
		if(!shouldValidateEndDate){
			array.push("end_datetime");
		}
		if(isConversion){
			array.push("conversion_id");
			array.push("pixel_id");
			// array.push("conversion_event_location");
		}
		return array;
	},[isConversion,shouldValidateEndDate])

	useEffect(() => {
		if(!stepsExtended) return;
		validateData(validationField,
			validationSchema(null,shouldValidateEndDate, isConversion),
			createCompaign,
			setErrors
		);
	}, [createCompaign, stepsExtended]);

	const formatedErrors = { ...errors, ...errorsFromBack };

	const data = {
		platform: {
			value: (platform && PLATFORMS_NAME[platform]) || "",
			canToMove: false,
			error: !!formatedErrors.platform
		},
		adAccount: {
			value: adAccount?.name,
			canToMove: stepsExtended,
			error: !!formatedErrors.adAccount || !!formatedErrors.platform_parameters
		},
		details: {
			value: (budget && `${budget} ${currency}, ${audience?.name}`) || "",
			canToMove: stepsExtended,
			error: !!formatedErrors.audience || !!formatedErrors.name || !!formatedErrors.budget || !!formatedErrors.start_datetime || !!formatedErrors.end_datetime   || formatedErrors?.age_from || formatedErrors?.age_to || isConversion && (
				formatedErrors.conversion_id || formatedErrors.pixel_id || formatedErrors.conversion_event_location
			)
		},
		creative: {
			value: creative?.fields?.creative_name,
			canToMove: stepsExtended,
			error: !!formatedErrors.creative
		},
		review: {
			status: !!creative && "pending",
			canToMove: stepsExtended,
			error: !!Object.keys(formatedErrors).length
		}
	};

	const onChangeStepAside = e => {
		const step = e.target.dataset.step;
		toStep(+step);
	};

	const removeErrors = keys => {
		if(Object.keys(errorsFromBack).length){
			setErrorsFromBack(state =>removeErrorsByKeys(state,keys));
		}
		if(Object.keys(errors).length){
			setErrors(state =>removeErrorsByKeys(state,keys));
		}
	};

	return (
		<div className={style["campaign-wrp"]}>
			<CampaignsAside step={+step} data={data} onChangeStep={onChangeStepAside} />
			<Steps
				edit={stepsExtended}
				errors={formatedErrors}
				removeErrors={removeErrors}
				setStepsExtended={setStepsExtended}
				step={+step}
				setHasNotSaveData={setHasNotSaveData}
				onChangeStep={toStep}
			/>
			<Prompt when={hasNotSaveData} message="message" />
		</div>
	);
};

export default withRouter(NewCampaignFacebook);
