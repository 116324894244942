import React from "react";
import { DeleteCampaignIcon } from "../../../../../icons";
import ConfirmModal from "../../../../../components/modal/confirm-modal.jsx";

const DeleteCampaign = ({ onClose, open, onAgree, loading }) => {
	return (
		<div>
			<ConfirmModal
				open={open}
				title="Delete campaign?"
				icon={<DeleteCampaignIcon />}
				loading={loading}
				onClose={onClose}
				onAgree={onAgree}
				text={
					<>
						Campaign and reports about it will be deleted forever. <br /> Are you sure?
					</>
				}
				textAgree="Yes, delete"
				textClose="No, decline"
			/>
		</div>
	);
};

export default DeleteCampaign;