export default {

	FETCH_GET_CAMPAIGNS_REQUEST: "FETCH_GET_CAMPAIGNS_REQUEST",
	FETCH_GET_CAMPAIGNS_SUCCESS: "FETCH_GET_CAMPAIGNS_SUCCESS",
	FETCH_GET_CAMPAIGNS_FAILURE: "FETCH_GET_CAMPAIGNS_FAILURE",

	FETCH_GET_CAMPAIGN_REQUEST: "FETCH_GET_CAMPAIGN_REQUEST",
	FETCH_GET_CAMPAIGN_SUCCESS: "FETCH_GET_CAMPAIGN_SUCCESS",
	FETCH_GET_CAMPAIGN_FAILURE: "FETCH_GET_CAMPAIGN_FAILURE",

	FETCH_CREATE_CAMPAIGNS_REQUEST: "FETCH_CREATE_CAMPAIGNS_REQUEST",
	FETCH_CREATE_CAMPAIGNS_SUCCESS: "FETCH_CREATE_CAMPAIGNS_SUCCESS",
	FETCH_CREATE_CAMPAIGNS_FAILURE: "FETCH_CREATE_CAMPAIGNS_FAILURE",

	FETCH_DELETE_CAMPAIGNS_REQUEST: "FETCH_DELETE_CAMPAIGNS_REQUEST",
	FETCH_DELETE_CAMPAIGNS_SUCCESS: "FETCH_DELETE_CAMPAIGNS_SUCCESS",
	FETCH_DELETE_CAMPAIGNS_FAILURE: "FETCH_DELETE_CAMPAIGNS_FAILURE",

	FETCH_EDIT_CAMPAIGN_REQUEST: "FETCH_EDIT_CAMPAIGN_REQUEST",
	FETCH_EDIT_CAMPAIGN_SUCCESS: "FETCH_EDIT_CAMPAIGN_SUCCESS",
	FETCH_EDIT_CAMPAIGN_FAILURE: "FETCH_EDIT_CAMPAIGN_FAILURE",

	FETCH_PAGINATION_CAMPAIGNS_REQUEST: "FETCH_PAGINATION_CAMPAIGNS_REQUEST",
	FETCH_PAGINATION_CAMPAIGNS_SUCCESS: "FETCH_PAGINATION_CAMPAIGNS_SUCCESS",

	FETCH_FILTER_CAMPAIGNS_REQUEST: "FETCH_FILTER_CAMPAIGNS_REQUEST",
	FETCH_FILTER_CAMPAIGNS_SUCCESS: "FETCH_FILTER_CAMPAIGNS_SUCCESS",
	

	FETCH_START_CAMPAIGN_REQUEST: "FETCH_START_CAMPAIGN_REQUEST",
	FETCH_START_CAMPAIGN_SUCCESS: "FETCH_START_CAMPAIGN_SUCCESS",
	FETCH_START_CAMPAIGN_FAILURE: "FETCH_START_CAMPAIGN_FAILURE",


	FETCH_PAUSE_CAMPAIGN_REQUEST: "FETCH_PAUSE_CAMPAIGN_REQUEST",
	FETCH_PAUSE_CAMPAIGN_SUCCESS: "FETCH_PAUSE_CAMPAIGN_SUCCESS",
	FETCH_PAUSE_CAMPAIGN_FAILURE: "FETCH_PAUSE_CAMPAIGN_FAILURE",

	FETCH_RESTART_CAMPAIGN_REQUEST: "FETCH_RESTART_CAMPAIGN_REQUEST",
	FETCH_RESTART_CAMPAIGN_SUCCESS: "FETCH_RESTART_CAMPAIGN_SUCCESS",
	FETCH_RESTART_CAMPAIGN_FAILURE: "FETCH_RESTART_CAMPAIGN_FAILURE",
	
    SET_DATA_CREATE_CAMPAIGNS: "SET_DATA_CREATE_CAMPAIGNS",

	GET_CAMPAIGNS_AUDIENCES_REQUEST: "GET_CAMPAIGNS_AUDIENCES_REQUEST",
	GET_CAMPAIGNS_AUDIENCES_SUCCESS: "GET_CAMPAIGNS_AUDIENCES_SUCCESS",

	GET_CAMPAIGNS_CREATIVES_REQUEST: "GET_CAMPAIGNS_CREATIVES_REQUEST",
	GET_CAMPAIGNS_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_CAMPAIGNS_SUCCESS",

    GET_CAMPAIGNS_CREATIVES_REQUEST: "GET_CAMPAIGNS_CREATIVES_REQUEST",
	GET_CAMPAIGNS_CREATIVES_SUCCESS: "GET_CAMPAIGNS_CREATIVES_SUCCESS",
	GET_CAMPAIGNS_CREATIVES_FAILURE: "GET_CAMPAIGNS_CREATIVES_FAILURE",

	CREATE_CAMPAIGNS_REQUEST: "CREATE_CAMPAIGNS_REQUEST",
	CREATE_CAMPAIGNS_SUCCESS: "CREATE_CAMPAIGNS_SUCCESS",
	CREATE_CAMPAIGNS_FAILURE: "CREATE_CAMPAIGNS_FAILURE",

	RESTE_DRAFT_CAMPAIGN: "RESTE_DRAFT_CAMPAIGN",
	RESET_CAMPAIGNS_TABLE_PARAMS: "RESET_CAMPAIGNS_TABLE_PARAMS"
};