import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Autocomplete from "app/uikit/autocomplete";
import Button from "../../../../../uikit/button/active-button";
import validationSchema from "./validationSchema";
import { getErrorKeys } from "../../helper";
import Select from "app/uikit/select";
import style from "./style.module.scss";

const StepOne = ({ onSubmit, listAdAccounts, data, errors, removeErrors, edit }) => {
	const { campaign_targets } = useSelector(state => state.handBooks);
	const formatedListTarget = useMemo(() => campaign_targets?.map(elem => ({ text: elem.name, value: elem.id })) || [], [
		campaign_targets
	]);
	const formatedListAdAcoounts = useMemo(() => {
		return (
			listAdAccounts
				?.filter(el => el.is_can_create_campaigns)
				?.map(el => ({
					...el,
					description: `Account ID: ${el.account_id}`
				})) || []
		);
	}, [listAdAccounts]);

	const initialValues = {
		adAccount: data?.adAccount || (formatedListAdAcoounts && formatedListAdAcoounts[0]) || "",
		target: data?.target?.id || (formatedListTarget && formatedListTarget[0].value),
		platform_parameters:
			(data?.platform_parameters?.page_id && {
				id: data?.platform_parameters?.page_id,
				name: data?.platform_parameters?.page_name
			}) ||
			data?.platform_parameters ||
			formatedListAdAcoounts[0]?.promote_pages[0] ||
			""
	};

	const handleSubmit = values => {
		if (removeErrors) {
			removeErrors(Object.keys(values));
		}
		const initJson = JSON.stringify(initialValues);
		const valuesJson = JSON.stringify(initialValues);
		if (edit && initJson === valuesJson) {
			return onSubmit({}, true);
		}
		const newValues = {
			...values,
			target: campaign_targets?.find(elem=>elem.id === values.target) || values.target
		}
		onSubmit(newValues);
	};

	const formik = useFormik({
		initialValues,
		validateOnChange: false,
		validateOnBlur: false,
		initialErrors: {
			adAccount: errors?.adAccount,
			platform_parameters: errors?.platform_parameters
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		const dirtiFields = formik.status?.dirtyFields;
		if (dirtiFields && edit) {
			const newValues = {
				...formik.values,
				target: campaign_targets?.find(elem=>elem.id === formik.values?.target) || formik.values?.target
			}
			onSubmit(newValues, false, true);
			let ke = getErrorKeys(dirtiFields, errors);
			removeErrors(ke);
		}
	}, [formik.values, formik.status?.dirtyFields]);

	const onChangeAaAccount = value => {
		if (formik.values.adAccount.id === value.id) return;
		formik.setFieldValue("adAccount", value);
		formik.setFieldValue("platform_parameters", value?.promote_pages[0]);
		formik.setStatus({
			...formik.status,
			dirtyFields: {
				...formik.status?.dirtyFields,
				'adAccount': true,
				'platform_parameters': true
			}
		});
	};

	const listPages = formik?.values?.adAccount?.promote_pages?.map(el => ({ name: el.name, id: el.id })) || [];

	return (
		<>
			<div className="step-two">
				<p className="title-subheading title-subheading_bold m-b--4">Ad account</p>
				<p className="text-main m-b--24">Choose an Ad account.</p>
				<form onSubmit={formik.handleSubmit} className={style.form} noValidate>
					<Autocomplete
						required
						fullWidth
						validateField
						label="Ad account"
						name="adAccount"
						form={formik}
						options={formatedListAdAcoounts}
						onChange={onChangeAaAccount}
						placeholder="Search an ad account by name"
						showError={edit}
						noOptionsText="No ad acounts have been found by your request"
					/>
					<Autocomplete
						required
						fullWidth
						validateField
						label="Facebook page"
						name="platform_parameters"
						info="Your Facebook Page that represents your business in ads. If there are no available pages in this list, check your Facebook business account and provide the necessary access."
						form={formik}
						options={listPages}
						placeholder="Search an ad account by Facebook pages"
						noOptionsText="No Facebook pages have been found by your request"
						showError={edit}
					/>
					<Select
						required
						fullWidth
						info={
							<>
								<span className="color--orange">Traffic:</span> Send people to a destination, like a website, app, Facebook
								event or Messenger conversation. <br />
								<span className="color--orange">Conversions:</span> Show your ads to people most likely to take valuable
								actions, like making a purchase or adding payment info, on your website, app or in Messenger.
							</>
						}
						list={formatedListTarget}
						name="target"
						label="Campaign Objective"
						form={formik}
					/>
				</form>
			</div>
			<div className="campaign-footer">
				<Button onClick={formik.handleSubmit} size="medium" colorVariant="gradient">
					Next Step
				</Button>
			</div>
		</>
	);
};

export default StepOne;