import React, { useEffect } from "react";
import { useFormik } from "formik";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import Input from "app/uikit/input";
import { handlerErrors } from "app/helper/handlerErrors";

const UploadSteps = ({ onClose, data, loading, errors, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			name: data?.name,
			email: data?.email,
			comment: ""
		},
		validationSchema: validationSchema,
		onSubmit: values =>{
			onSubmit(values)
		}
	});

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<Input fullWidth disabled name="name" label="Name" form={formik} required />
			<Input fullWidth disabled name="email" label="Email" form={formik} required />
			<Input multiline rows={5} fullWidth name="comment" label="Describe your question" form={formik} required />
			<Error error={errors} />
			<div className="modal-footer">
				<Button onClick={onClose} size="medium" colorVariant="ghost">
					Cancel
				</Button>
				<Button type="submit" loading={loading} size="medium" colorVariant="gradient">
					Send request
				</Button>
			</div>
		</form>
	);
};

export default UploadSteps;
