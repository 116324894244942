import {
	FacebookIcon,
	GoogleadsIcon,
	LemistIcon,
	LinkedinIcon,
	ProspectinIcon
} from "app/icons";
import { PLATFORMS } from "app/emun/platforms";

export const getPlatformIcon = (type) => {
	switch (type) {
		case PLATFORMS.facebook_ads:
			return <FacebookIcon />;
		case PLATFORMS.lemist:
			return <LemistIcon />;
		case PLATFORMS.linkedin:
			return <LinkedinIcon />;
		case PLATFORMS.prospectin:
			return <ProspectinIcon />;
		case PLATFORMS.google_ads:
			return <GoogleadsIcon />;
		default:
			return "";
	}
};

export const getPlatformList = (platforms, systemName) =>(platforms?.map(el=>({text:el.name,value: (systemName && el.system_name) || el.id, icon: getPlatformIcon(el.system_name)}))) || [];