import React, { useState } from "react";
import DropZone from "./index";
import style from "./style.module.scss";
import { toBase64 } from "app/helper/helper.files";
import BaseAvatar from "../avatar";

function DropZoneAvatar(props) {
	const [baseFile, setBaseFile] = useState({});
	const { src, size} = props;
	const onChange = async file => {
		if (!file.length) return;
		const fileBase = await toBase64(file[0]);
		setBaseFile({
			src: fileBase,
			name: file[0].name
		});
	};

	const omRemoveFile = () => {
		setBaseFile({});
	};

	const avatar = () => (
		<>
			<div className={style.dropzone_avatar}>
				<BaseAvatar src={baseFile?.src || src} />
				<div className={style.dropzone_avatarCaption}>
					<p className={`main-text main-text_bold ${style.dropzone_inner__dark}`}>Change profile photo</p>
					<span className={`small-text ${style.dropzone_inner__light}`}>Photo size shouldn’t be more {(size / 1000000) || 25} mb</span>
				</div>
			</div>
			<span className="orange-link no-wrap d-inline-block p-y--5">Choose photo</span>
		</>
	);
	return (
		<DropZone
			fullWidth
			onRemoveFile={omRemoveFile}
			content={avatar()}
			onChange={onChange}
			className={style.dropzone_gray}
			type="img"
			size={size}
			{...props}
		/>
	);
}

export default DropZoneAvatar;