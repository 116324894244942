import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { DeleteEmployeeIcon } from "app/icons";
import UserLayout from "app/components/includs/user-layout";
import PageTitle from "app/components/page-title";
import Select from "app/uikit/select";
import Button from "app/uikit/button/active-button";
import Table from "app/components/table";
import columns from "./columns";
import actions from "app/store/users/actions";
import Statistic from "./statistic";
import Menu from "./menu";
import ModalAddNewUser from "./modals/add-new-user";
import PATHS from "PATHS";
import { getTableItemsById } from "app/helper";
import toastify from "app/utils/toastify";

const ScreenUsers = () => {
	const {
		isLoading,
		users: { items: userItems },
		pagination,
		deleteLoading,
		filter,
		statistic,
		actionLoading,
		actionErrors
	} = useSelector(state => state.users);
	const {
		user_statuses = []
	} = useSelector(state => state.handBooks);
	const [newUserModal, setNewUserModal] = useState(false);
	const dispatch = useDispatch();
	const lastLocation = useLastLocation();
	const history = useHistory();


	useEffect(() => {
		if (lastLocation && lastLocation.pathname.indexOf(PATHS.adminPanel.users) === -1) {
			dispatch(actions.resetTableParams());
		}
		dispatch(actions.getStatistic());
		dispatch(actions.getUsers());
	}, [dispatch]);

	const onChangeDownload = (ids)=>{
		dispatch(actions.getUsersExport(ids));
	};

	const onChangeDownloadProgress = (ids)=>{
		dispatch(actions.getUsersExportProgress());
	};

	const openDetailsUser = (id)=>{
		history.push(PATHS.adminPanel.userAccount.replace(':id',id));
	}


	const handleChangeEditItem = (id, key) => {
		switch (key) {
			case "details":
				openDetailsUser(id);
				return;
			case "download":
				onChangeDownload(id);
				return;
			default:
				return;
		}
	};

	const deleteNames = ids => {
		const names = getTableItemsById(ids, userItems).map(el => el?.full_name);
		const namesMap = names.join(", ");
		return (
			<>
				{names.length > 1
					? "Are you sure that you want to delete these employees"
					: "Are you sure that you want to delete this employee"}
				:
				<br />
				<span className="small-text_bold">{namesMap} </span>
			</>
		);
	};

	const openChangeNewUserModal = () => {
		setNewUserModal(true);
	};

	const closeChangeNewUserModal = () => {
		setNewUserModal(false);
	};

	const onSubmitNewUser = data => {
		dispatch(actions.fetchNewUser(data, closeChangeNewUserModal));
	};

	const onChangeStatusFilter = value =>{
		dispatch(actions.fetchFilter({'filter[status_id]': value}))
	};

	const onChangeDelete = (item, cb) =>{
		toastify.error('This feature is not available');
		cb();
	};
	const onRowClick = (record) => {
		openDetailsUser(record.id)
	};
	const options = [ ...user_statuses?.map(el=>({text:el.name,value: el.id})), {text:"All statuses", value: ''}] || [];

	return (
		<UserLayout>
			<PageTitle
				title="Signum users"
				showAddingContent
				subtitle={
					<Statistic data={statistic} />
				}
				subtitleAddingContent={
					<>
						<Button onClick={onChangeDownloadProgress} size="medium" colorVariant="gradient" className="m-r--24">
							Download users info
						</Button>
						<Select list={options} hasAllSelect staticWidth onChange={onChangeStatusFilter} value={filter['filter[status_id]']} className="m-r--24" name="select-users" />
						<Button onClick={openChangeNewUserModal} size="medium" colorVariant="gradient" className="m-l--12">
							Add new user
						</Button>
					</>
				}
			/>

			<Table
				title="Lists of users"
				scroll={{ x: 1000 }}
				loading={isLoading}
				onChangeDelete={onChangeDelete}
				columns={columns}
				data={userItems}
				filter={filter}
				action={actions}
				onChangeDownload={onChangeDownload}
				onRowClick={onRowClick}
				settingsData={{
					menu: {
						component: Menu,
						onChange: handleChangeEditItem
					}
				}}
				pagination={pagination}
				titleDelete={{
					one: "Delete user?",
					many: "Delete users?"
				}}
				deleteIcon={<DeleteEmployeeIcon />}
				deleteText={deleteNames}
				deleteLoading={deleteLoading}
			/>

			<ModalAddNewUser
				errors={actionErrors}
				loading={actionLoading}
				onSubmit={onSubmitNewUser}
				onClose={closeChangeNewUserModal}
				open={newUserModal}
			/>
		</UserLayout>
	);
};

export default ScreenUsers;
