import types from "./types";

const actions = {
	getCategoriesAudience: params => ({
		type: types.GET_CATEGORIES_AUDIENCE_REQUEST,
		params,
		actionName: "table" 
	}),
	getCategoriesAudienceSuccess: (actionName,data) => ({
		type: types.GET_CATEGORIES_AUDIENCE_SUCCESS,
		actionName,data
	}),
	getCategoriesAudienceFailure: (actionName,error) => ({
		type: types.GET_CATEGORIES_AUDIENCE_FAILURE,
		actionName,error
	}),

	getTags: () => ({
		type: types.GET_CATEGORIES_AUDIENCE_TAGS_REQUEST,
		actionName: "tags" 
	}),
	getTagsSuccess: (actionName,data) => ({
		type: types.GET_CATEGORIES_AUDIENCE_TAGS_SUCCESS,
		actionName,data
	}),
	getTagsFailure: (actionName,error) => ({
		type: types.GET_CATEGORIES_AUDIENCE_TAGS_FAILURE,
		actionName,error
	}),
	

	deleteItems: (ids, cb) => ({
		type: types.FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST,
		ids,
		cb,
		actionName: "delete"
	}),
	deleteItemsSuccess: (actionName, data) => ({
		type: types.FETCH_DELETE_CATEGORIES_AUDIENCE_SUCCESS,
		actionName, data
	}),
	deleteItemsFailure: (actionName,error) => ({
		type: types.FETCH_DELETE_CATEGORIES_AUDIENCE_FAILURE,
		actionName,error
	}),

	
	fetchCreateCategoryAudience: (data, cb) => ({
		type: types.FETCH_CREATE_CATEGORIES_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "create" 
	}),
	fetchCreateCategoryAudienceSuccess: (actionName,data)  => ({
		type: types.FETCH_CREATE_CATEGORIES_AUDIENCE_SUCCESS,
		actionName,data
	}),

	fetchCreateCategoryAudienceFailure:  (actionName,error)  => ({
		type: types.FETCH_CREATE_CATEGORIES_AUDIENCE_FAILURE,
		actionName,error
	}),
	
	
	fetchEditCategoryAudience: (id,data, cb) => ({
		type: types.FETCH_EDIT_CATEGORIES_AUDIENCE_REQUEST,
		data,
		cb,
		id,
		actionName: "edit" 
	}),
	fetchEditCategoryAudienceSuccess: (actionName,data)  => ({
		type: types.FETCH_EDIT_CATEGORIES_AUDIENCE_SUCCESS,
		actionName,data
	}),

	fetchEditCategoryAudienceFailure:  (actionName,error)  => ({
		type: types.FETCH_EDIT_CATEGORIES_AUDIENCE_FAILURE,
		actionName,error
	}),
	
	fetchPublic: (id, isPublic) => ({
		type: types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_REQUEST,
		id,
		isPublic,
		propertyName: "id",
		actionName: "public",
	}),
	fetchPublicSuccess: (id,actionName,propertyName) => ({
		type: types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_SUCCESS,
		actionName,id,propertyName
	}),

	fetchPublicFailure:  (id,error,actionName,propertyName)  => ({
		type: types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_FAILURE,
		actionName,id,error,propertyName
	}),
	
	
	
	fetchPagination: data => ({
		type: types.FETCH_PAGINATION_CATEGORIES_AUDIENCE_REQUEST,
		data
	}),
	fetchPaginationSuccess: data => ({
		type: types.FETCH_PAGINATION_CATEGORIES_AUDIENCE_SUCCESS,
		data
	}),

	fetchFilter: data => ({
		type: types.FETCH_FILTER_CATEGORIES_AUDIENCE_REQUEST,
		data
	}),
	fetchFilterSuccess: data => ({
		type: types.FETCH_FILTER_CATEGORIES_AUDIENCE_SUCCESS,
		data
	}),

	resetTableParams: () => ({
		type: types.RESET_CATEGORIES_AUDIENCE_TABLE_PARAMS
	}),
	
};
export default actions;
