import {
	FacebookIcon,
	GoogleadsIcon,
	LemistIcon,
	LinkedinIcon,
	ProspectinIcon,
} from "../../icons";

import { PLATFORMS } from "../../emun/platforms";


export const getIcon = (variant,inactive) => {
    switch (variant) {
        case PLATFORMS.facebook_ads:
            return <FacebookIcon inactive={inactive } />;
        case PLATFORMS.lemist:
            return <LemistIcon inactive={inactive} />;
        case PLATFORMS.linkedin:
            return <LinkedinIcon inactive={inactive} />;
        case PLATFORMS.prospectin:
            return <ProspectinIcon inactive={inactive} />;
        case PLATFORMS.google_ads:
            return <GoogleadsIcon inactive={inactive} />;
        default:
            return "";
    }
};