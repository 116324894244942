import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Modal from "app/components/modal";
import Input from "app/uikit/input";
import ChooseTags from "./choose-tags";
import {validationSchema} from "./validationSchema";
import { handlerErrors } from "app/helper/handlerErrors";
import action from "app/store/people/actions";

const ModalChoseAudience = ({ onClose, data, open }) => {
	const { isLoading, error } = useSelector(state => state.people);
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			tag_ids: [],
			name: ""
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			dispatch(action.fetchComposeAudience({...values,tag_ids: values.tag_ids?.map(elem=>elem.id)},onClose))
		}
	});

	useEffect(() => {
		handlerErrors(error.composeAudience, formik.setErrors);
	}, [error.composeAudience, formik.setErrors]);

	return (
		<Modal
			title={data?.name}
			size="middle"
			iconTitle={<img src={data?.image?.url}  alt={data?.image?.name}/>}
			onClose={onClose}
			open={open}
			footer
			buttonAgreeText="Generate audience"
			loadingAgree={isLoading.composeAudience}
			onSend={formik.handleSubmit}
			disabled={!formik.values.tag_ids.length}
			resetForm={formik.resetForm}
		>
			<ChooseTags name="tag_ids" form={formik} data={data} />
			<Input fullWidth name="name" label="Audience name" form={formik} required />
		</Modal>
	);
};

export default ModalChoseAudience;