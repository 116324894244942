import yup from "app/yup";
import directory from "app/directory/errors";

const dir = directory.en;

const validationSchema = yup.object({
	internal_audience: yup.object().nullable().required(dir.required),
    // companyName: ErrorName
});

export default validationSchema;