import React from "react";
import { EmptyPlaceholder as IconEmpty } from "app/icons";
import cl from "classnames";

const EmptyPlaceholder = props => {
	const { title, subtitle, content, className, icon } = props;
	return (
		<div className={cl("empty-placeholder", className)}>
			{icon || <IconEmpty />}
			<h3 className="title-h3 m-t--32 m-b--8">{title}</h3>
			{subtitle && <p className="main-text m-b--8">{subtitle}</p>}

			{content && <div className="m-t--32">{content}</div> || null}
		</div>
	);
};

export default EmptyPlaceholder;