import api from "app/api";
import { mappedParams } from "app/helper";
import download from "app/utils/download";

const API = api();

const getAudiences = async params => {
	try {
		return await API.get("/account/audience", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};
const getMarketplace = async params => {
	try {
		return await API.get("/account/contact-categories", { params: { ...mappedParams(params) } });
	} catch (error) {
		return error;
	}
};

const deleteAudiences = async ids => {
	try {
		return await API.delete("/account/audience/delete-multiple", { params: { ids } });
	} catch (error) {
		return error;
	}
};


// const fetchEditName = async (id, data) => {
// 	try {
// 		return await API.put(`/account/audience/${id}`, data);
// 	} catch (error) {
// 		return error;
// 	}
// };


const getTemplateAudieces = async () => {
	try {
		return download("/files/audience-template", true);
	} catch (error) {
		return error;
	}
};

const fetchComposeAudience = async data => {
	try {
		return await API.post("/account/marketplace/audience/compose", data);
	} catch (error) {
		return error;
	}
};


export default {
	getAudiences,
	getMarketplace,
	deleteAudiences,
	fetchComposeAudience,
	// fetchEditName,
	getTemplateAudieces
};
