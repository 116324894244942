import React from "react";
import Pill from "app/components/pills";
import style from "./style.module.scss";

const Status = ({color, text, icon, iconAction, action}) => (
	<div className={style.root}>
		<div className={style.wrapPill}>
			<Pill color={color} text={text} icon={icon} />
		</div>
		{iconAction && action && <button data-target-table="action-button" onClick={action}>{iconAction}</button>}
	</div>
);

export default Status;