import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import style from "./style.module.scss";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

const TableCreative = ({ value, data, onChange, withCount, list }) => (
	<Tabs className={style.tabs} value={value} onChange={onChange}>
		{list?.map((elem, i) => {
			const count = (elem?.data?.count ?? (data && data[elem?.name]?.count)) || "0";
			return (
				<Tab
					key={elem.label}
					className={style.tab}
					label={(withCount && `${elem.label} ${count}`) || elem.label}
					{...a11yProps(i)}
				/>
			);
		})}
	</Tabs>
);

export default TableCreative;