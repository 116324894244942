import React, { useState, useEffect } from "react";
import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "../checkbox";
import { Search } from "../../icons";
import { InputAdornment } from "@material-ui/core";
import useStyles from "./style";
import Chip from "../chip";
import cl from "classnames/bind";

const AutocompleteMultiple = ({ name, error, placeholder, value, onChange, options, disablePortal, noOptionsText }) => {
	const classes = useStyles();
	const cx = cl.bind(classes);
	const [anchorEl, setAnchorEl] = useState(null);
	const [pendingValue, setPendingValue] = useState([]);
	const [values, setValues] = useState([]);

	useEffect(() => {
		setValues(value || []);
		setPendingValue(value || []);
	}, [value]);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (event, newValue) => {
		setPendingValue(newValue);
		if (newValue !== null) {
			onChange(newValue);
		}
	};
	const handleClose = (event, reason) => {
		if (reason === "toggleInput") {
			return;
		}
		if (anchorEl) {
			anchorEl.focus();
		}
		setAnchorEl(null);
	};
	const onDelete = index => {
		const mewArr = [...values];
		mewArr.splice(+index, 1);
		setPendingValue(mewArr);
		onChange(mewArr);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<ButtonBase disableRipple className={cx("button", { error: error })} onClick={handleClick}>
				<Chip variant="bordered" list={values} onDelete={onDelete} />
			</ButtonBase>
			<Popper
				id={`${name}-popper`}
				open={open}
				anchorEl={anchorEl}
				disablePortal={disablePortal ?? true}
				style={{ minWidth: anchorEl?.clientWidth }}
				modifiers={{
					flip: {
						enabled: true
					},
					preventOverflow: {
						enabled: true,
						boundariesElement: "viewport"
					}
				}}
				placement="bottom"
				className={cx("popper", { popperPortal: !disablePortal })}
			>
				<Autocomplete
					open
					multiple
					disablePortal
					onClose={handleClose}
					value={pendingValue}
					onChange={handleChange}
					disableCloseOnSelect
					renderTags={() => null}
					noOptionsText={noOptionsText || "No items have been found by your request"}
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal
					}}
					getOptionSelected={(option, value)=>option?.name === value?.name}
					renderOption={(option, { selected }) => (
						<div className={classes.text}>
							<Checkbox checked={selected} />
							<span className="main-text main-text_bold">{option.name}</span>
						</div>
					)}
					options={options}
					getOptionLabel={option => option.name || ""}
					renderInput={params => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={cl(classes.inputSearch, classes.inputBase)}
							placeholder={placeholder}
							fullWidth
							startAdornment={
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							}
						/>
					)}
				/>
			</Popper>
		</>
	);
};
export default AutocompleteMultiple;
