export const settingsChartName = {
	clicks_count: {
		colors: ["hsl(10, 77%, 66%)"]
	},
	cpr_values: {
		colors: ["#5F74BE"]
	}
};
export const getTickScaleX = len => {
	if (len >= 0 && len < 10) {
		return "every 1 day";
	} else if (len > 10 && len < 15) {
		return "every 2 day";
	} else if (len > 10 && len < 53) {
		return "every 1 week";
	} else if (len > 53 && len < 101) {
		return "every 2 week";
	} else if (len > 101 && len < 120) {
		return "every 3 week";
	} else if (len > 101 && len < 120) {
		return "every 1 month";
	} else if (len > 101 && len < 120) {
		return "every 1 month";
	} else if (len > 180 && len < 366) {
		return "every 2 month";
	} else if (len > 366 && len < 365) {
		return "every 3 month";
	}
	return "every 1 month";
};

export const getHasData = obj => {
	let flag = false;
	for (let mark in obj) {
		if (mark !== "charts" && mark !== "spent_budget_currency_code") {
			if (!flag) {
				flag = !!obj[mark];
			}
		} else if (mark === "charts") {
			// eslint-disable-next-line no-loop-func
			obj[mark].forEach(element => {
				if (!flag) {
					flag = !!element.points.length;
				}
			});
		}
	}
	return flag;
};