import React, { useState, useEffect } from "react";
import Input from "../../uikit/input";
import { PasswordHidden, PasswordShown, Confirmed } from "../../icons";

const Registration = ({ form = {}, ...rest }) => {
	const { values } = form;
	const [showPassword, setShowPassword] = useState(false);
	const [confirmed, setConfirmed] = useState(null);

	useEffect(() => {
		const { values: { password, password_confirmation } = {} } = form;
		if (password !== "" && password_confirmation !== "" && password === password_confirmation) {
			setConfirmed(true);
		} else if (confirmed !== null || password_confirmation !== "") {
			setConfirmed(false);
		}
	}, [values]);

	const handleChangePassType = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<Input
				fullWidth
				required
				name="password"
				type={showPassword ? "text" : "password"}
				label="Password"
				form={form}
				inputProps={{ autoComplete: "new-password" }}
				icon={
					(showPassword && (
						<div className="icon-pointer" onClick={handleChangePassType}>
							<PasswordShown />
						</div>
					)) || (
						<div className="icon-pointer" onClick={handleChangePassType}>
							<PasswordHidden />
						</div>
					)
				}
				iconPosition="end"
				{...rest}
			/>
			<Input
				fullWidth
				required
				name="password_confirmation"
				type="password"
				label="Confirm password"
				form={form}
				inputProps={{ autoComplete: "new-password" }}
				icon={
					(confirmed && (
						<div>
							<Confirmed />
						</div>
					)) ||
					(confirmed !== null && <Confirmed color="#ef5e39" />)
				}
				iconPosition="end"
				{...rest}
			/>
		</>
	);
};

export default Registration;
