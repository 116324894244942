import React from 'react';
import Pill from 'app/components/pills';

export const getStatus = (status)=>{
    switch(status.id) {
        case 1:
            return <Pill color="orange" text={status.name}/>
        case 2:
            return <Pill color="blue " text={status.name}/>
        case 3:
            return <Pill color="gray" text={status.name}/>
        default:
            return;
    }
};