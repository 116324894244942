import types from "./types";
import config from "app/config/config";
import {stateRequest,mappedOffset, getPaginationAfterDelete} from '../helper';

export const initialState = {
	categories: {
		count: 0,
		items: []
	},
	tags:{
		count: 0,
		items: []
	},
	pagination: {
		total: 0,
		limit: config.ui.pagination.size,
		page: 1
	},

	filter: {
		sort_by: 'name',
		sort_direction: 'asc'
	},

	error: {
		table: "",
		create: ""
	},
	isLoading: {
		table: false,
		create: false,
		delete: false,
		public:{

		}
	},
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_CATEGORIES_AUDIENCE_REQUEST:
		case types.GET_CATEGORIES_AUDIENCE_TAGS_REQUEST:
		case types.FETCH_CREATE_CATEGORIES_AUDIENCE_REQUEST:
		case types.FETCH_EDIT_CATEGORIES_AUDIENCE_REQUEST:
		case types.FETCH_DELETE_CATEGORIES_AUDIENCE_REQUEST:
			return {
				...state,
				...stateRequest(state,action,'request')
			};
		case types.FETCH_CREATE_CATEGORIES_AUDIENCE_SUCCESS:
		case types.FETCH_EDIT_CATEGORIES_AUDIENCE_SUCCESS:
			return {
				...state,
				...stateRequest(state,action,'success')
			};
		case types.FETCH_CREATE_CATEGORIES_AUDIENCE_FAILURE:
		case types.GET_CATEGORIES_AUDIENCE_TAGS_FAILURE:
		case types.GET_CATEGORIES_AUDIENCE_FAILURE:
		case types.FETCH_DELETE_CATEGORIES_AUDIENCE_FAILURE:
		case types.FETCH_EDIT_CATEGORIES_AUDIENCE_FAILURE:
			return {
				...state,
				...stateRequest(state,action,'failure')
			};

		case types.GET_CATEGORIES_AUDIENCE_SUCCESS:
			return {
				...state,
				categories: action.data,
				pagination: { ...state.pagination, total: action?.data?.count || 0 },
				...stateRequest(state,action,'success')
			};
		case types.GET_CATEGORIES_AUDIENCE_TAGS_SUCCESS:
			return {
				...state,
				...stateRequest(state,action,'success'),
				tags: action.data
			};
		case types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_REQUEST:
			return {
				...state,
				...stateRequest(state,action,'request',true)
			};
		case types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_SUCCESS:
			return {
				...state,
				...stateRequest(state,action,'success', true)
			};
		case types.FETCH_PUBLIC_CATEGORIES_AUDIENCE_FAILURE:
			return {
				...state,
				...stateRequest(state,action,'failure',true)
			};
		case types.FETCH_DELETE_CATEGORIES_AUDIENCE_SUCCESS:
			return {
				...state,
				pagination: getPaginationAfterDelete(state, action.data, 'categories'),
				...stateRequest(state,action,'success')
			};

		case types.FETCH_PAGINATION_CATEGORIES_AUDIENCE_SUCCESS:
			return {
				...state,
				pagination: { ...state.pagination, ...action.data, offset: mappedOffset(action.data, state.pagination) }
			};
		case types.FETCH_FILTER_CATEGORIES_AUDIENCE_SUCCESS:
			return {
				...state,
				pagination: { ...state.pagination, limit: initialState.pagination.limit, page: 1, offset: null },
				filter: { ...state.filter, ...action.data }
			};
		default:
			return state;
	}
};

export default reducer;
