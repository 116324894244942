export const handlerErrors = (backendErrors, setErrors, keys) => {
	if (!backendErrors) return;
	let newError = {...backendErrors};
	let errors = {};
	if (keys) {
		for (let key in newError) {
			const find = Object.keys(keys).find(elf => elf === key);
			if (!find) {
				delete newError[key];
			}
		}
	}
	for (let key in newError) {
		errors[key] = newError[key][0];
	}
	setErrors(errors);
};

export const getErrorsKeys = (obj) => {
	if(!obj) return;
	let newObj = {};
	for(let mark in obj){
		const key = mark.split('.');
		newObj[key[key.length - 1]] = obj[mark];
	}
	return newObj
};