import React, { useMemo } from "react";
import { FormHelperText } from "@material-ui/core";

const Error = ({ error, className }) => {
    const checkError = useMemo(()=>{
        if(typeof error === 'string' && error !== ''){
            return {
                error: Boolean(error),
                text: error
            }
        }else{
            return {
                error: false,
                text: ''
            }
        }
    },[error]);

	return <FormHelperText className={className} error={checkError.error}>{checkError.text}</FormHelperText>;
};

export default Error;