import React from "react";

import { useSelector } from "react-redux";

import StepOne from "./step-1";
import StepTwo from "./step-2";
import StepThree from "./step-3";
import StepFour from "./step-4";

const Steps = ({ step, setHasNotSaveData, ...rest }) => {
	const {
		createCompaign: { platform, adAccount: { currency } = {} }
	} = useSelector(state => state.campaigns);

	switch (step) {
		case 2:
			return <StepTwo step={step}  currency={currency} { ...rest} />;
		case 3:
			return <StepThree step={step}  { ...rest}/>;
		case 4:
			return <StepFour  setHasNotSaveData={setHasNotSaveData} { ...rest}/>;
		case 1:
		default:
			return <StepOne step={step}  platform={platform} { ...rest}/>;
	}
};

export default Steps;
