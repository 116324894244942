import React, { useState, useEffect } from "react";
import style from "./style.module.scss";
import Footer from "../../../../../uikit/table/footer";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import SearchInput from "app/components/SearchField";
import classNames from "classnames";
import CreativeItem from "app/components/CreativeItem";
import Nodata from "app/components/no-data";
import Button from "app/uikit/button";
import CircularProgress from "app/uikit/CircularProgress";
import EmptySearch from "app/uikit/table/emptySearch";
import { formateDate } from "app/helper";
import { PLATFORMS } from "app/emun/platforms";
import { Reserch } from "app/icons";
import PATHS from "PATHS";
const CURRENTPLATFORM = PLATFORMS.facebook_ads;

const Creatives = ({
	creatives,
	onSearch,
	params,
	pagination,
	onPaginationChange,
	currentCreatives,
	loading,
	name,
	onRefresh,
	form
}) => {
	const [search, setSearch] = useState();
	const hasDataOnPage = currentCreatives && creatives.find(el => el.id === currentCreatives.id);
	const hasData = (params && params["filter[search]"]) !== undefined || creatives?.length;
	const toCreateCrative = () => {
		const win = window.open(PATHS.cabinet.createCreative.replace(":platform", CURRENTPLATFORM), "_blank");
		win.focus();
	};

	useEffect(() => {
		setSearch(params["filter[search]"]);
		if (currentCreatives) {
			form.setFieldValue("creative", currentCreatives.id);
		}
	}, [params["filter[search]"]]);

	const handleSearch = e => {
		const value = e.target.value;
		setSearch(value);
		if (onSearch) {
			onSearch(value);
		}
	};

	const onChange = async e => {
		const value = e.target.value;
		await form.setFieldValue(name, value);
		await form.validateField(name);
		form.setStatus({
			...form.status,
			dirtyFields: {
				...form.status?.dirtyFields,
				[name]: true
			}
		});
	};

	return (
		<>
			<div className={style["form-top"]}>
				<SearchInput onChange={handleSearch} value={search || ""} />
				<div>
					<Button className={style.btnTop} size="small" onClick={onRefresh} colorVariant="borderless">
						<Reserch />
						&ensp;Refresh
					</Button>
					<Button className={style.btnTop} size="small" onClick={toCreateCrative} colorVariant="ghost">
						Create creative
					</Button>
				</div>
			</div>
			<form onSubmit={form.handleSubmit} noValidate className={style.form}>
				<div className={classNames(style["form-list"], "long-radios")}>
					<FormControl className="radio">
						{currentCreatives && !hasDataOnPage && (
							<FormControlLabel
								className={style.currentElemnt}
								key={currentCreatives.id}
								control={
									<Radio
										name={name}
										checked={form.values.creative === currentCreatives.id}
										value={currentCreatives.id}
										onChange={onChange}
										color="primary"
									/>
								}
								label={
									<CreativeItem
										preview={currentCreatives.fields.banner.url}
										title={currentCreatives.fields.creative_name}
										description={currentCreatives.fields.description}
										date={formateDate(currentCreatives.created_at)}
										created
									/>
								}
							/>
						)}

						<RadioGroup name="creative-template">
							{(!!creatives?.length &&
								creatives.map(el => (
									<FormControlLabel
										key={el.id}
										control={
											<Radio
												name={name}
												checked={el.id == form.values.creative}
												value={el.id}
												onChange={onChange}
												color="primary"
											/>
										}
										label={
											<CreativeItem
												preview={el.fields.banner.url}
												title={el.fields.creative_name}
												description={el.fields.description}
												date={formateDate(el.created_at)}
												created
											/>
										}
									/>
								))) ||
								(!loading && hasData && <EmptySearch />) ||
								(!loading && <Nodata text="No creatives have been created." />) ||
								null}
						</RadioGroup>
					</FormControl>
					{loading && <CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" />}
				</div>
				<Footer pagination={pagination} paginationChange={onPaginationChange} />
			</form>
		</>
	);
};

export default Creatives;