import React, { useMemo, useCallback } from "react";
import { MoreVert } from "app/icons";
import baseMenu from "app/components/menu/base-menu";
import Menu from "app/components/menu";
import { DeleteEmptyCreativeIcon, DeleteIcon } from "app/icons";


const getMenu = (menu, index, onChangeMenu, record) => {
	if (menu) {
		return menu(index, onChangeMenu, record);
	} else {
		return baseMenu(index, onChangeMenu);
	}
};

const useColumnsMenu = (columns = [], data, onChangeMenu, menu, render, hide, renderWithHandler, iconDelete, renderIconDelete) => {

	const onChange = useCallback((event)=>{
		const target = event.currentTarget;
		const id = target?.dataset?.id;
		const key = target?.dataset?.key;
		onChangeMenu(id, key, event);
	},[onChangeMenu]);
	
	const columnsWithMenu = useMemo(() => {
		if (hide) {
			return columns;
		}
		return [
			...columns,
			{
				title: "",
				dataIndex: "menu",
				key: "menu",
				fixed: "right",
				width: 62,
				render: (index, record) => {
					if(renderIconDelete || iconDelete) {
						return <div className="menu-button-icon" data-key="delete" data-id={record.id} onClick={onChange}>{renderIconDelete || <DeleteIcon color="#B3BECB" /> }</div>
					}
					if (renderWithHandler) {
						const element = renderWithHandler(index, record);
						return React.cloneElement(element, { ...element.props, 'data-id': record.id, onClick: onChange });
					}
					if (render) {
						return render(index, record);
					}
					return (
						<Menu
							control={
								<div className="menu-button" data-target-table="menu">
									<MoreVert />
								</div>
							}
							menuItem={getMenu(menu, record.id, onChangeMenu, record)}
							placement="left-start"
						/>
					);
				}
			}
		];
	}, [data, columns]);

	return columnsWithMenu;
};

export default useColumnsMenu;
