import React from "react";

const LabelContent = props => {
	return (
		<div className="modal-radio-label">
			<div className="modal-radio-icon">{props.icon}</div>
			<div className="modal-radio-caption">
				<p className="small-text small-text_bold">{props.text}</p>
				<span className="sub-text">{props.subtext}</span>
			</div>
		</div>
	);
};
export default LabelContent;