export const converingData = (data)=>
	data.map(el => ({
			id: el?.id,
			last_activity: el?.updated_at,
			creative: {
				banner: el?.fields?.banner?.url,
				title: el?.fields?.creative_name,
				description: el?.fields?.description
			},
			owner: {
				avatar: el?.user_owner?.avatar?.url,
				name: el?.user_owner?.full_name
			},
			platform: {
				type: el?.integration_type?.system_name,
				name: el?.integration_type?.name
			}}
	))