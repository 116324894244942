import types from "./types";
import localStore from "app/helper/local-store";
const userStorage = localStore.getInfo();

const user = {
	avatar: "",
	email: "",
	id: "",
	name: "",
	permissions: {}
};

export const initialState = {
	user: userStorage || user,
	isChanging: false,
	changeError: "",
	error: "",
	isLoading: false
};

export const resetState = {
	user: user,
	isChanging: false,
	changeError: "",
	error: "",
	isLoading: false
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_INFO_USER_REQUEST:
			return {
				...state,
				error: "",
				isLoading: true
			};
		case types.GET_INFO_USER_SUCCESS:
			return {
				...state,
				user: action.data,
				error: "",
				isLoading: false
			};
		case types.GET_INFO_USER_FAILURE:
			return {
				...state,
				error: action.error,
				isLoading: false
			};

		case types.CHANGE_INFO_USER_REQUEST:
			return {
				...state,
				changeError: "",
				isChanging: true
			};
		case types.CHANGE_INFO_USER_SUCCESS:
			return {
				...state,
				user: action.data,
				changeError: "",
				isChanging: false
			};
		case types.CHANGE_INFO_USER_FAILURE:
			return {
				...state,
				changeError: action.error,
				isChanging: false
			};
		case types.RESET_INFO_USER:
			return {...initialState,user};
		case types.TRAINING_PASSED:
			const newUser = {...localStore.getInfo(), is_first_login: false};
			localStore.setInfo(newUser);
			return {...state, user:newUser}
		default:
			return state;
	}
};

export default reducer;
