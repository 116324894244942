import React from "react";
import Button from "app/uikit/button/active-button";
import style from "./style.module.scss";
import DownloadTemplate from "../downloadTemplate";

const ToolbarHeader = ({action}) => (
	<div className={style.container}>
        <DownloadTemplate placement="top" className={style.download} />
		<Button
			size="medium"
			colorVariant="gradient"
			onClick={action}
		>
		Upload new audience
		</Button>
	</div>
);

export default ToolbarHeader;
