import React from "react";
import { ResetUserPasswordIcon } from "../../../../../icons";
import ConfirmModal from "../../../../../components/modal/confirm-modal.jsx";

const AddNewUser = ({ onClose, name, open, onAgree, loading }) => {
	return (
		<div>
			<ConfirmModal
				open={open}
				title="Reset the user password?"
				icon={<ResetUserPasswordIcon />}
				loading={loading}
				onClose={onClose}
				onAgree={onAgree}
				text={
					<>
						Are you sure that you want to reset the password for <br />
						the user: <strong>{name}</strong>?
					</>
				}
				textAgree="Yes, reset"
				textClose="No, decline"
			/>
		</div>
	);
};

export default AddNewUser;
