import {  makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	button: {
		display: "flex",
		// flexDirection: "column",
		cursor: "pointer",
		alignItems: "flex-start",
		fontSize: 14,
		lineHeight: "22px",
		width: "100%",
		textAlign: "left",
		color: "#56637B",
		fontWeight: 600,
        margin: 0,
		"&:hover,&:focus": {
			color: "#0366d6"
		},
		label: {
			fontSize: 14,
			lineHeight: "22px",
			color: "#56637B"
		},
		"& span": {
			width: "100%"
		},
		"& svg": {
			width: 16,
			height: 16
		},
        '& .MuiFormControl-root':{
            margin: 0
        },
		'& .MuiInputBase-input':{
			cursor: 'pointer'
		},
		'& .MuiInputBase-root':{
			cursor: 'pointer'
		}
	},
	error:{
		'& .MuiInputBase-root':{
			borderColor: '#EF5E39 !important'
		}
	},
	menuItemText:{
		display: "block",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "100%"
	},
	innerOptionItem:{
		flexGrow: 1,
		maxWidth: "100%"
	},
	innerOptionItemWithImg:{
		maxWidth: "calc(100% - 48px)"
	},
	imgItem:{
		marginRight: 8
	},
	menuItemBlock:{
		width: "100%",
		display:"flex",
		alignItems: "center"
	},
	popper: {
		boxShadow: "0 8px 20px rgba(40, 52, 101, 0.102)",
		border: "1px solid #F7F8FA",
		borderRadius: 6,
		width: "100%",
		zIndex: 1400,
		fontSize: 14,
		lineHeight: "22px",
		color: "#56637B",
		backgroundColor: "#fff",
	},
	popperPortal:{
		width: "auto"
	},
	header: {
		borderBottom: "1px solid #e1e4e8",
		padding: "8px 10px",
		fontWeight: 600
	},
	inputSearch: {
		marginBottom: 0,
		width: "100%",
		border: "none !important",
		borderBottom: "1px solid #E8EAEF !important",
		borderRadius: "6px 6px 0 0 !important",
		"& input": {
			backgroundColor: theme.palette.common.white,
			padding: "0 12px",
			height: "46px",
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			border: "none !important",
			borderRadius: 0,
			fontSize: 14
		}
	},
	inputSearchSecond: {
		paddingLeft: 12
	},
	inputBase: {
		marginTop: -16,
	},
	paper: {
		boxShadow: "none",
		margin: 0,
		color: "#56637B",
		fontSize: 14,
		lineHeight: "22px"
	},
	optionItem:{
		borderRadius: 4,
	},
	optionItemActive:{
		backgroundColor: '#F7F8FA !important'
	},
	option: {
		minHeight: "auto",
		alignItems: "flex-start",
		padding: 8,
		'&[aria-selected="true"]': {
			backgroundColor: "transparent"
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover
		}
	},
	noOptions:{
		color: "rgba(0, 0, 0, 0.54)",
		padding: "14px 16px"
	},
	popperDisablePortal: {
		position: "relative"
	},
	text: {
		flexGrow: 1
	},
	infiniteBlock:{
		padding: 8,
		'& ul':{
			position: "relative"
		}
	},
	infiniteBlockEmpty:{
		padding: 0,
	},
}));

export default useStyles;