import types from "./types";
import config from "app/config/config";
import { getPagination, getPaginationAfterDelete, getDefaultPagination, getFilter } from "../helper";


const getLoadingCopy = (elems,ids,loading = false) =>{
	let obj = {};
	if(ids.split(',').length > 1){
		obj.multiple = loading;
	}else{
		obj[ids] = loading
	}
	return {...elems,...obj};
};


export const initialState = {
	creatives: {
		count: 0,
		items: []
	},

	templates: {
		count: 0,
		items: []
	},

	currentCreative: {},

	pagination: {
		creatives: {
			total: 0,
			limit: config.ui.pagination.size,
			page: 1
		},
		templates: {
			total: 0,
			limit: config.ui.pagination.size,
			page: 1
		}
	},
	filter: {
		creatives:{
			sort_by: "updated_at",
			sort_direction: "desc"
		},
		templates:{
			"filter[is_public]":1,
			sort_by: "updated_at",
			sort_direction: "desc"
		}
	},

	error: "",
	isLoading: {
		table: false,
		delete: false,
		public:{},
		copy:{
			multiple:false
		},
		generatingContent: false
	},
	genereteContent: [],
	createingCreative: false,
	genereteContentError: '',
	createCreativeError: ''
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.FETCH_GET_CREATIVES_REQUEST:
		case types.FETCH_GET_CREATIVE_REQUEST:
			return {
				...state,
				error: "",
				isLoading: {...state.isLoading, table:true}
			};
		case types.FETCH_GET_CREATIVES_SUCCESS:
			return {
				...state,
				isLoading: {...state.isLoading, table:false} ,
				error: "",
				[action.data.target]: action.data.data,
				pagination: { ...state.pagination, [action.data.target]: { ...state.pagination[action.data.target], total: action?.data?.data?.count || 0} }
			};
		case types.FETCH_GET_CREATIVES_FAILURE:
		case types.FETCH_GET_CREATIVE_FAILURE:
			return {
				...state,
				isLoading: {...state.isLoading, table:false} ,
				error: action.error
			};
		case types.FETCH_GET_CREATIVE_SUCCESS:
			return {
				...state,
				isLoading: {...state.isLoading, table:false} ,
				error: "",
				currentCreative: action.data
			};

		case types.FETCH_DELETE_CREATIVE_REQUEST:
			return {
				...state,
				isLoading: {...state.isLoading, delete:true}
			};
		case types.FETCH_DELETE_CREATIVE_SUCCESS:
			return {
				...state,
				pagination: getPaginationAfterDelete(state,action.data.data, action.data.target, action.data.target),
				isLoading: {...state.isLoading, delete:false}
			};
		case types.FETCH_DELETE_CREATIVE_FAILURE:
			return {
				...state,
				isLoading: {...state.isLoading, delete:false}
			};
		case types.FETCH_CREATE_CREATIVE_REQUEST:
		case types.FETCH_EDIT_CREATIVE_REQUEST:
			return {
				...state,
				createCreativeError: "",
				createingCreative: true
			};
		case types.FETCH_CREATE_CREATIVE_SUCCESS:
		case types.FETCH_EDIT_CREATIVE_SUCCESS:
			return {
				...state,
				createCreativeError: "",
				createingCreative: false
			};
		case types.FETCH_CREATE_CREATIVE_FAILURE:
		case types.FETCH_EDIT_CREATIVE_FAILURE:
			return {
				...state,
				createingCreative: false,
				createCreativeError: action.error
			};

		case types.RESET_CREATE_CREATIVES:
			return {
				...state,
				createingCreative: false,
				createCreativeError: ""
			};
		case types.FETCH_PUBLIC_CREATIVE_REQUEST:
			return {
				...state,
				isLoading: {...state.isLoading, public:{...state.isLoading.public,[action.id]: true}}
			};
		case types.FETCH_PUBLIC_CREATIVE_SUCCESS:
		case types.FETCH_PUBLIC_CREATIVE_FAILURE:
			return {
				...state,
				isLoading: {...state.isLoading, public:{...state.isLoading.public,[action.data.id]: false}}
			};
		case types.FETCH_COPY_CREATIVES_REQUEST:
			return {
				...state,
				isLoading: {...state.isLoading, copy:getLoadingCopy(state.isLoading.copy,action.ids, true)}
			};
		case types.FETCH_COPY_CREATIVES_SUCCESS:
			let templates = [...state.templates.items];
			action.data.ids.split(',').forEach(element => {
				const itemIndex = templates.findIndex(elem=>elem.id === +element);
				if(itemIndex !== -1) {
					let item = templates[itemIndex];
					templates[itemIndex] = {
						...item,
						copies_count: ++item.copies_count
					}
				}
			});
			return {
				...state,
				templates:{
					count: state.templates.count,
					items: templates
				},
				isLoading: {...state.isLoading, copy:getLoadingCopy(state.isLoading.copy,action.data.ids)}
			};
		case types.FETCH_COPY_CREATIVES_FAILURE:
			return {
				...state,
				isLoading: {...state.isLoading, copy:getLoadingCopy(state.isLoading.copy,action.data.ids)}
			};
		case types.FETCH_GENERATE_CONTENT_CREATIVE_REQUEST:
			return {
				...state,
				genereteContentError: '',
				isLoading: {...state.isLoading, generatingContent: true}
			};
		case types.FETCH_GENERATE_CONTENT_CREATIVE_SUCCESS:
			return {
				...state,
				genereteContent: action.data,
				genereteContentError: '',
				isLoading: {...state.isLoading, generatingContent: false}
			};
		case types.FETCH_GENERATE_CONTENT_CREATIVE_FAILURE:
			return {
				...state,
				genereteContentError: action.error,
				isLoading: {...state.isLoading, generatingContent: false}
			};
		case types.RESET_GENERATE_CONTENT:
			return {
				...state,
				genereteContentError: "",
				isLoading: {...state.isLoading, generatingContent: false},
				genereteContent: initialState.genereteContent
			};

		case types.FETCH_PAGINATION_CREATIVE_SUCCESS:
			return {
				...state,
				pagination: getPagination(state, action.data?.data, action.data?.target)
			};
		case types.FETCH_FILTER_CREATIVE_SUCCESS:
			return {
				...state,
				pagination: getDefaultPagination(state, action.data?.target),
				filter: getFilter(state, action.data?.data, action.data?.target)
			};
		case types.RESET_CREATIVES_TABLE_PARAMS: {
			return {
				...state,
				pagination: initialState.pagination,
				filter: initialState.filter
			};
		}
		default:
			return state;
	}
};

export default reducer;