import yup from "app/yup";
import { ErrorName, ErrorArrayRequired } from "app/errors-shcema";

export const validationSchema = yup.object({
	name: ErrorName,
	tag_ids: ErrorArrayRequired
});

export const validationSchemaGenerate = yup.object({
	name: ErrorName,
	tag_ids: ErrorArrayRequired
});