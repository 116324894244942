import axios from "axios";
import history from 'app/routers/history';
import localStore from "app/helper/local-store";
import PATHS from "PATHS";

export const path = process.env.REACT_APP_API_HOST;
export const host = process.env.REACT_APP_HOST;

const getAxiosInstance = (flag) => {
	switch (flag) {
		case "pdf":
			return axios.create({
				baseURL: path,
				headers: {
					Authorization: localStore.getAccessToken(),
					"Content-Type": "application/pdf"
				},
				responseType: "blob"
			});
		default:
			return axios.create({
				baseURL: path,
				headers: {
					"Content-Type": "application/json",
					Authorization: localStore.getAccessToken(),
					Accept: "application/json"
				},
				responseType: "json"
			});
	}
};

const CreateIstance = (serviceName, flag) => {
	const axiosInstance = getAxiosInstance();

	axiosInstance.interceptors.request.use(request => {
		if (localStore.getAccessToken() !== null) {
			request.headers.Authorization = localStore.getAccessToken();
		}
		return request;
	});

	axiosInstance.interceptors.response.use(
		response => response,
		error => {
			if (error && error.response && error.response.status === 401 && localStore.getAccessToken()) {
				localStore.clear();
				history.push('/login');
			}
			if (error && error.response && error.response.status === 404 && localStore.getAccessToken()) {
				history.push(PATHS.pageNotFound);
			}
			return Promise.reject(error);
		}
	);
	return axiosInstance;
};

export default CreateIstance;