const facebookAppId = 1077756442744608;

class initFacebookSdk {
	constructor() {
		this.load = false;
		this.init();
	}
	login() {
		return new Promise((res, rej) => {
			try {
				window.FB.login(
					response => {
						res(response);
					},
					{ scope: "public_profile,email,ads_management,ads_read,pages_show_list" }
				);
			} catch (e) {
				rej(e);
			}
		});
	}
	logout() {
		window.FB.api("/me/permissions", "delete", null, () => window.FB.logout());
	}
	loaded() {
		this.load = true;
	}
	init() {
		const self = this;
		const language = "en_US";
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.addEventListener("load", () => {
				self.loaded();
			});
			js.id = id;
			js.src = `https://connect.facebook.net/${language}/sdk.js`;
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: facebookAppId,
				cookie: true,
				xfbml: true,
				version: "v9.0"
			});
		};
	}
}

export default new initFacebookSdk();
