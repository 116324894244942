import React from "react";
import style from "./style.module.scss";

const TOOLTIP_MAP = {
	clicks_count: "Clicks",
	cpr_values: "CPR"
};

const ChartInfo = ({ point, currency }) => {
	const {
		point: {
			data: { xFormatted, yFormatted },
			serieId
		}
	} = point;
	return (
		<div className={style.tooltipe}>
			<div className={style.tooltipe__item}>
				<span className={style.tooltipe__name}>Date:</span>
				<span className={style.tooltipe__value}>{xFormatted}</span>
			</div>
			<div className={style.tooltipe__item}>
				<span className={style.tooltipe__name}>{TOOLTIP_MAP[serieId] || "Value"}:</span>
				<span className={style.tooltipe__value}>
					{yFormatted} {serieId === "cpr_values" ? currency : ""}
				</span>
			</div>
		</div>
	);
};

export default ChartInfo;
