import React, { useState, useMemo, useEffect, useCallback } from "react";
import Checbox from "app/uikit/checkbox";

const mapInitCheckedData = (data)=>{
	let obj = { "id-all": false};
	data && data.forEach(elem =>{
		obj[`id-${elem.id}`] = false;
	});
	return obj;
}

const useColumnsCheck = (columns = [], data, showId, notUse) => {
	const [idTable, setIdTable] = useState(mapInitCheckedData(data));
	const handleChangeRow = useCallback(e => {
		const target = e.currentTarget;
		const name = target.getAttribute("name");
		const checked = target.checked;
		if (name === "id-all") {
			let newData = {};
			for (let mark in idTable) {
				newData[mark] = checked;
			}
			setIdTable(newData);
		} else {
			let newArr = [];

			for (let mark in idTable) {
				if (mark === name) {
					newArr.push(checked);
				} else if (mark !== "id-all") {
					newArr.push(idTable[mark]);
				}
			}
			if (newArr.some(elem => elem === false)) {
				setIdTable(state => ({
					...state,
					"id-all": false,
					[name]: checked
				}));
			} else if (newArr.every(elem => elem)) {
				setIdTable(state => ({
					...state,
					"id-all": true,
					[name]: checked
				}));
			}
		}
	},[idTable]); 
	
	useEffect(() => {
		setIdTable(mapInitCheckedData(data));
	}, [data]);

	const handleChecked = useCallback(id=>{
		if(!id) return;
		switch ((id)) {
			case 'all':
				const newIds1 = {};
				Object.keys(idTable).forEach(elem=>(newIds1[elem] = true));
				setIdTable(newIds1);
				break;
			case 'unselect':
				const newIds2 = {};
				Object.keys(idTable).forEach(elem=>(newIds2[elem] = false));
				setIdTable(newIds2);
				break;
			default:
				setIdTable(`id-${id}`);
			break
		}
	},[idTable])

	
	const mapColumns = useMemo(() => {
		if(notUse){
			return columns;
		}
		return [
			{
				dataIndex: "id",
				key: "id",
				width: showId ? 90 : 67,
				fixed: 'left',
				title: <Checbox label={showId && "ID" || ''} checked={idTable["id-all"] || false} onChange={handleChangeRow} name="id-all" />,
				render: elemRender => {
					const elemName = `id-${elemRender}`;
					return <Checbox data-target-table="checbox" label={showId && elemRender || ''} checked={idTable[elemName] || false} onChange={handleChangeRow} name={elemName} />;
				}
			},
			...columns
		];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleChangeRow,idTable]);

	return useMemo(()=>({
		columnsWithCheck: mapColumns,
		ctrl: { checked: idTable, setChecked: handleChecked  }
	}), [mapColumns, idTable, handleChecked])
};

export default useColumnsCheck;
