import React from "react";
import { WarningIcon, ErrorIcon } from "app/icons";
import { Alert } from "@material-ui/lab";
import cl from 'classnames';
const getIcon = type => {
	switch (type) {
		case "error":
			return <ErrorIcon />
		case "warning":
		default:
			return <WarningIcon />;
	}
};

const getAlertClass = type => {
	switch (type) {
		case "error":
			return 'alert--error'
		default:
			return '';
	}
};

const AlertBase = ({ text, type, className }) => {
	const alertClass = getAlertClass(type);
	if (Array.isArray(text)) {
		if(!text.length) return null;
		return (
			<div className={cl("alert alert-multiple", alertClass, className)}>
				<Alert severity="warning" icon={false}>
					{text.map((elm, i) => {
						return (
							<div className="alert-multiple-item" key={i}>
								{getIcon(type)}
								<span className="small-text">{elm}</span>
							</div>
						);
					})}
				</Alert>
			</div>
		);
	} else if (text) {
		return (
			<div className={cl('alert', alertClass, className)}>
				<Alert severity="warning" icon={false}>
					{getIcon(type)}
					<span className="small-text">{text}</span>
				</Alert>
			</div>
		);
	}
    return null;
};

export default AlertBase;
