import React from "react";
import BaseAvatar from "../../../components/avatar";
import { getStatus } from "./helper";
import { formateDate } from "app/helper";

const columns = [
	{
		title: "User",
		dataIndex: "user",
		key: "user",
		render: (value, record) => (
			<div className="owner-cell">
				<BaseAvatar src={record?.avatar?.url} name={record?.name} />
				<span className="small-text">{record?.full_name}</span>
			</div>
		),
		width: 230
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		render: email => <div title={email} className="users-email-cell">{email}</div>,
	},
	{
		title: "Registered",
		dataIndex: "created_at",
		key: "created_at",
		render: value => formateDate(value),
		width: 140
	},
	{
		title: "Tariff plan",
		dataIndex: "tariff_plan",
		key: "tariff_plan",
		render: value => value.name,
		width: 100
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: value => getStatus(value),
		width: 100
	}
];

export default columns;
