import { combineReducers } from "redux";
import { authReducer, authInitialState } from "./auth";
import { userReducer, userInitialState } from "./user";
import { handBooksReducer, handBooksInitialState } from "./handBooks";
import { employeesReducer, employeesInitialState} from "./employees";
import { peopleReducer, peopleInitialState } from "./people";
import { integrationsReducer, integrationsInitialState } from "./integrations";
import { creativesReducer, creativesinitialState } from "./creatives";
import { usersReducer, usersInitialState } from "./users";
import { campaignsReducer, campaignsInitialState } from "./campaign";
import { reportsReducer, reportsInitialState } from "./reports";
import { uploadAuciencesReducer, uploadInitialState } from "./uploadAuciences";
import { appSettingsReducer, appSettingsInitialState } from "./appSettings";
import { categoriesAudienceReducer, categoriesInitialState } from "./categories-audience";
import { generalReducer, generalInitialState } from "./general";
import { dashboardReducer, dashboardInitialState } from "./dashboard";
import { connectRouter } from "connected-react-router/immutable";
import types from "./auth/types";
import localStore from "app/helper/local-store";

const initialState = {
	auth: authInitialState,
	user: userInitialState,
	handBooks: handBooksInitialState,
	general: generalInitialState,
	employees: employeesInitialState,
	people: peopleInitialState,
	integrations: integrationsInitialState,
	creatives: creativesinitialState,
	users: usersInitialState,
	campaigns: campaignsInitialState,
	reports: reportsInitialState,
	uploadAuciences: uploadInitialState,
	appSettings: appSettingsInitialState,
	categoriesAudience: categoriesInitialState,
	dashboard: dashboardInitialState
};

const createAppReducer = history =>
	combineReducers({
		router: connectRouter(history),
		auth: authReducer,
		user: userReducer,
		handBooks: handBooksReducer,
		general: generalReducer,
		employees: employeesReducer,
		people: peopleReducer,
		integrations: integrationsReducer,
		creatives: creativesReducer,
		users: usersReducer,
		campaigns: campaignsReducer,
		reports: reportsReducer,
		uploadAuciences: uploadAuciencesReducer,
		appSettings: appSettingsReducer,
		categoriesAudience: categoriesAudienceReducer,
		dashboard: dashboardReducer
	});

const createRootReducer = history => (state, action) => {
	if (action.type === types.FETCH_LOGOUT_SUCCESS) {
		localStore.clear();
		state = initialState;
	}
	return createAppReducer(history)(state, action);
};

export default createRootReducer;
