export const NoCampaignIcon = ({ width = 222, height = 168 }) => (
	<svg viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_i)">
			<circle cx="112" cy="74" r="74" fill="#DCEEFF" />
		</g>
		<path
			d="M186.341 32.2356H188.524C188.784 32.2356 189 32.0217 189 31.7638V29.6567C189 29.1409 189.387 28.6818 189.907 28.6566C190.459 28.6252 190.916 29.0655 190.916 29.6001V31.7638C190.916 32.0217 191.132 32.2356 191.392 32.2356H193.518C194.038 32.2356 194.501 32.6193 194.527 33.135C194.559 33.6823 194.114 34.1351 193.575 34.1351H191.392C191.132 34.1351 190.916 34.349 190.916 34.6069V36.714C190.916 37.2298 190.529 37.689 190.009 37.7141C189.457 37.7456 189 37.3053 189 36.7706V34.6069C189 34.349 188.784 34.1351 188.524 34.1351H186.398C185.877 34.1351 185.414 33.7515 185.389 33.2357C185.357 32.6885 185.795 32.2356 186.341 32.2356Z"
			fill="#CFD3DE"
		/>
		<path
			d="M56.5853 12.2015H57.0549C57.3913 12.2015 57.6578 11.931 57.6578 11.6039V11.1385C57.6578 10.9246 57.8291 10.7548 58.0449 10.7548C58.2606 10.7548 58.432 10.9246 58.432 11.1385V11.6039C58.432 11.9373 58.7049 12.2015 59.0348 12.2015H59.5044C59.7202 12.2015 59.8915 12.3713 59.8915 12.5852C59.8915 12.799 59.7202 12.9688 59.5044 12.9688H59.0348C58.6985 12.9688 58.432 13.2393 58.432 13.5664V14.0318C58.432 14.2457 58.2606 14.4155 58.0449 14.4155C57.8291 14.4155 57.6578 14.2457 57.6578 14.0318V13.5664C57.6578 13.233 57.3849 12.9688 57.0549 12.9688H56.5853C56.3696 12.9688 56.1982 12.799 56.1982 12.5852C56.2046 12.3776 56.3759 12.2015 56.5853 12.2015Z"
			fill="#CFD3DE"
		/>
		<path
			d="M151.745 1.44668H152.214C152.55 1.44668 152.817 1.17622 152.817 0.849141V0.383686C152.817 0.169829 152.988 0 153.204 0C153.42 0 153.591 0.169829 153.591 0.383686V0.849141C153.591 1.18251 153.864 1.44668 154.194 1.44668H154.664C154.879 1.44668 155.051 1.61651 155.051 1.83037C155.051 2.04423 154.879 2.21406 154.664 2.21406H154.194C153.858 2.21406 153.591 2.48452 153.591 2.8116V3.27706C153.591 3.49091 153.42 3.66074 153.204 3.66074C152.988 3.66074 152.817 3.49091 152.817 3.27706V2.8116C152.817 2.47823 152.544 2.21406 152.214 2.21406H151.745C151.529 2.21406 151.357 2.04423 151.357 1.83037C151.357 1.61651 151.529 1.44668 151.745 1.44668Z"
			fill="#CFD3DE"
		/>
		<path
			d="M199.018 43.4356C199.392 43.4356 199.697 43.7376 199.697 44.1088C199.697 44.4801 199.392 44.7821 199.018 44.7821C198.643 44.7821 198.339 44.4801 198.339 44.1088C198.339 43.7376 198.643 43.4356 199.018 43.4356Z"
			fill="#CFD3DE"
		/>
		<path
			d="M192.132 95.2953C192.408 95.2953 192.632 94.9939 192.632 94.622C192.632 94.2502 192.408 93.9488 192.132 93.9488C191.856 93.9488 191.632 94.2502 191.632 94.622C191.632 94.9939 191.856 95.2953 192.132 95.2953Z"
			fill="#CFD3DE"
		/>
		<path
			d="M101.139 162.399H103.322C103.582 162.399 103.797 162.185 103.797 161.927V159.82C103.797 159.304 104.185 158.845 104.705 158.82C105.257 158.788 105.714 159.229 105.714 159.763V161.927C105.714 162.185 105.93 162.399 106.19 162.399H108.316C108.836 162.399 109.299 162.783 109.325 163.298C109.356 163.846 108.912 164.298 108.373 164.298H106.19C105.93 164.298 105.714 164.512 105.714 164.77V166.877C105.714 167.393 105.327 167.852 104.806 167.877C104.254 167.909 103.797 167.469 103.797 166.934V164.77C103.797 164.512 103.582 164.298 103.322 164.298H101.196C100.675 164.298 100.212 163.915 100.187 163.399C100.155 162.852 100.593 162.399 101.139 162.399Z"
			fill="#E8EAEF"
		/>
		<path
			d="M15.3959 77.2557H17.5789C17.839 77.2557 18.0548 77.0418 18.0548 76.7839V74.6768C18.0548 74.161 18.4419 73.7019 18.9622 73.6767C19.5143 73.6452 19.9712 74.0855 19.9712 74.6202V76.7839C19.9712 77.0418 20.187 77.2557 20.4472 77.2557H22.573C23.0934 77.2557 23.5566 77.6394 23.582 78.1551C23.6138 78.7024 23.1695 79.1552 22.6301 79.1552H20.4472C20.187 79.1552 19.9712 79.3691 19.9712 79.627V81.7341C19.9712 82.2499 19.5841 82.709 19.0638 82.7342C18.5117 82.7657 18.0548 82.3254 18.0548 81.7907V79.627C18.0548 79.3691 17.839 79.1552 17.5789 79.1552H15.453C14.9326 79.1552 14.4694 78.7715 14.444 78.2558C14.4123 77.7085 14.8501 77.2557 15.3959 77.2557Z"
			fill="#E8EAEF"
		/>
		<path
			d="M189.197 133.606H189.794C190.221 133.606 190.56 133.262 190.56 132.845V132.253C190.56 131.98 190.778 131.764 191.052 131.764C191.326 131.764 191.544 131.98 191.544 132.253V132.845C191.544 133.27 191.89 133.606 192.31 133.606H192.906C193.181 133.606 193.398 133.822 193.398 134.094C193.398 134.367 193.181 134.583 192.906 134.583H192.31C191.882 134.583 191.544 134.927 191.544 135.344V135.936C191.544 136.209 191.326 136.425 191.052 136.425C190.778 136.425 190.56 136.209 190.56 135.936V135.344C190.56 134.919 190.213 134.583 189.794 134.583H189.197C188.923 134.583 188.705 134.367 188.705 134.094C188.705 133.83 188.923 133.606 189.197 133.606Z"
			fill="#E8EAEF"
		/>
		<path
			d="M218.694 62.6117H219.163C219.5 62.6117 219.766 62.3413 219.766 62.0142V61.5487C219.766 61.3349 219.938 61.165 220.153 61.165C220.369 61.165 220.54 61.3349 220.54 61.5487V62.0142C220.54 62.3475 220.813 62.6117 221.143 62.6117H221.613C221.829 62.6117 222 62.7815 222 62.9954C222 63.2093 221.829 63.3791 221.613 63.3791H221.143C220.807 63.3791 220.54 63.6496 220.54 63.9766V64.4421C220.54 64.656 220.369 64.8258 220.153 64.8258C219.938 64.8258 219.766 64.656 219.766 64.4421V63.9766C219.766 63.6433 219.493 63.3791 219.163 63.3791H218.694C218.478 63.3791 218.307 63.2093 218.307 62.9954C218.307 62.7815 218.484 62.6117 218.694 62.6117Z"
			fill="#E8EAEF"
		/>
		<path
			d="M15.4856 57.454C15.8601 57.454 16.1647 57.756 16.1647 58.1272C16.1647 58.4985 15.8601 58.8005 15.4856 58.8005C15.1112 58.8005 14.8066 58.4985 14.8066 58.1272C14.8066 57.756 15.1112 57.454 15.4856 57.454Z"
			fill="#E8EAEF"
		/>
		<path
			d="M155.772 166.653C156.146 166.653 156.451 166.955 156.451 167.327C156.451 167.698 156.146 168 155.772 168C155.397 168 155.093 167.698 155.093 167.327C155.093 166.955 155.397 166.653 155.772 166.653Z"
			fill="#E8EAEF"
		/>
		<path
			d="M55.0581 155.024C55.4325 154.992 55.7561 155.269 55.7879 155.64C55.8196 156.011 55.5404 156.332 55.166 156.364C54.7916 156.395 54.4679 156.118 54.4362 155.747C54.4108 155.376 54.6837 155.055 55.0581 155.024Z"
			fill="#E8EAEF"
		/>
		<path
			d="M19.9469 123.02C20.6704 123.02 21.2605 123.605 21.2605 124.322C21.2605 125.039 20.6704 125.624 19.9469 125.624C19.2235 125.624 18.6334 125.039 18.6334 124.322C18.627 123.598 19.2172 123.02 19.9469 123.02Z"
			fill="#E8EAEF"
		/>
		<path
			d="M1.31359 33.4013C2.03702 33.4013 2.62718 33.9862 2.62718 34.7033C2.62718 35.4203 2.03702 36.0053 1.31359 36.0053C0.590164 36.0053 0 35.4266 0 34.7033C0 33.9862 0.590164 33.4013 1.31359 33.4013Z"
			fill="#E8EAEF"
		/>
		<path
			d="M132.966 130.002C133.341 130.002 133.645 130.304 133.645 130.675C133.645 131.047 133.341 131.349 132.966 131.349C132.592 131.349 132.287 131.047 132.287 130.675C132.293 130.304 132.592 130.002 132.966 130.002Z"
			fill="#E8EAEF"
		/>
		<path
			d="M35.613 6.2036C35.9874 6.21619 36.2793 6.5308 36.2666 6.90204C36.2539 7.27328 35.9366 7.56272 35.5622 7.55013C35.1878 7.53755 34.8959 7.22294 34.9086 6.8517C34.9213 6.47417 35.2386 6.18472 35.613 6.2036Z"
			fill="#E8EAEF"
		/>
		<path
			d="M141.28 54.5181V93.582H82.7202V54.5181C82.7202 52.8516 84.0718 51.5 85.7397 51.5H138.26C139.928 51.5 141.28 52.8516 141.28 54.5181Z"
			fill="white"
		/>
		<path
			d="M117.551 90.7017C117.551 91.8648 116.608 92.8077 115.445 92.8077H108.555C107.392 92.8077 106.449 91.8648 106.449 90.7017H80.292C80.1309 90.7017 80 90.8326 80 90.9937V93.6285C80 95.1714 81.251 96.4224 82.7935 96.4224H141.207C142.749 96.4224 144 95.1714 144 93.6285V90.9937C144 90.8321 143.869 90.7017 143.708 90.7017H117.551Z"
			fill="#939BAB"
		/>
		<path
			d="M141.28 54.5181V60.6011H82.7202V54.5181C82.7202 52.8516 84.0718 51.5 85.7397 51.5H138.26C139.928 51.5 141.28 52.8516 141.28 54.5181Z"
			fill="#B3BECB"
		/>
		<path
			d="M85.7397 51.5H96.9595C95.6416 51.5 94.5723 52.5688 94.5723 53.8872C94.5723 54.5454 94.3052 55.1431 93.873 55.5742C93.4419 56.0068 92.8452 56.2739 92.1855 56.2739H82.7202V54.5181C82.7202 52.8516 84.0718 51.5 85.7397 51.5Z"
			fill="#939BAB"
		/>
		<path
			d="M141.28 54.5181V56.2739H110.576C109.258 56.2739 108.189 55.2051 108.189 53.8872C108.189 53.2285 107.922 52.6309 107.49 52.1997C107.059 51.7671 106.462 51.5 105.802 51.5H138.26C139.928 51.5 141.28 52.8516 141.28 54.5181Z"
			fill="#939BAB"
		/>
		<path
			d="M136.033 86.0085H114.843C114.574 86.0085 114.357 85.7913 114.357 85.5232V65.4622C114.357 65.1941 114.574 64.9763 114.843 64.9763H136.033C136.302 64.9763 136.519 65.1941 136.519 65.4622V85.5232C136.519 85.7913 136.302 86.0085 136.033 86.0085Z"
			fill="#5F74BE"
		/>
		<path
			d="M118.469 79.4878C118.469 79.4629 118.477 79.4175 118.494 79.3516L120.802 71.7803C120.868 71.5737 121.02 71.4141 121.255 71.3022C121.491 71.1904 121.762 71.1348 122.068 71.1348C122.374 71.1348 122.646 71.1909 122.881 71.3022C123.117 71.4141 123.268 71.5737 123.334 71.7803L125.655 79.3516C125.672 79.418 125.68 79.4629 125.68 79.4878C125.68 79.7031 125.545 79.8892 125.277 80.0464C125.008 80.2036 124.729 80.2822 124.439 80.2822C124.083 80.2822 123.872 80.1626 123.806 79.9224L123.384 78.3711H120.765L120.343 79.9224C120.277 80.1626 120.065 80.2822 119.71 80.2822C119.42 80.2822 119.141 80.2036 118.872 80.0464C118.603 79.8892 118.469 79.7031 118.469 79.4878ZM121.162 76.8813H122.975L122.068 73.5552L121.162 76.8813Z"
			fill="white"
		/>
		<path
			d="M126.4 79.6982V71.792C126.4 71.6353 126.479 71.4985 126.636 71.3823C126.793 71.2666 126.996 71.209 127.244 71.209H129.441C130.367 71.209 131.108 71.4448 131.663 71.9165C132.217 72.3877 132.494 73.1284 132.494 74.1382V77.3525C132.494 78.3623 132.217 79.1025 131.663 79.5742C131.108 80.0459 130.367 80.2817 129.441 80.2817H127.244C126.996 80.2817 126.793 80.2241 126.636 80.1079C126.479 79.9922 126.4 79.8555 126.4 79.6982ZM128.336 78.5938H129.441C130.186 78.5938 130.558 78.1802 130.558 77.3525V74.1377C130.558 73.3105 130.186 72.897 129.441 72.897H128.336V78.5938Z"
			fill="white"
		/>
		<path
			d="M108.674 67.0119H88.1104C87.5273 67.0119 87.0547 66.5392 87.0547 65.9562C87.0547 65.3732 87.5273 64.9006 88.1104 64.9006H108.674C109.257 64.9006 109.729 65.3732 109.729 65.9562C109.729 66.5392 109.257 67.0119 108.674 67.0119Z"
			fill="#CFD3DE"
		/>
		<path
			d="M108.674 72.014H88.1104C87.5273 72.014 87.0547 71.5413 87.0547 70.9583C87.0547 70.3753 87.5273 69.9026 88.1104 69.9026H108.674C109.257 69.9026 109.729 70.3753 109.729 70.9583C109.729 71.5413 109.257 72.014 108.674 72.014Z"
			fill="#CFD3DE"
		/>
		<path
			d="M108.674 77.0168H88.1104C87.5273 77.0168 87.0547 76.5442 87.0547 75.9612C87.0547 75.3781 87.5273 74.9055 88.1104 74.9055H108.674C109.257 74.9055 109.729 75.3781 109.729 75.9612C109.729 76.5442 109.257 77.0168 108.674 77.0168Z"
			fill="#CFD3DE"
		/>
		<path
			d="M108.674 82.0192H88.1104C87.5273 82.0192 87.0547 81.5465 87.0547 80.9635C87.0547 80.381 87.5273 79.9083 88.1104 79.9083H108.674C109.257 79.9083 109.729 80.3805 109.729 80.9635C109.729 81.5465 109.257 82.0192 108.674 82.0192Z"
			fill="#CFD3DE"
		/>
		<path
			d="M101.447 87.0215H88.1104C87.5273 87.0215 87.0547 86.5489 87.0547 85.9659C87.0547 85.3834 87.5273 84.9102 88.1104 84.9102H101.447C102.03 84.9102 102.502 85.3829 102.502 85.9659C102.502 86.5489 102.03 87.0215 101.447 87.0215Z"
			fill="#CFD3DE"
		/>
		<g filter="url(#filter1_d)">
			<path d="M100.5 107.25L112 86.5833L122.75 107.25H100.5Z" fill="white" />
			<path
				d="M101.96 109H122.04C124.093 109 125.373 106.773 124.347 105L114.307 87.6533C113.28 85.8799 110.72 85.8799 109.693 87.6533L99.6533 105C98.6267 106.773 99.9067 109 101.96 109ZM112 99.6666C111.267 99.6666 110.667 99.0666 110.667 98.3333V95.6666C110.667 94.9333 111.267 94.3333 112 94.3333C112.733 94.3333 113.333 94.9333 113.333 95.6666V98.3333C113.333 99.0666 112.733 99.6666 112 99.6666ZM113.333 105H110.667V102.333H113.333V105Z"
				fill="#EB7D68"
			/>
		</g>
		<defs>
			<filter
				id="filter0_i"
				x="38"
				y="0"
				width="148"
				height="153"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="7" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.0972195 0 0 0 0 0.116039 0 0 0 0 0.207909 0 0 0 0.0927939 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
			</filter>
			<filter
				id="filter1_d"
				x="76"
				y="69"
				width="72"
				height="72"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="8" />
				<feGaussianBlur stdDeviation="10" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.155882 0 0 0 0 0.205639 0 0 0 0 0.39589 0 0 0 0.102 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
		</defs>
	</svg>
);

export const NoDataIcon = ({ width = 222, height = 168 }) => (
	<svg viewBox={`0 0 ${width} ${height}`} width={width} height={height} xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_i)">
			<circle cx="112" cy="74" r="74" fill="#DCEEFF" />
		</g>
		<path
			d="M186.341 32.2356H188.524C188.784 32.2356 189 32.0217 189 31.7638V29.6567C189 29.1409 189.387 28.6818 189.907 28.6566C190.459 28.6252 190.916 29.0655 190.916 29.6001V31.7638C190.916 32.0217 191.132 32.2356 191.392 32.2356H193.518C194.038 32.2356 194.501 32.6193 194.527 33.135C194.559 33.6823 194.114 34.1351 193.575 34.1351H191.392C191.132 34.1351 190.916 34.349 190.916 34.6069V36.714C190.916 37.2298 190.529 37.689 190.009 37.7141C189.457 37.7456 189 37.3053 189 36.7706V34.6069C189 34.349 188.784 34.1351 188.524 34.1351H186.398C185.877 34.1351 185.414 33.7515 185.389 33.2357C185.357 32.6885 185.795 32.2356 186.341 32.2356Z"
			fill="#CFD3DE"
		/>
		<path
			d="M56.5853 12.2015H57.0549C57.3913 12.2015 57.6578 11.931 57.6578 11.6039V11.1385C57.6578 10.9246 57.8291 10.7548 58.0449 10.7548C58.2606 10.7548 58.432 10.9246 58.432 11.1385V11.6039C58.432 11.9373 58.7049 12.2015 59.0348 12.2015H59.5044C59.7202 12.2015 59.8915 12.3713 59.8915 12.5852C59.8915 12.799 59.7202 12.9688 59.5044 12.9688H59.0348C58.6985 12.9688 58.432 13.2393 58.432 13.5664V14.0318C58.432 14.2457 58.2606 14.4155 58.0449 14.4155C57.8291 14.4155 57.6578 14.2457 57.6578 14.0318V13.5664C57.6578 13.233 57.3849 12.9688 57.0549 12.9688H56.5853C56.3696 12.9688 56.1982 12.799 56.1982 12.5852C56.2046 12.3776 56.3759 12.2015 56.5853 12.2015Z"
			fill="#CFD3DE"
		/>
		<path
			d="M151.745 1.44668H152.214C152.55 1.44668 152.817 1.17622 152.817 0.849141V0.383686C152.817 0.169829 152.988 0 153.204 0C153.42 0 153.591 0.169829 153.591 0.383686V0.849141C153.591 1.18251 153.864 1.44668 154.194 1.44668H154.664C154.879 1.44668 155.051 1.61651 155.051 1.83037C155.051 2.04423 154.879 2.21406 154.664 2.21406H154.194C153.858 2.21406 153.591 2.48452 153.591 2.8116V3.27706C153.591 3.49091 153.42 3.66074 153.204 3.66074C152.988 3.66074 152.817 3.49091 152.817 3.27706V2.8116C152.817 2.47823 152.544 2.21406 152.214 2.21406H151.745C151.529 2.21406 151.357 2.04423 151.357 1.83037C151.357 1.61651 151.529 1.44668 151.745 1.44668Z"
			fill="#CFD3DE"
		/>
		<path
			d="M199.018 43.4356C199.392 43.4356 199.697 43.7376 199.697 44.1088C199.697 44.4801 199.392 44.7821 199.018 44.7821C198.643 44.7821 198.339 44.4801 198.339 44.1088C198.339 43.7376 198.643 43.4356 199.018 43.4356Z"
			fill="#CFD3DE"
		/>
		<path
			d="M192.132 95.2953C192.408 95.2953 192.632 94.9939 192.632 94.622C192.632 94.2502 192.408 93.9488 192.132 93.9488C191.856 93.9488 191.632 94.2502 191.632 94.622C191.632 94.9939 191.856 95.2953 192.132 95.2953Z"
			fill="#CFD3DE"
		/>
		<path
			d="M101.139 162.399H103.322C103.582 162.399 103.797 162.185 103.797 161.927V159.82C103.797 159.304 104.185 158.845 104.705 158.82C105.257 158.788 105.714 159.229 105.714 159.763V161.927C105.714 162.185 105.93 162.399 106.19 162.399H108.316C108.836 162.399 109.299 162.783 109.325 163.298C109.356 163.846 108.912 164.298 108.373 164.298H106.19C105.93 164.298 105.714 164.512 105.714 164.77V166.877C105.714 167.393 105.327 167.852 104.806 167.877C104.254 167.909 103.797 167.469 103.797 166.934V164.77C103.797 164.512 103.582 164.298 103.322 164.298H101.196C100.675 164.298 100.212 163.915 100.187 163.399C100.155 162.852 100.593 162.399 101.139 162.399Z"
			fill="#E8EAEF"
		/>
		<path
			d="M15.3959 77.2557H17.5789C17.839 77.2557 18.0548 77.0418 18.0548 76.7839V74.6768C18.0548 74.161 18.4419 73.7019 18.9622 73.6767C19.5143 73.6452 19.9712 74.0855 19.9712 74.6202V76.7839C19.9712 77.0418 20.187 77.2557 20.4472 77.2557H22.573C23.0934 77.2557 23.5566 77.6394 23.582 78.1551C23.6138 78.7024 23.1695 79.1552 22.6301 79.1552H20.4472C20.187 79.1552 19.9712 79.3691 19.9712 79.627V81.7341C19.9712 82.2499 19.5841 82.709 19.0638 82.7342C18.5117 82.7657 18.0548 82.3254 18.0548 81.7907V79.627C18.0548 79.3691 17.839 79.1552 17.5789 79.1552H15.453C14.9326 79.1552 14.4694 78.7715 14.444 78.2558C14.4123 77.7085 14.8501 77.2557 15.3959 77.2557Z"
			fill="#E8EAEF"
		/>
		<path
			d="M189.197 133.606H189.794C190.221 133.606 190.56 133.262 190.56 132.845V132.253C190.56 131.98 190.778 131.764 191.052 131.764C191.326 131.764 191.544 131.98 191.544 132.253V132.845C191.544 133.27 191.89 133.606 192.31 133.606H192.906C193.181 133.606 193.398 133.822 193.398 134.094C193.398 134.367 193.181 134.583 192.906 134.583H192.31C191.882 134.583 191.544 134.927 191.544 135.344V135.936C191.544 136.209 191.326 136.425 191.052 136.425C190.778 136.425 190.56 136.209 190.56 135.936V135.344C190.56 134.919 190.213 134.583 189.794 134.583H189.197C188.923 134.583 188.705 134.367 188.705 134.094C188.705 133.83 188.923 133.606 189.197 133.606Z"
			fill="#E8EAEF"
		/>
		<path
			d="M218.694 62.6117H219.163C219.5 62.6117 219.766 62.3413 219.766 62.0142V61.5487C219.766 61.3349 219.938 61.165 220.153 61.165C220.369 61.165 220.54 61.3349 220.54 61.5487V62.0142C220.54 62.3475 220.813 62.6117 221.143 62.6117H221.613C221.829 62.6117 222 62.7815 222 62.9954C222 63.2093 221.829 63.3791 221.613 63.3791H221.143C220.807 63.3791 220.54 63.6496 220.54 63.9766V64.4421C220.54 64.656 220.369 64.8258 220.153 64.8258C219.938 64.8258 219.766 64.656 219.766 64.4421V63.9766C219.766 63.6433 219.493 63.3791 219.163 63.3791H218.694C218.478 63.3791 218.307 63.2093 218.307 62.9954C218.307 62.7815 218.484 62.6117 218.694 62.6117Z"
			fill="#E8EAEF"
		/>
		<path
			d="M15.4856 57.454C15.8601 57.454 16.1647 57.756 16.1647 58.1272C16.1647 58.4985 15.8601 58.8005 15.4856 58.8005C15.1112 58.8005 14.8066 58.4985 14.8066 58.1272C14.8066 57.756 15.1112 57.454 15.4856 57.454Z"
			fill="#E8EAEF"
		/>
		<path
			d="M155.772 166.653C156.146 166.653 156.451 166.955 156.451 167.327C156.451 167.698 156.146 168 155.772 168C155.397 168 155.093 167.698 155.093 167.327C155.093 166.955 155.397 166.653 155.772 166.653Z"
			fill="#E8EAEF"
		/>
		<path
			d="M55.0581 155.024C55.4325 154.992 55.7561 155.269 55.7879 155.64C55.8196 156.011 55.5404 156.332 55.166 156.364C54.7916 156.395 54.4679 156.118 54.4362 155.747C54.4108 155.376 54.6837 155.055 55.0581 155.024Z"
			fill="#E8EAEF"
		/>
		<path
			d="M19.9469 123.02C20.6704 123.02 21.2605 123.605 21.2605 124.322C21.2605 125.039 20.6704 125.624 19.9469 125.624C19.2235 125.624 18.6334 125.039 18.6334 124.322C18.627 123.598 19.2172 123.02 19.9469 123.02Z"
			fill="#E8EAEF"
		/>
		<path
			d="M1.31359 33.4013C2.03702 33.4013 2.62718 33.9862 2.62718 34.7033C2.62718 35.4203 2.03702 36.0053 1.31359 36.0053C0.590164 36.0053 0 35.4266 0 34.7033C0 33.9862 0.590164 33.4013 1.31359 33.4013Z"
			fill="#E8EAEF"
		/>
		<path
			d="M132.966 130.002C133.341 130.002 133.645 130.304 133.645 130.675C133.645 131.047 133.341 131.349 132.966 131.349C132.592 131.349 132.287 131.047 132.287 130.675C132.293 130.304 132.592 130.002 132.966 130.002Z"
			fill="#E8EAEF"
		/>
		<path
			d="M35.613 6.2036C35.9874 6.21619 36.2793 6.5308 36.2666 6.90204C36.2539 7.27328 35.9366 7.56272 35.5622 7.55013C35.1878 7.53755 34.8959 7.22294 34.9086 6.8517C34.9213 6.47417 35.2386 6.18472 35.613 6.2036Z"
			fill="#E8EAEF"
		/>
		<path
			d="M140.781 55.3018V65.2173H83.2192V55.3018C83.2192 53.8196 84.0537 52.5319 85.2796 51.8855C85.8179 51.5996 86.4308 51.4386 87.0824 51.4386H136.917C138.4 51.4386 139.687 52.2731 140.334 53.499C140.62 54.0373 140.781 54.6502 140.781 55.3018Z"
			fill="#B3BECB"
		/>
		<path
			d="M98.6718 51.4386V58.6499C98.6718 59.3607 98.0949 59.9376 97.3841 59.9376H91.4606C90.7498 59.9376 90.1729 59.3607 90.1729 58.6499V51.4386H98.6718Z"
			fill="#B3BECB"
		/>
		<path
			d="M131.767 51.4386V58.6499C131.767 59.3607 131.19 59.9376 130.479 59.9376H124.555C123.844 59.9376 123.268 59.3607 123.268 58.6499V51.4386H131.767Z"
			fill="#B3BECB"
		/>
		<path d="M85.2796 51.8855V65.2173H83.2192V55.3018C83.2192 53.8196 84.0537 52.5319 85.2796 51.8855Z" fill="#B3BECB" />
		<path
			d="M93.7786 72.9205H100.404C100.76 72.9205 101.048 73.2088 101.048 73.5644V80.1897C101.048 80.5453 100.76 80.8336 100.404 80.8336H93.7786C93.4231 80.8336 93.1348 80.5453 93.1348 80.1897V73.5644C93.1348 73.2088 93.4231 72.9205 93.7786 72.9205Z"
			fill="white"
		/>
		<path
			d="M93.7786 82.7652H100.404C100.76 82.7652 101.048 83.0535 101.048 83.409V90.0357C101.048 90.3912 100.76 90.6795 100.404 90.6795H93.7786C93.4231 90.6795 93.1348 90.3912 93.1348 90.0357V83.409C93.1348 83.0535 93.4231 82.7652 93.7786 82.7652Z"
			fill="white"
		/>
		<path
			d="M93.7786 92.6111H100.404C100.76 92.6111 101.048 92.8994 101.048 93.255V99.8803C101.048 100.236 100.76 100.524 100.404 100.524H93.7786C93.4231 100.524 93.1348 100.236 93.1348 99.8803V93.255C93.1348 92.8994 93.4231 92.6111 93.7786 92.6111Z"
			fill="white"
		/>
		<path
			d="M103.623 72.9205H110.39C110.746 72.9205 111.034 73.2088 111.034 73.5644V80.1897C111.034 80.5453 110.746 80.8336 110.39 80.8336H103.623C103.268 80.8336 102.979 80.5453 102.979 80.1897V73.5644C102.979 73.2088 103.268 72.9205 103.623 72.9205Z"
			fill="white"
		/>
		<path
			d="M103.623 82.7652H110.39C110.746 82.7652 111.034 83.0535 111.034 83.409V90.0357C111.034 90.3912 110.746 90.6795 110.39 90.6795H103.623C103.268 90.6795 102.979 90.3912 102.979 90.0357V83.409C102.979 83.0535 103.268 82.7652 103.623 82.7652Z"
			fill="white"
		/>
		<path
			d="M103.623 92.6111H110.39C110.746 92.6111 111.034 92.8994 111.034 93.255V99.8803C111.034 100.236 110.746 100.524 110.39 100.524H103.623C103.268 100.524 102.979 100.236 102.979 99.8803V93.255C102.979 92.8994 103.268 92.6111 103.623 92.6111Z"
			fill="white"
		/>
		<path
			d="M113.61 72.9205H120.377C120.732 72.9205 121.021 73.2088 121.021 73.5644V80.1897C121.021 80.5453 120.732 80.8336 120.377 80.8336H113.61C113.254 80.8336 112.966 80.5453 112.966 80.1897V73.5644C112.966 73.2088 113.254 72.9205 113.61 72.9205Z"
			fill="white"
		/>
		<path
			d="M113.61 82.7652H120.377C120.732 82.7652 121.021 83.0535 121.021 83.409V90.0357C121.021 90.3912 120.732 90.6795 120.377 90.6795H113.61C113.254 90.6795 112.966 90.3912 112.966 90.0357V83.409C112.966 83.0535 113.254 82.7652 113.61 82.7652Z"
			fill="white"
		/>
		<path
			d="M113.61 92.6111H120.377C120.732 92.6111 121.021 92.8994 121.021 93.255V99.8803C121.021 100.236 120.732 100.524 120.377 100.524H113.61C113.254 100.524 112.966 100.236 112.966 99.8803V93.255C112.966 92.8994 113.254 92.6111 113.61 92.6111Z"
			fill="white"
		/>
		<path
			d="M130.865 73.5644V80.1897C130.865 80.5453 130.577 80.8336 130.221 80.8336H123.596C123.24 80.8336 122.952 80.5453 122.952 80.1897V73.5644C122.952 73.2088 123.24 72.9205 123.596 72.9205H130.221C130.577 72.9205 130.865 73.2088 130.865 73.5644Z"
			fill="white"
		/>
		<path
			d="M123.596 82.7652H130.221C130.577 82.7652 130.865 83.0535 130.865 83.409V90.0357C130.865 90.3912 130.577 90.6795 130.221 90.6795H123.596C123.24 90.6795 122.952 90.3912 122.952 90.0357V83.409C122.952 83.0535 123.24 82.7652 123.596 82.7652Z"
			fill="white"
		/>
		<path
			d="M123.596 92.6111H130.221C130.577 92.6111 130.865 92.8994 130.865 93.255V99.8803C130.865 100.236 130.577 100.524 130.221 100.524H123.596C123.24 100.524 122.952 100.236 122.952 99.8803V93.255C122.952 92.8994 123.24 92.6111 123.596 92.6111Z"
			fill="white"
		/>
		<path
			d="M83 64V98.7147C83 100.529 84.7426 102 86.8926 102H137.107C139.257 102 141 100.529 141 98.7147V64H83Z"
			fill="white"
		/>
		<path
			d="M98.672 46.2877V56.5895C98.672 57.3004 98.0951 57.8773 97.3843 57.8773H93.5211C92.8103 57.8773 92.2334 57.3004 92.2334 56.5895V46.2877C92.2334 45.5769 92.8103 45 93.5211 45H97.3843C98.0951 45 98.672 45.5769 98.672 46.2877Z"
			fill="#939BAB"
		/>
		<path d="M93.1348 68.9205H101.048V76.8336H93.1348V68.9205Z" fill="#5F74BE" />
		<path d="M122.952 68.9205H130.865V76.8336H122.952V68.9205Z" fill="#5F74BE" />
		<path d="M102.979 68.9205H111.034V76.8336H102.979V68.9205Z" fill="#5F74BE" />
		<path d="M93.1348 78.7652H101.048V86.6795H93.1348V78.7652Z" fill="#5F74BE" />
		<path d="M122.952 78.7652H130.865V86.6795H122.952V78.7652Z" fill="#CFD3DE" />
		<path d="M102.979 78.7652H111.034V86.6795H102.979V78.7652Z" fill="#5F74BE" />
		<path d="M93.1348 88.6111H101.048V96.5242H93.1348V88.6111Z" fill="#CFD3DE" />
		<path d="M122.952 88.6111H130.865V96.5242H122.952V88.6111Z" fill="#CFD3DE" />
		<path d="M102.979 88.6111H111.034V96.5242H102.979V88.6111Z" fill="#CFD3DE" />
		<path d="M112.966 68.9205H121.021V76.8336H112.966V68.9205Z" fill="#5F74BE" />
		<path d="M112.966 78.7652H121.021V86.6795H112.966V78.7652Z" fill="#5F74BE" />
		<path d="M112.966 88.6111H121.021V96.5242H112.966V88.6111Z" fill="#CFD3DE" />
		<path
			d="M131.767 46.2877V56.5895C131.767 57.3004 131.19 57.8773 130.479 57.8773H126.616C125.905 57.8773 125.328 57.3004 125.328 56.5895V46.2877C125.328 45.5769 125.905 45 126.616 45H130.479C131.19 45 131.767 45.5769 131.767 46.2877Z"
			fill="#939BAB"
		/>
		<path
			d="M95.5815 57.8773H93.5211C92.8103 57.8773 92.2334 57.3004 92.2334 56.5895V46.2877C92.2334 45.5769 92.8103 45 93.5211 45H94.2938V56.5895C94.2938 57.3004 94.8707 57.8773 95.5815 57.8773Z"
			fill="#939BAB"
		/>
		<path
			d="M128.676 57.8773H126.616C125.905 57.8773 125.328 57.3004 125.328 56.5895V46.2877C125.328 45.5769 125.905 45 126.616 45H127.388V56.5895C127.388 57.3004 127.965 57.8773 128.676 57.8773Z"
			fill="#939BAB"
		/>
		<g filter="url(#filter1_d)">
			<path d="M100.5 107.25L112 86.5833L122.75 107.25H100.5Z" fill="white" />
			<path
				d="M101.96 109H122.04C124.093 109 125.373 106.773 124.347 105L114.307 87.6533C113.28 85.8799 110.72 85.8799 109.693 87.6533L99.6533 105C98.6267 106.773 99.9067 109 101.96 109ZM112 99.6666C111.267 99.6666 110.667 99.0666 110.667 98.3333V95.6666C110.667 94.9333 111.267 94.3333 112 94.3333C112.733 94.3333 113.333 94.9333 113.333 95.6666V98.3333C113.333 99.0666 112.733 99.6666 112 99.6666ZM113.333 105H110.667V102.333H113.333V105Z"
				fill="#EB7D68"
			/>
		</g>
		<defs>
			<filter
				id="filter0_i"
				x="38"
				y="0"
				width="148"
				height="153"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="5" />
				<feGaussianBlur stdDeviation="7" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.0972195 0 0 0 0 0.116039 0 0 0 0 0.207909 0 0 0 0.0927939 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
			</filter>
			<filter
				id="filter1_d"
				x="76"
				y="69"
				width="72"
				height="72"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="8" />
				<feGaussianBlur stdDeviation="10" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.155882 0 0 0 0 0.205639 0 0 0 0 0.39589 0 0 0 0.102 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
		</defs>
	</svg>
);

export const NoSelectMetricts = () => (
	<svg width="404" height="220" viewBox="0 0 404 220" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="404" height="212" rx="8" fill="#F7F8FA" />
		<path
			d="M43.7285 75.245L19.6594 121.777V192.279H384.341V110.52L351.699 116.884C349.172 117.376 346.573 117.376 344.045 116.884L311.404 110.52L285.211 106.69C278.77 105.749 272.272 108.001 267.796 112.728L249.519 132.028C243.065 138.844 232.739 140.254 224.693 135.418L203.919 122.93C202.644 122.164 201.458 121.257 200.383 120.227L174.746 95.6534C169.126 90.2674 160.893 88.6307 153.641 91.4582L136.329 98.2081C131.657 100.03 126.471 100.03 121.798 98.2081L96.381 88.2979C93.8829 87.3239 91.6062 85.8575 89.6861 83.9859L75.4529 70.112C65.8365 60.7385 49.8982 63.3173 43.7285 75.245Z"
			fill="url(#paint0_linear123)"
		/>
		<path
			d="M19.6594 121.227L43.7441 75.028C49.9246 63.1728 65.7726 60.6028 75.3828 69.8973L89.6846 83.7293C91.6058 85.5873 93.8799 87.0415 96.3726 88.0058L121.848 97.8611C126.491 99.6573 131.637 99.6573 136.28 97.8611L153.715 91.1163C160.93 88.325 169.108 89.9452 174.713 95.2766L200.381 119.687C201.457 120.71 202.644 121.611 203.919 122.372L224.769 134.806C232.783 139.586 243.038 138.189 249.481 131.438L267.811 112.233C272.281 107.55 278.742 105.32 285.15 106.249L311.404 110.058L344.074 116.377C346.583 116.863 349.162 116.863 351.671 116.377L384.341 110.058"
			stroke="#939BAB"
			strokeWidth="2"
		/>
		<ellipse cx="384.341" cy="109.451" rx="11.7956" ry="11.8326" fill="#939BAB" fillOpacity="0.1" />
		<ellipse cx="384.341" cy="109.451" rx="3.93187" ry="3.94419" fill="#939BAB" />
		<rect x="27.5231" y="19.7211" width="62.91" height="5.91628" rx="1" fill="#CFD3DE" />
		<rect x="27.5231" y="29.5815" width="33.4209" height="5.91628" rx="1" fill="#F1F3F6" />
		<rect x="122.871" y="19.7211" width="62.91" height="5.91628" rx="1" fill="#CFD3DE" />
		<rect x="122.871" y="29.5815" width="33.4209" height="5.91628" rx="1" fill="#F1F3F6" />
		<rect x="218.219" y="19.7211" width="62.91" height="5.91628" rx="1" fill="#CFD3DE" />
		<rect x="218.219" y="29.5815" width="33.4209" height="5.91628" rx="1" fill="#F1F3F6" />
		<rect x="313.567" y="19.7211" width="62.91" height="5.91628" rx="1" fill="#CFD3DE" />
		<rect x="313.567" y="29.5815" width="33.4209" height="5.91628" rx="1" fill="#F1F3F6" />
		<rect y="125.228" width="404" height="86.7721" rx="6" fill="url(#paint1_linear123)" />
		<g filter="url(#filter0_d123)">
			<rect x="40" y="118" width="88" height="74" rx="8" fill="white" />
			<rect x="40.5" y="118.5" width="87" height="73" rx="7.5" stroke="#F7F8FA" />
		</g>
		<rect x="52" y="162" width="64" height="6" rx="1" fill="#CFD3DE" />
		<rect x="52" y="172" width="34" height="6" rx="1" fill="#F1F3F6" />
		<path
			d="M64 138C61.79 138 60 139.79 60 142C60 144.21 61.79 146 64 146C66.21 146 68 144.21 68 142C68 139.79 66.21 138 64 138ZM72.94 141C72.48 136.83 69.17 133.52 65 133.06V131H63V133.06C58.83 133.52 55.52 136.83 55.06 141H53V143H55.06C55.52 147.17 58.83 150.48 63 150.94V153H65V150.94C69.17 150.48 72.48 147.17 72.94 143H75V141H72.94ZM64 149C60.13 149 57 145.87 57 142C57 138.13 60.13 135 64 135C67.87 135 71 138.13 71 142C71 145.87 67.87 149 64 149Z"
			fill="#939BAB"
		/>
		<defs>
			<filter
				id="filter0_d123"
				x="20"
				y="106"
				width="128"
				height="114"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="8" />
				<feGaussianBlur stdDeviation="10" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.155882 0 0 0 0 0.205639 0 0 0 0 0.39589 0 0 0 0.102 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<linearGradient id="paint0_linear123" x1="202" y1="61.9388" x2="202" y2="192.279" gradientUnits="userSpaceOnUse">
				<stop stopColor="#939BAB" stopOpacity="0.2" />
				<stop offset="1" stopColor="#939BAB" stopOpacity="0" />
			</linearGradient>
			<linearGradient id="paint1_linear123" x1="202" y1="125.228" x2="202" y2="212" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="1" stopColor="white" />
			</linearGradient>
		</defs>
	</svg>
);
