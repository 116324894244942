import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import Routers from "./app/routers/routers";
import store from "./app/store";
import "style/scss/index.scss";

const theme = createMuiTheme({
	typography: {
		fontSize: 16,
		fontFamily: "Rubik, sans-serif",
		h1: {
			fontSize: 38,
			fontWeight: 500
		},
		h2: {
			fontSize: 30,
			fontWeight: 500
		},
		h3: {
			fontSize: 24,
			fontWeight: 500
		},
		h4: {
			fontSize: 20,
			fontWeight: 500
		},
		body1: {
			fontSize: 16,
			fontFamily: "Rubik, sans-serif",
			color: "#66728d"
		},
		button: {
			fontFamily: "Rubik, sans-serif"
		}
	},
	props: {
		MuiButtonBase: {
			disableRipple: true
		}
	},
	overrides: {
		MuiTypography: {
			gutterBottom: {
				marginBottom: "0.8em"
			}
		}
	}
});

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<ToastContainer />
			<Routers></Routers>
		</ThemeProvider>
	</Provider>,
	document.getElementById("root")
);
