import React from "react";
import { PauseCampaignIcon } from "../../../../../icons";
import ConfirmModal from "../../../../../components/modal/confirm-modal.jsx";

const PauseCampaign = ({ onClose, name, open, onAgree, loading }) => {
	return (
		<div>
			<ConfirmModal
				open={open}
				title="Pause campaign?"
				icon={<PauseCampaignIcon />}
				loading={loading}
				onClose={onClose}
				onAgree={onAgree}
				text={
					<>
						Are you sure that you want to pause this campaign: <br/>
						<strong>{name}</strong>?
					</>
				}
				textAgree="Yes, pause"
				textClose="No, decline"
			/>
		</div>
	);
};

export default PauseCampaign;
