import types from "./types";

const actions = {
	getReport: params => ({
		type: types.FETCH_GET_REPORT_REQUEST,
		params
	}),
	getReportSuccess: data => ({
		type: types.FETCH_GET_REPORT_SUCCESS,
		data
	}),
	getReportFailure: () => ({
		type: types.FETCH_GET_REPORT_FAILURE
	}),

	getDefaultReports: (params,integration_type) => ({
		type: types.FETCH_GET_DEFAULT_REPORT_REQUEST,
		params,
		integration_type
	}),
	getDefaultReportsSuccess: () => ({
		type: types.FETCH_GET_DEFAULT_REPORT_SUCCESS
	}),
	getDefaultReportsFailure: () => ({
		type: types.FETCH_GET_DEFAULT_REPORT_FAILURE
	}),

	fetchFilter: data => ({
		type: types.FETCH_FILTER_REPORTS_REQUEST,
		data
	}),
	fetchFilterSuccess: data => ({
		type: types.FETCH_FILTER_REPORTS_SUCCESS,
		data
	}),

	getCampaignByAdAccount: params => ({
		type: types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_REQUEST,
		params
	}),
	getCampaignByAdAccountSuccess: data => ({
		type: types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_SUCCESS,
		data
	}),
	getCampaignByAdAccountFailure: data => ({
		type: types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_FAILURE,
		data
	})
};
export default actions;
