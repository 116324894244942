import React from "react";
import style from "./style.module.scss";
import classNames from "classnames/bind";

const HeaderLight = props => {
	const cx = classNames.bind(style);
	return <div className={cx("wrapper", props.className, { gray: props.gray })}>{props.children}</div>;
};

export default HeaderLight;
