import types from "./types";
import config from "app/config/config";
import { mappedOffset } from "../helper";

export const initialState = {
	users: {
		count: 0,
		items: []
	},
	user: {},
	dataUser: {
		count: 0,
		items: []
	},
	userIntegraions:{

	},
	error: "",
	isLoading: false,
	deleteLoading: false,

	actionLoading: false,

	dataUserLoading: false,

	statistic: {},

	filter: {
		sort_by: "updated_at",
		sort_direction: "desc"
	},
	dataUserFilter: {
		report: {},
		audiences: {},
		audiencesAccount: {
			sort_by: "order_number",
			sort_direction: "asc"
		},
		creatives: {
			sort_by: "updated_at",
			sort_direction: "desc"
		},
		campaigns: {}
	},

	pagination: {
		total: 0,
		limit: config.ui.pagination.size,
		page: 1,
		offset: null
	},

	dataUserPagination: {
		total: 0,
		limit: config.ui.pagination.size,
		page: 1,
		offset: null
	},

	isLoadingAction: {

	},
	errorsAction: {

	}
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.GET_USERS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: ""
			};
		case types.GET_USERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: "",
				users: action.data,
				pagination: { ...state.pagination, total: action?.data?.count || 0 }
			};
		case types.GET_USERS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error
			};


		case types.GET_USER_INTEGRAIONS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: ""
			};
		case types.GET_USER_INTEGRAIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: "",
				userIntegraions: action.data,
			};
		case types.GET_USER_INTEGRAIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error
			};



		case types.GET_USER_REQUEST:
			return {
				...state,
				profileLoading: true
			};
		case types.GET_USER_SUCCESS:
			return {
				...state,
				profileLoading: false,
				user: action.data
			};
		case types.GET_USER_FAILURE:
			return {
				...state,
				profileLoading: false
			};

		case types.GET_USER_DATA_REQUEST:
			return {
				...state,
				dataUserLoading: true,
				error: ""
			};
		case types.GET_USER_DATA_SUCCESS:
			return {
				...state,
				dataUser: action.data,
				dataUserLoading: false,
				dataUserPagination: { ...state.dataUserPagination, total: action?.data?.count || 0 }
			};
		case types.GET_USER_DATA_FAILURE:
			return {
				...state,
				dataUserLoading: false
			};

		case types.DELETE_USERS_REQUEST:
			return {
				...state,
				deleteLoading: true
			};
		case types.DELETE_USERS_SUCCESS:
			let newPagination = state.pagination;

			if (action.data >= state.users.items.length) {
				newPagination.page = 1;
				newPagination.offset = null;
			}
			return {
				...state,
				pagination: newPagination,
				deleteLoading: false
			};
		case types.DELETE_USERS_FAILURE:
			return {
				...state,
				deleteLoading: false
			};

		case types.FETCH_NEW_USER_REQUEST:
			return {
				...state,
				actionLoading: true
			};
		case types.FETCH_NEW_USER_SUCCESS:
			return {
				...state,
				actionLoading: false
			};
		case types.FETCH_NEW_USER_FAILURE:
			return {
				...state,
				actionErrors: action.error,
				actionLoading: false
			};

		case types.FETCH_RESET_PASSWORD_USER_REQUEST:
		case types.FETCH_EDIT_USER_REQUEST:
			return {
				...state,
				actionLoading: true
			};
		case types.FETCH_RESET_PASSWORD_USER_SUCCESS:
		case types.FETCH_EDIT_USER_SUCCESS:
			return {
				...state,
				actionLoading: false
			};
		case types.FETCH_RESET_PASSWORD_USER_FAILURE:
		case types.FETCH_EDIT_USER_FAILURE:
			return {
				...state,
				actionErrors: action.error,
				actionLoading: false
			};

		case types.FETCH_USER_SHARE_AUDIENCE_REQUEST:
		case types.FETCH_USER_SHARE_CREATIVE_REQUEST:
			return {
				...state,
				isLoadingAction: {...state.isLoadingAction,[action.actionName]: true},
				errorsAction: {...state.errorsAction, [action.actionName]: ''}
			};
		case types.FETCH_USER_SHARE_AUDIENCE_SUCCESS:
		case types.FETCH_USER_SHARE_CREATIVE_SUCCESS:
			return {
				...state,
				isLoadingAction: {...state.isLoadingAction,[action.data.actionName]: false},
				errorsAction: {...state.errorsAction, [action.data.actionName]: ''}
			};
		case types.FETCH_USER_SHARE_AUDIENCE_FAILURE:
		case types.FETCH_USER_SHARE_CREATIVE_FAILURE:
			return {
				...state,
				isLoadingAction: {...state.isLoadingAction,[action.data.actionName]: false},
				errorsAction: {...state.errorsAction, [action.data.actionName]: action.data.errors}
			};


		case types.GET_USERS_STATISTIC_SUCCESS:
			return {
				...state,
				statistic: action.data || {}
			};
		case types.FETCH_PAGINATION_USERS_SUCCESS:
			let offset = mappedOffset(action.data, state.pagination);
			return {
				...state,
				pagination: { ...state.pagination, ...action.data, offset }
			};
		case types.FETCH_PAGINATION_USER_SUCCESS:
			let offsets = mappedOffset(action.data, state.dataUserPagination);
			return {
				...state,
				dataUserPagination: { ...state.dataUserPagination, ...action.data, offset: offsets }
			};
		case types.FETCH_FILTER_USERS_SUCCESS:
			return {
				...state,
				pagination: { ...state.pagination, limit: initialState.pagination.limit, page: 1, offset: null },
				filter: { ...state.filter, ...action.data }
			};
		case types.FETCH_FILTER_USER_SUCCESS:
			return {
				...state,
				dataUserPagination: { ...state.dataUserPagination, limit: initialState.pagination.limit, page: 1, offset: null },
				dataUserFilter: {
					...state.dataUserFilter,
					[action.actionName]: { ...state.dataUserFilter[action.actionName], ...action.data }
				}
			};
		case types.RESET_USERS_TABLE_PARAMS: {
			return {
				...state,
				pagination: initialState.pagination,
				filter: initialState.filter
			};
		}
		case types.RESET_USER_TABLE_DATA_PARAMS: {
			return {
				...state,
				dataUserLoading: initialState.dataUserLoading,
				dataUserPagination: initialState.dataUserPagination,
				dataUserFilter: initialState.dataUserFilter,
				dataUser: initialState.dataUser
			};
		}
		default:
			return state;
	}
};

export default reducer;