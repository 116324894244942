import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import style from "./style.module.scss";
import { UploadIcon } from "app/icons";
import Error from "app/uikit/error";
import { getAccept, getValidator, getFiles, getRejectionsFiles, getTextFiles } from "./helper";
import { InputLabel, FormControl } from "app/uikit/form-components";
import { FormHelperText } from "@material-ui/core";
import Alert from "app/uikit/alert";
import cl from "classnames";

function DropZone({
	onChange,
	onRemoveFile,
	initialValue,
	name,
	form,
	type,
	info,
	infoPlacement = "right",
	label,
	required,
	content,
	className,
	labelClassName = "",
	clear,
	clearErrors,
	validation,
	warning,
	size,
	labelContent,
	fullWidth,
	wrapperClassName,
	infoTextType,
	showInfoTextType,
	accept,
	acceptText,
	hideFile = false
}) {
	const [acceptedFiles, setAcceptedFiles] = useState((Array.isArray(initialValue) && initialValue) || []);
	const [rejectedFiles, setRejectedFiles] = useState([]);

	const removeAll = () => {
		setAcceptedFiles([]);
		setRejectedFiles([]);
		onRemoveFile && onRemoveFile();
	};

	const removeFiles = () => {
		setAcceptedFiles([]);
		onRemoveFile && onRemoveFile();
	};

	const removeRejectedFiles = () => {
		setRejectedFiles([]);
	};

	const onDrop = files => {
		if (files.length) {
			setAcceptedFiles([...files]);
			setRejectedFiles([]);
		}
	};
	const onDropRejected = files => {
		if (files.length) {
			setRejectedFiles([...files]);
			removeFiles();
		}
	};

	useEffect(() => {
		if (Array.isArray(initialValue) && initialValue) {
			setAcceptedFiles(initialValue);
		}
	}, [initialValue]);

	useEffect(() => {
		onChange && onChange(acceptedFiles);
		if (form) {
			form.setFieldValue(name, acceptedFiles);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles]);

	const dropZone = useDropzone({
		accept: accept || getAccept(type),
		onDrop,
		onDropRejected,
		multiple: false,
		validator: validation || getValidator(type, size, accept?.split(','), acceptText)
	});

	const { getRootProps, getInputProps } = dropZone;

	const files = getFiles(acceptedFiles, removeAll);

	const getTextTypeFiles = acceptText || getTextFiles(type);

	const fileRejectionItems = getRejectionsFiles(rejectedFiles);

	useEffect(() => {
		if (clear) {
			removeAll();
		}
	}, [clear]);

	useEffect(() => {
		if (clearErrors) {
			removeRejectedFiles();
		}
	}, [clearErrors]);

	return (
		<FormControl fullWidth={fullWidth} className={wrapperClassName} >
			<InputLabel
				htmlFor={name}
				name={name}
				info={info}
				labelContent={labelContent}
				infoPlacement={infoPlacement}
				required={required}
				label={label}
				className={labelClassName}
			/>
			<div>
				<div {...getRootProps({ className: cl(style.dropzone, className) })}>
					<input {...getInputProps()} />
					{content || (acceptedFiles.length && <p className={style.dropzone_inner}>File has been uploaded</p>) || content || (
						<>
							<p className={style.dropzone_inner}>
								<UploadIcon />
								<span className={cl("main-text", style.dropzone_inner__dark)}>Upload file</span>
								&nbsp;
								<span className={cl("small-text", style.dropzone_inner__light)}>{getTextTypeFiles}</span>
							</p>
							<span className="orange-link">Choose file</span>
						</>
					)}
				</div>
				<div className={`${style.dropzone_inputCaption}`}>
					{!hideFile && files}
					{fileRejectionItems}
				</div>
				{name && form && <Error error={fileRejectionItems.length ? false : form?.touched[name] && form?.errors[name]} />}
				{!(form?.touched[name] && form?.errors[name]) && warning && (
					<FormHelperText error={true} className={style.warning} component="div">
						{!(form?.touched[name] && form?.errors[name]) && warning && (
							<>
								<Alert type="warning" text={warning} />
							</>
						)}
					</FormHelperText>
				)}
			</div>
			{showInfoTextType && <p className="small-text upload-caption ">{infoTextType || "You can upload 1 file up to 5 mb"}</p>}
		</FormControl>
	);
}

export default DropZone;
