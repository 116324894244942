import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Modal from "app/components/modal";
import { useFormik } from "formik";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import Input from "app/uikit/input";
import { RefreshIcon } from "app/icons";
import { handlerErrors } from "app/helper/handlerErrors";
import action from "app/store/creatives/actions";
import style from "./style.module.scss";
import cl from "classnames/bind";

const UploadSteps = ({ open, onClose, errors, onChoose }) => {
	const { genereteContentError, genereteContent, isLoading } = useSelector(state => state.creatives);
	const [currentOption, setCurrentOption] = useState(0);
	const cs = cl.bind(style);
	const dispatch = useDispatch();

	const onGenerateContent = data => {
		dispatch(action.generateContent(data));
	};

	const formik = useFormik({
		initialValues: {
			product_name: "",
			description: "",
			interested: ""
		},
		validationSchema: validationSchema,
		onSubmit: onGenerateContent
	});

	useEffect(() => {
		setCurrentOption(0);
	}, [genereteContent]);

	useEffect(() => {
		if (open) {
			dispatch(action.resetGenerateContent());
		}
	}, [open]);
	
	useEffect(() => {
		handlerErrors(genereteContentError, formik.setErrors);
	}, [genereteContentError, formik.setErrors]);

	const handleSelected = () => {
		const element = genereteContent.find(elem => +elem?.index === +currentOption);
		onChoose && onChoose(element?.text);
	};

	const onChangeContent = e => {
		const value = e.target.value;
		setCurrentOption(+value);
	};

	const hasContent = !!genereteContent.length;

	return (
		<Modal
			size={hasContent && "auto"}
			resetForm={formik.resetForm}
			title="Generate a primary text for your creative"
			onClose={onClose}
			open={open}
		>
			<div className={cs("container", { container_big: hasContent })}>
				<div className={style.item}>
					<form className={style.form} onSubmit={formik.handleSubmit} noValidate>
						<div className={style.content}>
							<Input fullWidth name="product_name" label="Product name" form={formik} required />
							<Input fullWidth multiline name="description" label="Description" form={formik} rows={2} required />
							<Input fullWidth name="interested" label="For whom the ad is intended" form={formik} />
							<Error error={errors} />
						</div>
						<div className="modal-footer">
							<Button onClick={onClose} size="medium" colorVariant="ghost">
								Cancel
							</Button>
							{(!hasContent && (
								<Button type="submit" loading={isLoading.generatingContent} size="medium" colorVariant="gradient">
									Generate content
								</Button>
							)) || (
								<Button type="submit" loading={isLoading.generatingContent} size="medium" colorVariant="ghost">
									<RefreshIcon />
									&ensp;Refresh
								</Button>
							)}
						</div>
					</form>
				</div>
				{hasContent && (
					<div className={cl(style.item, style.item_option)}>
						<p className={style.title}>Your options</p>
						<div className={style.content}>
							<FormControl className="radio">
								<RadioGroup name="upload-sheet">
									{genereteContent?.map(el => (
										<FormControlLabel
											className={cs("radio", { radio_checked: currentOption === el.index })}
											key={el.index}
											value={el.index}
											onChange={onChangeContent}
											control={<Radio name="metod" checked={currentOption === +el.index} value={el.index} color="primary" />}
											label={el.text}
										/>
									))}
								</RadioGroup>
							</FormControl>
						</div>
						<div className="modal-footer modal-footer_end">
							<Button onClick={handleSelected} size="medium" colorVariant="gradient">
								Select
							</Button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default UploadSteps;