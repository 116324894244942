import types from "./types";

const actions = {
	fetchMatchUserAudience: (data, cb) => ({
		type: types.FETCH_MATCH_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "userAudience"
	}),
	fetchMatchCategoryAudience: (data, cb) => ({
		type: types.FETCH_MATCH_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "categoryAudience"
	}),
	fetchMatchAudience: (data, cb) => ({
		type: types.FETCH_MATCH_AUDIENCE_REQUEST,
		data,
		cb
	}),
	fetchMatchAudienceSuccess: data => ({
		type: types.FETCH_MATCH_AUDIENCE_SUCCESS,
		data
	}),
	fetchMatchAudienceFailure: error => ({
		type: types.FETCH_MATCH_AUDIENCE_FAILURE,
		error
	}),
	fetchCreateUserAudience: (data, cb) => ({
		type: types.FETCH_CREATE_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "userAudience"
	}),
	fetchCreateCategoryAudience: (data, cb) => ({
		type: types.FETCH_CREATE_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "categoryAudience"
	}),
	fetchCreateAudience: (data, cb) => ({
		type: types.FETCH_CREATE_AUDIENCE_REQUEST,
		data,
		cb
	}),
	fetchCreateAudienceSuccess: data => ({
		type: types.FETCH_CREATE_AUDIENCE_SUCCESS,
		data
	}),
	fetchCreateAudienceFailure: error => ({
		type: types.FETCH_CREATE_AUDIENCE_FAILURE,
		error
	}),

	resetDataAudiences: () => ({
		type: types.RESET_DATA_AUDIENCES
	}),
};
export default actions;
