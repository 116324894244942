import React from "react";
import style from "./style.module.scss";
import classNames from "classnames/bind";

const FooterLight = props => {
	const cx = classNames.bind(style);
	return <footer className={cx("footer", props.className)}>{props.children}</footer>;
};

export default FooterLight;
