import React from "react";
import ConfirmDelete from "../modal/confirm-modal.jsx";
import {ConfirmExit} from "app/icons";

const CustomPrompt = ({ message, cleanUp }) => {
	//   const parsedMessage = JSON.parse(message);
	// const parsedMessage = message;
	const onClose = () => cleanUp(false);
	const onAgree = () => cleanUp(true);

	return (
		<ConfirmDelete
			icon={<ConfirmExit />}
			title="Want to leave the page without saving data?"
			text={<>If you leave this page all changes will not be saved. <br/>
				Do you want to leave page?</>}
			open={true}
			onClose={onClose}
			onAgree={onAgree}
			textAgree="Yes, leave"
			textClose="No, stay"
		/>
	);
};

export default CustomPrompt;
