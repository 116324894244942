import { takeEvery, put, all, call } from "redux-saga/effects";
import services from "./services";
import types from "./types";
import actions from "./actions";
import toastify from "app/utils/toastify";
import {setInfo} from "app/helper";
import directory from "app/directory/errors";

const dir = directory.en;


function* getInfo() {
	try {
		const response = yield call(services.getInfo); 
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.getInfoSuccess(response?.data));
			setInfo(response?.data);
		}
	} catch (error) {
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.getInfoFailure(""));
	}
}

function* changeInfo({data}) {
	try {
		const response = yield call(services.changeInfo, data); 
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.changeSuccess(response?.data?.user));
			toastify.success(response?.data?.message);
			setInfo(response?.data?.user);
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			return yield put(actions.changeFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			return yield put(actions.changeFailure(error?.response?.data?.message));
		}
		toastify.error(error?.response?.data?.message || dir.serverNotWorking);
		yield put(actions.changeFailure(""));
	}
}


export default function* root() {
	yield all([takeEvery(types.GET_INFO_USER_REQUEST, getInfo)]);
	yield all([takeEvery(types.CHANGE_INFO_USER_REQUEST, changeInfo)]);
}