import React, { useEffect, useMemo } from "react";
import Modal from "app/components/modal";
import Button from "../../../../../uikit/button/active-button";
import ButtonActive from "../../../../../uikit/button/active-button";
import { useFormik } from "formik";
import validationSchema from "./validationSchema";
import { RelaunchIcon } from "../../../../../icons";
import Input from "../../../../../uikit/input";
import DataPicker from "../../../../../uikit/data-picker";
import { handlerErrors } from "app/helper/handlerErrors";
import moment from "moment";

const RelaunchUpdatesModal = ({ onSubmit, onClose, open, loading, errors, data, currency }) => {

	const isDalyBudget = data?.budget_type?.id === 2 || data?.budget_type_id?.id === 2;
	
	const initialValues = useMemo(()=>{
		let obj = {
			budget: data?.budget || "",
			start_datetime: data?.start_datetime || moment().format("YYYY-MM-DD")
		}
		if(!isDalyBudget){
			obj.end_datetime = data?.end_datetime || moment().add("days", 1).format("YYYY-MM-DD")
		}
		return obj;
	},[data]);
	
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema(isDalyBudget),
		onSubmit: onSubmit
	});

	const onChangeBudget = (value, name) => {
		if (value.match(/^(?=\d*[1-9])\d+$/) || value === "") {
			return formik.setFieldValue(name, +value);
		}
		return new Promise(rej => rej());
	};

	useEffect(() => {
		handlerErrors(errors, formik.setErrors);
	}, [errors, formik.setErrors]);

	return (
		<div>
			<Modal
				onClose={onClose}
				resetForm={formik.resetForm}
				open={open}
				footer
				buttonClose={
					<Button onClick={onClose} size="medium" colorVariant="ghost">
						Cancel
					</Button>
				}
				buttonAgree={
					<ButtonActive loading={loading} onClick={formik.submitForm} size="medium" colorVariant="gradient">
						Duplicate & Relaunch&nbsp;
						<RelaunchIcon />
					</ButtonActive>
				}
			>
				<div>
					<h2 className="title-subheading title-subheading_bold m-b--8">Relaunching updates</h2>
				</div>
				<form action="">
					<Input
						required
						fullWidth
						name="budget"
						label={data?.budget_type?.name || data?.budget_type_id?.name }
						currencyAdornment
						iconPosition="end"
						icon={currency || "USD"}
						form={formik}
						handleChange={onChangeBudget}
					/>
					<DataPicker fullWidth name="start_datetime" label="Start date" name="start_datetime" form={formik} />
					{!isDalyBudget && <DataPicker fullWidth label="End date" name="end_date" name="end_datetime" form={formik} />}
				</form>
			</Modal>
		</div>
	);
};

export default RelaunchUpdatesModal;
