import React, { useEffect, useState } from "react";
import Button from "app/uikit/button/active-button";
import SearchInput from "app/components/SearchField";
import style from "./style.module.scss";
import cl from "classnames";

const Toolbar = ({
	hideDelete,
	onChangeDelete,
	hideUnselect,
	onChangeUnselect,
	hideSearch,
	onChangeSearch,
	hideDownload,
	onChangeDownload,
	searchValue,
	componentsList,
	selected,
	elementList
}) => {
	const [valueSearch, setValueSearch] = useState("");

	useEffect(() => {
		setValueSearch(searchValue || "");
	}, [searchValue]);

	if (
		(!onChangeDelete || hideDelete) &&
		(!onChangeUnselect || hideUnselect) &&
		(!onChangeSearch || hideSearch) &&
		(!onChangeDownload || hideDownload)
	) {
		return null;
	}
	const onSearch = e => {
		const value = e.target.value;
		if (onChangeSearch) {
			onChangeSearch(value, e);
		}
		setValueSearch(value);
	};
	
	const isSelected = Object.values(selected || {})?.filter(elem=>elem);

	return (
		<div className={style.toolbar}>
			{!!isSelected?.length && <p className={cl(style.toolbar__item)}>
				{selected['id-all'] ? isSelected.length -1 :isSelected.length} selected
			</p>}
			
			{onChangeUnselect && !hideUnselect && (
				<div className={cl(style.toolbar__item, style.toolbar__item_order_1)}>
					<Button size="small" colorVariant="ghost" onClick={onChangeUnselect}>
						Unselect
					</Button>
				</div>
			)}
			{onChangeDelete && !hideDelete && (
				<div className={cl(style.toolbar__item, style.toolbar__item_order_2)}>
					<Button size="small" colorVariant="ghost" onClick={onChangeDelete}>
						Delete
					</Button>
				</div>
			)}
			{onChangeDownload && !hideDownload && (
				<div className={cl(style.toolbar__item, style.toolbar__item_order_3)}>
					<Button size="small" colorVariant="ghost" onClick={onChangeDownload}>
						Download
					</Button>
				</div>
			)}
			{onChangeSearch && !hideSearch && (
				<div className={cl(style.toolbar__item, style.search, style.toolbar__item_order_4)}>
					<SearchInput variant="table" onChange={onSearch} wrapperClassName={style.search__elem} value={valueSearch} />
				</div>
			)}
			{(Array.isArray(componentsList) &&
				componentsList.map((elem, i) => (
					<div
						className={cl(
							style.toolbar__item,
							style[`toolbar__item_order_${elem.props.order !== undefined ? elem.props.order : i + 5}`]
						)}
					>
						{elem}
					</div>
				))) ||
				null}
			{(elementList?.length &&
				elementList.map((elem, i) => {
					const { order, text, size, colorVariant, ...rest } = elem;
					return (
						<div key={i} className={cl(style.toolbar__item, style[`toolbar__item_order_${order !== undefined ? order : i + 5}`])}>
							<Button loading={elem.loading} size={size || "small"} colorVariant={colorVariant || "ghost"} {...rest}>
								{elem.text}
							</Button>
						</div>
					);
				})) ||
				null}
		</div>
	);
};

export default Toolbar;
