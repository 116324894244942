import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DropZone from "app/components/dropzone";
import { useFormik } from "formik";
import Button from "app/uikit/button/active-button";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import { getBase64Content } from "app/helper/helper.files";
import { handlerErrors } from "app/helper/handlerErrors";
import actionsUploadAudience from "app/store/uploadAuciences/actions";

const UploadFile = ({ onClose, changeStep, nextStep }) => {
	const { uploadingAudience, uploadAudienceError } = useSelector(state => state.uploadAuciences);
	const dispatch = useDispatch(); 

	const onNextStep = () => {
		changeStep(nextStep);
	};

    const handleSubmit = async values => {
		const value = {
			file: {
				name: values.file[0].name,
				content: await getBase64Content(values.file[0]),
				mime: values.file[0].type
			},
			fileData: values.file[0]
		};
		dispatch(actionsUploadAudience.fetchMatchCategoryAudience(value, onNextStep));
	};

	const formik = useFormik({
		initialValues: {
			file: []
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		handlerErrors(uploadAudienceError, formik.setErrors);
	}, [uploadAudienceError, formik.setErrors]);

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<DropZone
				fullWidth
				type="sheet"
				name="file"
				form={formik}
				label={
					<p className="upload-label main-text_bold">
                        Upload your file
					</p>
				}
				showInfoTextType={!formik.values.file?.length}
			/>
			<Error error={uploadAudienceError} />
			<div className="modal-footer">
				<Button onClick={onClose} size="medium" colorVariant="ghost">
					Cancel
				</Button>
				<Button type="submit" loading={uploadingAudience} size="medium" colorVariant="gradient">
					Match
				</Button>
			</div>
		</form>
	);
};

export default UploadFile;