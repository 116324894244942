import React, { useState, useLayoutEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import TableTabs from "app/components/table/tabs-table";

import ModalUploadAudience from "./components/modal-upload-audience";
import UserLayout from "app/components/includs/user-layout";
import PageTitle from "../../../components/page-title";
import MarkertplaceContainer from "./components/marketplace";
import MarkertplaceItem from "./components/marketplace/item";
import actions from "app/store/people/actions";
import actionsUploadAudiences from "app/store/uploadAuciences/actions";
import columns from "./columns";
import Button from "app/uikit/button";
import {ModalChoseAudience, ModalGenerateAudience} from "./components/modal-audiences";
import ToolbarHeader from "./components/ToolbarHeader";
import EmptyPlaceholder from "./components/emptyAudience";
import PATHS from "PATHS";

const ENUMSCREENS = {
	marketplace: 0,
	audiences: 1
};

const ScreenPeople = () => {
	const { isLoading, pagination, tableItems, filter } = useSelector(state => state.people);
	const history = useHistory();
	const [step, setStep] = useState(1);
	const [modalUploadOpen, setModalUpploadOpen] = useState(false);
	const [modalAudienceOpen, setModalAudienceOpen] = useState(false);
	const [modalGenerateAudienceOpen, setModalGenerateAudienceOpen] = useState(false);
	const [audienceData, setAudienceData] = useState({});
	const [currentTab, setCurrentTab] = useState(ENUMSCREENS[history.location.pathname.slice(1)] ?? 0);
	const dispatch = useDispatch();
	useLayoutEffect(() => {
		dispatch(actions.getAudiences());
		dispatch(actions.getMarketPlace());
		return () => {
			dispatch(actions.resetTableParams());
		};
	}, [dispatch]);

	const handleOpenModalUpload = useCallback(() => {
		setStep(1);
		setModalUpploadOpen(true);
		dispatch(actionsUploadAudiences.resetDataAudiences());
		dispatch(actions.resetDataModal());
	},[dispatch]);

	const handleCloseModalUpload = () => {
		setModalUpploadOpen(false);
	};

	const handleOpeModalAudience = () => {
		setModalAudienceOpen(true);
	};

	const handleCloseModalAudience = () => {
		setModalAudienceOpen(false);
	};

	const handleOpeModalGenerateAudience = () => {
		setModalGenerateAudienceOpen(true);
	};

	const handleCloseModalGenerateAudience = () => {
		setModalGenerateAudienceOpen(false);
	};

	const changeStep = steps => {
		if (!step) return;
		setStep(steps);
	};

	const onOpenAudience = useCallback(value => {
		setAudienceData(value);
		handleOpeModalAudience();
	}, []);

	const tabList = useMemo(()=>[
		{
			label: "Marketplace",
			name: "marketplace",
			path: PATHS.cabinet.marketplace,
			emptySearchText: (
				<>
					No categories have been found
					<br />
					by your request.
				</>
			),
			wrapComponent: <MarkertplaceContainer />,
			componentItem: <MarkertplaceItem onClick={onOpenAudience} />,
			settings: {
				toolbar: {
					delete: false,
					unSelect: false
				}
			},
			onRequest: () => {
				dispatch(actions.getMarketPlace());
			}
		},
		{
			label: "My audiences",
			name: "audiences",
			columns: columns,
			emptyData: <EmptyPlaceholder action={handleOpenModalUpload} />,
			path: PATHS.cabinet.audiences,
			emptySearchText: (
				<>
					No audiences have been found
					<br />
					by your request.
				</>
			),
			settings: {
				check:{
					showId: true
				},
				menu: {
					iconDelete: true
				},
				delete: {
					title: {
						one: "Are you sure you want to delete selected audience?",
						many: "Are you sure you want to delete selected audiences?"
					},
					text: {
						one: "Audience",
						many: "Audiences"
					}
				}
			},
			onRequest: () => {
				dispatch(actions.getAudiences());
			}
		}
	],[dispatch, handleOpenModalUpload, onOpenAudience]);

	return (
		<UserLayout>
			<PageTitle
				title="Audiences"
				subtitle="Lists of target audiences for your Facebook ad campaigns."
				showAddingContent
				subtitleAddingContent={currentTab === 0 && 	<Button
					size="medium"
					colorVariant="gradient"
					onClick={handleOpeModalGenerateAudience}
				>
					Generate audience
				</Button> || currentTab === 1 && <ToolbarHeader action={handleOpenModalUpload} />}
			/>
			<TableTabs
				tab={currentTab}
				setTab={setCurrentTab}
				action={actions}
				data={tableItems}
				pagination={pagination}
				filter={filter}
				loading={isLoading}
				tabsList={tabList}
			/>
			<ModalUploadAudience
				step={step}
				open={modalUploadOpen}
				onClose={handleCloseModalUpload}
				changeStep={changeStep}
			/>
			<ModalChoseAudience open={modalAudienceOpen} onClose={handleCloseModalAudience} data={audienceData} />
			<ModalGenerateAudience open={modalGenerateAudienceOpen} onClose={handleCloseModalGenerateAudience} />
		</UserLayout>
	);
};

export default ScreenPeople;