import React from "react";
import Modal from "../../components/modal";
import Button from "../../uikit/button/active-button";
import ButtonActive from "../../uikit/button/active-button";
import {DeleteCampaignIcon} from "app/icons";

const ConfirmDelete = ({ onClose, showCloseIcon, loadingSubAgree = false, onAgree, onSubAgree, open, text, loading,  icon, title, textAgree, textClose }) => {
	return (
		<Modal showCloseIcon={showCloseIcon} onClose={onClose} open={open}>
			<div className="text-centered">
				<div className="verification-icon m-y--16">{icon || <DeleteCampaignIcon />}</div>
				<h2 className="title-subheading title-subheading_bold m-b--8">{title || "Delete this item"}</h2>
				<p className="small-text">{text || "Are you sure you want to delete this"}</p>
					
				<div className="modal-footer modal-footer_centered">
					<Button onClick={onSubAgree || onClose} loading={loadingSubAgree} size="medium" colorVariant="ghost">
						{textClose || 'No, decline'} 
					</Button>
					<ButtonActive onClick={onAgree} loading={loading} type="submit" size="medium" colorVariant="gradient">
						{textAgree || 'Yes, delete'} 
					</ButtonActive>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmDelete;