import React, { useEffect, useState } from "react";
import { FormControl, InputLabel } from "../form-components";
import { Switch } from "@material-ui/core";
import useStyles from "./style";
import cl from "classnames";

const BaseSwitch = props => {
	const {
		active,
		onChange: handleChange,
		name,
		form,
		info,
		labelContent,
		infoPlacement,
		required,
		label,
		labelClassName,
		wrapperClassName,
		className,
		disabled,
		...rest
	} = props;
	const [isActive, setIsActive] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		const isActive = form && form.values[name];
		setIsActive(isActive ?? active);
	}, [active, form]);

	const onChange = e => {
		if (form) {
			const checked = e.target.checked;
			return form.setFieldValue(name,checked);
		}
		if (handleChange) {
			handleChange(e);
		}
		if (active === undefined) {
			setIsActive(!isActive);
		}
	};

	return (
		<FormControl className={cl(classes.wrapper, wrapperClassName)}>
			<InputLabel
				name={name}
				info={info}
				labelContent={labelContent}
				infoPlacement={infoPlacement}
				required={required}
				label={label}
				className={labelClassName}
			/>
			<Switch
				checked={isActive}
				onChange={onChange}
				className={cl("switch", className, { switch_disabled: disabled })}
				{...rest}
			/>
		</FormControl>
	);
};

export default BaseSwitch;
