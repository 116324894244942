import {formateDate} from "app/helper";

const columns = [
	{
		title: "Sheet name",
		dataIndex: "name",
		key: "name",
        render: value => <div className="text-lines text-lines--1">{value}</div>
	},
	{
		title: "Accounts",
		dataIndex: "accounts_count",
		key: "accounts_count",
		width: 150,
        render: value => value || 0
	},
	{
		title: "Created",
		dataIndex: "created_at",
		key: "created_at",
		width: 200,
        render: value => formateDate(value)
	}
];

export default columns;