import {
	makeStyles
} from "@material-ui/core/styles";

const useStyles = makeStyles({
	input: {
		border: "1px solid #E8EAEF",
		borderRadius: 6,
		minHeight: 50,
		transition: "0.2s",
		color: "#38465D",
		fontSize: "16px",
		width: props => (props.staticWidth ? "230px" : "auto"),
		"&.Mui-focused": {
			borderColor: "#CFD3DE",
			border: "1px solid #38465D"
		},
		"&.MuiInput-underline:before,&.MuiInput-underline:after": {
			content: "none"
		},
		"& .MuiSelect-nativeInput": {
			padding: 0
		},
		"& .MuiSelect-root": {
			padding: "12px 40px 12px 12px"
		},
		"& [data-info]": {
			display: 'none !important'
		}
	},
	error: {
		borderColor: "#EF5E39"
	},
	subHeader: {
		background: '#fff',
		fontSize: 16,
		fontWeight: 'bold',
		color: '#3E4555',
		pointerEvents: 'none !important'
	}
});

export default useStyles;