export default {
	FETCH_GET_CREATIVES_REQUEST: "FETCH_GET_CREATIVES_REQUEST",
	FETCH_GET_CREATIVES_SUCCESS: "FETCH_GET_CREATIVES_SUCCESS",
	FETCH_GET_CREATIVES_FAILURE: "FETCH_GET_CREATIVES_FAILURE",

	FETCH_GET_TEMPLATES_REQUEST: "FETCH_GET_TEMPLATES_REQUEST",
	FETCH_GET_TEMPLATES_SUCCESS: "FETCH_GET_TEMPLATES_SUCCESS",
	FETCH_GET_TEMPLATES_FAILURE: "FETCH_GET_TEMPLATES_FAILURE",

	FETCH_GET_CREATIVE_REQUEST: "FETCH_GET_CREATIVE_REQUEST",
	FETCH_GET_CREATIVE_SUCCESS: "FETCH_GET_CREATIVE_SUCCESS",
	FETCH_GET_CREATIVE_FAILURE: "FETCH_GET_CREATIVE_FAILURE",

	FETCH_CREATE_CREATIVE_REQUEST: "FETCH_CREATE_CREATIVE_REQUEST",
	FETCH_CREATE_CREATIVE_SUCCESS: "FETCH_CREATE_CREATIVE_SUCCESS",
	FETCH_CREATE_CREATIVE_FAILURE: "FETCH_CREATE_CREATIVE_FAILURE",

	FETCH_GENERATE_CONTENT_CREATIVE_REQUEST: "FETCH_GENERATE_CONTENT_CREATIVE_REQUEST",
	FETCH_GENERATE_CONTENT_CREATIVE_SUCCESS: "FETCH_GENERATE_CONTENT_CREATIVE_SUCCESS",
	FETCH_GENERATE_CONTENT_CREATIVE_FAILURE: "FETCH_GENERATE_CONTENT_CREATIVE_FAILURE",

	RESET_GENERATE_CONTENT: "RESET_GENERATE_CONTENT",

	FETCH_PUBLIC_CREATIVE_REQUEST: "FETCH_PUBLIC_CREATIVE_REQUEST",
	FETCH_PUBLIC_CREATIVE_SUCCESS: "FETCH_PUBLIC_CREATIVE_SUCCESS",
	FETCH_PUBLIC_CREATIVE_FAILURE: "FETCH_PUBLIC_CREATIVE_FAILURE",

	FETCH_COPY_CREATIVES_REQUEST: "FETCH_COPY_CREATIVES_REQUEST",
	FETCH_COPY_CREATIVES_SUCCESS: "FETCH_COPY_CREATIVES_SUCCESS",
	FETCH_COPY_CREATIVES_FAILURE: "FETCH_COPY_CREATIVES_FAILURE",

	FETCH_DELETE_CREATIVE_REQUEST: "FETCH_DELETE_CREATIVE_REQUEST",
	FETCH_DELETE_CREATIVE_SUCCESS: "FETCH_DELETE_CREATIVE_SUCCESS",
	FETCH_DELETE_CREATIVE_FAILURE: "FETCH_DELETE_CREATIVE_FAILURE",

	FETCH_EDIT_CREATIVE_REQUEST: "FETCH_EDIT_CREATIVE_REQUEST",
	FETCH_EDIT_CREATIVE_SUCCESS: "FETCH_EDIT_CREATIVE_SUCCESS",
	FETCH_EDIT_CREATIVE_FAILURE: "FETCH_EDIT_CREATIVE_FAILURE",

	FETCH_PAGINATION_CREATIVE_REQUEST: "FETCH_PAGINATION_CREATIVE_REQUEST",
	FETCH_PAGINATION_CREATIVE_SUCCESS: "FETCH_PAGINATION_CREATIVE_SUCCESS",

	FETCH_FILTER_CREATIVE_REQUEST: "FETCH_FILTER_CREATIVE_REQUEST",
	FETCH_FILTER_CREATIVE_SUCCESS: "FETCH_FILTER_CREATIVE_SUCCESS",

	RESET_CREATIVES_TABLE_PARAMS: "RESET_CREATIVES_TABLE_PARAMS",
	RESET_CREATE_CREATIVES: "RESET_CREATE_CREATIVES"
};
