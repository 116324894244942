const columns = [
	{
		title: "Id",
		dataIndex: "id",
		key: "id",
		width: 50
	},
	{
		title: "Pic",
		dataIndex: "image",
		key: "image",
		width: 64,
		render: value => <div className="img-table-center"><img src={value.url} alt={value.name} /></div>
	},
	{
		title: "Category name",
		dataIndex: "name",
		key: "name",
		width: 200,
		render: value => <div title={value} className="text-lines text-lines--1">{value}</div>
	},
	{
		title: "Tags",
		dataIndex: "tags",
		key: "tags",
		render: value => {
			const mappedValues = value?.map(elem => elem.name).join(", ");
			return <div title={mappedValues} className="text-lines text-lines--1">{mappedValues}</div>
		}
	},
	{
		title: "",
		dataIndex: "is_visible",
		key: "is_visible",
		width: 200
	}
];

export default columns;
