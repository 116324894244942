import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	icon:{
		width: 18,
		height: 18,
		borderRadius: '50%',
		border: '1px solid #CFD3DE',
	},
	checkedIcon:{
		border: '6px solid #EB7D68',
	},
	iconDisabled:{
		borderColor: '#CFD3DE !important'
	}
});

export default useStyles;
