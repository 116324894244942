import types from "./types";

const actions = {
	getAudiences: params => ({
		type: types.GET_AUDIENCES_REQUEST,
		params,
		propertyName: "table",
		actionName: "audiences"
	}),
	getAudiencesSuccess: (data,actionName,propertyName) => ({
		type: types.GET_AUDIENCES_SUCCESS,
		propertyName,actionName,data
	}),
	getAudiencesFailure: (error,actionName,propertyName) => ({
		type: types.GET_AUDIENCES_FAILURE,
		propertyName,actionName,error
	}),

	getMarketPlace: params => ({
		type: types.GET_MARKETPLACE_REQUEST,
		params,
		propertyName: "table",
		actionName: "marketplace"
	}),
	getMarketPlaceSuccess: (data,actionName,propertyName) => ({
		type: types.GET_MARKETPLACE_SUCCESS,
		propertyName,actionName,data
	}),
	getMarketPlaceFailure: (error,actionName,propertyName) => ({
		type: types.GET_MARKETPLACE_FAILURE,
		propertyName,actionName,error
	}),


	fetchComposeAudience: (data, cb) => ({
		type: types.FETCH_COMPOSE_AUDIENCE_REQUEST,
		data,
		cb,
		actionName: "composeAudience"
	}),
	fetchComposeAudienceSuccess: (actionName, data) => ({
		type: types.FETCH_COMPOSE_AUDIENCE_SUCCESS,
		actionName,
		data
	}),
	fetchComposeAudienceFailure: (actionName, error) => ({
		type: types.FETCH_COMPOSE_AUDIENCE_FAILURE,
		actionName,
		error
	}),
	
	
	deleteItems: (ids, cb, actionName) => ({
		type: types.FETCH_DELETE_AUDIENCES_REQUEST,
		ids,
		cb,
		actionName: actionName,
		propertyName: 'delete'
	}),
	deleteItemsSuccess: (data,actionName,propertyName) => ({
		type: types.FETCH_DELETE_AUDIENCES_SUCCESS,
		data,actionName,propertyName
	}),
	deleteItemsFailure:(error,actionName,propertyName) => ({
		type: types.FETCH_DELETE_AUDIENCES_FAILURE,
		error,actionName,propertyName
	}),

	// fetchEditName: (id, data, cb) => ({
	// 	type: types.FETCH_EDIT_NAME_AUDIENCE_REQUEST,
	// 	id,
	// 	data,
	// 	cb
	// }),
	// fetchEditNameSuccess: data => ({
	// 	type: types.FETCH_EDIT_NAME_AUDIENCE_SUCCESS,
	// 	data
	// }),
	// fetchEditNameFailure: error => ({
	// 	type: types.FETCH_EDIT_NAME_AUDIENCE_FAILURE,
	// 	error
	// }),
	
	getTemplateAudieces: () => ({
		type: types.FETCH_GET_TEMPLATE_AUDIENCES_REQUEST
	}),

	fetchPagination: (data,actionName) => ({
		type: types.FETCH_PAGINATION_AUDIENCES_REQUEST,
		actionName, data
	}),

	fetchPaginationSuccess: (data,actionName) => ({
		type: types.FETCH_PAGINATION_AUDIENCES_SUCCESS,
		data,
		actionName
	}),

	fetchFilter: (data,actionName) => ({
		type: types.FETCH_FILTER_AUDIENCES_REQUEST,
		data,
		actionName
	}),
	fetchFilterSuccess: (data,actionName) => ({
		type: types.FETCH_FILTER_AUDIENCES_SUCCESS,
		data,actionName
	}),

	resetTableParams: () => ({
		type: types.RESET_AUDIENCES_TABLE_PARAMS
	}),
	resetDataModal: () => ({
		type: types.RESET_CONTACT_MODAL
	})
};
export default actions;
