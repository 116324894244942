import React from "react";
import EmptyPlaceholder from "app/components//empty-placeholder";
import Button from "app/uikit/button";
import DownloadTemplate from "../downloadTemplate";
import {EmptyPeople} from "app/icons";
import style from "./style.module.scss";

const EmptyAudience = ({ action }) => {
	return (
		<EmptyPlaceholder
            icon={<EmptyPeople />}
			title={
				<>
					You haven’t created a single spreadsheet
					<br />
					with target accounts.
				</>
			}
			content={
				<>
					<Button className={style.btn} onClick={action} size="medium" colorVariant="gradient">
						Upload new audience
					</Button>
					<DownloadTemplate />
				</>
			}
		/>
	);
};

export default EmptyAudience;

