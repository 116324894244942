import React, { useState, useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import cl from "classnames";

import Input from "app/uikit/input";
import Autocomplete from "app/uikit/autocomplete";
import DataPicker from "app/uikit/data-picker";
import { getListWithCategory } from "app/helper";
import serviceAudience from "app/store/people/services";
import Checkbox from "app/uikit/checkbox";
import Select from "app/uikit/select";
import useFormatedSelectList from "app/hooks/useFormatedSelectList";
import Alert from "app/uikit/alert";
import Button from "app/uikit/button/active-button";
import config from "app/config"

import { getErrorKeys } from "../../helper";

import ChooseBudget from "./choose-budget";
import validationSchema from "./validationSchema";
import style from "./style.module.scss";


const ageList = [...(new Array(config.maxAge - config.minAge + 1).fill(1).map((el,i)=>({value:i+config.minAge,text:`${i+config.minAge}`})))];

const formatedListConversionLocation = [{ value: "Website", text: "Website" }];

const StepTwo = ({ onSubmit, edit, showInitialError, currency, removeErrors, errors = {}, data }) => {
	const { campaign_budget_types, campaign_conversion_types, countries, facebook_standard_conversions, gender_types } = useSelector(
		state => state.handBooks
	);
	const [budgetType, setBudgetType] = useState(
		data?.budget_type_id || (campaign_budget_types && campaign_budget_types[0]?.id)
	);
	const statusId = data?.status && data?.status?.id;
	const canEditContData = statusId === 4 || !statusId;
	//убран кастомный выбор
	const formatedListConversionTypes = getListWithCategory(campaign_conversion_types && [campaign_conversion_types[0]], [
		facebook_standard_conversions
	]);
	const formatedListPixels = useFormatedSelectList(data?.adAccount?.pixels, elem => ({
		text: `${elem.name}(${elem.id})`,
		value: elem.id
	}));

	const genderList = useFormatedSelectList(gender_types, null, true);

	const isConversion = data?.target?.id === 2;

	const initialValues = useMemo(() => {
		let obj = {
			name: data?.name || "",
			budget_type_id: data.budget_type_id?.id || (campaign_budget_types && campaign_budget_types[0]?.id),
			audience: data?.audience || "",
			is_use_similar_audience: data?.is_use_similar_audience || false,
			budget: data?.budget || "",
			start_datetime: data?.start_datetime || moment().format("YYYY-MM-DD"),
			end_datetime: data?.end_datetime || moment().add("days", 1).format("YYYY-MM-DD"),
			countries: data?.countries || [],
			gender_type: data?.gender_type?.id || data?.gender_type || '',
			age_from: data?.age_from || 18,
			age_to: data?.age_to || 65,
		};
		if (isConversion) {
			obj.conversion_event_location = data?.conversion_event_location || formatedListConversionLocation[0].value || "";
			obj.conversion_id =
				data?.conversion_id ||
				(!edit && formatedListConversionTypes && formatedListConversionTypes[0]?.list[0]?.value) ||
				"";
			obj.conversion_type_id =
				data?.conversion_type_id || (!edit && campaign_conversion_types && campaign_conversion_types[0]?.id);
			obj.pixel_id = data?.pixel_id || (!edit && formatedListPixels && formatedListPixels[0]?.value) || "";
		}
		return obj;
	}, [data, isConversion]);

	const handleSubmit = values => {
		if (removeErrors) {
			removeErrors(Object.keys(values));
		}
		const initJson = JSON.stringify(initialValues);
		const valuesJson = JSON.stringify(values);
		if (edit && initJson === valuesJson) {
			return onSubmit({}, true);
		}
		let obj = { ...values, gender_type: values.gender_type || '' };
		obj.budget_type_id = campaign_budget_types.find(el => el.id === +obj.budget_type_id) || obj.budget_type_id;
		onSubmit(obj);
	};

	const formik = useFormik({
		initialValues,
		validateOnChange: false,
		validateOnBlur: false,
		initialErrors: {
			name: errors?.name,
			audience: errors?.audience,
			budget: errors?.budget,
			start_datetime: errors?.start_datetime,
			end_datetime: errors?.end_datetime,
			pixel_id: errors?.pixel_id,
			conversion_id: errors?.conversion_id,
			country: errors?.countries,
			genders: errors?.genders,
			age_from: errors?.age_from,
			age_to: errors?.age_to
		},
		validationSchema: validationSchema(canEditContData, +budgetType === 2, isConversion),
		onSubmit: handleSubmit
	});

	useEffect(() => {
		const dirtyFields = formik.status?.dirtyFields;
		if (dirtyFields && edit) {
			let obj = { ...formik.values, gender_type: formik.values.gender_type === null ? '' : formik.values.gender_type };
			obj.budget_type_id = campaign_budget_types.find(el => el.id === +obj.budget_type_id) || obj.budget_type_id;
			onSubmit(obj, false, true);
			let errorsKey = getErrorKeys(dirtyFields, errors);
			removeErrors(errorsKey);
		}
	}, [formik.values, formik.status?.dirtyFields]);

	const errorDate = () => {
		let errors = [];
		if ((formik.touched.start_datetime || edit || showInitialError) && Boolean(formik.errors.start_datetime)) {
			const splitStartDate = formik.errors.start_datetime.split("#{date}");

			if (splitStartDate.length <= 1) {
				errors.push(formik.errors.start_datetime);
			} else {
				errors.push(
					<>
						{splitStartDate[0]} <strong>"{moment(formik.values.start_datetime).format("DD MMMM YYYY")}"</strong>
						{splitStartDate[1]}
					</>
				);
			}
		}

		if ((formik.touched.end_datetime || edit || showInitialError) && Boolean(formik.errors.end_datetime)) {
			const splitEndDate = formik.errors.end_datetime.split("#{date}");
			if (splitEndDate.length <= 1) {
				errors.push(formik.errors.end_datetime);
			} else {
				errors.push(
					<>
						{splitEndDate[0]}
						<strong>"{moment(formik.values.end_datetime).format("DD MMMM YYYY")}"</strong>
						{splitEndDate[1]}
						{splitEndDate[2] && (
							<>
								<strong>"{moment(formik.values.start_datetime).format("DD MMMM YYYY")}"</strong> {splitEndDate[2]}
							</>
						)}
					</>
				);
			}
		}

		return errors;
	};

	const startDateIsAfter = moment(moment().format("YYYY-MM-DD")).isAfter(formik.values.start_datetime);

	const onChangeStartDate = async (e, value) => {
		await formik.setFieldValue("start_datetime", moment(value).format("YYYY-MM-DD"));
		if (moment(value).isAfter(formik.values.end_datetime)) {
			await formik.setFieldValue("end_datetime", moment(value).add("days", 1).format("YYYY-MM-DD"), true);
		}
		formik.setStatus({
			...formik.status,
			dirtyFields: {
				...formik.status?.dirtyFields,
				start_datetime: true
			}
		});
		await formik.validateField("start_datetime");
	};

	const onChangeBudget = (value, name) => {
		if (value.match(/^(?=\d*[1-9])\d+$/) || value === "") {
			return formik.setFieldValue(name, +value);
		}
		return new Promise(rej => rej());
	};

	const isDailyluBudeget = +formik.values.budget_type_id !== 2;

	const handleChangeConversionEvent = (value, name, _, eLement) => {
		const category = eLement.props["data-category"];
		formik.setFieldValue(name, value);
		formik.setFieldValue("conversion_type_id", category);
	};

	const onChangeMaxAge = async (value,name)=>{
		await formik.setFieldValue(name,value,true);
		await formik.validateField('age_from');
	}

	const onChangeMinAge = async(value,name)=>{
		await formik.setFieldValue(name,value,true);
		await formik.validateField('age_to');
	}

	return (
		<>
			<div className={style["step-three"]}>
				<p className="title-subheading title-subheading_bold m-b--4">Campaign details</p>
				<p className="text-main m-b--24">Define your campaign details.</p>
				<form onSubmit={formik.handleSubmit} noValidate>
					<Input
						validateField
						label="Campaigns name"
						name="name"
						form={formik}
						required
						fullWidth
						showError={edit || showInitialError}
					/>
					<Autocomplete
						required
						validateField
						fullWidth
						label="Target audience"
						name="audience"
						action={serviceAudience.getAudiences}
						form={formik}
						placeholder="Search target audience by it's name"
						showError={edit || showInitialError}
						noOptionsText="No audiences have been found by your request"
					/>
					<Checkbox
						name="is_use_similar_audience"
						form={formik}
						wrapperClassName="m-b--16"
						label="Launch a campaign with a lookalike audience"
						info="Don't forget to indicate countries, else the lookalike audience will be created for the USA"
					/>
					<Autocomplete
						fullWidth
						multiple
						label="Targeting countries"
						name="countries"
						options={countries || []}
						form={formik}
						placeholder="Search country by it's name"
						showError={edit || showInitialError}
						noOptionsText="No countries have been found by your request"
					/>
					<Select
						fullWidth
						hasAllSelect
						list={genderList}
						name="gender_type"
						label="Genders"
						showError={edit || showInitialError}
						form={formik}
					/>
					<div className={style.block}>
						<div className={style.block__item}>
							<Select
								fullWidth
								hasAllSelect
								list={ageList}
								name="age_from"
								label="Minimum age"
								showError={edit || showInitialError}
								form={formik}
								handleChange={onChangeMinAge}
							/>
						</div>
						<div className={style.block__item}>
							<Select
								fullWidth
								hasAllSelect
								list={ageList}
								name="age_to"
								label="Maximum age"
								showError={edit || showInitialError}
								form={formik}
								handleChange={onChangeMaxAge}
							/>
						</div>
					</div>
					<ChooseBudget
						disabled={canEditContData}
						name="budget_type_id"
						setBudgetType={setBudgetType}
						removeErrors={removeErrors}
						form={formik}
						list={campaign_budget_types}
						info={
							<>
								A <span className="color--orange">Daily budget</span> is the average you'll spend every day (this works
								indefinitely as long as your Campaign is active). <br /> A{" "}
								<span className="color--orange">Lifetime budget</span> is the maximum you'll spend during the lifetime of your
								Campaign (between "Start date" and "End date").'
							</>
						}
					/>
					<Input
						currencyAdornment
						validateField
						required
						fullWidth
						name="budget"
						label="Budget"
						form={formik}
						iconPosition="end"
						icon={currency || "USD"}
						showError={edit || showInitialError}
						handleChange={onChangeBudget}
					/>
					<div className={cl(style.dateInputs, !isDailyluBudeget && style.dateInputs100)}>
						<div className={style.dateInputs__item}>
							<DataPicker
								validateField
								fullWidth
								disabled={!canEditContData}
								minDate={(canEditContData && formik.values.start_datetime) || ""}
								minDateMessage=" "
								InputAdornmentProps={{
									readOnly: true
								}}
								label="Start date"
								name="start_datetime"
								value={formik.values.start_datetime}
								onChange={onChangeStartDate}
								error={(formik.touched.start_datetime || edit) && Boolean(formik.errors.start_datetime)}
							/>
						</div>

						{(isDailyluBudeget && (
							<div className={style.dateInputs__item}>
								<DataPicker
									validateField
									fullWidth
									minDateMessage=" "
									InputAdornmentProps={{
										readOnly: true
									}}
									label="End date"
									name="end_datetime"
									value={formik.values.end_datetime}
									minDate={!startDateIsAfter && formik.values.start_datetime}
									onChange={async value => {
										await formik.setFieldValue("end_datetime", moment(value).format("YYYY-MM-DD"));
										await formik.validateField("end_datetime");
										formik.setStatus({
											...formik.status,
											dirtyFields: {
												...formik.status?.dirtyFields,
												end_datetime: true
											}
										});
									}}
									error={(formik.touched.end_datetime || edit) && Boolean(formik.errors.end_datetime)}
								/>
							</div>
						)) ||
							null}
					</div>
					<Alert type="error" text={errorDate()} />
					{(isConversion && (
						<>
							<Select
								required
								fullWidth
								validateField
								disabled={!canEditContData}
								list={formatedListConversionLocation}
								name="conversion_event_location"
								label="Conversion event location"
								showError={edit || showInitialError}
								form={formik}
							/>
							<Select
								required
								validateField
								fullWidth
								disabled={!canEditContData}
								showError={edit || showInitialError}
								list={formatedListPixels}
								name="pixel_id"
								label="Pixel"
								form={formik}
							/>
							<Select
								required
								fullWidth
								validateField
								name="conversion_id"
								label="Conversion event"
								form={formik}
								disabled={!canEditContData}
								showError={edit || showInitialError}
								list={formatedListConversionTypes}
								handleChange={handleChangeConversionEvent}
							/>
						</>
					)) ||
						null}
				</form>
			</div>
			<div className="campaign-footer">
				<Button onClick={formik.handleSubmit} size="medium" colorVariant="gradient">
					Next Step
				</Button>
			</div>
		</>
	);
};

export default StepTwo;
