import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import actions from "app/store/campaign/actions";
import StepThreeBase from "../../../steps/step-3";

const StepThree = ({ data, onChangeStep, nextStep, onChangeDate, ...rest  }) => {
	const dispatch = useDispatch();
	const {
		creatives: { items, params },
		creativesLoading,
	} = useSelector(state => state.campaigns);

	const {creative} =  data;
	
	useLayoutEffect(() => {
		dispatch(actions.getTempaltes(params));
	}, [dispatch]);

	const onChangeFilter = value => {
		dispatch(actions.getTempaltes({ ...params, ...value }));
	};

	const onSubmit = ({creative}, shouldNotUpdate, shouldNotoNextStep) => {
		if(creative){
			if(!shouldNotUpdate){
				const findCreative = items.find(el=>el.id === +creative);
				onChangeDate({ creative: findCreative || creative});		
			}
		}
		if(!shouldNotoNextStep){
			onChangeStep(nextStep);
		}
	};

	return (
		<StepThreeBase
			edit
			onSubmit={onSubmit}
			creatives={items}
			loading={creativesLoading}
			onChangeFilter={onChangeFilter}
			currentCreatives={creative}
			params={params}
			{...rest}
		/>
	);
};

export default StepThree;