import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Header from "app/uikit/table/header";
import Footer from "app/uikit/table/footer";
import Body from "./body";
import CircularProgress from "app/uikit/CircularProgress";
import Tabs from "app/components/tabs/Tabs";
import TabPanel from "app/components/tabs/TabPanel";
import ConfirmDelete from "app/components/modal/confirm-modal";
import Toolbar from "app/uikit/table/toolbar";
import { getTableItemsById } from "app/helper";
import { DeleteEmptyCreativeIcon } from "app/icons";
import ModalPreviewCreative from "../preview-modal";
import { CREATIVE_ACTION_MAP } from "app/emun";
import actions from "app/store/creatives/actions";
import PATHS from "PATHS";

const TableCreative = ({
	mounted,
	actionName,
	pagination,
	tabsList,
	currentTab,
	handleChangeTabs,
	data,
	dataTemplate,
	platform,
	filter,
	loading,
	isAdmin
}) => {
	const [previewItem, setPreviewItem] = useState({});
	const [openPreview, setOpenPreview] = useState(false);
	const [currentDeleteItems, setCurrentDeleteItems] = useState([]);
	const [checked, setChecked] = useState({});
	const [deleteModal, setDeleteModal] = useState(false);
	const { creative_template_types } = useSelector(state => state.handBooks);

	const dispatch = useDispatch();
	const history = useHistory();
	const onChangeUnselect = () => {
		setChecked({});
	};
	const onChangeDelete = () => {
		const currentSelectedItems = Object.keys(checked).filter(elem => checked[elem]);
		if(!currentSelectedItems.length) return;
		setCurrentDeleteItems(currentSelectedItems);
		handleOpenDeleteModal();
	};
	const onChangeSearch = value => {
		dispatch(actions.fetchFilter(actionName, { "filter[search]": value }));
	};

	const paginationChange = value => {
		dispatch(actions.fetchPagination(actionName, value));
	};
	const onChangeChecked = value => {
		setChecked(state => ({ ...state, ...value }));
	};
	const onDelete = id => {
		setCurrentDeleteItems([id]);
		handleOpenDeleteModal();
	};
	const onEdit = id => {
		toTemplate(id);
	};
	const onPreview = data => {
		setPreviewItem(data);
		openPreviewModal();
	};
	const openPreviewModal = () => {
		setOpenPreview(true);
	};

	const closePreviewModal = () => {
		setOpenPreview(false);
	};
	const handleCloseDeleteModal = () => {
		setDeleteModal(false);
		onChangeUnselect();
	};

	const handleOpenDeleteModal = () => {
		setDeleteModal(true);
	};
	const onDeleteAgree = () => {
		dispatch(actions.deleteItems(actionName, currentDeleteItems.join(","), handleCloseDeleteModal));
	};
	const onPublicCteative = (id, isPublic,cb) => {
		dispatch(actions.publicCreative(id, { is_public: isPublic }, cb));
	};
	const onCopyCreative = (id, cb) => {
		dispatch(actions.copyTemplate(String(id), cb));
	};
	const onCopyCreativeMultiple = () => {
		const selectedItems = Object.keys(checked);
		if(!selectedItems.length) return;
		dispatch(actions.copyTemplate(selectedItems.join(","), onChangeUnselect));
	};
	const isSearch = filter["filter[search]"] !== undefined;

	const bodyProps = useMemo(
		() => ({
			checked,
			data,
			filter,
			isSearch,
			onDelete,
			onChangeChecked,
			loading,
			onEdit,
			isAdmin,
			onCopyCreative,
			onPublicCteative,
			onPreview,
			platform
		}),
		[checked, data, filter, loading, platform]
	);

	const listPanel = useMemo(() => [<Body {...bodyProps} />, <Body {...bodyProps} isTemplate data={dataTemplate} />], [
		bodyProps,
		dataTemplate
	]);

	useEffect(() => {
		setChecked({});
	}, [currentTab]);

	const actionList = useMemo(() => {
		const find = creative_template_types?.find(
			el => el?.integration_type?.system_name === previewItem?.integration_type?.system_name
		);
		if (!find) return [];
		const findCallToAction = find?.fields?.find(el => el.system_name === "call_to_action");
		if (!findCallToAction) return [];
		return findCallToAction?.values?.map(el => ({ text: el.name, value: el.id }));
	}, [creative_template_types, previewItem]);

	const toTemplate = id => {
		if (!id) return;
		const findElem = data.find(el => el.id === +id);
		if (!findElem) return;
		history.push(
			PATHS.cabinet.creativeEdit.replace(":platform", findElem.integration_type.system_name).replace(":id", findElem.id)
		);
	};

	const deleteCreatives = useMemo(() => {
		const names = getTableItemsById(currentDeleteItems, data).map(el => el?.fields?.creative_name);
		const namesMap = names.join(", ");
		return (
			<>
				Creative{names.length > 1 && "s"}: <span className="small-text_bold">{namesMap} </span>
			</>
		);
	}, [currentDeleteItems]);

	const delteTitle =
		currentDeleteItems.length === 1
			? "Are you sure you want to delete selected creative?"
			: "Are you sure you want to delete selected creatives?";
	
	return (
		<>
			<div className="table">
				<Header
					title={<Tabs list={tabsList} value={currentTab} onChange={handleChangeTabs} />}
					toolbar={
						<Toolbar
							selected={checked}
							searchValue={filter["filter[search]"]}
							onChangeSearch={onChangeSearch}
							onChangeUnselect={onChangeUnselect}
							onChangeDelete={CREATIVE_ACTION_MAP[0] === actionName && onChangeDelete}
							elementList={
								(CREATIVE_ACTION_MAP[1] === actionName && [
									{
										order: 3,
										text: "Add to my creatives",
										loading: loading.copy.multiple,
										onClick: onCopyCreativeMultiple
									}
								]) ||
								null
							}
						/>
					}
				/>
				{
					(mounted && <>
						<TabPanel value={currentTab} index={0}>
							<Body {...bodyProps} />
						</TabPanel>
						<TabPanel value={currentTab} index={1}>
							<Body {...bodyProps} isTemplate data={dataTemplate} />
						</TabPanel>
					</>)
				}
				<Footer paginationChange={paginationChange} pagination={pagination} />
				{loading.table && (
					<CircularProgress color="#000" size={50} position="center" colorBg="rgba(0,0,0,0.03)" height="100%" />
				)}
			</div>
			<ConfirmDelete
				icon={<DeleteEmptyCreativeIcon />}
				title={delteTitle}
				text={deleteCreatives}
				loading={loading.delete}
				open={deleteModal}
				onClose={handleCloseDeleteModal}
				onAgree={onDeleteAgree}
			/>
			<ModalPreviewCreative
				isAdmin={isAdmin}
				isTemplate={CREATIVE_ACTION_MAP[1] === actionName}
				actionList={actionList}
				open={openPreview}
				loading={loading}
				onCopy={onCopyCreative}
				onPublicCteative={onPublicCteative}
				onClose={closePreviewModal}
				data={previewItem}
			/>
		</>
	);
};

export default TableCreative;