import React from "react";
import DataPickerBase from "./data-picker";
import moment from "moment";

const DataPicker = props => {
	const { name, form, showError, format, validateField, ...rest } = props;
	const handleChange = (e, value) => {
		form.setFieldValue(name, moment(value).format(format || "YYYY-MM-DD"));
	};
	if (form && Object.keys(form).length) {
		return (
			<DataPickerBase
				value={form.values[name]}
				onChange={handleChange}
				error={(form.touched[name] || showError) && Boolean(form.errors[name])}
				errorText={(form.touched[name] || showError) && form.errors[name]}
				name={name}
				format={format}
				form={form}
				{...rest}
			/>
		);
	} else {
		return <DataPickerBase name={name} format={format} {...rest} />;
	}
};
export default DataPicker;
