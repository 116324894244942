import React from "react";
import { CampaignCreatedIcon } from "../../../../../icons";
import ConfirmModal from "../../../../../components/modal/confirm-modal.jsx";

const ModalCampaignStart = ({ onClose, text, open, onAgree, loading, launch, name }) => {
	return (
		<div>
			<ConfirmModal
				open={open}
				title="Start campaign?"
				icon={<CampaignCreatedIcon />}
				isCloseloading={launch}
				loading={loading}
				onClose={onClose}
				onAgree={onAgree}
				text={
					<>
						{text || "Would you like to start this campaign:"} <br /> <strong>{name}</strong> ?
					</>
				}
				textAgree="Yes, Start"
				textClose="No, decline"
			/>
		</div>
	);
};

export default ModalCampaignStart;
