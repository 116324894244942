import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";
import { FormControl, InputLabel } from "../form-components";
import Autocomplete from "./autocomplete-base";
import AutocompleteAction from "./autocomplete-action";
import AutocompleteMultiple from "./autocomplete-multiple";
import cl from "classnames";

const useStyles = makeStyles({
	formControl: {
		"& .MuiInputBase-input": {
			color: "#3E4555"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#E8EAEF",
			transition: "border-color .3s"
		},
		"&:hover": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#56637B"
			}
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #CFD3DE"
		},
		"& .MuiOutlinedInput-root:hover": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "#CFD3DE"
			}
		},
		"& legend": {
			maxWidth: "0"
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			transform: "none"
		}
	},
	error: {
		"&.Mui-error": {
			color: "#EF5E39",
			fontSize: "16px"
		}
	},
	info: {
		marginLeft: "10px",
		fontSize: "0px",
		lineHeight: "0px",
		cursor: "pointer"
	}
});

export default function SelectBase(props) {
	const {
		name,
		label,
		error,
		variant,
		errorText,
		infoPlacement = "right",
		fullWidth,
		info,
		required,
		action,
		wrapClassName,
		multiple,
		...rest
	} = props;
	const classes = useStyles();
	return (
		<FormControl variant={variant} fullWidth={fullWidth} className={cl(classes.formControl, wrapClassName)}>
			<InputLabel name={name} info={info} infoPlacement={infoPlacement} required={required} label={label} />
			{(multiple && !action && (
				<AutocompleteMultiple name={name} action={action} error={error && Boolean(error)} {...rest} />
			)) ||
				(action && (
					<AutocompleteAction multiple={multiple} action={action} name={name} error={error && Boolean(error)} {...rest} />
				)) || <Autocomplete name={name} error={error && Boolean(error)} {...rest} />}
			{error && Boolean(error) && <FormHelperText error={true}>{errorText}</FormHelperText>}
		</FormControl>
	);
}
