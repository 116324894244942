import React from "react";
import AlertBase from "../../../../../uikit/alert";
import Button from "../../../../../uikit/button";
import classnames from "classnames";
import { Edit } from "../../../../../icons";

const CheckBlock = ({ title, editStep, itemsCheckList }) => (
	<div className="check-wrapper">
		<div className="check-top">
			<p className="main-text main-text_bold">{title}</p>
			{editStep && (
				<Button className="check-button" onClick={editStep}>
					<Edit />
				</Button>
			)}
		</div>
		{itemsCheckList &&
			itemsCheckList.map(({ hide, itemValue, error, render, itemName }, i) => {
				if (hide !== undefined && hide) {
					return null;
				}
				return (
					<div key={itemName || i} className="check-row">
						<div className="check-row__content">
							{!error && render}
							{itemName && <p className={classnames("main-text", "check-title", error && "error")}>{itemName}:</p>}
							{itemValue && (
								<p className={classnames("main-text", "check-value", error && "error")}>
									{(Array.isArray(itemValue) && itemValue.map(el => el.name).join(", ")) || itemValue}
								</p>
							)}
						</div>
						<AlertBase text={error} type="error" />
					</div>
				);
			})}
	</div>
);

export default CheckBlock;
