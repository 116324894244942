import React, { useLayoutEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserLayout from "../../../components/includs/user-layout";
import PageTitle from "../../../components/page-title";
import Table from "../../../components/table";
import actions from "../../../store/campaign/actions";
import ContentWithLoader from "../../../components/content-with-loader";
import PATHS from "../../../../PATHS";
import NotAnyActivateAccounts from "../../../components/notAnyActivateAccounts";
import columns from "./columns";
import { DeleteEmptyCreativeIcon } from "app/icons";
import menu from "./menu";
import actionIntegration from "app/store/integrations/actions";
import {hasAnyAdAcounts} from "app/helper";
import PauseModal from "./modals/pause-campaign";
import StartModal from "./modals/campaign-start";
import RelaunchModal from "./modals/campaign-relaunch";

const ScreenCampaigns = () => {
	const [actionItem, setActionItem] = useState({});
	const [startModalOpen, setStartModalOpen] = useState(false);
	const [pauseModalOpen, setPauseModalOpen] = useState(false);
	const [relaunchMoldaOpen, setRelaunchMoldaOpen] = useState(false);
	const { campaigns, actionLoading, deleteLoading, isLoading, filter, pagination } = useSelector(
		state => state.campaigns
	);
	const { adAccounts, adAccountsLoading } = useSelector(state => state.integrations);
	const dispatch = useDispatch();
	const history = useHistory();

	const {hasActiveAccounts, canCreateCampaign} = useMemo(()=>hasAnyAdAcounts(adAccounts),[adAccounts]);

	useLayoutEffect(() => {
		dispatch(actions.getCampaigns());
		dispatch(actionIntegration.getAdAccounts());
		return ()=>{
			dispatch(actions.resetTableParams());
		}
	}, [dispatch]);

	const toCampaign = item => {
		if (!item) return;
		history.push(
			PATHS.cabinet.campaingEdit.replace(":platform", item?.integration_type?.system_name).replace(":id", item.id)
		);
	};

	const toCreateCampaign = () => {
		history.push(PATHS.cabinet.createCampaing);
	};
	
	const handleChangeMenu = (id, key) => {
		const findElem = campaigns.items.find(el => el.id === +id);
		if (!findElem) return;
		switch (key) {
			case "Edit":
				return onEdit(findElem);
			case "Start":
				return onStart(findElem);
			case "Pause":
				return onPause(findElem);
			case "Relaunch":
				return onRelaunch(findElem);
			default:
				return;
		}
	};

	const hasData = (filter && filter["filter[search]"]) !== undefined || !!campaigns.items.length;

	const handleOpenModalRelaunch = () => {
		setRelaunchMoldaOpen(true);
	};
	
	const handleCloseModalRelaunch = () => {
		setRelaunchMoldaOpen(false);
	};

	const handleRelaunch = data => {
		dispatch(actions.restartCompaign(actionItem.id, data, handleCloseModalRelaunch, true));
	};

	const onPause = item => {
		setActionItem(item);
		handleOpenModalPause();
	};
	const handlePause = () => {
		dispatch(actions.pauseCompaign(actionItem.id, handleCloseModalPause, true));
	};
	const handleOpenModalPause = () => {
		setPauseModalOpen(true);
	};

	const handleCloseModalPause = () => {
		setPauseModalOpen(false);
	};

	const onEdit = item => {
		toCampaign(item);
	};

	const onRelaunch = item => {
		setActionItem(item);
		handleOpenModalRelaunch();
	};

	const onStart = item => {
		setActionItem(item);
		handleOpenModalStart();
	};

	const handleStart = () => {
		dispatch(actions.startCompaign(actionItem.id, handleCloseModalStart, true));
	};

	const handleOpenModalStart = () => {
		setStartModalOpen(true);
	};

	const handleCloseModalStart = () => {
		setStartModalOpen(false);
	};

	const columnsMapped = useMemo(() => {
		return columns({
			edit: onEdit,
			start: onStart,
			pause: onPause,
			restart: onRelaunch
		});
	}, []);

	const onRowClick = record => {
		toCampaign(record);
	};

	const hasAdAccont = hasActiveAccounts && hasData;

	return (
		<UserLayout>
			<PageTitle
				title="Campaigns"
				subtitle="Start your first experiments."
				showButton={hasAdAccont}
				buttonAction={toCreateCampaign}
				buttonDisabled={!canCreateCampaign}
				buttonText="Create campaign"
			/>
			<ContentWithLoader
				title="No campaigns have been created."
				subtitle="Create your first campaign. "
				content={
					<Link to={PATHS.cabinet.createCampaing} className="button button_medium button_gradient">
						Create new campaign
					</Link>
				}
				isLoading={isLoading || adAccountsLoading}
				hasData={hasAdAccont}
				placeholderComponent={ !hasActiveAccounts && <NotAnyActivateAccounts/>}
			>
				<Table
					title="Lists of campaigns"
					data={campaigns.items}
					columns={columnsMapped}
					pagination={pagination}
					action={actions}
					loading={isLoading}
					filter={filter}
					scroll={{ x: 1300 }}
					emptySearchText="No campaigns have been found by your request"
					onRowClick={onRowClick}
					hideElements={{
						delete: true,
						unSelect: true
					}}
					settingsData={{
						check: {
							hide: true
						},
						menu: {
							component: menu,
							onChange: handleChangeMenu
						}
					}}
					titleDelete="Delete campaign?"
					deleteIcon={<DeleteEmptyCreativeIcon />}
					deleteText={<> Campaign and reports about it will be deleted forever. <br/> Are you sure?</>}
					deleteLoading={deleteLoading}
				/>
			</ContentWithLoader>
			<StartModal
				open={startModalOpen}
				loading={actionLoading.start}
				onClose={handleCloseModalStart}
				onAgree={handleStart}
				name={actionItem.name}
			/>
			<PauseModal
				open={pauseModalOpen}
				loading={actionLoading.pause}
				onClose={handleCloseModalPause}
				onAgree={handlePause}
				name={actionItem.name}
			/>
			<RelaunchModal 
				open={relaunchMoldaOpen}
				loading={actionLoading.restart}
				onClose={handleCloseModalRelaunch}
				onSubmit={handleRelaunch}
				data={actionItem}
				currency={actionItem?.integration_ad_account?.currency}
			/>
		</UserLayout>
	);
};

export default ScreenCampaigns;
