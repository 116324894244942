import types from "./types";

const initialFilter = JSON.parse(localStorage.getItem('reportFilter'));

export const initialState = {
	reports: {

	},
	filter: {
		...initialFilter
	},
	isLoading: false,
	campaign: {
		count: 0,
		items: [],
		loaded: false
	},
	campaignLoading: false,
	campaignError: "",
	isLoadingReports: false,
	error: ""
};

const reducer = function (state = initialState, action) {
	switch (action.type) {
		case types.FETCH_GET_REPORT_REQUEST:
			return {
				...state,
				error: "",
				isLoading: true
			};
		case types.FETCH_GET_REPORT_SUCCESS:
			return {
				...state,
				reports: action.data,
				error: "",
				isLoading: false
			};
		case types.FETCH_GET_REPORT_FAILURE:
			return {
				...state,
				error: action.error,
				isLoading: false
			};
		case types.FETCH_GET_DEFAULT_REPORT_REQUEST:
			return {
				...state,
				error: "",
				isLoadingReports: true
			};
		case types.FETCH_GET_DEFAULT_REPORT_SUCCESS:
			return {
				...state,
				error: "",
				isLoadingReports: false
			};
		case types.FETCH_GET_DEFAULT_REPORT_FAILURE:
			return {
				...state,
				error: action.error,
				isLoadingReports: false
			};
		case types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_REQUEST:
			return {
				...state,
				campaign: {count: 0,items: [], loaded: state.campaign.loaded},
				campaignError: "",
				isLoading: false
			};
		case types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_SUCCESS:
			return {
				...state,
				campaign: {...action.data, loaded: true},
				campaignLoading: false,
			};
		case types.FETCH_GET_CAMPAIGN_BY_ADACCOUNT_FAILURE:
			return {
				...state,
				campaignError: action.error,
				campaignLoading: false
			};
		case types.FETCH_FILTER_REPORTS_SUCCESS:
			return {
				...state,
				filter: action.data
			};
		default:
			return state;
	}
};

export default reducer;
