import React from "react";
import style from "./style.module.scss";
import { Grid } from "@material-ui/core";
import cl from "classnames";

export const FilterContainer = ({ children, className, ...rest }) => (
	<Grid container className={cl(className, style.filterBlock)} {...rest}>
		{children}
	</Grid>
);
export const FilterItem = ({ children, className, ...rest }) => (
	<Grid item className={cl(className, style.filterBlock__item)} {...rest}>
		{children}
	</Grid>
);
