import React from "react";
import { Link } from "react-router-dom";
import UserLayout from "../../components/includs/user-layout";
import EmptyPlaceholder from "../../components/empty-placeholder";
import { NotFoundIcon } from "../../icons";
import Button from "app/uikit/button/active-button";
import localStore from "app/helper/local-store";
import PATHS from "../../../PATHS";
import config from "app/config";

const PageNotFound = () => {
	const token = localStore.getAccessToken();
	return (
		<UserLayout>
			<EmptyPlaceholder
				icon={<NotFoundIcon />}
				title="Oops! The page you were looking for doesn’t exist."
				subtitle="You may have misstyped the address or the page may have moved."
				content={
					<>
						<Button colorVariant="ghost" size="large" onClick={() => window.history.back()}>
							Go back
						</Button>
						{(token && (
							<Link className="button button_gradient button_large m-l--24" to={config.defaultPage.link}>
								{config.defaultPage.textRedirect}
							</Link>
						)) || (
							<Link className="button button_gradient button_large m-l--24" to={PATHS.auth.login}>
								Go to Login
							</Link>
						)}
					</>
				}
			/>
		</UserLayout>
	);
};

export default PageNotFound;