import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import DropZone from "app/components/dropzone";
import Input from "app/uikit/input";
import Button from "app/uikit/button/active-button";
import Modal from "app/components/modal";
import validationSchema from "./validationSchema";
import Error from "app/uikit/error";
import { getBase64Content, urlfileToObject } from "app/helper/helper.files";
import { useError } from "app/hooks/useErorrs";
import action from "app/store/categories-audience/actions";
import service from "app/store/categories-audience/services";
import Autocomplete from "app/uikit/autocomplete";

const CreateEditCategoryAudience = ({ data, onClose, open }) => {
	const { error, isLoading } = useSelector(state => state.categoriesAudience);
	const [initialImg, setInitialImg] = useState(null);
	const dispatch = useDispatch();

	const handleSubmit = async values => {
		const value = {
			...values,
			tag_ids: values.tag_ids.map(el => el.id),
			image: {
				name: values.image[0].name,
				content: await getBase64Content(values.image[0]),
				mime: values.image[0].type
			}
		};
		if (data) return dispatch(action.fetchEditCategoryAudience(data.id, value, onClose));
		dispatch(action.fetchCreateCategoryAudience(value, onClose));
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: data?.name || "",
			image: [],
			tag_ids: data?.tags?.map(elem => ({ id: elem.id, name: `${elem.name} (${elem.contacts_count})` })) || []
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useError(error.create || error.edit, formik.setErrors);

	useEffect(() => {
		const url = data?.image?.url;
		const name = data?.image?.name;
		if (!url && !name) return;
		urlfileToObject(url, name).then(res => {
			if (res.type === "error") return;
			setInitialImg([res]);
		});
	}, [data]);

	const resetForm = useCallback(() => {
		formik.resetForm();
		setInitialImg(null);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
		<Modal title={(data?.id && "Edit category") || "New category"} resetForm={resetForm} onClose={onClose} open={open}>
			<form onSubmit={formik.handleSubmit} noValidate>
				<Input fullWidth name="name" form={formik} label="Category name" required />
				<DropZone
					required
					fullWidth
					label="Category picture"
					type="imgs"
					name="image"
					form={formik}
					initialValue={initialImg}
					showInfoTextType={!formik.values.image?.length}
					infoTextType="You can upload 1 file up to 25 mb"
				/>
				<Autocomplete
					fullWidth
					multiple
					required
					action={service.getTags}
					name="tag_ids"
					form={formik}
					label="Category tags"
					formateData={data =>
						data.map(el => ({
							...el,
							name: `${el.name} (${el.contacts_count})`
						}))
					}
				/>
				<Error error={error.create} />
				<div className="modal-footer">
					<Button onClick={onClose} size="medium" colorVariant="ghost">
						Cancel
					</Button>
					<Button type="submit" loading={isLoading.create || isLoading.edit} size="medium" colorVariant="gradient">
						{(data?.id && "Edit") || "Save"}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default CreateEditCategoryAudience;
