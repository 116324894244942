export const getBase64Content = file =>
	new Promise((resolve, reject) => {
		const based = toBase64(file);
		based
			.then(res => {
				const arr = res.split("base64,");
				resolve(arr[1]);
			})
			.catch(e => {
				reject(e);
			});
	});

export const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});


const loadImage = url => {
	return new Promise((resolve,reg) => {
		const image = new Image();
		image.crossOrigin = "anonymous";
		image.addEventListener("load", () => {
			resolve(image);
		});
		image.addEventListener("error", (e) => {
			reg(e);
		});
		image.src = url;
	});
};

export const getParamsImage = async (file) => {
	var reader  = new FileReader();
	reader.readAsDataURL(file);
	const res  = await new Promise((res)=>{
		reader.onloadend = async () => {
			let img = await loadImage(reader.result);
			res(img);
		}
	});
	const height = res.naturalHeight;
	const width = res.naturalWidth;
	return {
		height,
		width,
		ratio: width / height
	}
}

export const urlfileToObject = async (url,name) => {
	try {
		const response = await fetch(url);
		const blob = await response.blob();
		return new File([blob], name, {
			type: blob.type,
		});
	} catch (e) {
		console.warn(e);
		return e;
	}
};