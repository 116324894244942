import { toast } from "react-toastify";
import CustomNotif from "../components/notification";

const autoCloseTimeout = 5000;

class Toastify {
	success = (title, txt, autoClose = autoCloseTimeout, delay = 0, position = toast.POSITION.RIGHT) => {
		toast.success(
			<CustomNotif variant="success" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};

	error = (title, txt, autoClose = autoCloseTimeout, delay = 0, position = toast.POSITION.RIGHT) => {
		toast.error(
			<CustomNotif variant="error" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};

	warn = (title, txt, autoClose = autoCloseTimeout, delay = 0, position = toast.POSITION.RIGHT) => {
		toast.warn(
			<CustomNotif variant="warn" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};

	info = (title, txt, autoClose = autoCloseTimeout, delay = 0, position = toast.POSITION.RIGHT) => {
		toast.info(
			<CustomNotif variant="info" title={title}>
				{txt}
			</CustomNotif>,
			{
				autoClose,
				position,
				delay
			}
		);
	};
}

const toastify = new Toastify();
export default toastify;
