import React, {useState} from "react";
import DesktopAdPreview from "app/components/ads-preview";
import style from "./style.module.scss";
import Button from "@material-ui/core/Button";
import classNames from "classnames/bind";


const TemplatePost = ({ className, description, banner, webSite, headline, nameAction, loadingImg }) => {
	const [isMobile, setIsMobile] = useState(false);

	const cx = classNames.bind(style);

	const handleChange = (value) => {
		setIsMobile(value);
	};

	return (
		<div className={className}>
			<div className={style['template-tabs']}>
				<Button
					className={cx("template-tabs__button", {active: !isMobile} )}
					onClick={() => handleChange(false)}
				>
					Desktop
				</Button>
				<Button
					className={cx("template-tabs__button", {active: isMobile} )}
					onClick={() => handleChange(true)}
				>
					Mobile
				</Button>
			</div>

			<div className={style.template_wrapper}>
				<DesktopAdPreview
					isMobile={isMobile}
					description={description}
					banner={banner}
					webSite={webSite}
					headline={headline}
					nameAction={nameAction}
					loadingImg={loadingImg}
				/>
			</div>
		</div>
	);
};

export default TemplatePost;
