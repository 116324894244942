import React from "react";
import Creative from "./creative";
import style from "./style.module.scss";
import EmptyData from "app/uikit/table/EmptyData";
import EmptySearch from "app/uikit/table/emptySearch";
import {EmptyCreative} from "../../componentsEmpty";
import cl from "classnames";

const BodyTableCreative = ({ platform,isTemplate, isSearch, emptySearchText, data, loading, ...rest }) => {
	return (
		<div className={cl(style.body, !data?.length && style.body_empty)}>
			{(data?.length && data.map(elem => <Creative key={elem.id} data={elem} loading={loading} isTemplate={isTemplate} {...rest} />)) ||
				(isSearch && (
					<EmptySearch
						text={
							<>
								No Creatives have been found <br /> by your request.{" "}
							</>
						}
					/>
				)) || isTemplate &&  <EmptyData text="No templates available right now." /> || !loading.table && <EmptyCreative platform={platform} /> }
		</div>
	);
};

export default BodyTableCreative;