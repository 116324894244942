import React from "react";
import {
	CheckIcon,
	WarningIcon
} from "../../icons";
import BaseAvatar from "../../components/avatar";

import {getIcon} from './helper';

const IntegrationsCard = props => {
	const { variant, allAccounts, activeAcount, connectedAccounts, button, comingSoon, title } = props;

	const hasAccount = !!allAccounts?.length;

	const connected =
		(connectedAccounts !== null || connectedAccounts !== undefined) && isNaN(Number(connectedAccounts)) ? false : true;

	const inactive = !hasAccount || comingSoon || !connected;

	const icon = getIcon(variant, inactive);
	
	const noWarning = activeAcount === connectedAccounts;

	return (
		<div className={`card card_${variant} ${!hasAccount && "card_inactive"}`}>
			<div className="card-header">
				<div className={`card-icon ${!hasAccount && "card-icon_inactive"}`}>{icon}</div>
				{hasAccount && (
					<BaseAvatar isGroup max={5}>
						{allAccounts.map(el => ({
							id: el.facebook_account_id,
							name: el.full_name,
							src: el.avatar_url
						}))}
					</BaseAvatar>
				)}
			</div>
			<div className="card-content">
				<p className="title-subheading title-subheading_bold">{title}</p>
				{(hasAccount && activeAcount !== undefined && (
					<p className="card-connected-accounts small-text">
						{(noWarning && (
							<>
								<CheckIcon />
								<span>{connectedAccounts} Ad accounts connected</span>
							</>
						)) || (
							<>
								<WarningIcon />
								<span>
									{connectedAccounts}/{activeAcount} Ad accounts connected
								</span>
							</>
						)}
					</p>
				)) ||
					null}
				{!hasAccount && <p className="card-connected-accounts small-text">No accounts connected</p>}
				{comingSoon && <p className="card-connected-accounts small-text">Coming soon</p>}
				<div className="card-footer">{button}</div>
			</div>
		</div>
	);
};

export default IntegrationsCard;
