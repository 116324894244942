import { all, fork } from "redux-saga/effects";
import { authSagas } from "./auth";
import { employeesSagas } from "./employees";
import { peopleSagas } from "./people";
import { handBooksSagas } from "./handBooks";
import { integrationsSagas } from "./integrations";
import { userSagas } from "./user";
import { creativesSagas } from "./creatives";
import { usersSagas } from "./users";
import { campaignsSagas } from "./campaign";
import { reportsSagas } from "./reports";
import { uploadAuciencesSagas } from "./uploadAuciences";
import { appSettingsSagas } from "./appSettings";
import { categoriesAudienceSagas } from "./categories-audience";
import { generalSagas } from "./general";
import { dashboardSagas } from "./dashboard";

export default function* rootSaga(dispatch) {
	yield all([fork(authSagas, dispatch)]);
	yield all([fork(userSagas, dispatch)]);
	yield all([fork(employeesSagas, dispatch)]);
	yield all([fork(peopleSagas, dispatch)]);
	yield all([fork(handBooksSagas, dispatch)]);
	yield all([fork(integrationsSagas, dispatch)]);
	yield all([fork(creativesSagas, dispatch)]);
	yield all([fork(usersSagas, dispatch)]);
	yield all([fork(campaignsSagas, dispatch)]);
	yield all([fork(reportsSagas, dispatch)]);
	yield all([fork(uploadAuciencesSagas, dispatch)]);
	yield all([fork(appSettingsSagas, dispatch)]);
	yield all([fork(categoriesAudienceSagas, dispatch)]);
	yield all([fork(generalSagas, dispatch)]);
	yield all([fork(dashboardSagas, dispatch)]);

}