import React, { useEffect } from "react"; 
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Modal from "app/components/modal";
import validationSchema from "./validationSchema";
import ButtonActive from "app/uikit/button/active-button";
import Input from "app/uikit/input";
import { Grid } from "@material-ui/core";
import Select from "app/uikit/select";
import Button from "app/uikit/button/active-button";
import action from "app/store/employees/actions";
import Error from "app/uikit/error";
import { handlerErrors } from "app/helper/handlerErrors";
import config from "app/config";

const AddNewEmployee = ({ onClose, open, data = {}, isEdit }) => {
	const { handBooks:{employee_roles}, employees:{actionUserLoading, actionedUser, actionUserError} } = useSelector(state=>state);
	const dispatch = useDispatch();
	const mappedRoles = employee_roles?.map(elem => ({ text: elem.name, value: elem.id }));
	const notEmptyData = data && Object.keys(data).length;

	useEffect(() => {
		if (actionedUser) onClose();
	}, [actionedUser]);

	const handleSubmit = values => {
		if (notEmptyData && isEdit) {
			dispatch(action.fetchEditEmployee(values, data.id));
		} else {
			dispatch(action.fetchNewEmployee(values));
		}
	};

	const initialValues = () => {
		let initData = {
			name: "",
			surname: "",
			email: "",
			role_id: config.roleDefault
		};
		if (notEmptyData && isEdit) {
			initData.name = data?.name || "";
			initData.surname = data?.surname || "";
			initData.email = data?.email || "";
			initData.role_id = data?.role?.id || config.roleDefault;
		}
		return initData;
	};

	const formik = useFormik({
		initialValues: initialValues(),
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (!open) formik.resetForm();
	}, [open, formik.resetForm]);

	useEffect(() => {
		handlerErrors(actionUserError, formik.setErrors);
	}, [actionUserError, formik.setErrors]);

	const getTextSendButton = () => {
		if (isEdit) {
			return "Save";
		} else {
			return "Add employee";
		}
	};

	const getTitle = () => {
		if (isEdit) {
			return "Editing employee";
		} else {
			return "Add new employee";
		}
	};

	return (
		<div>
			<Modal title={getTitle()} onClose={onClose} open={open}>
				<form onSubmit={formik.handleSubmit} noValidate>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<Input fullWidth name="name" label="Name" form={formik} required />
						</Grid>
						<Grid item md={6} xs={12}>
							<Input fullWidth name="surname" label="Surname" form={formik} required />
						</Grid>
					</Grid>
					<Input fullWidth name="email" label="Email" type="email" form={formik} required />

					<Select fullWidth list={mappedRoles} label="Company role" name="role_id" form={formik} required />

					<Error error={actionUserError} />

					<div className="modal-footer">
						<Button onClick={onClose} size="medium" colorVariant="ghost">
							Cancel
						</Button>
						<ButtonActive
							size="medium"
							colorVariant="gradient"
							type="submit"
							loading={actionUserLoading}
						>
							{getTextSendButton()}
						</ButtonActive>
					</div>
				</form>
			</Modal>
		</div>
	);
};

export default AddNewEmployee;
