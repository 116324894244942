import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import actions from "app/store/auth/actions";
import validationSchema from "./validationSchema";
import Button from "app/uikit/button/active-button";
import Passwords from "app/components/passwords";
import { parseQuery } from "app/helper";
import { handlerErrors } from "app/helper/handlerErrors";
import Error from "app/uikit/error";
import PATHS from "PATHS";

const NewPassword = () => {
	const history = useHistory();
	const { isLoading, error } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const handleSubmit = values => {
		const params = parseQuery(window.location.search);
		dispatch(actions.fetchNewPasswordRequest({ ...values, code: params.code }, history));
	};

	const formik = useFormik({
		initialValues: {
			password: "",
			password_confirmation: ""
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			handleSubmit(values);
		}
	});

	useEffect(() => {
		handlerErrors(error, formik.setErrors);
	}, [error, formik.setErrors]);

	return (
		<div className="login-box">
			<h1 className="title-main m-b--16">New password</h1>
			<form className="user-form" onSubmit={formik.handleSubmit}>
				<Passwords form={formik} />
				<Error error={error} />
				<Button type="submit" loading={isLoading} fullWidth className="before-button-space" size="large" colorVariant="gradient">
					Create new password
				</Button>

				<div className="text-centered m-t--24">
					<Link to={PATHS.auth.login} className="orange-link">Back to Login</Link>
				</div>
			</form>
		</div>
	);
};

export default NewPassword;
